import React from 'react'
import { Backdrop, Box, Fade, Modal } from '@material-ui/core'

import cross from 'assets/icons/cross.svg'
import { useStyles } from '../useStyles'

export default function ModalLayout({ open, setOpen, children, closeAll }) {
  const classes = useStyles()
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeAll ? closeAll : handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box className={classes.modalMainContainer}>
            <Box className={classes.modalmainContent}>
              <img
                alt=""
                src={cross}
                onClick={closeAll ? closeAll : handleClose}
                className={classes.closeIcon}
              />
              {children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
