/* 
  Axios utility to include the authorization token by default
*/
import _axios from 'axios'

let axios = null
let authTokenPresent = false

/**
 * Default axios instance for public components, for which axios instance with authentication
 * token will not be initialized
 */
const defaultAxios = _axios.create({
  headers: {
    Authorization: 'Bearer null' // set authToken value to be "null" instead of not sending the Authorization header
  }
})

/**
 * Initialize a axios instance with default parameters
 * @param {object} axiosConfig Default parameters for a axios request
 */
export const initiateAxiosInstance = ({ authToken } = {}) => {
  authTokenPresent = true
  axios = _axios.create({
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
}

/**
 * Return the axios instance, which contains the authorization headers by default
 * (Remember to initialize the instance before using it)
 */
/**
 *
 * @returns {import('axios').AxiosInstance}
 */
export const getAxiosInstance = () => axios || defaultAxios
export const isAuthTokenPresent = () => authTokenPresent
