import React, { useState, useEffect, useCallback } from 'react'
import ReactTooltip from 'react-tooltip'
import { OxbowDarkButton } from 'components/Helpers/Buttons'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useNavContext } from 'components/Helpers/Navbar'
import useMediaQueries from './useMediaQueries'

export default function useInfiniteScroll({
  itemsPerScroll,
  totalItems,
  scrollValue = 1
}) {
  const [numScroll, setNumScroll] = useState(
    Math.ceil(totalItems / itemsPerScroll)
  )
  const [currentScrollValue, setCurrentScrollValue] = useState(scrollValue)
  const [startValue, setStartValue] = useState(1)
  const [endValue, setEndValue] = useState(0)

  useEffect(() => {
    setNumScroll(Math.ceil(totalItems / itemsPerScroll))
  }, [itemsPerScroll, totalItems])

  useEffect(() => {
    setStartValue(1)
    setEndValue(
      itemsPerScroll * currentScrollValue > totalItems
        ? totalItems
        : itemsPerScroll * currentScrollValue
    )
  }, [currentScrollValue, itemsPerScroll, totalItems])

  return [
    numScroll,
    startValue,
    endValue,
    setCurrentScrollValue,
    currentScrollValue,
    itemsPerScroll
  ]
}

export const LoadMoreBtn = ({ disabled, onMore }) => {
  // extract `userPermissions` from Auth0Context
  const { userPermissions } = useAuth0()
  const { setSignupOpen } = useNavContext()
  const isUserAllowedToLoad = (userPermissions || []).includes('use:load-more')

  // if user is allowed to load and the button is not disabled for other reasons
  const canUserLoad = isUserAllowedToLoad && !disabled
  const { downSm: isMobileDevice } = useMediaQueries()

  const showSignUpModal = useCallback(() => {
    setSignupOpen(true)
  }, [setSignupOpen])

  return (
    <div
      data-tip="scrollBtnToolTip"
      data-for="scrollBtnToolTip"
      onClick={!canUserLoad ? showSignUpModal : null}
      style={{ textAlign: isMobileDevice && 'center' }}
    >
      <OxbowDarkButton disabled={!canUserLoad} onClick={onMore} size="small">
        LOAD MORE PRODUCTS
      </OxbowDarkButton>
      {!canUserLoad && (
        <ReactTooltip id="scrollBtnToolTip" className="customTooltip">
          Click to access full Magellan content
        </ReactTooltip>
      )}
    </div>
  )
}
