import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    color: theme.palette.primary.dark,
    maxWidth: 250,
    fontSize: 13,
    fontFamily: 'Open Sans',
    border: '1px solid #dadde9',
    borderRadius: 'unset'
  }
}))(Tooltip)

export default function OxbowToolTip(props) {
  return (
    <HtmlTooltip
      placement="right"
      title={<React.Fragment>{props.tip}</React.Fragment>}
    >
      {props.children ? props.children : null}
    </HtmlTooltip>
  )
}
