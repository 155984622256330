import React, { useState, useEffect, useCallback } from 'react'
import { CircularProgress, Fade } from '@material-ui/core'
import classnames from 'classnames'
import { useFilterStateContext } from '../../Contexts/FilterStateContext'
import { OxbowDarkButton } from '../Buttons'
import SearchAutoSuggest from './AutoSuggest'
import useDebounce from 'utils/customHooks/useDebounce'
import { useRef } from 'react'
import { useStyles } from './useStyles'
import SearchIcon from '@material-ui/icons/Search'
import { getClearFilterSectionState } from 'utils/FilterBootstrapUtils'
import { useHistory } from 'react-router'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'

export default function SearchBar({
  marginTop,
  size,
  showSearchIcon,
  placeholder,
  width,
  ml,
  maxHeight
}) {
  const classes = useStyles({
    marginTop,
    size,
    showSearchIcon,
    width,
    ml
  })
  const history = useHistory()

  const {
    setFilterValues,
    filterValues,
    bootstrappingFilters,
    filterConfig,
    isSavedSearchModalOpen,
    setFreeUserSelectedFilters,
    sendFreeUserFilters,
    isUnlimitedSearches,
    freeUserFilters,
    selectedFilters
  } = useFilterStateContext()
  const { setAllCompaniesPage } = useSearchPaginationContext()

  const [value, setValue] = useState(filterValues.key)
  const debouncedValue = useDebounce(value, 500)
  const showAutoSuggestions = useRef(false)

  useEffect(() => {
    if (bootstrappingFilters) return
    if (filterValues['key'] !== undefined) {
      setValue(_value =>
        _value !== filterValues['key'] ? filterValues['key'] : _value
      )
    }
  }, [filterValues, bootstrappingFilters])

  const sendToSearchPage = useCallback(() => {
    if (history.location.pathname !== '/search') history.push('/search')
  }, [history])

  const search = useCallback(
    (filterValues = {}) => {
      showAutoSuggestions.current = false
      setAllCompaniesPage(1)
      setFilterValues(prev => ({
        ...prev,
        ...filterValues,
        key: value
      }))
      sendToSearchPage()
    },
    [value, setFilterValues, sendToSearchPage, setAllCompaniesPage]
  )

  const setValueFromUserInput = useCallback(e => {
    showAutoSuggestions.current = true
    setValue(e.target.value)
  }, [])

  const setValueFromSuggestionsBox = useCallback(
    _value => {
      showAutoSuggestions.current = false
      setValue(_value)
      setAllCompaniesPage(1)
      sendToSearchPage()
      setFilterValues(prev => ({
        ...prev,
        key: _value
      }))
    },
    [setFilterValues, sendToSearchPage, setAllCompaniesPage]
  )
  const handleEnterPress = e => {
    if (e.keyCode === 13 || !e.keyCode || e.keyCode === ' ') {
      if (!isUnlimitedSearches) {
        sendFreeUserFilters({ ...freeUserFilters, key: value })
        setAllCompaniesPage(1)
        setFreeUserSelectedFilters({ ...freeUserFilters, key: value })
      } else {
        applyFilters()
        const clearedFilterValues = {
          ...getClearFilterSectionState(filterConfig['productFilters']),
          ...getClearFilterSectionState(filterConfig['companyFilters']),
          category: { supplier: false, distributor: false, other: false }
        }

        search(clearedFilterValues)
      }
    }
  }

  const applyFilters = () => {
    sendFreeUserFilters({
      ...freeUserFilters,
      key: value
    })
    setAllCompaniesPage(1)
    setFreeUserSelectedFilters(selectedFilters)
  }

  return (
    <div className={classes.inputMainContainer}>
      <div className={classnames(classes.root)}>
        <div
          className={classnames(classes.inputContainer, {
            disabled: bootstrappingFilters
          })}
        >
          <input
            value={value || ''}
            onChange={setValueFromUserInput}
            placeholder={
              placeholder ? placeholder : 'Search e.g cyber risk analytics'
            }
            name="searchkey"
            type="text"
            onKeyDown={handleEnterPress}
            disabled={bootstrappingFilters}
            autoComplete={'off'}
          />

          {bootstrappingFilters && (
            <CircularProgress
              style={{ position: 'absolute', top: 13, right: 75 }}
              color="primary"
              size={20}
            />
          )}
        </div>
        {value && value.length >= 2 && showAutoSuggestions.current && (
          <Fade in>
            <div className={classes.autoSuggestContainer}>
              <SearchAutoSuggest
                searchQuery={debouncedValue}
                immediateSearchQuery={value}
                setInputValue={setValueFromSuggestionsBox}
                onFilterSelect={() => {
                  showAutoSuggestions.current = false
                }}
                maxHeight={maxHeight}
              />
            </div>
          </Fade>
        )}
        <OxbowDarkButton
          disabled={isSavedSearchModalOpen}
          onClick={handleEnterPress}
          size={size ? size : 'md'}
          className={classes.searchBtn}
          startIcon={<SearchIcon color="inherit" size="18px" />}
        />
      </div>
    </div>
  )
}
