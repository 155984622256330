import React, { useEffect, useMemo, useState } from 'react'
import { Box, Typography, Grid, CircularProgress } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { useStyles } from './useStyles'
import { getQueryResults } from 'utils/api'
import { getSubtitle } from 'components/Helpers/ProductTile/SubTitle'
import { getStageDetails } from 'utils'
import Chip from 'components/Helpers/Inputs/ChipInput/Chips'
import SearchIcon from '@material-ui/icons/Search'
import { useNavContext } from 'components/Contexts/NavbarContext'
import { useAuth0 } from 'components/Contexts/AuthContext'
import analytics from 'utils/analytics'
import { useQueryStringValues } from 'components/Contexts/QueryStringContext'
import { useProductPageContext } from 'components/Pages/NewProductDetails'
import { OxbowPrimaryTransparentButton } from 'components/Helpers/Buttons'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'
import { getSelectedFilters } from 'components/Pages/SearchResults/Pagination'
import staticFilterConfig from 'configs/filters.config'

export default function SimilarProducts({ productValues }) {
  const { isAuthenticated } = useAuth0()
  const classes = useStyles()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [start, setStarting] = useState(false)
  const { setSignupOpen } = useNavContext()
  const [noProductFound, setNoProductFound] = useState(false)
  const { itcOnly } = useQueryStringValues()
  const productPageContextValue = useProductPageContext()

  const {
    sendFreeUserFilters,
    filterValues,
    setFreeUserSelectedFilters,
    suggestInputsFiltersData
  } = useFilterStateContext()
  const { setAllCompaniesPage } = useSearchPaginationContext()

  const productData = useMemo(() => {
    if (productPageContextValue && productPageContextValue.productData) {
      return productPageContextValue.productData
    } else {
      return productValues
    }
  }, [
    productValues,
    productPageContextValue.productData,
    productPageContextValue
  ])
  const history = useHistory()

  const [similarProductPayload, setSimilarProductPayload] = useState({})
  useEffect(() => {
    if (!productData) return

    if (start) {
      return
    }
    setStarting(true)
    ;(async () => {
      let payload
      const getLabels = data => data?.map(item => item.label)
      if (productData?.companyType[0] === 'Supplier') {
        payload = {
          must_not: {
            pId: productData.id,
            cId: productData?.company?.sys?.id
          },
          filters: {
            companyType: 'Supplier',
            primaryFunction: productData.primaryFunction,
            secondaryFunction: getLabels(productData.secondaryFunction),
            targetClient: getLabels(productData.targetClient),
            geographiesWithImplementations:
              productData.geographiesWithImplementations,
            companyStage: productData.company?.fields?.companyStage,
            hq: productData?.company?.fields?.hq
          }
        }
      } else {
        payload = {
          must_not: {
            pId: productData.id,
            cId: productData?.company?.sys?.id
          },
          filters: {
            companyType: 'Distributor',
            businessModel: productData.businessModel,
            insuranceClass: getLabels(productData.insuranceClass),
            insuranceSubclass: getLabels(productData.insuranceSubclass),
            companyStage: productData.company?.fields?.companyStage
          }
        }
      }

      const queryStringParameters = {
        isFor: 'similar_product'
      }

      // Get the Results
      const { products } = await getQueryResults(
        '',
        payload,
        queryStringParameters,
        itcOnly
      )

      // Set the Data to product state variable products
      products.length < 1 && setNoProductFound(true)
      const payloadFilters = payload.filters
      if (payloadFilters.hq) {
        delete Object.assign(payloadFilters, {
          ['headquaters']: payloadFilters['hq']
        })['hq']
      }
      const companyStageData = [
        {
          label: 'Company Stage',
          value: 'stage1'
        },
        {
          label: 'Scale Up',
          value: 'stage2'
        },
        {
          label: 'Established',
          value: 'stage3'
        },
        {
          label: 'Large Enterprise',
          value: 'stage4'
        }
      ]
      const stages = []
      if (payloadFilters.companyStage) {
        companyStageData.forEach(item => {
          payloadFilters.companyStage.forEach(stage => {
            if (item.label === stage) {
              stages.push(item.value)
            }
          })
        })
      }

      setSimilarProductPayload({
        ...payload,
        filters: { ...payloadFilters, companyStage: stages }
      })
      setProducts(products)
      setLoading(false)
    })()
  }, [isAuthenticated, productData, itcOnly, start])

  if (noProductFound) {
    return (
      <Grid container justify="center">
        No results
      </Grid>
    )
  }

  const similarProductsFilters = () => {
    const filters = {}
    if (similarProductPayload?.filters) {
      for (const [key, value] of Object.entries(
        similarProductPayload.filters
      )) {
        filters[key] = value ?? []
      }
    }

    return filters
  }
  const filterDetails = {
    ...suggestInputsFiltersData,
    ...staticFilterConfig.categoryFilters.filters
  }

  const selectedSimilarFilters = () => {
    if (Object.keys(filterValues).length > 0) {
      return getSelectedFilters(similarProductsFilters(), filterDetails)
    }
  }

  return (
    <Box
      mt={3}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      {loading ? (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {products.map(product => {
            const productTypeTag = getSubtitle(product)
            const companyStageDetails = getStageDetails(product?.company?.stage)
            return (
              <Grid
                container
                key={product?.id}
                wrap="wrap"
                justify={
                  products?.length > 2 ? 'space-between' : 'space-evenly'
                }
                className={classes.productTileRoot}
                alignItems="center"
              >
                {product?.logo && (
                  <Grid item className={classes.imageWrapper}>
                    <img
                      className={classes.img}
                      alt="complexf"
                      src={
                        product?.logo ||
                        'https://dummyimage.com/200/cccccc/ffffff.jpg?text=Logo'
                      }
                    />
                  </Grid>
                )}
                <Grid item xs>
                  <Box>
                    <Box>
                      {isAuthenticated ? (
                        <Link
                          to={{
                            pathname: `/product/${product?.slug}`,
                            state: {
                              breadcrumb: {
                                key: 'productName',
                                label: product?.name
                              }
                            }
                          }}
                          replace
                          className={classes.link}
                        >
                          <Grid container alignItems="center">
                            <Typography
                              variant="h6"
                              className={classes.productName}
                              onClick={() =>
                                analytics.createEvent({
                                  category: 'ProductDetails',
                                  action: 'Similar Product clicked',
                                  label: `from ${
                                    window.location.pathname.split('/')[2]
                                  } to ${product?.slug}`
                                })
                              }
                            >
                              {product?.name}
                            </Typography>
                            {!product.company.isActive && (
                              <Typography
                                variant="h6"
                                className={classes.isCompanyActive}
                              >
                                Inactive
                              </Typography>
                            )}
                          </Grid>
                        </Link>
                      ) : (
                        <Typography
                          variant="h6"
                          onClick={() => setSignupOpen(true)}
                          className={classes.productName}
                        >
                          {product?.name}
                        </Typography>
                      )}

                      <p className={classes.companyName}>
                        {`By ${product?.companyName || product?.company.name}`}
                        {` , ${product?.company?.hq[0]}`}
                      </p>
                    </Box>
                    <Box pt={2}>
                      <Typography variant="h5" className={classes.clipTextY}>
                        {product.description}
                      </Typography>
                    </Box>
                    <Box mb={3} className={classes.chipsWrapper} mt={3}>
                      <Box mr={1}>
                        {productTypeTag && (
                          <Chip color="secondary" className="chip">
                            {productTypeTag}
                          </Chip>
                        )}
                      </Box>
                      {companyStageDetails && (
                        <Chip
                          className="chip"
                          label={companyStageDetails.label}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )
          })}
          <OxbowPrimaryTransparentButton
            fontSize="md"
            height="lg"
            width="max-content"
            onClick={() => {
              sendFreeUserFilters({
                ...filterValues,
                ...similarProductsFilters()
              })
              setAllCompaniesPage(1)
              setFreeUserSelectedFilters(selectedSimilarFilters())

              analytics.createEvent({
                category: 'ProductDetails',
                action: 'View All Similar Products',
                label: `from ${window.location.pathname.split('/')[2]}`
              })

              history.push({
                pathname: '/search',
                search: `?filters=${encodeURIComponent(
                  JSON.stringify(similarProductsFilters())
                )}`
              })
            }}
            startIcon={<SearchIcon color="primary" size="18px" />}
          >
            Search more similar companies
          </OxbowPrimaryTransparentButton>
        </>
      )}
    </Box>
  )
}
