import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import './index.scss'

const Toggle = ({ show, setShow, label }) => {
  const onToggle = () => setShow(!show)

  return (
    <Grid
      container
      justify="space-between"
      style={{
        marginBottom: 20
      }}
    >
      <Typography
        variant="subtitle2"
        style={{
          fontWeight: 600,
          marginTop: 2
        }}
      >
        {label}
      </Typography>
      <label className="toggle-switch">
        <input type="checkbox" checked={show} onChange={onToggle} />
        <span className="switch">
          <span
            className={clsx('value', {
              selectedValue: show
            })}
          />
          <span
            className={clsx('value', {
              selectedValue: !show
            })}
          />
        </span>
      </label>
    </Grid>
  )
}
export default Toggle
