import React, { useState } from 'react'

import {
  Box,
  useMediaQuery,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core'
import ProfileBadge from 'components/Helpers/UserProfile/ProfileBadge'
import { OxbowTextButton } from '../Buttons'
import { useAutocomplete } from '@material-ui/lab'
import clsx from 'clsx'

import { ArrowDropDown } from '@material-ui/icons'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { makeStyles } from '@material-ui/styles'
import Logout_icon from 'assets/icons/Logout_icon.png'

import ProfileIcon from 'assets/icons/ProfileIcon.png'
import { Link } from 'react-router-dom'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'
import { userName, userNameAccronym } from 'utils'

const useStyles = makeStyles(theme => ({
  authBlock: {
    width: 'unset',
    borderBottom: '3px solid transparent',
    paddingBottom: 10,
    '& button': {
      fontSize: 16,
      fontWeight: 700
    }
  },
  inputContainer: {
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    '& div': {
      alignItems: 'center',
      display: 'flex'
    },
    '& span': {
      fontSize: '16px',
      left: '10px'
    },
    '& svg': {
      cursor: 'pointer'
    }
  },
  hidden: {
    width: 'max-content',
    right: 0,
    marginTop: '2px',
    padding: theme.spacing(2),
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.primary.lighterDark,
    overflow: 'auto',
    maxHeight: '250px',
    zIndex: 3,
    color: 'black',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  listBox: {
    paddingLeft: 26,
    backgroundColor: 'white',
    '& a': {
      textDecoration: 'none',
      color: 'black'
    },
    '& li': {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      textTransform: 'none',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      padding: theme.spacing(1, 0),
      '& img': {
        marginRight: 15
      }
    },

    '& li[data-focus="true"]': {
      cursor: 'pointer'
    }
  },

  mainInputContainer: {
    '& input': {
      color: '#fff',
      textTransform: 'uppercase',
      zIndex: 4,
      backgroundColor: 'transparent',
      position: 'relative',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontSize: '16px',
      boxShadow: 'unset',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '-webkit-border-radius': 'unset',
      appearance: 'none',
      cursor: 'pointer',
      border: 'none',
      marginTop: 0,
      [theme.breakpoints.down(1248)]: {
        fontSize: 14
      }
    },

    scrollbarColor: 'white'
  },
  userNameMenu: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    [theme.breakpoints.down(1248)]: {
      marginBottom: 10
    }
  },
  userNameAccronym: {
    backgroundColor: theme.palette.primary.contrastText,
    width: 40,
    height: 40,
    borderRadius: '50%',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 14
  },
  userNameInfo: {
    display: 'flex'
  },
  toolbarList: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    '& li': {
      cursor: 'pointer',
      textTransform: 'uppercase',
      '& a': {
        display: 'inline-block',
        fontFamily: 'Open Sans',
        color: 'white',
        fontSize: '16px',
        fontWeight: 'lighter',
        borderBottom: '2px solid transparent',
        paddingBottom: 10,
        '&:hover': {
          borderBottom: '2px solid ' + theme.palette.secondary.main,
          color: theme.palette.secondary.main
        },
        [theme.breakpoints.down(1248)]: {
          fontSize: 14
        }
      }
    },
    '& li a.selected': {
      color: theme.palette.secondary.main,
      borderBottom: '2px solid ' + theme.palette.secondary.main
    },
    '& li:not(:first-child)': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& li': {
        borderBottom: '1px solid #494340',
        position: 'relative',
        color: 'white',
        padding: '14px 0 !important',
        '& a': {
          fontSize: '18px !important',
          fontWeight: '500 !important',
          fontFamily: theme.typography.secondaryFont,
          lineHeight: '1.3',
          textDecoration: 'none',
          display: 'block',
          textTransform: 'none'
        },
        '& .title': {
          fontSize: '18px !important',
          fontWeight: '500 !important',
          textTransform: 'none'
        }
      },
      '& li:not(:first-child)': {
        margin: 0
      }
    }
  }
}))

export const NavAuthButtons = () => {
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    loading,
    user
  } = useAuth0()
  const classes = useStyles()
  const { personalData } = useCustomisationJourney()
  const isMobileDevice = useMediaQuery('(max-width:600px)')
  const [open, setOpen] = useState(false)

  const selectOptions = [
    {
      value: 'Account information',
      func: () => {},
      img: ProfileIcon,
      path: '/personal-account'
    },
    { value: 'Logout', func: () => logout(), img: Logout_icon }
  ]
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    focused
  } = useAutocomplete({
    options: selectOptions,
    getOptionLabel: option => option.value
  })

  const profileMenu = () => {
    return (
      <Box className={classes.mainInputContainer}>
        <Grid item className={classes.userNameMenu}>
          <div className={classes.userNameAccronym}>
            {userNameAccronym(user.name, userName(personalData))}
          </div>
          <div>
            <div>
              <Box position="relative">
                <div
                  {...getRootProps()}
                  ref={setAnchorEl}
                  open={open}
                  className={clsx(classes.inputContainer, {
                    focused
                  })}
                  onClick={() => setOpen(true)}
                >
                  <div {...getInputProps()}>
                    <Typography
                      className={classes.selectedValue}
                      component="span"
                      variant="inherit"
                    >
                      {userName(personalData)
                        ? userName(personalData)
                        : user.name}
                    </Typography>
                    <ArrowDropDown
                      fontSize="small"
                      onClick={() => setOpen(true)}
                    />
                  </div>
                </div>
                {open && groupedOptions.length > 0 && (
                  <ul
                    {...getListboxProps()}
                    className={`${classes.listBox} ${classes.hidden}`}
                  >
                    {groupedOptions.map((option, index) => {
                      const itemProps = getOptionProps({
                        option,
                        index
                      })
                      return (
                        <React.Fragment key={option.value}>
                          {option.value !== 'Logout' ? (
                            <Link
                              to={option.path}
                              onClick={() => setOpen(false)}
                            >
                              <li
                                {...itemProps}
                                key={option.value}
                                id={index}
                                onClick={option.func}
                              >
                                <img src={option.img} alt="icon" />
                                <span>{option.value}</span>
                              </li>
                            </Link>
                          ) : (
                            <li
                              {...itemProps}
                              key={option.value}
                              id={index}
                              onClick={option.func}
                            >
                              <img src={option.img} alt="icon" />
                              <span>{option.value}</span>
                            </li>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </ul>
                )}
              </Box>
            </div>
          </div>
        </Grid>
      </Box>
    )
  }

  return (
    <>
      {loading && <CircularProgress color="secondary" />}

      {!isAuthenticated && !loading && (
        <Grid container alignItems="center" className={classes.authBlock}>
          <div className={classes.btnBlock}>
            <OxbowTextButton
              className={classes.loginButton}
              light="true"
              size="large"
              onClick={loginWithRedirect}
            >
              Login
            </OxbowTextButton>
          </div>
          <div
            style={{
              width: 6,
              height: 6,
              borderRadius: '50%',
              background: '#fff3',
              margin: '0 1rem'
            }}
          ></div>
          <div className={classes.btnBlock}>
            <OxbowTextButton
              onClick={() =>
                loginWithRedirect({
                  prompt: 'login',
                  screen_hint: 'signup'
                })
              }
              className={classes.loginButton}
              light="true"
              size="large"
            >
              Sign Up
            </OxbowTextButton>
          </div>
        </Grid>
      )}

      {isAuthenticated && (
        <Grid container alignItems="center" style={{ width: 'unset' }}>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Box ml={2}>
              <ProfileBadge
                isAuthenticated={isAuthenticated}
                loading={loading}
                user={user}
              >
                {!isMobileDevice && profileMenu()}
              </ProfileBadge>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}
