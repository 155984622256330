import React, { useMemo } from 'react'

export const getSubtitle = productData => {
  if (!productData?.companyType) return null

  if (productData?.companyType?.includes('Supplier')) {
    return (
      productData?.primaryFunction?.length > 0 &&
      productData?.primaryFunction[0]
    )
  } else if (productData?.companyType?.includes('Distributor')) {
    return (
      productData?.distributorType?.length > 0 &&
      productData?.distributorType[0]
    )
  }
}

export const SubTitle = ({ productData }) => {
  const subtitleString = useMemo(() => getSubtitle(productData), [productData])
  return subtitleString ? <span>{subtitleString}</span> : null
}
