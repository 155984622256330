import React, { useState, useEffect, useCallback } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { BaseLayoutBody } from 'components/Layouts/Base'
import { useStyles } from './useStyles'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'

import { useAuth0 } from 'components/Contexts/AuthContext'
import PricingPageInfoData from './pricingContent'
import { useCan } from 'rbac/can'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import useDeviceType from 'utils/customHooks/useIsMobile'
import ModalLayout from './Modals/ModalLayout'
import ContactUsModal from './Modals/ContactusModal'
import { sendContactUsData } from 'utils/api'

export const PricingPageHeader = () => {
  const classes = useStyles()
  return (
    <Box className={classes.pricingHeaderTitle}>
      <Typography variant="h1">
        Choose the right Magellan solution to achieve your business goals
      </Typography>
    </Box>
  )
}

export const PricingMainContainer = () => {
  const classes = useStyles()
  const { isMobile } = useDeviceType()
  const { isAuthenticated, loginWithRedirect, user, orgData } = useAuth0()
  const [open, setOpen] = useState(false)

  const [contactUsModalOpen, setContactUsModalOpen] = useState(false)

  const isFullUser = useCan('content_depth:all')

  const settings = {
    autoPlay: false,
    showThumbs: false,
    showArrows: true,
    showStatus: false
  }

  const requestUpgrade = useCallback(async () => {
    await sendContactUsData({
      firstName: user.given_name ? user.given_name : user.name,
      lastName: user.family_name || null,
      email: user.email,
      companyName: orgData ? orgData.name : null,
      subscribe: false,
      submissionType: 2
    })
    setOpen(true)
  }, [orgData, user])

  const renderButton = (isAuthenticated, i) => {
    const props = {
      disabled: !i.buttonActive
    }

    if (!isAuthenticated) {
      // signup
      props.onClick = () =>
        loginWithRedirect({
          appState: {
            targetUrl: `${window.location.pathname}${
              i.premium ? '?action=upgrade' : ''
            }`
          },
          prompt: 'login',
          screen_hint: 'signup'
        })

      props.light = 'true'
      props.size = 'large'
    } else if (i.buttonActive) {
      props.onClick = requestUpgrade
    }

    return (
      <OxbowPrimaryButton
        height="lg"
        background="md"
        className={classes.pricingBtn}
        {...props}
      >
        {i.buttonName}
      </OxbowPrimaryButton>
    )
  }

  const renderPricingPageInfoData = () => {
    const pricingContent = PricingPageInfoData(isAuthenticated, isFullUser).map(
      (i, index) => (
        <Box className={classes.pricingMainBox} key={index}>
          <img alt="" src={i.img} className={classes.img} />

          <Typography variant="h1" className={classes.fieldName}>
            {i.name}
          </Typography>
          <Box className={classes.article}>
            <Typography variant="h3">{i.heading}</Typography>
            <Box>
              {i.content.map((text, index) => (
                <Box className={classes.articleContainer} key={index}>
                  <span className={classes.dot}></span>
                  <Typography
                    variant="h4"
                    key={index}
                    className={classes.mainText}
                  >
                    {text}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={i.tellUs && classes.tellUsText}>
              <Typography variant="h3">{i.tellUs}</Typography>
            </Box>
            <>
              {i.name === 'Curated search' ||
              i.buttonName === 'Find out more' ? (
                <OxbowPrimaryButton
                  height="lg"
                  background="md"
                  onClick={() => setContactUsModalOpen(true)}
                  className={classes.pricingBtn}
                >
                  {i.buttonName}
                </OxbowPrimaryButton>
              ) : (
                renderButton(isAuthenticated, i)
              )}
            </>
          </Box>
        </Box>
      )
    )
    return pricingContent
  }

  useEffect(() => {
    ;(async () => {
      const url = new URL(window.location)
      const action = url.searchParams.get('action')
      if (action === 'upgrade') {
        if (!isFullUser) {
          await requestUpgrade()
        }
      }
    })()
    return () => {}
  }, [isFullUser, requestUpgrade])

  return (
    <Grid container className={classes.pricingMainContainer}>
      {isMobile ? (
        <Carousel {...settings}>{renderPricingPageInfoData()}</Carousel>
      ) : (
        renderPricingPageInfoData()
      )}
      {open && (
        <ModalLayout open={open} setOpen={setOpen}>
          <Box className={classes.mainThxModal}>
            <Grid style={{ marginBottom: '15px' }}>
              <Typography component="h2">Thank you</Typography>
              <Typography>
                We appreciate you contacting us and will get to you soon{' '}
              </Typography>
            </Grid>
            {/* <Link to="/search">
              <OxbowPrimaryButton
                height="lg"
                background="md"
                type="button"
                className={classes.contactUsBtn}
              >
                Explore the platform
              </OxbowPrimaryButton>
            </Link> */}
          </Box>
        </ModalLayout>
      )}
      {contactUsModalOpen && (
        <ContactUsModal
          open={contactUsModalOpen}
          setContactUsModalOpen={setContactUsModalOpen}
        />
      )}
    </Grid>
  )
}

const PricingPage = () => {
  const { downSm } = useMediaQueries()
  const classes = useStyles()

  return (
    <BaseLayoutBody
      pageHeaderBottomLine={false}
      style={{
        flexDirection: downSm && 'row',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
      parentBodyBg={false}
      rightPanel={
        <Box className={classes.contactUsMainContainer}>
          <PricingMainContainer />
        </Box>
      }
      pageHeader={<PricingPageHeader />}
      bottomBanner={false}
    ></BaseLayoutBody>
  )
}
export default PricingPage
