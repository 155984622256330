import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { derivedFromProp } from 'utils'
const useStyles = makeStyles(theme => ({
  tooltip: {
    left: derivedFromProp('left', {
      false: 'unset',
      default: '170px',
      md: '215px'
    }),
    right: derivedFromProp('right', {
      false: 'unset',
      true: '170px',
      default: 'unset'
    }),

    padding: derivedFromProp('padding', {
      xs: '15px 20px 15px',
      sm: '15px 20px 15px 5px',
      default: '20px 5px 15px 32px'
    }),
    position: 'absolute',
    backgroundColor: 'white',
    border: '2px solid #0E90D9',
    zIndex: 99,
    width: 'max-content',
    // top: '-25px',
    top: derivedFromProp('top', {
      xs: '-10px',
      default: '-25px'
    }),
    '& .triangle': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      position: 'absolute',

      '&.right': {
        position: 'absolute',
        borderBottom: '9px solid transparent',
        borderTop: '9px solid transparent',
        borderLeft: '9px solid #0E90D9',
        top: 18,
        right: -10,
        '& .inner-triangle': {
          position: 'relative',
          top: -10,
          left: -12,
          width: 0,
          height: 0,
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid white'
        }
      },
      '&.left': {
        borderBottom: '9px solid transparent',
        borderTop: '9px solid transparent',
        borderRight: '9px solid #0E90D9',

        top: 20,
        left: -10,
        '& .inner-triangle': {
          position: 'relative',
          top: -20,
          left: 2,
          width: 0,
          height: 0,
          borderTop: '20px solid transparent',
          borderBottom: '20px solid transparent',
          borderRight: '20px solid white'
        }
      },
      '&.bottom': {
        borderLeft: '9px solid transparent',
        borderTop: '9px solid #0E90D9',
        borderRight: '9px solid transparent',
        bottom: -10,
        left: 80,
        '& .inner-triangle': {
          position: 'relative',
          top: -22,
          left: -20,
          width: 0,
          height: 0,
          borderLeft: '20px solid transparent',
          borderTop: '20px solid white',
          borderRight: '20px solid transparent'
        }
      }
    },
    '& >p': {
      fontFamily: 'Open Sans',
      fontSize: 12,
      marginTop: 0,
      textTransform: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      width: 190,

      top: derivedFromProp('top', {
        false: 'unset',
        true: '470px',
        default: '330px',
        xs: '285px'
      }),
      position: 'fixed',
      right: 16,
      padding: '10px 10px 10px 12px',
      left: derivedFromProp('left', {
        false: 'unset',
        default: '170px',
        true: '70px'
      }),
      '& .triangle-left': {
        content: '""',
        display: 'block',
        width: 0,
        height: 0,
        borderTop: '9px solid #0E90D9 !important',
        borderLeft: '9px solid transparent !important',

        borderRight: '9px solid transparent !important',
        position: 'absolute',
        bottom: -18,
        left: 59,
        top: 'unset',
        '& .inner-triangle': {
          position: 'relative',
          top: '-22px !important',
          left: '-20px !important',
          width: 0,
          height: 0,
          borderTop: '20px solid white !important',
          borderLeft: '20px solid transparent !important',
          borderRight: '20px solid transparent !important'
        }
      }
    }
  }
}))
export const MagellanTooltip = ({
  top = 'default',
  left,
  right,
  trianglePosition,
  padding,
  position,
  children,
  wrapperRef,
  onClick
}) => {
  const classes = useStyles({ top, left, right, padding, position })

  return (
    <div className={classes.tooltip} ref={wrapperRef} onClick={onClick}>
      {children}

      <div
        className={clsx('triangle', {
          left: trianglePosition === 'left',
          right: trianglePosition === 'right',
          bottom: trianglePosition === 'bottom'
        })}
      >
        <div className="inner-triangle"></div>
      </div>
    </div>
  )
}
