import React, { useCallback, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import IconLabel from './IconLabel'
import { useEditProductDetailsContext } from 'components/Contexts/EditProductDetailsContext'
import useEditModeStyles from './EditModeUtils/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'none',
    display: 'flex',
    flexDirection: props => (props.column ? 'column' : 'row'),
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  sectionLinkTile: {
    height: '20px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontSize: '14px',
    cursor: 'pointer',
    '@supports not (aspect-ratio: 1/1)': {
      position: 'absolute',
      width: '100%',
      height: '100%'
    }
  }
}))

const IS_EDITABLE = true

export default function IconLabelGroup(props) {
  const { renderInEditMode, blurValue } = props
  if (renderInEditMode) {
    if (!blurValue && IS_EDITABLE) return <EditableIconLabelGroup {...props} />
  }
  return <_IconLabelGroup {...props} />
}

export function EditableIconLabelGroup(props) {
  const { options, id, isEditableWithoutValue } = props
  const editModeStyles = useEditModeStyles()
  const { setProductDetails, productDetails } = useEditProductDetailsContext()
  const optionsString = useMemo(() => {
    if (options)
      return options
        .map(option => option.clientName)
        .filter(option => option)
        .join(',')
    else if (isEditableWithoutValue) return ''
  }, [options, isEditableWithoutValue])

  useEffect(() => {
    if (optionsString) setProductDetails(id, optionsString, true)
  }, [optionsString, id, setProductDetails])

  const handleInputValueChange = useCallback(
    e => {
      setProductDetails(id, e.target.value)
    },
    [id, setProductDetails]
  )

  if (!options) return null

  //EXISTING CLIENTS
  return (
    <div
      style={{
        display: 'flex'
      }}
    >
      <textarea
        rows={4}
        type="text"
        value={productDetails[id]?.value || ''}
        onChange={handleInputValueChange}
        id={id}
        className={editModeStyles['textArea']}
      />
    </div>
  )
}

function _IconLabelGroup(props) {
  const {
    children,
    connected = false,
    connectorLegth = 5,
    column = false,
    size = 'sm',
    gutter = 2,
    limitTo = 20,
    // linkTo = null,
    options,
    ...rest
  } = props

  const classes = useStyles({ column, connected, gutter, size, connectorLegth })

  const _children = useMemo(() => {
    if (children) return React.Children.toArray(children).slice(0, limitTo)
    if (options)
      return options.slice(0, limitTo).map((iconProps, index, alloptions) => {
        const _iconProps = {
          ...iconProps,
          first: index === 0,
          last: index === alloptions.length - 1,
          gutter,
          size: iconProps.size || size,
          ...rest
        }
        return <IconLabel key={index} {..._iconProps} />
      })
  }, [options, size, gutter, limitTo, rest, children])

  return <div className={classes.root}>{_children}</div>
}
