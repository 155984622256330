import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'

export const useStyles = makeStyles(theme => ({
  landingPage: {
    color: 'white',
    width: '100%',
    height: 600,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderBottomLeftRadius: 50,
    [theme.breakpoints.down('sm')]: {
      height: 700
    },
    [theme.breakpoints.down('xs')]: {
      height: 850
    }
  },

  landingPageMainBlock: {
    paddingTop: 200,
    paddingLeft: 110,
    '& h1': {
      fontWeight: 700
    },
    '& h6': {
      margin: '20px 0 45px '
    },
    [theme.breakpoints.up(1920)]: {
      paddingLeft: 280
    },
    [theme.breakpoints.down(1248)]: {
      paddingLeft: 60
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 25
    }
  },
  landingMainHeaderBox: {
    '& h1': {
      display: 'flex',
      alignItems: 'center',
      padding: '50px 0',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        padding: '40px 0'
      }
    },
    '& hr': {
      width: 220,
      background: theme.palette.primary.primaryBig,
      marginRight: 20
    },
    [theme.breakpoints.up(1920)]: {
      margin: '0 280px',
      '& hr': {
        width: 70
      }
    }
  },
  landingAboutUs: {
    marginBottom: 300,
    '& a': {
      color: theme.palette.primary.mainLightBlue,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.primaryBigHovered
      }
    },
    [theme.breakpoints.up(1920)]: {
      margin: '0 170px 300px'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  magellanInAction: {
    background: theme.palette.secondary.mainLightGrey
  },
  landingMagellanInActionHeader: {
    marginTop: '-14%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-35%'
    }
  },

  landingMagellanInAction: {
    padding: '0 140px 110px 110px',
    '& >div': {
      justifyContent: 'space-around',
      '& div': {
        justifyContent: 'flex-end'
      }
    },

    '& :nth-child(2)': {
      flexDirection: 'row-reverse'
    },
    '& >:nth-child(2)': {
      padding: '60px 0'
    },
    '& img': {
      width: 525,
      height: 'auto'
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 210px 110px 210px',
      '& img': {
        width: 690,
        height: 550
      },
      '& >div': {
        justifyContent: 'space-evenly'
      }
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 25px',
      '& >div': {
        flexWrap: 'wrap'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& img': {
        width: 350,
        height: 'auto'
      }
    }
  },
  landingAboutUsBlock: {
    background: '#fff',
    border: '1px solid #DADADA',
    width: '80%',
    margin: '-50px auto 50px',
    padding: 95,
    '& h1': {
      marginBottom: 50,
      textAlign: 'center'
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%'
    },
    [theme.breakpoints.down('md')]: {
      padding: 50
    },
    [theme.breakpoints.down(1248)]: {
      padding: '50px 25px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '25px 25px',
      width: '95%'
    }
  },
  landingTrustedBy: {
    background: '#fff',
    border: '1px solid #DADADA',
    width: '80%',
    margin: '-50px auto 50px',
    transform: 'translateY(-50%)',

    padding: '60px 90px 120px',
    '& >div': {
      justifyContent: 'space-evenly'
    },
    '& h1': {
      marginBottom: 70,
      textAlign: 'center'
    },
    [theme.breakpoints.up('xl')]: {
      width: '60%',
      margin: '-50px auto 150px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 50
    },
    [theme.breakpoints.down(1248)]: {
      padding: '50px 25px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '25px 25px',
      width: '95%',
      '& >div': {
        flexDirection: 'column',
        alignItems: 'center',
        '& img': {
          width: 'fit-content',
          height: 'auto',
          marginBottom: 30
        }
      },
      '& h1': {
        marginBottom: 40
      }
    }
  },
  textBox: {
    width: 520,
    height: '60%',
    paddingLeft: 10,
    '& >div': {
      height: '100%'
    },
    '& h1': {
      padding: '10px 0 20px'
    },
    [theme.breakpoints.up('xl')]: {
      width: 670
    },
    [theme.breakpoints.down(1200)]: {
      height: '80%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingBottom: 50,
      paddingTop: 40
    }
  },

  landingWhyMagellanContent: {
    flexWrap: 'wrap',
    paddingBottom: 75,
    justifyContent: 'space-around',
    '& img': {
      width: 170,
      height: 170
    },
    [theme.breakpoints.up(1920)]: {
      // justifyContent: 'space-around'
      width: 'inherit',
      justifyContent: 'space-between',
      margin: '0 280px'
    }
  },
  landingWhyMagellanBlock: {
    padding: '0 30px',
    width: 400,
    alignItems: 'center',
    textAlign: 'center',
    '& h6': {
      marginBottom: 40
    },
    '& h1': {
      padding: '40px 0 20px'
    },
    [theme.breakpoints.up('lg')]: {
      width: 450
    },
    [theme.breakpoints.down(1200)]: {
      width: 350
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px',
      width: '100%'
    }
  },
  demoContainer: {
    padding: '90px 0 100px',
    background: theme.palette.secondary.mainLightGrey,
    '& .demoRequestContainer': {
      width: 560,
      margin: '0 auto',
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.mainLightBlue
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: '90px 25px'
    }
  },

  requestTitle: {
    fontFamily: theme.typography.mainFont,
    fontSize: 36,
    fontWeight: 500,
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  requestArticle: {
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  cross: {
    position: 'absolute',
    right: 30,
    top: 20,
    cursor: 'pointer'
  },

  contactUsBtn: {
    fontSize: 16
  },

  mainInputContainer: {
    margin: '0 auto',
    '& input, textarea': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
      boxShadow: 'unset',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '-webkit-border-radius': 'unset',
      appearance: 'none'
    },
    '& .MuiGrid-item': {
      marginRight: '10px !important'
    },
    scrollbarColor: 'white',
    '& .mainInputBox >div': {
      position: 'relative',
      marginTop: '11px',
      marginBottom: '8px',
      marginRight: '20px',
      '& .errorText': {
        marginTop: '5px',
        color: 'red',
        fontFamily: 'Open Sans',
        fontSize: '13px'
      },
      '& .hintError': {
        zIndex: 9999999,
        width: '40px',
        height: '39px',
        backgroundColor: 'red',
        position: 'absolute',
        top: '0',
        right: '0',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& >svg': {
          height: '18px'
        },
        [theme.breakpoints.down('sm')]: {
          height: '40px'
        }
      }
    },
    '& label, option': {
      fontWeight: 600
    },
    '& input': {
      marginTop: '11px',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      outline: 'none',
      height: '40px',
      border: '1px solid #C8C8C8',
      display: 'block',
      width: '260px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '5px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& .mainInputBox ': {
        flexDirection: 'column',
        '& >div': {
          marginTop: '5px',
          marginBottom: '5px'
        }
      }
    }
  },
  emailInput: {
    width: derivedFromProp('emailInputWidth', {
      full: '100%',
      default: 'unset'
    }),
    '& input': {
      width: derivedFromProp('emailInputWidth', {
        full: '100%',
        default: '260px'
      }),
      [theme.breakpoints.down('sm')]: {
        width: derivedFromProp('emailInputWidth', {
          default: '260px'
        })
      }
    }
  },
  inputError: {
    border: '1px solid red !important'
  },

  checkBoxContainer: {
    margin: '27px 0',
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.mainLightBlue,
      '&:hover': {
        color: theme.palette.primary.primaryBigHovered
      }
    },
    '& .errorText': {
      color: 'red',
      fontFamily: 'Open Sans',
      fontSize: '13px'
    },
    '& label': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0 0 ',
      fontSize: '16px',

      justifyContent: derivedFromProp('checkBoxContainerPosition', {
        center: 'center',
        default: 'unset'
      }),

      '& input': {
        height: '18px',
        width: '18px',
        marginRight: '13px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  modalMainContainer: {
    [theme.breakpoints.down('xs')]: {
      overflow: 'scroll'
    }
  },
  modalContainer: {
    color: '#000',
    background: '#fff',
    width: derivedFromProp('modalWindowWidth', {
      lg: '860px',
      unset: 'unset',
      default: '522px'
    }),
    padding: derivedFromProp('modalWindowPadding', {
      lg: '57px 148px',
      default: '50px 40px'
    }),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.down('sm')]: {
      width: derivedFromProp('modalWindowWidth', {
        default: '560px'
      }),
      padding: derivedFromProp('modalWindowPadding', {
        default: '25px 25px'
      })
    },
    [theme.breakpoints.down('xs')]: {
      width: derivedFromProp('modalWindowWidth', {
        default: '95%'
      }),
      padding: derivedFromProp('modalWindowPadding', {
        default: '10px 25px'
      })
    }
  }
}))
