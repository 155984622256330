import React, { useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@material-ui/core'

import { moreTabData } from '../../../dataHelper.js'

import useStyles, { useCustomStyles } from '../../../styles'
import clsx from 'clsx'
import { CheckboxListComponent } from 'components/Helpers/Data-Display/CheckboxList/index.js'
import useMediaQueries from 'utils/customHooks/useMediaQueries.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const More = ({ productData, dataLength, isActive }) => {
  const display = 'grid'
  const background = 'grey'
  const classes = useStyles({ display, background })
  const customClasses = useCustomStyles()
  const { downXs } = useMediaQueries()
  const {
    moreDataChips,
    moreDataArticle,
    accessAndSupport,
    wedApiAndCapabilities,
    commercialsTopBlock,
    commercialsBottomBlock,
    trialing,
    policies,
    services
  } = moreTabData(productData)
  const [expanded, setExpanded] = useState('panel1')
  const [expandedCommercials, setExpandedCommercials] = useState('panel2')
  const [deliveryBlock, setDeliveryBlock] = useState('panel3')

  const handleChange = (panel, func) => (_, isExpanded) => {
    func(isExpanded ? panel : false)
  }
  const productAndConfigurationData = [
    ...moreDataChips,
    ...moreDataArticle,
    ...accessAndSupport
  ]

  const summCommercialsLength =
    dataLength(commercialsTopBlock) + dataLength(commercialsBottomBlock)

  const title = <Typography variant="h3">Product and configuration</Typography>
  const commercialsTitle = <Typography variant="h3">Commercials</Typography>

  const providedBy = (
    <Typography
      variant="h5"
      style={{ fontWeight: 400, color: 'rgba(0, 0, 0, 0.5)', marginBottom: 23 }}
    >
      Data on this page was provided by {productData.name}{' '}
    </Typography>
  )
  const commercialsBlocks = (
    <>
      {commercialsTopBlock.length > 0 && (
        <Grid
          style={{
            gridTemplateColumns:
              commercialsTopBlock.length === 2 ? '1fr 1fr' : '1fr'
          }}
        >
          {commercialsTopBlock?.map(({ title, dataField }, i) => (
            <React.Fragment key={title}>
              {dataField && (
                <Box key={i}>
                  <Typography variant="h4">{title}</Typography>
                  <CheckboxListComponent options={dataField} />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}
      {commercialsBottomBlock.length > 0 && (
        <Grid
          style={{
            gridTemplateColumns:
              commercialsBottomBlock.length === 2 ? '1fr 1fr' : '1fr'
          }}
        >
          {commercialsBottomBlock.map(({ title, dataField }, i) => (
            <Box key={i}>
              {dataField && (
                <>
                  <Typography variant="h4">{title}</Typography>
                  {Array.isArray(dataField) ? (
                    <CheckboxListComponent options={dataField} />
                  ) : (
                    <Typography
                      variant="h4"
                      style={{ fontWeight: 400, paddingTop: 8 }}
                    >
                      {dataField}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          ))}
        </Grid>
      )}
    </>
  )

  const producAndConfigurationBlock = (
    <>
      {moreDataChips.length > 0 && (
        <Grid>
          {moreDataChips.map(({ title, dataField }, i) => (
            <React.Fragment key={i}>
              {dataField && (
                <Box>
                  <Typography variant="h4">{title}</Typography>
                  <CheckboxListComponent options={dataField} />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}
      {dataLength(productAndConfigurationData) > 0 && (
        <Grid className={customClasses.chipsContainer}>
          {moreDataArticle.map(({ title, dataField }) => (
            <React.Fragment key={title}>
              {dataField && (
                <Box key={title}>
                  <Typography variant="h4">{title}</Typography>

                  <Typography key={dataField} variant="h5">
                    {dataField}
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}
      {dataLength(accessAndSupport) > 0 && (
        <Grid>
          {accessAndSupport.map(({ title, dataField }, i) => (
            <React.Fragment key={title}>
              {dataField && (
                <Box key={i}>
                  <Typography variant="h4">{title}</Typography>
                  <CheckboxListComponent options={dataField} />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Grid>
      )}
    </>
  )

  const deliveryMainBlock = (
    <>
      {policies && policies.dataField && (
        <>
          <Typography variant="h4" style={{ marginTop: 20 }}>
            {policies.title}
          </Typography>
          <Typography variant="h4" style={{ fontWeight: 400, marginTop: 20 }}>
            {policies.dataField.toLocaleString()}
          </Typography>
        </>
      )}
      {services.length > 0 && (
        <span>
          {services.map(({ title, dataField }, i) => (
            <React.Fragment key={title}>
              {dataField && (
                <Box key={i}>
                  <Typography variant="h4">{title}</Typography>
                  <CheckboxListComponent options={dataField} />
                </Box>
              )}
            </React.Fragment>
          ))}
        </span>
      )}
    </>
  )

  return (
    <Grid
      className={clsx({
        [classes.inActive]: !isActive
      })}
    >
      {dataLength(productAndConfigurationData) > 0 && (
        <Grid
          className={classes.productPageFullWidthBlock}
          style={{
            marginTop: '0px'
          }}
        >
          {downXs ? (
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1', setExpanded)}
            >
              <Grid
                container
                className={classes.productPageFullWidthBlockHeader}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {title}
                </AccordionSummary>

                {providedBy}
              </Grid>

              {producAndConfigurationBlock}
            </Accordion>
          ) : (
            <>
              <Grid
                container
                className={classes.productPageFullWidthBlockHeader}
              >
                {title}

                {providedBy}
              </Grid>

              {producAndConfigurationBlock}
            </>
          )}
        </Grid>
      )}
      {dataLength(wedApiAndCapabilities) > 0 && (
        <Grid className={classes.productPageFullWidthBlock}>
          <Typography variant="h3">Web API capabilities</Typography>
          <Grid>
            {wedApiAndCapabilities.map(({ title, dataField }, i) => (
              <React.Fragment key={title}>
                {dataField && (
                  <Box key={i}>
                    <Typography variant="h4">{title}</Typography>
                    <CheckboxListComponent options={dataField} />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      )}

      {summCommercialsLength > 0 && (
        <Grid className={classes.productPageFullWidthBlock}>
          {downXs ? (
            <Accordion
              expanded={expandedCommercials === 'panel2'}
              onChange={handleChange('panel2', setExpandedCommercials)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {commercialsTitle}
              </AccordionSummary>

              {commercialsBlocks}
            </Accordion>
          ) : (
            <>
              {commercialsTitle}

              {commercialsBlocks}
            </>
          )}
        </Grid>
      )}
      {dataLength(trialing) > 0 && (
        <Grid className={classes.productPageFullWidthBlock}>
          <Typography variant="h3">Trialing</Typography>
          <article>
            {trialing.map(({ title, dataField }) => (
              <React.Fragment key={title}>
                {dataField && (
                  <Box key={title}>
                    <Typography variant="h4">{title}</Typography>

                    <Typography variant="h4" style={{ fontWeight: 400 }}>
                      {dataField}
                    </Typography>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </article>
        </Grid>
      )}

      {(policies?.dataField || services.length > 0) && (
        <Grid className={classes.productPageFullWidthBlock}>
          {downXs ? (
            <Accordion
              expanded={deliveryBlock === 'panel3'}
              onChange={handleChange('panel3', setDeliveryBlock)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">
                  Delivery and implementation
                </Typography>
              </AccordionSummary>
              {deliveryMainBlock}
            </Accordion>
          ) : (
            <>
              <Typography variant="h3">Delivery and implementation</Typography>
              {deliveryMainBlock}
            </>
          )}
        </Grid>
      )}
    </Grid>
  )
}
export default More
