import { Box, Grid, Typography } from '@material-ui/core'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import LandingModal from 'components/Pages/LandingPage/LandingModal'
import ModalLayout from 'components/Pages/PricingPage/Modals/ModalLayout'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from '../useStyles'

export const FreeRestrictions = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.restrictionBlock}>
      <Box>
        <Typography variant="h3">
          You have used up all of your free searches{' '}
        </Typography>
        <Typography variant="subtitle2">
          To get more, upgrade to Magellan Premium where you can also see full
          vendor profiles, and access features like saved searches,
          notifications and company lists.
        </Typography>
        <Typography variant="h3" style={{ fontWeight: 400 }}>
          <Link to="/contact-us"> Contact our team</Link> to discuss how we can
          help
        </Typography>
      </Box>
    </Grid>
  )
}

export const UserCTA = () => {
  const classes = useStyles()
  const {
    isFullUser,

    isFreeUser
  } = useFilterStateContext()
  const { user } = useAuth0()
  const { personalData } = useCustomisationJourney()

  const [contactUsForm, setContactUsForm] = useState(false)
  const [thxModal, setThxModal] = useState(false)

  const modalData = {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail'
  }

  const closeAllModals = () => {
    setContactUsForm(false)
    setThxModal(false)
  }
  const handleThxModalChange = useCallback(() => {
    if (thxModal) {
      setContactUsForm(false)
    }
  }, [thxModal, setContactUsForm])

  useEffect(() => {
    handleThxModalChange()
  }, [handleThxModalChange])
  return (
    <Grid className={classes.freeUserCta}>
      <Typography variant="h3">
        Start building your short-list of insurance technology companies
      </Typography>
      {isFreeUser ? (
        <>
          <Typography variant="subtitle2">
            As a free user you can run <b>20 searches.</b>
          </Typography>
          <Typography variant="subtitle2">
            To get more, <b>upgrade to Magellan Premium</b> where you can also
            see full vendor profiles, and access features like saved searches,
            notifications and company lists.
          </Typography>
          <Typography variant="subtitle2">
            <span onClick={() => setContactUsForm(true)}>Contact our team</span>{' '}
            to discuss how we can help
          </Typography>
        </>
      ) : (
        <>
          {isFullUser && (
            <>
              <Typography variant="subtitle2">
                Need help with your search?
              </Typography>
              <Typography variant="subtitle2">
                <span onClick={() => setContactUsForm(true)}>Contact us</span>{' '}
                about a Curated Search
              </Typography>
            </>
          )}
        </>
      )}
      <LandingModal
        emailInputWidth="full"
        checkBoxContainerPosition="center"
        submitBtnText="Submit"
        modalData={modalData}
        userName={personalData.firstName}
        userEmail={user.email}
        userNickName={personalData.lastName}
        headerTitle="Contact us"
        isDemoFormOpen={contactUsForm}
        setDemoFormOpen={setContactUsForm}
        isThxModalOpen={false}
        btnWidth="max-content"
        isFreeUser={isFreeUser}
        submissionType={3}
        modalWindowWidth="lg"
        modalWindowPadding="lg"
        setThxModal={setThxModal}
      />
      <ModalLayout open={thxModal} setOpen={closeAllModals}>
        <Box
          style={{
            padding: '30px 30px 50px',
            '& p': {
              margin: '0 !important'
            }
          }}
        >
          <Grid style={{ marginBottom: '15px' }}>
            <Typography component="h2">Thank you</Typography>
            <Typography>
              We appreciate you contacting us and will get to you soon{' '}
            </Typography>
          </Grid>
        </Box>
      </ModalLayout>
    </Grid>
  )
}
