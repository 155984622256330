import React, { useContext, useState, useEffect, useRef, useMemo } from 'react'
import axios from 'axios'
import { getQueryResults } from 'utils/api'
import { capitalize, converyObjectToArray } from 'utils'
import { useFilterStateContext } from './FilterStateContext'
import { useQuery } from 'react-query'
import { useAuth0 } from './AuthContext'
import { useSearchPaginationContext } from './SeacrhPaginationContext'
import { useQueryStringValues } from './QueryStringContext'

// TEST FUNCTION START
const testRequest = async (key, filters) => {
  await axios('http://localhost:4000/search', {
    params: {
      searchQuery: key.toLowerCase(),
      filters: JSON.stringify(filters)
    }
  })
}

window.testRequest = testRequest
// TEST FUNCTION END

export const fetchSearchResults = async (
  filtersObj,
  queryStringParameters,
  excludeIds
) => {
  if (!filtersObj || Object.keys(filtersObj).length === 0)
    return { products: [], aggregations: {}, totalResults: null }
  const { key: searchKey, ...filters } = filtersObj

  if (filters.category) {
    filters.companyType = converyObjectToArray(filters.category).map(capitalize)
  } else {
    filters.companyType = []
  }

  if (filters.primaryFunction) {
    filters.secondaryFunction = filters.primaryFunction;
  }

  const searchResults = await getQueryResults(
    searchKey,
    { filters },
    queryStringParameters,
    excludeIds
  )
  return searchResults
}

const SearchContext = React.createContext()
export const useSearchContext = () => useContext(SearchContext)

export default function SearchContextProvider({ children }) {
  const [searchResults, setSearchResults] = useState({})
  const [loadingResults, setLoadingResults] = useState(false)
  const [searchResultsLength, setSearchResultsLength] = useState(0)
  const [isSavedSearchApplyed, setSavedSearchApply] = useState(false)

  const {
    filterValues,
    handleChangeInSearchAggregations,
    bootstrappingFilters,
    freeUserSelectedFilters,
    isFreeUser,
    tempFilters,
    checkedCountries,
    isApplyFiltersBtnVisible,
    categories
  } = useFilterStateContext()
  const {
    itcOnly,
    sortField,
    sortOrder,
    showInActiveCompanies
  } = useQueryStringValues()
  const { isAuthenticated } = useAuth0()

  const firstRenderRef = useRef(true)
  const [excludeIds, setExcludeIds] = useState([])
  const { allCompaniesPage, pageSize } = useSearchPaginationContext()

  const [freeUserSearchLimits, setFreeUserSearchLimits] = useState(0)
  const [freeUserData, setFreeUserData] = useState(false)

  const queryStringParameters = {
    page: allCompaniesPage,
    pageSize,
    sortField,
    sortOrder,
    itcOnly
  }
  const [firstRender, setFirstRender] = useState(true)

  const selectedFilterValues = useMemo(
    () =>
      Object.keys(filterValues).reduce((acc, filter) => {
        if (
          (typeof filterValues[filter]  === 'object' && !Array.isArray(filterValues[filter]) && Object.values(filterValues[filter] || {}).filter(item => item).length > 0) ||
          (filterValues[filter].length > 0)
        ) {
          acc[filter] = filterValues[filter]
        }
        return acc
      }, {}),
    [filterValues]
  )

  const filterOptions = useMemo(() => {
    let filters = {
      ...filterValues
    }

    if (showInActiveCompanies) {
      delete filters.isActive
    } else {
      filters.isActive = !showInActiveCompanies
    }

    return filters
  }, [showInActiveCompanies, filterValues])

  const [isSearchEnabled, setIsSearchEnabled] = useState(false)
  useEffect(() => {
    if (isFreeUser) {
      setIsSearchEnabled(true)
    } else {
      if (firstRender && Object.keys(selectedFilterValues).length === 0) {
        setIsSearchEnabled(false)
      } else {
        setIsSearchEnabled(true)
      }
    }
  }, [
    isFreeUser,
    freeUserSearchLimits,
    freeUserSelectedFilters,
    firstRender,
    selectedFilterValues,
    tempFilters,
    searchResults,
    checkedCountries,
    isApplyFiltersBtnVisible
  ])

  useEffect(() => {
    if (categories.category.distributor) {
      setIsSearchEnabled(true)
    }
  }, [categories.category.distributor])

  const {
    isLoading: isSearchResultsLoading,
    isError,
    error,
    data: searchResultsResponse,
    isIdle: isSearchResultsRequestIdle
  } = useQuery(
    [filterOptions, queryStringParameters, excludeIds],
    fetchSearchResults,
    {
      enabled:
        isSearchEnabled &&
        isAuthenticated &&
        filterValues &&
        !bootstrappingFilters
    }
  )
  useEffect(() => {
    if (
      !isSearchEnabled ||
      isSearchResultsLoading ||
      isSearchResultsRequestIdle
    )
      return setLoadingResults(true)
    if (!isSearchEnabled || isError) return setLoadingResults(false)

    const {
      products,
      aggregations,
      totalResults,
      searchesLeft
    } = searchResultsResponse
    if (isFreeUser && products.length === 3 && searchesLeft === 0) {
      setFreeUserData(true)
    } else {
      setFreeUserData(false)
    }
    if (searchesLeft) {
      setFreeUserSearchLimits(searchesLeft)
    } else {
      setFreeUserSearchLimits(0)
    }
    handleChangeInSearchAggregations(aggregations, 'productAggregations')
    handleChangeInSearchAggregations(aggregations, 'companyAggregations')
    setSearchResultsLength(totalResults)
    setSearchResults(() => {
      const productDocs = products.reduce((acc, product) => {
        acc[product.id] = product
        return acc
      }, {})
      return productDocs
    })

    setFirstRender(false)
    setLoadingResults(false)
  }, [
    handleChangeInSearchAggregations,
    isSearchResultsLoading,
    isError,
    error,
    searchResultsResponse,
    isSearchResultsRequestIdle,
    sortField,
    sortOrder,
    isFreeUser,
    isSearchEnabled
  ])

  useEffect(() => {
    firstRenderRef.current = false
  }, [])

  return (
    <SearchContext.Provider
      value={{
        searchResults,
        setSearchResults,
        loadingResults,
        searchResultsLength,
        excludeIds,
        setExcludeIds,
        freeUserSearchLimits,
        isFreeUser,
        freeUserData,
        isSavedSearchApplyed,
        setSavedSearchApply,
        isSearchEnabled,
        setIsSearchEnabled
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
