import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  searchResultSection: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: '100px'
    },
    '& .collection-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '&__main-box': {
        alignItems: 'center',
        width: 300
      },
      '& p': {
        marginTop: '23px',
        textTransform: 'uppercase',
        fontSize: '22px',
        fontFamily: '"Barlow Condensed", sans-serif',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          justifyContent: 'space-between'
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: 0
        }
      },

      '&__sorting-container': {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          flexWrap: 'wrap'
        },
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start !important'
        }
      }
    },
    '& hr': {
      marginTop: '15px',
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      '& .collection-header': {
        flexDirection: 'column',
        alignItems: 'flex-start !important',
        '&__main-box': {
          width: '100% !important',
          justifyContent: 'space-between',
          '& p': {
            marginTop: '0 !important'
          }
        }
      }
    }
  },
  saveSearchBtn: {
    fontWeight: 500,
    width: 140,
    height: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  searchTerm: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px 12px',
    background: theme.palette.secondary.main,
    height: 'max-content',
    flexWrap: 'wrap',
    color: theme.palette.primary.contrastText,
    margin: '3px 0',
    borderRadius: '20px',
    marginLeft: '5px',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',

    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  filterInfoContainer: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 15,
    fontFamily: 'Open Sans'
  },
  saveSearchBtnDisabled: {
    pointerEvents: 'none',
    opacity: 0.5
  },
  paginationTitle: {
    marginRight: 23
  },
  title: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.primary.main
  },
  cancelIcon: {
    height: '17px',
    width: 'auto',
    marginLeft: '7px',
    cursor: 'pointer'
  },

  itcColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.primary.mainLightBlue,
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: '14px'
  }
}))
