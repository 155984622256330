import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../components/Contexts/AuthContext'
import { Container, CircularProgress } from '@material-ui/core'
const PublicRoute = ({ component: Component, path, location, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const isPrivateCollection =
    // window.location.pathname.startsWith('/collections/personal') ||
    window.location.pathname.startsWith('/collections/organisation')

  useEffect(() => {
    async function fn() {
      await loginWithRedirect({
        appState: { targetUrl: location.pathname + location },
        prompt: 'login',
        screen_hint: 'signup'
      })
    }

    if (isPrivateCollection && !isAuthenticated) {
      fn()
    }
  }, [
    loading,
    isAuthenticated,
    loginWithRedirect,
    location,
    isPrivateCollection
  ])

  const render = props =>
    !loading ? (
      <Component {...props} />
    ) : (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          minHeight: 0
        }}
      >
        <CircularProgress />
      </Container>
    )

  return <Route path={path} render={render} {...rest} />
}

export default PublicRoute
