import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'
export const useStyles = makeStyles(theme => ({
  journeyModal: {
    position: 'relative',
    paddingTop: 50,
    background: '#fff !important',
    color: '#000',
    width: 970,

    '& h1': {
      fontSize: 36,
      lineHeight: '43.2px'
    },
    '& >h6, h4': {
      lineHeight: '24px'
    },
    '& h3': {
      lineHeight: '32px'
    },
    [theme.breakpoints.down(1000)]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      height: '75%',
      overflow: 'auto'
    }
  },
  modalHeading: {
    position: 'relative',
    '& img': {
      cursor: 'pointer',
      position: 'absolute',
      right: -12,
      top: -20
    }
  },
  mainStepsBlock: {
    width: derivedFromProp('width', {
      full: '100%',
      default: '70%'
    }),
    margin: derivedFromProp('margin', {
      none: '0px',
      default: '0 auto'
    }),
    '& >h6': {
      marginBottom: 10
    },
    '& h3': {
      lineHeight: '32px',
      marginBottom: 22
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  stepsChips: {
    alignItems: 'center',
    justifyContent: derivedFromProp('justifyContent', {
      unset: 'unset',
      default: 'center'
    })
  },
  journeyChips: {
    transition: '0.3s ease',
    width: 'max-content',
    border: '1px solid #00A3FF',
    background: 'rgba(0, 163, 255, 0.1)',
    borderRadius: 20,
    padding: '5px 18px',
    fontWeight: 400,
    cursor: 'pointer',
    marginRight: 10,
    marginTop: 10,
    '&:hover': {
      background: 'rgba(0, 163, 255, 0.3)',
      color: '#000'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      marginBottom: 5
    }
  },
  selectedChipsValue: {
    background: '#35AAEC',
    color: '#fff'
  },
  selectorBox: {
    '& hr': {
      width: '33%',
      [theme.breakpoints.down(660)]: {
        width: '25%'
      }
    },
    '& >div': {
      justifyContent: derivedFromProp('justifyContent', {
        unset: 'unset',
        default: 'center'
      })
    },
    '& h4': {
      fontWeight: derivedFromProp('fontWeight', {
        semibold: 600,
        default: 700
      }),
      alignSelf: derivedFromProp('alignSelf', {
        'flex-start': 'flex-start',
        default: 'unset'
      }),
      margin: '24px 0 24px',
      [theme.breakpoints.down('xs')]: {
        margin: '0 0 15px'
      }
    }
  },
  textAreaBox: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  errorText: {
    marginTop: '5px',
    color: 'red',
    fontFamily: 'Open Sans',
    fontSize: '13px'
  },

  wordsLimits: {
    position: 'absolute',
    bottom: 0,
    right: 15,
    color: 'rgb(128, 128, 128)',
    fontSize: 13
  },
  btnBox: {
    padding: '40px 0 50px',
    '& button:first-child': {
      marginRight: 10,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10
      }
    }
  },
  checkBox: {
    flexDirection: 'row !important',
    width: derivedFromProp('inputWidth', {
      'partly-hidden': '250px !important',
      default: '100%'
    }),
    justifyContent: derivedFromProp('inputWidth', {
      'partly-hidden': 'space-between',
      default: 'space-evenly'
    }),
    alignSelf: derivedFromProp('alignSelf', {
      'flex-start': 'flex-start',
      default: 'unset'
    }),
    '& .PrivateRadioButtonIcon-root ': {
      color: '#C8C8C8 !important',
      fill: '#C8C8C8 !important',
      width: 18,
      height: 18
    },

    '& .MuiIconButton-colorSecondary': {
      color: '#52B9F2 '
    }
  },
  intendToUse: {
    '& textarea': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
      boxShadow: 'unset',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '-webkit-border-radius': 'unset',
      appearance: 'none'
    }
  },
  stepper: {
    position: 'absolute',
    top: 0,
    padding: 0,
    left: -1,
    background: 'transparent',
    '& .MuiStep-horizontal': {
      paddingLeft: '0 ',
      paddingRight: '0 '
    },

    '& .MuiStep-completed': {
      background: '#52B9F2 !important',
      height: '6px !important'
    }
  },

  activeStep: {
    width: '13.75em',
    margin: '0 1px',
    background: 'rgba(82, 185, 242, 0.5)',
    height: 6
  },

  mainSelectorBox: {
    position: 'relative',
    width: derivedFromProp('inputWidth', {
      'partly-hidden': '400px !important',
      default: '100%'
    }),
    alignSelf: derivedFromProp('alignSelf', {
      'flex-start': 'flex-start',
      default: 'unset'
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      width: derivedFromProp('inputWidth', {
        'partly-hidden': '95% !important',
        default: '100%'
      })
    }
  },
  inputContainer: {
    alignItems: 'center',
    backgroundColor: '#fff',
    display: 'flex',
    position: 'relative',
    // borderBottomLeftRadius: 5,
    // borderTopRightRadius: 5,
    '& input': {
      padding: '8px 13px 10px',
      border: '1px solid #C8C8C8',
      height: '40px',
      fontSize: '16px',
      boxSizing: 'border-box',
      minWidth: '30px',
      flexGrow: 1,
      outline: 0,
      fontFamily: 'Open Sans',
      zIndex: 2,
      backgroundColor: 'transparent',
      '&::placeholder': {
        fontFamily: 'Open Sans'
      },
      '&:focus': {
        border: `1px solid ${theme.palette.secondary.main} !important`
      }
    }
  },

  mainScrollBox: {
    width: '100%',
    overflow: 'auto',
    height: 240,
    position: 'absolute',
    color: 'black',
    zIndex: 3,
    border: '1px solid #C8C8C8',
    backgroundColor: '#fff',
    '& .MuiButtonBase-root': {
      justifyContent: 'unset'
    },
    '& .MuiAccordion-root, .MuiCollapse-container, .MuiAccordionSummary-root, .MuiCollapse-container .MuiCollapse-hidden, .MuiAccordionSummary-content': {
      transition: 'unset',
      transitionDuration: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      flexGrow: 'unset'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'unset'
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 'unset'
    },

    '& .MuiAccordion-rounded:first-child': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    },
    '& .MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'unset'
    },
    '& .MuiAccordion-root:before': {
      content: 'none'
    },
    '& .MuiIconButton-root': {
      padding: '10px 0'
    }
  },

  hidden: {
    width: '100%',
    marginTop: '2px',
    padding: theme.spacing(2),
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.primary.lighterDark,
    overflow: 'auto',
    maxHeight: '250px',
    zIndex: 3,
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  listBox: {
    backgroundColor: 'white',
    '& li': {
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center'
    },

    '& li[data-focus="true"]': {
      cursor: 'pointer'
    }
  },
  itemList: {
    alignItems: 'center',
    padding: '8px 10px 0 40px'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    background: 'none',
    boxShadow: 'none',
    margin: 0,
    float: 'left'
  },
  chipWrapper: {
    marginRight: theme.spacing(1.3),
    marginBottom: theme.spacing(1.3)
  },
  chip: {
    width: 'max-content',
    border: '1px solid #00A3FF',
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex',
    height: '28px',
    background: theme.palette.primary.mainLightBlue,
    transition: 'background 0.3s ease',
    willChange: 'background',
    color: '#fff',
    fontWeight: 400,
    fontSize: '13px',
    padding: theme.spacing(0, 1.5)
  }
}))
