import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import React, { useState } from 'react'
import { exportCollectionCsv } from 'utils/api'
import { OxbowPrimaryTransparentButton } from '../Buttons'
import DownloadIcon from 'assets/icons/DownloadIcon.svg'
import { CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { MagellanTooltip } from '../MagellanTooltip'
import useMediaQueries from 'utils/customHooks/useMediaQueries'

const useStyles = makeStyles(() => ({
  circularProgress: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -4,
    '& svg': {
      width: '100% !important'
    }
  }
}))

export default function ExportCSV({
  productIdsOfSelectedCollection,
  selectedCollection,
  collectionNameState
}) {
  const classes = useStyles()
  const { isFullUser } = useFilterStateContext()
  const { isMobileDevice } = useMediaQueries()
  const [isTooltipOpened, setTooltipOpen] = useState(false)
  const [isExportCsvLoading, setExportCsvLoading] = useState(false)

  const exportExcelHandler = async () => {
    const collectionId = selectedCollection.collectionId
      ? selectedCollection.collectionId
      : selectedCollection.magellan_id
    setExportCsvLoading(true)
    try {
      await exportCollectionCsv(collectionId, collectionNameState)
      setExportCsvLoading(false)
    } catch (error) {
      setExportCsvLoading(false)
    }
  }
  return (
    <div
      style={{
        position: 'relative'
      }}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <OxbowPrimaryTransparentButton
        disabled={
          !isFullUser ||
          isExportCsvLoading ||
          !productIdsOfSelectedCollection.length
        }
        width="min-content"
        size="md"
        fontSize="sm"
        height="md"
        marginright="sm"
        background="light"
        onClick={() => exportExcelHandler()}
        startIcon={
          <img
            alt=""
            src={DownloadIcon}
            style={{
              opacity:
                !isFullUser ||
                isExportCsvLoading ||
                !productIdsOfSelectedCollection.length
                  ? 0.4
                  : 1
            }}
          />
        }
      >
        Export (CSV)
        {isExportCsvLoading ? (
          <CircularProgress className={classes.circularProgress} />
        ) : null}
      </OxbowPrimaryTransparentButton>
      {!isFullUser && isTooltipOpened && (
        <MagellanTooltip
          left={false}
          right={true}
          top={isMobileDevice ? true : 'xs'}
          trianglePosition="right"
          padding="xs"
          className="customTooltip"
        >
          <Typography
            variant="h6"
            style={{
              color: 'black',
              fontSize: 12,
              fontWeight: 400
            }}
          >
            Upgrade to premium to unlock collections export
          </Typography>
        </MagellanTooltip>
      )}
    </div>
  )
}
