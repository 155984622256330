import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import DefaultImgProductImg from 'assets/Logos/Magellan Logos/DefaultProductImg.png'
import { bucketName } from 'utils/api'
const useStyles = makeStyles(theme => ({
  postPageHeader: {
    '& img': {
      width: 385,
      height: 215,
      objectFit: 'cover',
      border: '1px solid #ccc'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      '& img': {
        width: 300,
        height: 200
      }
    }
  }
}))

export const PostPageHeader = ({ data, children }) => {
  const classes = useStyles()
  if (!data || !Object.keys(data).length) return null

  const img = () => {
    if (data.tileImage) return data.tileImage
    if (data.thumbnail)
      return `https://${bucketName}.s3.eu-west-2.amazonaws.com/collections-thumbnails/${data.thumbnail}`
    return DefaultImgProductImg
  }

  return (
    <Grid
      container
      wrap="nowrap"
      justify="space-between"
      className={classes.postPageHeader}
    >
      <Grid
        item
        style={{
          marginRight: 40
        }}
      >
        {children ? (
          children
        ) : (
          <Typography variant="subtitle2">: {data?.description}</Typography>
        )}
      </Grid>
      <Grid>
        <img src={img()} alt="post" />
      </Grid>
    </Grid>
  )
}
