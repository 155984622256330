import React, { useEffect, useState } from 'react'
import { BaseLayoutBody } from 'components/Layouts/Base'
import { Box, Grid, Typography } from '@material-ui/core'
import { useAutocomplete } from '@material-ui/lab'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import { useStyles } from './useStyles'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { Field, Form, Formik } from 'formik'
import { OxbowDarkButton } from 'components/Helpers/Buttons'
import magellanImgSmall from 'assets/Logos/Magellan Logos/magellanImgSmall.png'
import { Link } from 'react-router-dom'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { sendContactUsData } from 'utils/api'
import { useAuth0 } from 'components/Contexts/AuthContext'
import clsx from 'clsx'
import { ArrowDropDown } from '@material-ui/icons'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'

export const ContactUsHeader = () => {
  const classes = useStyles()
  return (
    <Typography variant="h1" className={classes.contactUsTitle}>
      Contact us
    </Typography>
  )
}

export const ContactUsMainComponent = ({ setThxPageOpen, thxPageOpen }) => {
  const classes = useStyles()
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const selectoptions = [
    { value: 'Broker' },
    { value: 'Insurer' },
    { value: 'Investor' },
    { value: 'Solution Provider' },
    { value: 'Other' }
  ]
  const areasOptions = [
    { value: 'Curated Search', title: 'Curated Search' },
    {
      value: 'Premium access to Magellan',
      title: 'Premium access to Magellan'
    },
    {
      value: 'I want to claim my company profile',
      title: 'I want to claim my company profile'
    },
    {
      value: 'Oxbow Partners consultancy service',
      title: 'Oxbow Partners consultancy service'
    },
    { value: 'Other', title: 'Other (specify below)' }
  ]
  const labelMappings = {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    companyName: 'Company name',
    jobTitle: 'Job title',
    message: 'How can we help you?'
  }

  const [interests, setInterests] = useState([])
  const [companyTypeError, setCompanyTypeError] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [policyError, setPolicyError] = useState(false)
  const [selectValue, setSelectedValue] = useState({ value: '' })

  useEffect(() => {
    if (selectValue.value) setCompanyTypeError(false)
  }, [selectValue])

  const handleChangePolicy = () => {
    setPolicy(prev => !prev)
    setPolicyError(false)
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const contactUsData = {
      ...values,
      interests: interests,
      companyType: selectValue.value
    }
    if (!selectValue.value) {
      setCompanyTypeError(true)
    } else if (!policy) {
      setPolicyError(true)
    } else {
      setSubmitting(true)
      try {
        await sendContactUsData(contactUsData)
        setSubmitting(false)
        resetForm()
        setThxPageOpen(true)
      } catch (error) {
        setSubmitting(false)
      }
    }
  }

  const validateFields = values => {
    const errors = {}

    Object.entries(values).forEach(([key, value]) => {
      if (
        !value &&
        key !== 'jobTitle' &&
        key !== 'subscribe' &&
        key !== 'companyType'
      ) {
        errors[key] = 'This is a required field.'
      } else if (
        value &&
        key !== 'jobTitle' &&
        key !== 'companyName' &&
        key !== 'email' &&
        key !== 'message' &&
        !/^[A-Za-z ]+$/i.test(value)
      ) {
        errors[key] = `Please enter a valid ${labelMappings[
          key
        ].toLowerCase()}!`
      } else if (
        value &&
        key === 'companyName' &&
        !/^[A-Z0-9 ]+$/i.test(value)
      ) {
        errors[key] = `Please enter a valid ${labelMappings[
          key
        ].toLowerCase()}!`
      }
    })
    if (!values.email) {
      errors['email'] = 'This is a required field.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Please enter a valid email address!'
    }
    return errors
  }

  const redirectToGoogleMaps = () => {
    window.open('https://goo.gl/maps/6vt39QknErG4xTBr5')
  }

  const redirectToNewEmail = () => {
    window.location = 'mailto:magellan@oxbowpartners.com'
  }

  const handleInterests = (_event, newInterest) => {
    if (newInterest.length) {
      setInterests(newInterest)
    } else setInterests([])
  }

  const errorMsgContainer = msg => {
    return (
      <>
        <span className="errorText">{msg}</span>
        <span className="hintError">
          <PriorityHigh />
        </span>
      </>
    )
  }

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    focused
  } = useAutocomplete({
    options: selectoptions,
    getOptionLabel: option => option?.value,
    onChange: (_, values) => {
      setSelectedValue(values)
    }
  })

  return (
    <>
      {!thxPageOpen ? (
        <>
          <div className={classes.contactUsMainComponent}>
            <Typography className={classes.contactHeaderText}>
              Let us know what you are interested in discussing and we will get
              back to you soon
            </Typography>

            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                companyType: selectValue.value,
                jobTitle: '',
                message: '',
                subscribe: false
              }}
              validate={validateFields}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting
              }) => (
                <Form>
                  <Box className={classes.mainInputContainer}>
                    <Grid container className="mainInputBox">
                      <Grid item>
                        <Typography variant="h5" htmlFor="firstName">
                          First name *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.firstName &&
                              touched.firstName &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.firstName &&
                            touched.firstName &&
                            errorMsgContainer(errors.firstName)}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant="h5" htmlFor="lastName">
                          Last name *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.lastName &&
                              touched.lastName &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.lastName &&
                            touched.lastName &&
                            errorMsgContainer(errors.lastName)}
                        </Box>
                      </Grid>

                      <Grid item>
                        <Typography variant="h5" htmlFor="email">
                          Work email *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.email &&
                              touched.email &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.email &&
                            touched.email &&
                            errorMsgContainer(errors.email)}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container className="mainInputBox">
                      <Grid item>
                        <Typography variant="h5" htmlFor="companyName">
                          Company name *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.companyName &&
                              touched.companyName &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="companyName"
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.companyName &&
                            touched.companyName &&
                            errorMsgContainer(errors.companyName)}
                        </Box>
                      </Grid>

                      <Grid item className={classes.companyTypeContainer}>
                        <Typography variant="h5" htmlFor="companyType">
                          Company type *
                        </Typography>
                        <div>
                          <Box position="relative">
                            <div
                              {...getRootProps()}
                              onBlur={handleBlur}
                              ref={setAnchorEl}
                              className={clsx(classes.inputContainer, {
                                focused
                              })}
                              style={{ border: '1px solid #C8C8C8' }}
                            >
                              <div {...getInputProps()}>
                                <select
                                  className={
                                    companyTypeError ? classes.inputError : ''
                                  }
                                  autoComplete={'no'}
                                  style={{ border: 'none', marginTop: '0 ' }}
                                />
                              </div>
                              <ArrowDropDown fontSize="small" />
                              <span> {selectValue?.value} </span>
                            </div>
                            {companyTypeError &&
                              errorMsgContainer('This is a required field.')}
                          </Box>
                        </div>

                        {groupedOptions?.length > 0 && (
                          <div style={{ position: 'relative' }}>
                            <div className={classes.selectContainer}>
                              <ul
                                style={{ border: '1px solid #ccc' }}
                                {...getListboxProps()}
                                className={`${classes.listBox} ${classes.hidden}`}
                              >
                                {groupedOptions?.map((option, index) => {
                                  const itemProps = getOptionProps({
                                    option,
                                    index
                                  })
                                  return (
                                    <li
                                      {...itemProps}
                                      key={option.value}
                                      id={index}
                                    >
                                      <span>{option.value}</span>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant="h5" htmlFor="jobTitle">
                          Job title
                        </Typography>
                        <Box position="relative">
                          <input
                            type="text"
                            name="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      className={classes.textAreaContainer}
                    >
                      <Typography variant="h5">Areas of interest</Typography>
                      <Grid item className={classes.interestsContainer}>
                        {areasOptions.map((option, index) => (
                          <React.Fragment key={index}>
                            <ToggleButtonGroup
                              color="primary"
                              value={interests}
                              onChange={handleInterests}
                            >
                              <ToggleButton
                                value={option.value}
                                className={classes.chipsBtn}
                              >
                                {option.title}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </React.Fragment>
                        ))}
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography variant="h5" htmlFor="message">
                          How can we help you? *
                        </Typography>
                        <textarea
                          className={
                            errors.message &&
                            touched.message &&
                            classes.inputError
                          }
                          required
                          value={values.message}
                          onChange={handleChange}
                          rows={10}
                          cols={50}
                          name="message"
                          onBlur={handleBlur}
                        ></textarea>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid container direction="column">
                    <Box className={classes.checkBoxContainer}>
                      <label>
                        <Field type="checkbox" name="subscribe" />
                        <span>
                          Subscribe me to Oxbow Partners insurance technology
                          emails
                        </span>
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          className={policyError ? classes.inputError : ''}
                          checked={policy}
                          onChange={handleChangePolicy}
                          name="policy"
                        />
                        <span>
                          I consent to Oxbow Partners storing and processing my
                          personal data
                          {/* I agree to Oxbow Partners{' '}
                          <a
                            href="https://oxbowpartners.com/privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a> */}
                          *
                        </span>
                      </label>
                      {policyError && (
                        <span className="errorText">
                          Please check this box if you want to proceed
                        </span>
                      )}
                    </Box>
                  </Grid>
                  <Box mt={2}>
                    <OxbowPrimaryButton
                      background="md"
                      type="submit"
                      disabled={isSubmitting}
                      className={classes.contactUsBtn}
                    >
                      {isSubmitting ? 'Please wait ...' : 'Submit'}
                    </OxbowPrimaryButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
          <Grid container className={classes.contactusFooter}>
            <img src={magellanImgSmall} alt="Magellan small logo" />
            <Typography onClick={redirectToGoogleMaps}>
              15 Bishopsgate, London, EC2N 3AR
            </Typography>
            <Typography>+44 203 947 8620</Typography>
            <Typography onClick={e => redirectToNewEmail(e)}>
              magellan@oxbowpartners.com
            </Typography>
          </Grid>
        </>
      ) : (
        <div className="thxContainer">
          <Typography variant="h1" className={classes.contactUsTitle}>
            Thank you
          </Typography>
          <Typography className={classes.contactHeaderText}>
            We appreciate you contacting us and will get to you soon
          </Typography>
          <Box mt={2}>
            {isAuthenticated ? (
              <Link to="/search">
                <OxbowDarkButton className={classes.contactUsBtn}>
                  Explore the platform
                </OxbowDarkButton>
              </Link>
            ) : (
              <OxbowDarkButton
                onClick={() =>
                  loginWithRedirect({
                    appState: {
                      targetUrl: window.location.pathname
                    },
                    prompt: 'login',
                    screen_hint: 'signup'
                  })
                }
                type="button"
                className={classes.contactUsBtn}
              >
                Sign up to expolre the platform
              </OxbowDarkButton>
            )}
          </Box>
        </div>
      )}
    </>
  )
}

const ContactUsComponent = () => {
  const matches = useMediaQueries()
  const { downSm } = useMediaQueries()
  const classes = useStyles()
  const [thxPageOpen, setThxPageOpen] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [thxPageOpen])

  return (
    <BaseLayoutBody
      parentBodyBg={false}
      pageHeaderBottomLine={false}
      style={{ flexDirection: downSm && 'row' }}
      rightPanel={
        <Box
          className={classes.contactUsMainContainer}
          px={matches.downSm ? 2 : '55px'}
          pb={matches.downMd ? 5 : 8}
          pt={3}
        >
          {!thxPageOpen ? <ContactUsHeader background="#fff" /> : <></>}
          <ContactUsMainComponent
            setThxPageOpen={setThxPageOpen}
            thxPageOpen={thxPageOpen}
          />
        </Box>
      }
      bottomBanner={false}
    ></BaseLayoutBody>
  )
}

export default ContactUsComponent
