import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { derivedFromProp } from 'utils'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '3px 12px !important',
    display: 'inline-block',
    borderRadius: derivedFromProp('type', {
      pill: '2rem',
      default: 0
    }),
    background: derivedFromProp('color', {
      primary: theme.palette.primary.mainLightBlue,
      default: theme.palette.secondary.main,
      secondary: theme.palette.secondary.mainLightBlue,
      transparent: 'rgba(0, 163, 255, 0.1) !important'
    }),
    color: derivedFromProp('color', {
      default: theme.palette.primary.contrastText,
      secondary: theme.palette.primary.dark,
      transparent: 'black !important'
    }),
    fontSize: 13,
    whiteSpace: 'nowrap',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    fontFamily: 'Open Sans',

    opacity: derivedFromProp('dim', {
      true: 0.2,
      default: 1
    }),
    filter: derivedFromProp('dim', {
      true: 'grayscale(100%)',
      default: null
    }),
    '& >div': {
      fontSize: 13
    }
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      background: derivedFromProp('color', {
        transparent: 'rgba(0, 163, 255, 0.3) !important',
        default: 'rgba(0, 163, 255, 0.3)'
      })
    }
  }
}))

export default function Chip({
  label,
  color = null,
  type = null,
  onClick = null,
  dim = false,
  children,
  className = null
}) {
  const classes = useStyles({ color, type, dim })
  if (!label && !children) return null
  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.clickable]: !!onClick
        },
        className
      )}
      onClick={onClick}
    >
      {label || children}
    </div>
  )
}
