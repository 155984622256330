import React from 'react'
import { useStyles } from './useStyles'
import { LinkedIn } from '@material-ui/icons'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import { useAuth0 } from 'components/Contexts/AuthContext'

export default function Footer() {
  const classes = useStyles()
  const { myFavId } = useCollections()
  const { isAuthenticated } = useAuth0()
  const footerNavLinksData = [
    {
      name: 'Privacy policy',
      link: 'https://oxbowpartners.com/privacy-policy/'
    }
  ]

  const navLinksData = [
    {
      name: 'Search',
      link: '/search'
    },
    {
      name: 'Favourites',
      link: `/collections/personal/${myFavId}`
    },
    {
      name: 'Insights',
      link: '/insights'
    },
    {
      name: 'Pricing',
      link: '/pricing'
    },
    {
      name: 'Contact us',
      link: '/contact-us'
    }
  ]

  const landingNavData = [
    {
      name: 'About Magellan',
      link: '/about-magellan'
    },
    {
      name: 'Insights',
      link: '/insights'
    },
    {
      name: 'Pricing',
      link: '/pricing'
    },
    {
      name: 'Contact us',
      link: '/contact-us'
    }
  ]

  const currYear = new Date().getFullYear()

  const footerContent = [
    { text: `©${currYear} Oxbow Partners Ltd` },
    { text: 'Registered in England and Wales 09863607' },
    { text: 'VAT registered 230 6113 59' },
    { text: 'Registered address 82 St John Street, London, EC1M 4JN' }
  ]
  const footerDataLinks = isAuthenticated ? navLinksData : landingNavData

  return (
    <footer className={classes.root}>
      <div className="footer-link-lists-wrapper">
        <ul className="footer-common-link-list">
          {footerDataLinks.map(item => (
            <li key={item.name} className="footer-link-list-item">
              <Link to={item.link}>{item.name}</Link>
            </li>
          ))}
          {footerNavLinksData.map((item, index) => (
            <li className="footer-link-list-item" key={index}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <Box mt={3}>
          <ul className="footer-social-link-list">
            <li className="footer-social-list-item">
              <a
                href="https://www.linkedin.com/showcase/magellan-insurance-technology-navigator/"
                target="_blank"
                rel="noopener noreferrer"
                alt="Oxbow Partner's Linked Profile"
              >
                <LinkedIn />
              </a>
            </li>
          </ul>
        </Box>
      </div>

      <div className="footer-content">
        {footerContent.map((item, index) => (
          <span key={index}>{item.text}</span>
        ))}
      </div>
    </footer>
  )
}
