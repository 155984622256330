/* eslint-disable no-unused-vars */
import { Box, Grid, Portal, Typography } from '@material-ui/core'
import clsx from 'clsx'
import analytics from 'utils/analytics'
import { useAuth0 } from 'components/Contexts/AuthContext'
import BookmarksButton from 'components/Helpers/BookmarksButton'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useCan } from '../../../../../rbac/can'
import useStyles, { useSectioButtonStyles } from '../../styles'
import useRect, { getElementOffset } from 'utils/customHooks/useRect'
import useMediaQueries from 'utils/customHooks/useMediaQueries'

import { useNavContext } from 'components/Contexts/NavbarContext'
import LockIcon from '@material-ui/icons/Lock'
import ArrowLeftBlue from 'assets/icons/ArrowLeftBlue.png'
import { useHistory } from 'react-router-dom'
import { capitalize } from 'utils'

import { useEditProductDetailsContext } from 'components/Contexts/EditProductDetailsContext'
import { OxbowPrimaryTransparentButton } from 'components/Helpers/Buttons'

import DownloadIcon from 'assets/icons/DownloadIcon.svg'
import { exportProductPdf } from 'utils/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { MagellanTooltip } from 'components/Helpers/MagellanTooltip'

function ProductPageHeader({
  isActive,
  rootContainerStyles,
  productId,
  currentView,
  setCurrentView,
  productData,
  sidebarOptions,
  productSlug,
  companyData,
  setCurrId
}) {
  const { isSuggestionsEditsEnabled } = useEditProductDetailsContext()

  const isFullUser = useCan('content_depth:all')
  const { isAuthenticated } = useAuth0()
  const [isExportPdfLoading, setExportPdfLoading] = useState(false)

  const [cursorPosition, setCursorPosition] = useState(0)
  const [isTooltipOpened, setTooltipOpen] = useState(false)

  const [showStickyNav, setShowStickyNav] = useState(false)
  const { downSm, downXs } = useMediaQueries()
  const sectionNavigationElRef = useRef()

  const { top: sectionNavigationTopOffset, ...all } = useRect(
    sectionNavigationElRef
  )

  const { navHeight, prevPage, navbarRef } = useNavContext()

  const classes = useStyles({ showStickyNav, navHeight })
  const navigateToHandleFactory = sectionKey => () => {
    setCurrentView(sectionKey)
    setCurrId('')
  }

  let history = useHistory()

  const goBack = () => {
    if (prevPage === 'home') {
      history.push('/home')
    } else {
      history.goBack()
    }
  }

  useEffect(() => {
    document.querySelector('#root').scrollTo(0, 0)
  }, [currentView])

  const scrollEventListener = useCallback(() => {
    const scrollPosition = document.querySelector('#root').scrollTop
    if (navHeight - scrollPosition < 200 && !showStickyNav) {
      setShowStickyNav(true)
    } else if (showStickyNav && navHeight - scrollPosition >= 200) {
      setShowStickyNav(false)
    }
  }, [navHeight, showStickyNav])

  useEffect(() => {
    navbarRef.current.children.item(0).classList.add('invisible')
    document
      .querySelector('#root')
      .addEventListener('scroll', scrollEventListener, { passive: true })
    return () => {
      navbarRef.current.children.item(0).classList.remove('invisible')
      document
        .querySelector('#root')
        .removeEventListener('scroll', scrollEventListener)
    }
  }, [navbarRef, scrollEventListener])

  const exportPdfHandler = async () => {
    setExportPdfLoading(true)
    try {
      await exportProductPdf(productId, companyData.name)
      setExportPdfLoading(false)
    } catch (err) {
      setExportPdfLoading(false)
    }
  }

  const addToCollectionSection = (
    <Grid container className={classes.btnContainer}>
      <Box
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          position: 'relative'
        }}
      >
        <OxbowPrimaryTransparentButton
          disabled={!isFullUser || isExportPdfLoading || !isActive}
          width="max-content"
          size="md"
          fontSize="sm"
          height="md"
          marginright="sm"
          background="light"
          onClick={() => exportPdfHandler()}
          startIcon={
            <img
              alt=""
              src={DownloadIcon}
              style={{
                opacity:
                  (!isFullUser || isExportPdfLoading || !isActive) && 0.4,
                marginRight: 15
              }}
            />
          }
        >
          Export (PDF)
          {isExportPdfLoading ? (
            <CircularProgress className={classes.circularProgress} />
          ) : null}
        </OxbowPrimaryTransparentButton>
        {!isFullUser && isTooltipOpened && (
          <MagellanTooltip
            left={false}
            right={true}
            trianglePosition="right"
            padding="xs"
            className="customTooltip"
          >
            <Typography
              variant="h6"
              style={{
                color: 'black',
                fontSize: 12,
                fontWeight: 400
              }}
            >
              Upgrade to premium to unlock company export
            </Typography>
          </MagellanTooltip>
        )}
      </Box>
      <Box className={classes.bookmarksButtonContainer}>
        {/* temp removed  */}
        {/* <OxbowPrimaryTransparentButton
          disabled={!isFullUser}
          width="md"
          size="md"
          fontSize="md"
          // padding='sm'
          onClick={() => setOpen(true)}
          startIcon={<Add />}
        >
          Add to collection
        </OxbowPrimaryTransparentButton> */}
        <BookmarksButton
          productTile={false}
          style={{
            display: 'flex',
            justifyContent: 'space-around'
          }}
          productID={productId}
          productSlug={productSlug}
        />
      </Box>
    </Grid>
  )

  return (
    <>
      <div
        className={clsx(classes.productPageMainHeader, {
          relative: showStickyNav
        })}
        style={rootContainerStyles}
      >
        {goBackComponent(classes, goBack)}

        <Grid
          container
          alignItems={downSm ? 'center' : 'flex-start'}
          wrap="nowrap"
          justify={downSm ? 'center' : 'flex-start'}
          direction={downSm ? 'column' : 'row'}
        >
          <Grid className={classes.productHeaderLeabelBox}>
            <Grid container>
              <Grid className={classes.imageWrapper}>
                <img
                  alt="Product Logo"
                  src={_.get(
                    productData,
                    'company.fields.logo.fields.file.url',
                    '/dummy-product-logo.jpg'
                  )}
                />
              </Grid>
              {downSm && (
                <Grid
                  container
                  alignItems="center"
                  className={classes.productHeaderLabel}
                >
                  <Typography variant="h3" className={classes.productName}>
                    {productData.name}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {downSm && addToCollectionSection}
          </Grid>

          <Grid item xs style={{ width: downSm ? '100%' : 'auto' }}>
            <Box
              px={downSm ? 0 : 9}
              pt={downSm ? 4 : 0}
              pb={downSm ? 2 : 0}
              className={classes.mainInfoWrapper}
            >
              <div
                className={
                  isSuggestionsEditsEnabled
                    ? `${classes.mainCompanyInfoContainer} ${classes.inActive}`
                    : classes.mainCompanyInfoContainer
                }
                style={{
                  flexDirection: downXs && 'column'
                }}
              >
                <div className={classes.titleColumn}>
                  <Box className={classes.mainProductInfo}>
                    {!downSm && (
                      <Grid container alignItems="center">
                        <Typography
                          variant="h3"
                          className={classes.productName}
                        >
                          {productData.name}
                        </Typography>
                      </Grid>
                    )}

                    <Box
                      className={classes.sectionNavigationContainer}
                      ref={sectionNavigationElRef}
                    >
                      <div className={classes.sectionNavigationMainBox}>
                        {Object.values(sidebarOptions)
                          .filter(option => option.show)
                          .map(({ key, sidebarOption, isLocked }) => {
                            return (
                              <React.Fragment key={sidebarOption.sectionTitle}>
                                <SectionButton
                                  key={sidebarOption.sectionTitle}
                                  onClick={navigateToHandleFactory(key)}
                                  selected={currentView === key}
                                  isLocked={isLocked}
                                  setCursorPosition={setCursorPosition}
                                >
                                  {capitalize(
                                    sidebarOption.sectionTitle.toLowerCase()
                                  )}
                                </SectionButton>
                              </React.Fragment>
                            )
                          })}
                      </div>
                    </Box>
                  </Box>
                </div>
                <div className={classes.actionButtonContainer}>
                  {isAuthenticated && !downSm && <>{addToCollectionSection}</>}
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      {showStickyNav && (
        <Portal>
          <StickySectionNav
            goBack={goBack}
            currentView={currentView}
            navigateToHandleFactory={navigateToHandleFactory}
            sidebarOptions={sidebarOptions}
            classes={classes}
            productName={productData.name}
          />
        </Portal>
      )}
    </>
  )
}

export const goBackComponent = (classes, goBack) => {
  return (
    <Box mb={1.5} className={classes.backToBtn} onClick={goBack}>
      <img alt="" src={ArrowLeftBlue} />
      <Typography variant="h5">Back to previous page</Typography>
    </Box>
  )
}

const StickySectionNav = ({
  goBack,
  sidebarOptions,
  currentView,
  navigateToHandleFactory,
  classes,
  productName
}) => {
  return (
    <Grid
      container
      className={clsx(classes.sectionNavigationContainer, 'sticky')}
    >
      {goBackComponent(classes, goBack)}
      <Box>
        <Grid container pr={0} className="navItems">
          <Typography variant="h4" className={classes.productName}>
            {productName}
          </Typography>
          <Box className="navItems__items">
            {Object.values(sidebarOptions)
              .filter(option => option.show)
              .map(({ key, sidebarOption, isLocked }) => {
                return (
                  <React.Fragment key={sidebarOption.sectionTitle}>
                    <SectionButton
                      key={sidebarOption.sectionTitle}
                      onClick={navigateToHandleFactory(key)}
                      selected={currentView === key}
                      isLocked={isLocked}
                    >
                      {capitalize(sidebarOption.sectionTitle.toLowerCase())}
                    </SectionButton>
                  </React.Fragment>
                )
              })}
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}

export const SectionButton = ({
  children,
  selected,
  setCursorPosition,
  isLocked,
  onClick,
  ...restProps
}) => {
  const classes = useSectioButtonStyles()
  const elRef = useRef()

  const { setSignupOpen } = useNavContext()

  useEffect(() => {
    if (selected && setCursorPosition) {
      const { left } = getElementOffset(elRef.current)
      const width = elRef.current.clientWidth
      setCursorPosition(left + Math.round(width / 2))
    }
  }, [selected, setCursorPosition])

  const handleClick = useCallback(
    e => {
      if (isLocked) {
        analytics.createEvent({
          category: 'ProductDetails',
          action: 'Section (Locked) clicked',
          label: window.location.pathname.split('/')[2]
        })
        return setSignupOpen(true)
      } else {
        analytics.createEvent({
          category: 'ProductDetails',
          action: 'Section clicked',
          label: window.location.pathname.split('/')[2]
        })
      }
      onClick(e)
    },
    [setSignupOpen, onClick, isLocked]
  )

  return (
    <button
      ref={elRef}
      {...restProps}
      onClick={handleClick}
      className={clsx(classes.root, {
        selected
      })}
    >
      {children}
      {isLocked && <LockIcon />}
    </button>
  )
}

export default ProductPageHeader
