import { history } from 'Router/history'

export const initUrlParams = setFiltersFromUrlParams => {
  // console.log('(fus) App Loaded...   location: ', history.location)
  initUrlParamsBase({
    path: history.location.pathname,
    searchParams: history.location.search,
    setFiltersFromUrlParams
  })
}

export const initUrlParamsRedirect = (
  setFiltersFromUrlParams,
  loginRedirectCompleted
) => {
  // console.log('(fus) App Loaded(Redirect)...   location: ', history.location)

  const urlStr = loginRedirectCompleted
    ? loginRedirectCompleted.targetUrl
    : undefined

  if (urlStr && urlStr.includes('?')) {
    initUrlParamsBase({
      path: urlStr.split('?')[0],
      searchParams: urlStr.split('?')[1],
      setFiltersFromUrlParams
    })
  }
}

export const trackFiltersInit = ({
  filterValues,
  syncFromUrlTaskDone,
  setFiltersInitialised
}) => {
  if (!syncFromUrlTaskDone) {
    if (Object.keys(filterValues).length > 0) {
      setFiltersInitialised(true)
    } else {
      setFiltersInitialised(false)
    }
  }
}

export const syncToUrl = (
  rawFilterValues,
  syncFromUrlTaskDone,
  setActiveFilters
) => {
  // console.log("(fus)Pagination -> syncFromUrlTaskDone",syncFromUrlTaskDone);
  if (syncFromUrlTaskDone) {
    const sf = {}
    Object.entries(rawFilterValues).forEach(([k, v]) => {
      if (Array.isArray(v) || k === 'key') {
        if (v.length > 0) sf[k] = v
      } else {
        const ssf = {}
        Object.entries(v).forEach(([kk, vv]) => {
          if (vv === true) {
            ssf[kk] = vv
          }
        })

        if (Object.keys(ssf).length > 0) sf[k] = ssf
      }
    })
    const encodedSF = encodeURIComponent(JSON.stringify(sf))
    // console.log('(fus) Syncing to URL...  : ', sf)
    Object.keys(sf).length > 0
      ? history.replace(history.location.pathname + '?filterObj=' + encodedSF)
      : history.replace(history.location.pathname)
    setActiveFilters(sf)
  }
}

export const syncFromUrl = ({
  filtersInitialised,
  filtersFromUrlParams,
  syncFromUrlTaskDone,
  setSyncFromUrlTaskDone,
  filterValues,
  setFilterValues
}) => {
  if (filtersInitialised) {
    // console.log('(fus) filtersInitialised: ', filtersInitialised)

    if (filtersFromUrlParams && !syncFromUrlTaskDone) {
      const sf = { ...filterValues }
      Object.entries(filtersFromUrlParams).forEach(([k, v]) => {
        if (Array.isArray(v) || k === 'key') {
          if (v.length > 0) sf[k] = v
        } else {
          const ssf = {}
          Object.entries(v).forEach(([kk, vv]) => {
            if (vv === true) {
              ssf[kk] = vv
            }
          })

          if (Object.keys(ssf).length > 0) sf[k] = { ...sf[k], ...ssf }
        }
      })
      // console.log('(fus) Syncing from URL...  : ', filtersFromUrlParams)
      setFilterValues(sf)
    }
    setSyncFromUrlTaskDone(true)
  }
}

// Helpers

export const initUrlParamsBase = ({
  path,
  searchParams,
  setFiltersFromUrlParams
}) => {
  if (path === '/search') {
    const query = new URLSearchParams(searchParams)
    let filterObj = query.get('filterObj')
    filterObj = filterObj ? JSON.parse(filterObj) : undefined

    if (filterObj) {
      setFiltersFromUrlParams(filterObj)
      // console.log('(fus) Filters extracted from URL Params.')
    }
  }
}
