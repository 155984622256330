import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
  withStyles
} from '@material-ui/core'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { useSearchContext } from 'components/Contexts/SearchContext'
import ProductTile from 'components/Helpers/ProductTile/ProductTile'
import { useBaseLayoutBodyContext } from 'components/Layouts/Base'
import queryString from 'query-string'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Joyride from 'react-joyride'
import { useHistory } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import useInfiniteScroll from 'utils/customHooks/useInfiniteScroll'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { baseConfig, steps } from './Guided-Tour-Steps'
import { Pagination } from './Pagination'
import { useStyles } from './useStyles'
import { OxbowDarkButton } from 'components/Helpers/Buttons'
import PaginationButtons, {
  SearchPagePagination
} from 'components/Helpers/PaginationButtons'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'
import { useAuth0 } from 'components/Contexts/AuthContext'
import LandingModal from '../LandingPage/LandingModal'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'
import ModalLayout from '../PricingPage/Modals/ModalLayout'
import { UserCTA } from './components/FreeRestrictions'

const FilterButton = withStyles(theme => ({
  root: {
    background: 'transparent',
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontSize: 22,
    fontWeight: 'bold',
    padding: theme.spacing(0, 1),
    '&:hover': {
      background: 'transparent'
    }
  }
}))(OxbowDarkButton)

export const FreeUserStickyNotes = () => {
  const classes = useStyles()
  const { setSearchRequestModalOpen } = useFilterStateContext()
  const { freeUserSearchLimits } = useSearchContext()
  const maxCount = 20
  return (
    <Grid className={classes.stickyNotes}>
      <Box>
        <Typography variant="h4">
          {freeUserSearchLimits} searches available
        </Typography>
        {freeUserSearchLimits <= maxCount / 2 && freeUserSearchLimits > 0 && (
          <Box>
            <Typography variant="h6">
              Running low on searches? <br />
              <span onClick={() => setSearchRequestModalOpen(true)}>
                Get in touch
              </span>{' '}
              for a top-up
            </Typography>
          </Box>
        )}
        {freeUserSearchLimits === 0 && (
          <Typography variant="h6">
            <span onClick={() => setSearchRequestModalOpen(true)}>
              Get in touch
            </span>{' '}
            for a top-up
          </Typography>
        )}
      </Box>
    </Grid>
  )
}

export function SearchResults() {
  const classes = useStyles()
  const isMobileDevice = useMediaQuery('(max-width:600px)')
  const history = useHistory()
  const matches = useMediaQueries()
  const { setLeftbarOpen } = useBaseLayoutBodyContext()
  const { user } = useAuth0()
  const {
    searchResults,
    loadingResults,
    searchResultsLength,
    freeUserData,
    isSearchEnabled,
    freeUserSearchLimits
  } = useSearchContext()
  const { personalData } = useCustomisationJourney()
  const { pageSize } = useSearchPaginationContext()
  const {
    bootstrappingFilters,
    filterValues,
    freeUserFilters,
    isSearchRequestModalOpen,
    setSearchRequestModalOpen,
    isApplyFiltersBtnVisible,
    isFreeUser,
    isFilterSelected
  } = useFilterStateContext()
  const currentScrollPositionRef = useRef(0)
  const [_DATA, , count] = PaginationButtons({
    data: searchResults ? searchResults : [],
    countPerPage: pageSize,
    searchResultsLength: searchResultsLength ? searchResultsLength : 1
  })
  //  ------------------ Manage Infinite Scroll --------------------- //
  const scrollValue = useMemo(() => {
    return parseInt(queryString.parse(history.location.search).scroll)
  }, [history.location.search])
  const [
    numScroll,
    startValue,
    endValue,
    setCurrentScrollValue,
    currentScrollValue,
    itemsPerScroll
  ] = useInfiniteScroll({
    itemsPerScroll: 10,
    totalItems: Object.keys(searchResults).length,
    scrollValue: scrollValue || 1
  })
  const [isMoreSearchesThxOpen, setMoreSearchesThxOpen] = useState(false)
  const [productsListWrapperStyles, setProductsListWrapperStyles] = useSpring(
    () => ({
      opacity: 0
    })
  )

  const handleScrollButtonClick = action => () => {
    if (action === 'more') {
      if (currentScrollValue !== numScroll) {
        sessionStorage.setItem('paginationscrollvalue', currentScrollValue + 1)
        setCurrentScrollValue(prevPageValue => prevPageValue + 1)
      }
      currentScrollPositionRef.current =
        window.pageYOffset || document.documentElement.scrollTop
    }
  }

  useEffect(() => {
    window.scrollTo(0, currentScrollPositionRef.current)
  }, [endValue])

  useEffect(() => {
    setCurrentScrollValue(1)
    if (matches.downSm) {
      currentScrollPositionRef.current = 0
      window.scrollTo(0, 0)
      // setLeftbarOpen(false)
    }
    if (matches.downXs) {
      setLeftbarOpen(true)
    }
  }, [filterValues, setCurrentScrollValue, setLeftbarOpen, matches])

  useEffect(() => {
    setCurrentScrollValue(1)
    currentScrollPositionRef.current = 0
    window.scrollTo(0, 0)
  }, [isApplyFiltersBtnVisible, setCurrentScrollValue])

  useEffect(() => {
    let paginationscrollvalue = sessionStorage.getItem('paginationscrollvalue')
    let searchscrollpos = sessionStorage.getItem('searchscrollpos')
    paginationscrollvalue = paginationscrollvalue && +paginationscrollvalue

    if (paginationscrollvalue) {
      setCurrentScrollValue(paginationscrollvalue)
    }

    if (searchscrollpos) {
      setTimeout(() => {
        const htmltag = document.getElementsByTagName('HTML')
        htmltag[0].style.scrollBehavior = 'smooth'
        window.scrollTo(0, parseFloat(searchscrollpos))
        htmltag[0].style.scrollBehavior = 'initial'
        sessionStorage.removeItem('searchscrollpos')
      }, 650)
    }
  }, [setCurrentScrollValue])

  // Flash effect for searchResults change
  useEffect(() => {
    // if (loadingResults) return
    setProductsListWrapperStyles({
      to: {
        opacity: 1
      },
      immediate: false
    })

    return () => {
      setProductsListWrapperStyles({
        to: {
          opacity: 0
        },
        immediate: true
      })
    }
  }, [filterValues, setProductsListWrapperStyles, loadingResults])

  const [openCommentsPopUp, setOpenCommentsPopUp] = useState(null)

  const productTiles = useMemo(
    () =>
      Object.entries(searchResults).map(([_id, item], index, items) => {
        const isLastTile = index === items.length - 1
        const isFirstTile = index === 0
        return item ? (
          <Box mb={isLastTile ? 0 : 1} mt={isFirstTile ? 2 : 0} key={item.id}>
            <ProductTile
              fromSearchPage
              productId={item.id}
              column={isMobileDevice}
              name={item.name || 'No name provided'}
              shortDescription={item.description || 'No short description'}
              slug={item.slug}
              logoUrl={item?.logo || item?.company?.logo}
              fields={item}
              openCommentsPopUp={openCommentsPopUp}
              setOpenCommentsPopUp={setOpenCommentsPopUp}
              rootStyles={{
                borderBottom: isLastTile ? 'none' : '1px solid #E5E5E5'
              }}
            />
          </Box>
        ) : null
      }),
    [searchResults, isMobileDevice, openCommentsPopUp]
  )

  if (isSearchEnabled && (loadingResults || bootstrappingFilters)) {
    return (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress />
      </Container>
    )
  }

  const modalData = {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail'
  }

  return (
    <div className={classes.root}>
      {matches.downSm && (
        <Box>
          <FilterButton
            size="xxl"
            onClick={() => setLeftbarOpen(prev => !prev)}
            startIcon={<Icon className="fas fa-filter" color="secondary" />}
          >
            Filters
          </FilterButton>
        </Box>
      )}

      <animated.div style={productsListWrapperStyles}>
        <Box mb={0} id="paginationContainer">
          <Pagination
            resultsLength={searchResultsLength}
            startValue={startValue}
            endValue={endValue}
            itemsPerPage={itemsPerScroll}
            handlePaginationBtnClick={handleScrollButtonClick}
            searchTerm={isFreeUser ? freeUserFilters.key : filterValues.key}
            totalNumberOfPages={numScroll}
            initialPage={currentScrollValue}
            hidePageOptions={true}
            rawFilterValues={isFreeUser ? freeUserFilters : filterValues}
            history={history}
          ></Pagination>
        </Box>
        <div
          style={{
            height: 'calc(100vh - 140px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <Box
            id="searchResultsContainer"
            style={{
              width: matches.downSm ? '100%' : isFreeUser ? '80%' : '100%',
              padding: '0 4px'
            }}
          >
            {isFreeUser && !matches.downSm && (
              <FreeUserStickyNotes
                setSearchRequestModalOpen={setSearchRequestModalOpen}
              />
            )}
            {!isFilterSelected || !isSearchEnabled ? (
              <UserCTA searchLimits={freeUserSearchLimits} />
            ) : (
              productTiles
            )}
            {/* { productTiles } */}
          </Box>
          {!isFilterSelected ||
          !isSearchEnabled ||
          (isFreeUser && freeUserData && freeUserSearchLimits === 0) ||
          searchResultsLength < 10 ? null : (
            <SearchPagePagination
              countPerPage={count}
              _DATA={_DATA}
              freeUserSearchLimits={!freeUserData}
            />
          )}
        </div>
      </animated.div>

      <Joyride steps={steps} run={false} debug={true} {...baseConfig} />
      <LandingModal
        emailInputWidth="full"
        checkBoxContainerPosition="center"
        submitBtnText="Confirm request"
        modalData={modalData}
        userName={personalData.firstName}
        userEmail={user.email}
        userNickName={personalData.lastName}
        headerTitle="Request more searches"
        isDemoFormOpen={isSearchRequestModalOpen}
        setDemoFormOpen={setSearchRequestModalOpen}
        isThxModalOpen={false}
        setMoreSearchesThxOpen={setMoreSearchesThxOpen}
        setThxModalOpen={() => {}}
        btnWidth="max-content"
        isFreeUser={isFreeUser}
        submissionType={2}
      />
      {isMoreSearchesThxOpen && (
        <ModalLayout
          open={isMoreSearchesThxOpen}
          setOpen={setMoreSearchesThxOpen}
        >
          <Box
            style={{
              padding: '30px 30px 50px',
              '& p': {
                margin: '0 !important'
              }
            }}
          >
            <Grid style={{ marginBottom: '15px' }}>
              <Typography component="h2">Thank you</Typography>
              <Typography>
                We appreciate you contacting us and will get to you soon{' '}
              </Typography>
            </Grid>
          </Box>
        </ModalLayout>
      )}
    </div>
  )
}

export default SearchResults
