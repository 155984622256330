import MuiChip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'
import React from 'react'
import {withStyles} from '@material-ui/core/styles'

const StyledChip = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.secondary.main,
    border: '1px solid #00A3FF',
    borderRadius: '20px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.secondaryFont,
    fontWeight: 400,
    fontSize: '13px',
    height: '28px',
    margin: '5px 5px 7px 0',
    padding: '4px 8px',
    width: 'max-content',
  },
  clickable: {
    '&:hover': {
      background: theme.palette.secondary.main,
    },
    '&:focus': {
      background: theme.palette.secondary.main,
    },
  },
  label: {
    paddingRight: '0',
    paddingLeft: '0',
  },
}))(MuiChip)

const Chip = (props) => {
  if (!props.label) return null

  return (
    <StyledChip {...props}/>
  )
}

Chip.propTypes = {
  label: PropTypes.string,
}

export default Chip
