import '@fortawesome/fontawesome-free/css/all.min.css'
import React, { useEffect } from 'react'
import Router from './Router'
import { hot } from 'react-hot-loader/root'
import AppProviders from './Providers/AppProviders'

import SignUpModal from './components/Helpers/SignUpModal'
import Auth0MessageDialog from 'components/Helpers/Auth0MessageDialog/Auth0MessageDialog'
import analytics from './utils/analytics'
import { useAuth0 } from './components/Contexts/AuthContext'

function App() {
  const { user } = useAuth0()
  useEffect(() => {
    analytics.initialize(user?.sub)
  }, [user])

  return (
    <AppProviders>
      <Router />
      <SignUpModal />
      <Auth0MessageDialog />
    </AppProviders>
  )
}

export default hot(App)
