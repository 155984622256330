import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useStyles } from '../useStyles'
import produce from 'immer'
import { ReactComponent as JourneyCheckBox } from 'assets/icons/JourneyCheckBox.svg'
import { ReactComponent as JourneyCheckBoxChecked } from 'assets/icons/JourneyCheckBoxChecked.svg'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import JourneyAutocomplete from '../JourneyAutocomplete'

import { ChipsToShow } from 'components/Helpers/ChipsToShow'

export const StepsComponent = ({
  checkBoxes = false,
  article,
  header,
  selectAll,
  chipsType,
  selectorType,
  dataDetails,
  setDataDetails,
  selectName,
  divider,
  isInsuranceProductDistributorsChecked,
  setInsuranceProductDistributorsChecked,
  countriesData,
  otherValue,
  setOtherValue,
  journeyPage,
  placeholder,
  setError,
  error,
  checkedCountries,
  setCheckedCountries,
  isTextFieldOpened,
  setIsTextFieldOpened,
  margin,
  justifyContent,
  width,
  fontWeight,
  alignSelf,
  inputWidth,
  isEditModeOpened
}) => {
  const classes = useStyles({
    margin,
    justifyContent,
    width,
    fontWeight,
    alignSelf,
    inputWidth
  })

  const { suggestInputsFiltersData } = useFilterStateContext()

  const [checkedIntentions, setCheckedIntentions] = useState(
    dataDetails['intentionsOfUse']?.length > 0
      ? dataDetails['intentionsOfUse']
      : []
  )
  const checkedCountriesArr = checkedCountries.map(i => i.children)
  const filteredSelectedRegions = checkedCountries.filter(i => !i.selected)
  const selectedCountries = filteredSelectedRegions.map(i => i.children).flat()
  const selectedRegion = checkedCountries.filter(i => i.selected)

  const isPersonalPage =
    window.location.pathname === '/personal-account' ? true : false

  const classType = chipsType
    ? chipsType
    : isPersonalPage
    ? chipsType
    : selectorType

  const optionsData = type => {
    if (type === 'regionsAndCountries') {
      return countriesData
    } else return suggestInputsFiltersData[type]?.options?.map(i => i)
  }

  const handleClick = name => {
    if (dataDetails[classType]?.includes(name)) {
      const filtered = dataDetails[classType].filter(item => item !== name)
      updateProduct(classType, filtered)
    } else {
      updateProductDetails(classType, [name])
    }
  }

  const removeSelectedItem = (type, name) => {
    const selectedValue = name ? name : type
    if (dataDetails[selectorType]?.includes(selectedValue)) {
      const filtered = dataDetails[selectorType].filter(
        item => item !== selectedValue
      )
      updateProduct(selectorType, filtered)
    } else {
      updateProductDetails(selectorType, [selectedValue])
    }
  }

  const updateProduct = (filterName, value) => {
    setDataDetails(prev =>
      produce(prev, draftState => {
        draftState[filterName] = value
      })
    )
  }

  const handleChipClick = (optionsDataId = null) => {
    // for countries and regions
    if (Array.isArray(optionsDataId)) {
      if (
        optionsDataId.length > 0 &&
        dataDetails[classType]?.includes(...optionsDataId)
      ) {
        const filtered = dataDetails[classType].filter(
          item => !optionsDataId.includes(item)
        )

        setDataDetails(prev =>
          produce(prev, draftState => {
            draftState[classType] = filtered
          })
        )
      } else {
        const filtered = optionsDataId.filter(
          item => !dataDetails[classType]?.includes(item)
        )
        setDataDetails(prev =>
          produce(prev, draftState => {
            draftState[classType] = prev[classType]
              ? [...prev[classType], ...filtered]
              : optionsDataId
          })
        )
      }
    } else {
      let allItems = []
      let allCountriesArr = []
      if (classType === 'regionsAndCountries') {
        const allCountriesData = optionsData(classType)?.map(i => {
          return i.children
        })

        allCountriesArr = allCountriesData.flat().map(i => i.value)
      } else {
        allItems = optionsData(classType)?.map(i => {
          return i.value
        })
      }
      const isAllCountriesSelected =
        allItems.length > 0 ? allItems : allCountriesArr
      if (dataDetails[classType]?.length === isAllCountriesSelected.length) {
        updateProduct(classType, [])
      } else {
        setDataDetails(prev =>
          produce(prev, draftState => {
            draftState[classType] = isAllCountriesSelected
          })
        )
      }
    }
  }

  const updateProductDetails = (filterName, value) => {
    setDataDetails(prev =>
      produce(prev, draftState => {
        draftState[filterName] = prev[filterName]
          ? [...prev[filterName], ...value]
          : value
      })
    )
  }

  const handleChange = event => {
    if (event.target.value === 'No') {
      setInsuranceProductDistributorsChecked(false)
    } else {
      setInsuranceProductDistributorsChecked(true)
    }
  }

  const handleRegionCheck = (country, region) => {
    const regionIndex = countriesData.map(i => i.region).indexOf(region)
    const isSelected = countriesData
      .map(i => i.children.map(i => i.value))
      [regionIndex].includes(...country)

    const filteredArr = checkedCountries.filter(
      (_, index) => index !== regionIndex
    ) // remove the region from the array

    const addedCountries = [
      ...filteredArr, // remove all regions except the one we are checking
      {
        ...checkedCountries[regionIndex],
        selected: !isSelected,
        children: [...checkedCountries[regionIndex].children, ...country]
      }
    ]

    const updateArr = [
      ...filteredArr,
      {
        ...checkedCountries[regionIndex],
        selected: isSelected,
        children: [...country]
      }
    ]

    if (checkedCountries[regionIndex].children.length !== country.length) {
      setCheckedCountries(
        updateArr.sort((a, b) => a.region.localeCompare(b.region))
      )
    } else {
      setCheckedCountries(
        addedCountries.sort((a, b) => a.region.localeCompare(b.region))
      )
      if (checkedCountries[regionIndex].children.includes(...country)) {
        // if the country is already selected
        const filteredCountries = checkedCountries[regionIndex].children.filter(
          item => !country.includes(item)
        )

        setCheckedCountries(prev =>
          [
            ...filteredArr,
            { ...prev[regionIndex], children: filteredCountries }
          ].sort((a, b) => a.region.localeCompare(b.region))
        )
      }
    }
  }

  const handleCountryCheck = (country, region) => {
    const regionIndex = countriesData.map(i => i.region).indexOf(region)

    const filteredArr = checkedCountries.filter(
      (_, index) => index !== regionIndex
    )

    const sortedCountries = [
      ...checkedCountries[regionIndex].children,
      country
    ].sort((a, b) => a.localeCompare(b))

    const addedCountries = [
      ...filteredArr,
      {
        ...checkedCountries[regionIndex],
        children: sortedCountries
      }
    ]
    if (checkedCountries[regionIndex].children.includes(country)) {
      const filteredCountries = checkedCountries[regionIndex].children.filter(
        item => item !== country
      )

      setCheckedCountries(prev =>
        [
          ...filteredArr,
          {
            ...prev[regionIndex],
            children: filteredCountries
          }
        ].sort((a, b) => a.region.localeCompare(b.region))
      )
    } else {
      setCheckedCountries(
        addedCountries.sort((a, b) => a.region.localeCompare(b.region))
      )
    }
  }

  const handleIntendChange = event => {
    if (event.target.name === 'Other') {
      setIsTextFieldOpened(!isTextFieldOpened)
    } else if (checkedIntentions.includes(event.target.name)) {
      const filtered = checkedIntentions.filter(
        item => item !== event.target.name
      )
      setCheckedIntentions(filtered)
      updateProduct('intentionsOfUse', filtered)
    } else {
      setCheckedIntentions([...checkedIntentions, event.target.name])
      updateProduct('intentionsOfUse', [
        ...checkedIntentions,
        event.target.name
      ])
    }
  }

  const setOtherValueHandler = e => {
    if (e.target.value) {
      setOtherValue(e.target.value)
    } else {
      setOtherValue('')
    }
  }
  const maxLength = 500
  const maxTextLength = () => {
    if (!otherValue) {
      return 0
    }
    return otherValue.length
  }

  useEffect(() => {
    let errors = ''
    if (!otherValue) {
      errors = 'Please fill out this field.'
    }
    setError(errors)
  }, [otherValue, setError])

  useEffect(() => {
    if (!checkedIntentions.includes('Other')) {
      setError('')
    }
  }, [checkedIntentions, setError])

  const errorMsgContainer = msg => {
    return (
      <>
        <span className={classes.errorText}>{msg}</span>
      </>
    )
  }
  if (!dataDetails) return null

  return (
    <Grid className={classes.mainStepsBlock}>
      {article && (
        <Typography variant="subtitle2" align="center">
          {article}
        </Typography>
      )}
      <Typography variant="h3" align="center">
        {header}
      </Typography>
      {checkBoxes ? (
        <Grid container direction="column" className={classes.intendToUse}>
          {checkBoxes.map((i, index) => {
            return (
              <FormControlLabel
                key={index}
                label={<Typography variant="subtitle2">{i}</Typography>}
                control={
                  <Checkbox
                    key={i}
                    name={i}
                    value={i === 'Other' ? otherValue : i}
                    id={`custom-checkbox-${index}`}
                    checked={
                      i === 'Other'
                        ? isTextFieldOpened
                        : checkedIntentions.includes(i)
                    }
                    onChange={event => handleIntendChange(event, index)}
                    icon={<JourneyCheckBox />}
                    checkedIcon={<JourneyCheckBoxChecked />}
                  />
                }
              />
            )
          })}

          {isTextFieldOpened ? (
            <Grid item>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 600, margin: '10px 0' }}
              >
                Please specify
              </Typography>
              <div className={classes.textAreaBox}>
                <textarea
                  required
                  style={{ paddingBottom: '20px', boxSizing: 'border-box' }}
                  maxLength={maxLength}
                  value={otherValue || ''}
                  onChange={e => setOtherValueHandler(e)}
                  rows={5}
                  cols={10}
                  name="description"
                />

                <p
                  className={classes.wordsLimits}
                  style={{ color: maxTextLength() >= maxLength && 'red' }}
                >
                  {maxTextLength()} / {maxLength}
                </p>
              </div>
              {error && errorMsgContainer(error)}
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Grid>
          {isEditModeOpened && classType === 'insuranceClass' ? (
            <Typography
              style={{ fontWeight: 600, marginBottom: 15 }}
              variant="h4"
            >
              Select Insurance class
            </Typography>
          ) : null}
          <Grid container className={classes.stepsChips}>
            {selectAll && (
              <>
                {journeyPage === 3 ? null : (
                  <Typography
                    variant="h6"
                    className={clsx(classes.journeyChips, {
                      [classes.selectedChipsValue]:
                        dataDetails[classType]?.length ===
                        optionsData(classType)?.length
                    })}
                    onClick={() => handleChipClick()}
                  >
                    Select all
                  </Typography>
                )}
              </>
            )}

            {classType &&
              classType !== 'basicSubclass' &&
              optionsData(classType)
                ?.slice(0, 4)
                .map((i, index) => {
                  return (
                    <React.Fragment key={index}>
                      {journeyPage === 3 ? null : (
                        <Typography
                          variant="h6"
                          className={clsx(classes.journeyChips, {
                            [classes.selectedChipsValue]: dataDetails[
                              classType
                            ]?.includes(i.value)
                          })}
                          onClick={() => {
                            handleClick(i.value)
                          }}
                        >
                          {i.value}
                        </Typography>
                      )}
                    </React.Fragment>
                  )
                })}
          </Grid>
          <Grid
            container
            alignItems="center"
            direction="column"
            className={classes.selectorBox}
          >
            <Grid container alignItems="center" wrap="nowrap">
              {divider ? (
                <>
                  <Divider style={{ marginRight: 20 }} />
                  <Typography variant="h4">{selectName}</Typography>
                  <Divider style={{ marginLeft: 20 }} />
                </>
              ) : (
                <Typography
                  variant="h4"
                  style={{
                    marginTop:
                      selectName === 'Select Insurance subclass' ? 15 : 0
                  }}
                >
                  {selectName}
                </Typography>
              )}
            </Grid>

            <JourneyAutocomplete
              productDetails={
                optionsData(selectorType) ? optionsData(selectorType) : []
              }
              setProductDetails={removeSelectedItem}
              id={selectorType}
              dataDetails={
                dataDetails[selectorType] ? dataDetails[selectorType] : []
              }
              countriesData={countriesData}
              handleChipClick={handleChipClick}
              placeholder={placeholder}
              handleCountryCheck={handleCountryCheck}
              handleRegionCheck={handleRegionCheck}
              checkedCountries={checkedCountriesArr}
              handleClick={handleClick}
              alignSelf={alignSelf}
              inputWidth={inputWidth}
            />
            <Box
              style={{
                justifyContent: 'flex-start',
                width: '100%',
                flexWrap: 'wrap',
                overflow: 'auto'
              }}
            >
              <ChipsToShow
                handleChipClick={handleChipClick}
                handleRegionCheck={handleRegionCheck}
                selectedRegion={selectedRegion}
                selectedItems={selectedCountries}
                data={dataDetails[selectorType]}
                isFromJourney={true}
                editable={true}
                onClick={handleClick}
                onClear={removeSelectedItem}
                type={selectorType}
                count={5}
                marginTop="sm"
                btnLabel="See"
              />
            </Box>
            {selectorType === 'primaryFunction' ? (
              <>
                <Typography
                  variant="h4"
                  style={{
                    marginTop: 15
                  }}
                >
                  Are you also interested in insurance product distributors,
                  such as Tech MGAs?
                </Typography>

                <FormControl className={classes.checkBox}>
                  <RadioGroup
                    className={classes.checkBox}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={isInsuranceProductDistributorsChecked}
                    onChange={e => handleChange(e)}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          checked={isInsuranceProductDistributorsChecked}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          checked={!isInsuranceProductDistributorsChecked}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            ) : null}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
