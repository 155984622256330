import React, { useState } from 'react'
import {
  Box,
  Typography,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Grid
} from '@material-ui/core'
import cross from 'assets/icons/cross.svg'
import { OxbowPrimaryButton, OxbowPrimaryTransparentButton } from '../Buttons'
import { useStyles } from './useStyles'
import { deleteSavedSearchesAppliedFilters } from 'utils/api'
import { getClearFilterSectionState } from 'utils/FilterBootstrapUtils'
import staticFilterConfig from 'configs/filters.config'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'

const DeleteSavedSearchModal = ({
  title,
  text,
  setDeleteModalOpen,
  isDeleteModalOpen,
  id,
  setReloadSavedSearches,
  setFilterValues
}) => {
  const classes = useStyles()
  const { filterConfig } = useFilterStateContext()
  const [loading, setLoading] = useState(false)
  const { setAllCompaniesPage } = useSearchPaginationContext()

  const clearAllFilters = () => {
    setAllCompaniesPage(1)
    setFilterValues(prev => ({
      ...prev,
      ...getClearFilterSectionState(filterConfig['productFilters']),
      ...getClearFilterSectionState(filterConfig['companyFilters']),
      ...getClearFilterSectionState(staticFilterConfig['categoryFilters']),
      key: ''
    }))
  }

  const saveChanges = async () => {
    setLoading(true)
    try {
      await deleteSavedSearchesAppliedFilters(id)

      setLoading(false)
      setDeleteModalOpen(false)
      setReloadSavedSearches(true)
      clearAllFilters()
    } catch (error) {
      // console.log(error)
      setLoading(false)
      setDeleteModalOpen(false)
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isDeleteModalOpen}
      onClose={() => setDeleteModalOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.deleteSavedSearch}
    >
      <Fade in={isDeleteModalOpen}>
        <Box className="savedSerchesConfirmBox">
          <Typography className={classes.textInputHeading}>{title}</Typography>
          <img alt="" src={cross} onClick={() => setDeleteModalOpen(false)} />
          <Box className="textHeading">{text}</Box>
          <Box
            mt={2}
            className={classes.btnContainer}
            style={{ justifyContent: 'center' }}
          >
            <OxbowPrimaryTransparentButton
              className={classes.contactUsBtn}
              onClick={() => setDeleteModalOpen(false)}
              width="smd"
              fontSize="md"
            >
              Cancel
            </OxbowPrimaryTransparentButton>

            <OxbowPrimaryButton
              fonttype="secondary"
              width="md"
              fontSize="md"
              className={classes.contactUsBtn}
              onClick={saveChanges}
            >
              Delete
            </OxbowPrimaryButton>
          </Box>
          {loading ? (
            <Grid className={classes.deleteLoader}>
              <CircularProgress />
            </Grid>
          ) : (
            ''
          )}
        </Box>
      </Fade>
    </Modal>
  )
}

export default DeleteSavedSearchModal
