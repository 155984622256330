import React, { createContext, useContext, useState } from 'react'

const CollectionTabsContext = createContext()
export const useCollectionTabsContext = () => useContext(CollectionTabsContext)

export default function CollectionTabsContextProvider({ children }) {
  const [defaultPage, setDefaultPage] = useState(null)

  return (
    <CollectionTabsContext.Provider
      value={{
        defaultPage,
        setDefaultPage
      }}
    >
      {children}
    </CollectionTabsContext.Provider>
  )
}
