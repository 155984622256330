import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Paper,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  Grid,
  CircularProgress
} from '@material-ui/core'
import staticFilterConfig from 'configs/filters.config'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import EditIcon from '@material-ui/icons/Edit'
import { useStyles } from './useStyles'
import EditSearcModal from './EditSearcModal'
import DeleteSavedSearchModal from './DeleteSavedSearchModal'
import PaginationButtons, { SavedSearchPagination } from '../PaginationButtons'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { getSavedSearchFilters } from 'utils/api'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'

import { MobileTable } from './SavedSearchMobileTable'
import { useSearchContext } from 'components/Contexts/SearchContext'
import { ChipsToShow } from '../ChipsToShow'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'

const text = () => {
  return (
    <>
      <Typography>Are you sure you want to delete this search?</Typography>
    </>
  )
}

function getSelectedFilters(allResData, filterDetails) {
  let _selectedFilters = {}
  for (let filterName in allResData?.filters) {
    const _filterConfig = filterDetails[filterName]
    const _filterValue = allResData?.filters[filterName]

    if (Array.isArray(_filterValue)) {
      if (_filterConfig && _filterValue.length > 0) {
        if (_filterConfig.isChild) {
          const parentName = _filterConfig.parentName

          if (!_selectedFilters[parentName])
            _selectedFilters[parentName] = {
              title: filterDetails[parentName].title,
              values: _filterValue,
              field: parentName
            }
          else
            _selectedFilters[parentName].values = [
              ...new Set([
                ..._selectedFilters[parentName].values,
                ..._filterValue
              ])
            ]
        } else {
          _selectedFilters[filterName] = {
            title: _filterConfig.title,
            values: _filterConfig.optionMapping
              ? _filterValue.map(
                  option => _filterConfig.optionMapping[option].label
                )
              : _filterValue,
            field: filterName
          }
        }
      }
    } else {
      if (_filterConfig) {
        const tf = []
        Object.entries(_filterValue).forEach(([kk, vv]) => {
          if (vv) {
            const valueLabel = _filterConfig.options.find(el => el.name === kk)
              ?.label
            tf.push(valueLabel)
          }
        })
        if (tf.length > 0) {
          _selectedFilters[filterName] = {
            title: _filterConfig.title,
            values: tf,
            field: filterName,
            rawValues: _filterValue
          }
        }
      }
    }
  }

  return _selectedFilters
}

function Row({
  row,
  setFilterValues,
  setReloadSavedSearches,
  savedSearchesData,
  getAppliedFilters,
  setSavedSearchModalOpen,
  appliedFiltersOpen,
  isFullUser,
  maxCheckedFiltersLength,
  setAllCompaniesPage,
  setSavedSearchApply
}) {
  const classes = useStyles()
  const { setExcludeIds } = useSearchContext()
  const { suggestInputsFiltersData } = useFilterStateContext()

  const [open, setOpen] = useState(false)
  const [isEditIconVisible, setEditIconVisible] = useState(false)
  const [isEditSearchOpen, setEditSearchOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const [filtersChips, setFiltersChips] = useState({})
  const [allResData, setAllResData] = useState({})

  useEffect(() => {
    if (savedSearchesData && savedSearchesData.length > 0) {
      setAllResData(savedSearchesData)
    }
  }, [savedSearchesData])

  const filterDetails = useMemo(
    () => ({
      ...suggestInputsFiltersData,
      ...staticFilterConfig.categoryFilters.filters
    }),
    [suggestInputsFiltersData]
  )

  const getSelectedFiltersData = useMemo(() => {
    const filters = getSelectedFilters(allResData, filterDetails)
    return filters
  }, [allResData, filterDetails])

  useEffect(() => {
    const newSelectedFilters =
      allResData.search_query === ''
        ? { ...getSelectedFiltersData }
        : {
            ...getSelectedFiltersData,
            search_query: allResData.search_query
          }

    setFiltersChips(newSelectedFilters)
  }, [allResData.search_query, getSelectedFiltersData])

  const getNewResultsData = data => {
    const dataValues = data ? data : allResData
    setAllCompaniesPage(1)
    setFilterValues(prev => ({
      ...prev,
      ...dataValues.filters,
      key: dataValues.search_query
    }))
    setExcludeIds(dataValues?.products_snapshot?.ids)
    setSavedSearchModalOpen(false)
  }

  const setNewResults = async id => {
    const { data } = await getSavedSearchFilters(id)
    if (data) {
      getNewResultsData(data)
      setSavedSearchApply(true)
    }
  }

  return (
    <React.Fragment>
      <TableRow className={classes.mainTableRow}>
        <TableCell
          className="tableArrow"
          onClick={() =>
            open
              ? setOpen(false)
              : appliedFiltersOpen(row.id, open, setOpen, setAllResData)
          }
        >
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <ArrowForwardIosIcon style={{ transform: 'rotate(90deg)' }} />
            ) : (
              <ArrowForwardIosIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          onMouseEnter={() => (open ? setEditIconVisible(true) : null)}
          onMouseLeave={() => setEditIconVisible(false)}
          scope="row"
          className={classes.tableName}
          style={{ width: 'max-content' }}
        >
          <Typography
            onClick={() => getAppliedFilters(row.id, setAllResData)}
            className={classes.savedSearchName}
          >
            {row.name}
          </Typography>
          {isFullUser && (
            <EditIcon
              className={classes.editIcon}
              style={{
                visibility: isEditIconVisible ? 'visible' : 'hidden'
              }}
              onClick={() => {
                setEditSearchOpen(true)
              }}
            />
          )}
        </TableCell>
        <TableCell align="left" style={{ width: '200px' }}>
          <Typography>{row.total_results}</Typography>
        </TableCell>

        <TableCell align="left" style={{ width: '200px' }}>
          <Typography
            onClick={() => setNewResults(row.id)}
            style={{
              cursor: row.new_results > 0 && 'pointer',
              pointerEvents: row.new_results === 0 && 'none',
              color: row.new_results > 0 && '#0E90D9'
            }}
          >
            {row.new_results}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          style={{ width: '200px' }}
          className={classes.tableDeleteBtn}
          onClick={() => setDeleteModalOpen(true)}
        >
          <Typography>Delete</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={classes.collapseBox}
          >
            <Box pl={5} pr={5}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.titleName}
              >
                Applied filters
              </Typography>
              <Box
                mt={1}
                mb={2}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center'
                }}
              >
                {filtersChips.search_query &&
                  filtersChips.search_query !== ' ' && (
                    <span
                      className={classes.searchTerm}
                      style={{
                        marginTop: 10
                      }}
                    >
                      <span>{filtersChips.search_query}</span>{' '}
                    </span>
                  )}
                {Object.entries(filtersChips)?.map(([filterName, el]) => {
                  return (
                    <React.Fragment key={filterName}>
                      <ChipsToShow
                        data={el?.values}
                        isFromJourney={true}
                        count={5}
                        marginTop="sm"
                        btnLabel="See"
                      />
                    </React.Fragment>
                  )
                })}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Delete Saved Search Modal */}
      <DeleteSavedSearchModal
        setFilterValues={setFilterValues}
        setReloadSavedSearches={setReloadSavedSearches}
        id={row.id}
        setDeleteModalOpen={setDeleteModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        title="Delete saved search"
        text={text()}
      />

      <EditSearcModal
        getAppliedFilters={getAppliedFilters}
        isEditSearchOpen={isEditSearchOpen}
        setEditSearchOpen={setEditSearchOpen}
        name={row.name}
        id={row.id}
        setFilterValues={setFilterValues}
        filtersChips={filtersChips}
        setFiltersChips={setFiltersChips}
        setAllResData={setAllResData}
        allResData={allResData}
        setOpen={setOpen}
        setReloadSavedSearches={setReloadSavedSearches}
        maxCheckedFiltersLength={maxCheckedFiltersLength}
      />
    </React.Fragment>
  )
}

const SavedSerchesTable = () => {
  const classes = useStyles()
  const { downXs, downSm } = useMediaQueries()
  const {
    savedSearchesData,
    savedSearchesLoading,
    setFilterValues,
    filterConfig,
    setReloadSavedSearches,
    setSavedSearchModalOpen,
    isFullUser,
    sendFreeUserFilters,
    setSearchRequestModalOpen
  } = useFilterStateContext()
  const { setSavedSearchApply } = useSearchContext()
  const { setAllCompaniesPage } = useSearchPaginationContext()
  const countPerPage = 5
  const [_DATA, , count] = PaginationButtons({
    data: savedSearchesData ? savedSearchesData?.data : [],
    countPerPage: countPerPage
  })
  const maxCheckedFiltersLength = 6
  const appliedFiltersOpen = async (id, open, setOpen, setAllResData) => {
    if (!open) {
      setOpen(true)

      try {
        const { data } = await getSavedSearchFilters(id)
        setAllResData(data)
      } catch (error) {
        // console.error(error)
      }
    }
    setOpen(!open)
  }

  const getAppliedFilters = async (id, setAllResData) => {
    try {
      const { data } = await getSavedSearchFilters(id)
      setAllResData(data)
      setAllCompaniesPage(1)
      if (isFullUser) {
        setFilterValues(prev => ({
          ...prev,
          ...data.filters,
          key: data.search_query
        }))
      } else {
        sendFreeUserFilters({
          ...data.filters,
          key: data.search_query
        })
      }

      setSavedSearchModalOpen(false)
      setSavedSearchApply(true)
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <Grid className={classes.mainTable}>
      {!savedSearchesLoading ? (
        <>
          {savedSearchesData && savedSearchesData.data.length > 0 ? (
            <>
              {downXs ? (
                <>
                  {_DATA?.currentData().map(row => (
                    <React.Fragment key={row.id}>
                      <MobileTable
                        appliedFiltersOpen={appliedFiltersOpen}
                        setSavedSearchModalOpen={setSavedSearchModalOpen}
                        getAppliedFilters={getAppliedFilters}
                        getSelectedFilters={getSelectedFilters}
                        text={text}
                        savedSearchesData={savedSearchesData}
                        filterConfig={filterConfig}
                        setReloadSavedSearches={setReloadSavedSearches}
                        row={row}
                        setFilterValues={setFilterValues}
                        isFullUser={isFullUser}
                        maxCheckedFiltersLength={maxCheckedFiltersLength}
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                  <Table
                    aria-label="collapsible table"
                    style={{
                      margin: 'auto',
                      width: downSm ? '100%' : '70%',
                      marginBottom: '50px'
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <Typography
                            variant="h4"
                            className={classes.titleName}
                          >
                            Search
                          </Typography>
                        </TableCell>
                        <TableCell
                          align={
                            savedSearchesData.data.length ? 'left' : 'center'
                          }
                        >
                          <Typography
                            variant="h4"
                            className={classes.titleName}
                          >
                            Total results
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="h4"
                            className={classes.titleName}
                          >
                            New results
                          </Typography>
                        </TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {savedSearchesData.data.length && (
                        <>
                          {_DATA?.currentData().map(row => (
                            <React.Fragment key={row.id}>
                              <Row
                                setSavedSearchModalOpen={
                                  setSavedSearchModalOpen
                                }
                                getAppliedFilters={getAppliedFilters}
                                savedSearchesData={savedSearchesData}
                                filterConfig={filterConfig}
                                setReloadSavedSearches={setReloadSavedSearches}
                                row={row}
                                setFilterValues={setFilterValues}
                                appliedFiltersOpen={appliedFiltersOpen}
                                isFullUser={isFullUser}
                                maxCheckedFiltersLength={
                                  maxCheckedFiltersLength
                                }
                                setAllCompaniesPage={setAllCompaniesPage}
                                setSavedSearchApply={setSavedSearchApply}
                              />
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Box
                style={{
                  visibility:
                    savedSearchesData.data.length > 5 ? 'visible' : 'hidden'
                }}
              >
                <SavedSearchPagination
                  countPerPage={count}
                  _DATA={_DATA}
                  allowedToLoad={true}
                />
              </Box>
            </>
          ) : (
            <>
              <div
                style={{
                  height: 300
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    maxWidth: '60%',
                    alignItems: 'center',
                    margin: 'auto'
                  }}
                >
                  <Typography variant="h4" className={classes.titleName}>
                    Search
                  </Typography>

                  <Typography variant="h4" className={classes.titleName}>
                    Total results
                  </Typography>

                  <Typography variant="h4" className={classes.titleName}>
                    New results
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: 40
                  }}
                >
                  {isFullUser ? (
                    <>
                      <Typography align="center" style={{ fontSize: 16 }}>
                        You have no saved searches in your list yet
                      </Typography>
                      <Typography align="center" style={{ fontSize: 16 }}>
                        Apply some filters to start saving your searches
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="h4" align="center">
                        {' '}
                        0 searches available
                      </Typography>
                      <Typography
                        align="center"
                        variant="h6"
                        style={{ fontWeight: 400 }}
                      >
                        <span
                          onClick={() => setSearchRequestModalOpen(true)}
                          style={{
                            color: '#35AAEC',
                            fontWeight: 700,
                            cursor: 'pointer'
                          }}
                        >
                          Get in touch
                        </span>{' '}
                        for a top-up
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Grid className={classes.loader}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  )
}

export default SavedSerchesTable
