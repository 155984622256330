import React, { useEffect } from 'react'
import { useStyles } from './useStyles'
import { useAuth0 } from '../../Contexts/AuthContext'
import { useNavContext } from '../../Contexts/NavbarContext'
import StyledLink from '../StyledLink'
import { Link, useHistory } from 'react-router-dom'
import { ReactComponent as OxbowLogo } from 'assets/Logos/Magellan Logos/magellan-logo-white.svg'
import { Box, Typography } from '@material-ui/core'
import SearchBar from '../SearchBar/searchBar'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { getClearFilterSectionState } from 'utils/FilterBootstrapUtils'

export default function NavbarContent() {
  const classes = useStyles()
  const {
    toggleDrawer,
    myFavCollectionsLink,
    setCollectionsSelected
  } = useNavContext()
  const { myFavId } = useCollections()
  const { downSm } = useMediaQueries()
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  const {
    setFilterValues,
    filterConfig,
    isUnlimitedSearches
  } = useFilterStateContext()

  useEffect(() => {
    if (history.location.pathname.includes('/personal/')) {
      setCollectionsSelected(true)
    } else setCollectionsSelected(false)
  }, [myFavId, setCollectionsSelected, history.location.pathname])

  const openClearedSearchPage = () => {
    if (isUnlimitedSearches) {
      setFilterValues(prev => ({
        ...prev,
        ...Object.keys(filterConfig)
          .map(sectionId => getClearFilterSectionState(filterConfig[sectionId]))
          .reduce((acc, v) => Object.assign(acc, v), {})
      }))
    }
  }

  return (
    <>
      <ul
        className={classes.toolbarList}
        style={{ width: !isAuthenticated && '50%' }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {!downSm && (
            <Link to="/home">
              <OxbowLogo alt="OxbowLogo" className={classes.navbarLogo} />
            </Link>
          )}
          {!isAuthenticated && !downSm && (
            <Box ml={6} style={{ width: '100%' }}>
              <SearchBar
                marginTop="none"
                size="xs"
                hideSearchButton
                showSearchIcon
                placeholder={'Search e.g cyber risk analytics'}
              />
            </Box>
          )}
        </div>

        {isAuthenticated && (
          <>
            {downSm ? (
              <Box>
                <li onClick={toggleDrawer}>
                  <StyledLink to="/home" selectedClass="selected">
                    <Typography
                      style={{ textTransform: 'uppercase' }}
                      variant="h4"
                    >
                      Home
                    </Typography>
                  </StyledLink>
                </li>
                <li onClick={toggleDrawer}>
                  <StyledLink
                    to="/search"
                    selectedClass="selected"
                    onClick={openClearedSearchPage}
                  >
                    <Typography
                      style={{ textTransform: 'uppercase' }}
                      variant="h4"
                    >
                      Search
                    </Typography>
                  </StyledLink>
                </li>
                <li onClick={toggleDrawer}>
                  <StyledLink
                    onClick={() => setCollectionsSelected(false)}
                    to={myFavCollectionsLink}
                    selectedClass="selected"
                    selected={window.location.pathname.includes(
                      '/collections/personal/'
                    )}
                  >
                    <Typography
                      style={{ textTransform: 'uppercase' }}
                      variant="h4"
                    >
                      Favourites
                    </Typography>
                  </StyledLink>
                </li>

                <li onClick={toggleDrawer}>
                  <StyledLink to="/insights" selectedClass="selected">
                    <Typography
                      style={{ textTransform: 'uppercase' }}
                      variant="h4"
                    >
                      Insights
                    </Typography>
                  </StyledLink>
                </li>
                <li onClick={toggleDrawer}>
                  <StyledLink to="/pricing" selectedClass="selected">
                    <Typography
                      style={{ textTransform: 'uppercase' }}
                      variant="h4"
                    >
                      Pricing
                    </Typography>
                  </StyledLink>
                </li>
                <li onClick={toggleDrawer}>
                  <StyledLink to="/contact-us" selectedClass="selected">
                    <Typography
                      style={{ textTransform: 'uppercase' }}
                      variant="h4"
                    >
                      Contact&nbsp;Us
                    </Typography>
                  </StyledLink>
                </li>
                {/* {isAdminUser && (
                  <li onClick={toggleDrawer}>
                    <StyledLink
                      to="/collections/oxbow-partners"
                      selectedClass="selected"
                    >
                      <Typography
                        style={{ textTransform: 'uppercase' }}
                        variant="h4"
                      >
                        Collections
                      </Typography>
                    </StyledLink>
                  </li>
                )} */}
              </Box>
            ) : (
              <>
                <li onClick={toggleDrawer}>
                  <StyledLink to="/home" selectedClass="selected">
                    Home
                  </StyledLink>
                </li>
                <li onClick={toggleDrawer}>
                  <StyledLink
                    to="/search"
                    selectedClass="selected"
                    onClick={openClearedSearchPage}
                  >
                    Search
                  </StyledLink>
                </li>
                <li>
                  <StyledLink
                    to={myFavCollectionsLink}
                    selectedClass="selected"
                    selected={window.location.pathname.includes(
                      '/collections/personal/'
                    )}
                  >
                    Favourites
                  </StyledLink>
                </li>

                <li onClick={toggleDrawer}>
                  <StyledLink to="/insights" selectedClass="selected">
                    Insights
                  </StyledLink>
                </li>
                <li onClick={toggleDrawer}>
                  <StyledLink to="/pricing" selectedClass="selected">
                    Pricing
                  </StyledLink>
                </li>
                <li onClick={toggleDrawer}>
                  <StyledLink to="/contact-us" selectedClass="selected">
                    Contact&nbsp;Us
                  </StyledLink>
                </li>
                {/* {isAdminUser && (
                  <li onClick={toggleDrawer}>
                    <StyledLink
                      to="/collections/oxbow-partners"
                      selectedClass="selected"
                    >
                      Collections
                    </StyledLink>
                  </li>
                )} */}
              </>
            )}
          </>
        )}
      </ul>
    </>
  )
}
