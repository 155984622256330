import React, { useMemo, useState } from 'react'
import { Box, Backdrop, Fade, Modal, Typography, Grid } from '@material-ui/core'
import { OxbowPrimaryButton, OxbowPrimaryTransparentButton } from '../Buttons'
import { useStyles } from './useStyles'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { updateSavedSearches } from 'utils/api'
import cross from 'assets/icons/cross.svg'

import { EditSearchChips } from './SavedSearchChips'
import DeleteSavedSearchModal from './DeleteSavedSearchModal'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'
import CloseIcon from '@material-ui/icons/Close'

const EditSearcModal = ({
  isEditSearchOpen,
  setEditSearchOpen,
  name,
  id,
  setFilterValues,
  filtersChips,
  setFiltersChips,
  setAllResData,
  allResData,
  setReloadSavedSearches
}) => {
  const classes = useStyles()
  const { setAllCompaniesPage } = useSearchPaginationContext()

  const { downXs } = useMediaQueries()
  const [isEditSearchNameError, setEditSearchNameError] = useState(false)
  const [saveSerchesName, setSaveSerchesName] = useState(name)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const [isDefaultStateChanged, setDefaultStateChanged] = useState(false)
  const [, setNewProducts] = useState({})
  const setValueOfCollection = e => {
    if (e.target.value) {
      setSaveSerchesName(e.target.value)
    } else {
      setSaveSerchesName('')
    }
    setDefaultStateChanged(true)
  }
  const handleClose = () => {
    setEditSearchNameError(false)
    setEditSearchOpen(false)
  }
  const selectedFiltersLength = useMemo(() => {
    return Object.values(filtersChips).reduce((totalLength, filter) => {
      if (typeof filter === 'string') {
        return totalLength + 1
      }
      if (filter?.values?.length) {
        return totalLength + filter.values.length
      }

      return totalLength
    }, 0)
  }, [filtersChips])

  const handleDelete = (filterName, el) => {
    if (selectedFiltersLength === 1) {
      return setDeleteModalOpen(true)
    }
    const {
      [filterName]: { values: filterValues }
    } = filtersChips
    setFiltersChips(prev => ({
      ...prev,
      [filterName]: {
        ...prev[filterName],
        values: filterValues.filter(val => val !== el)
      }
    }))

    const { [filterName]: filterData } = allResData.filters
    setAllResData(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        [filterName]: filterData.filter(val => val !== el)
      }
    }))

    setDefaultStateChanged(true)
  }

  const handleQuery = () => {
    if (selectedFiltersLength === 1) {
      return setDeleteModalOpen(true)
    }
    setFiltersChips(prev => ({
      ...prev,
      search_query: ''
    }))
    setAllResData(prev => ({
      ...prev,
      search_query: ''
    }))
  }

  const saveChanges = async () => {
    const allData = { ...allResData, name: saveSerchesName, id: id }

    if (!saveSerchesName) {
      setEditSearchNameError(true)
      return false
    }

    setEditSearchNameError(false)

    try {
      if (isDefaultStateChanged) {
        const { data } = await updateSavedSearches(id, allData)

        setFilterValues(prev => ({
          ...prev,
          ...data.filters,
          key: data.search_query
        }))
        setNewProducts(data)
        setAllCompaniesPage(1)
        setReloadSavedSearches(true)
      }

      setEditSearchOpen(false)
      setDefaultStateChanged(false)
    } catch (error) {
      console.log(error)
    }
  }

  const text = () => {
    return (
      <>
        <Typography>
          Removing all filters will delete this saved search.
        </Typography>
        <Typography> Are you sure you wish to proceed?</Typography>
      </>
    )
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isEditSearchOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.editSearchBox}
    >
      <Fade in={isEditSearchOpen}>
        <Box className={classes.editSearchContentBox}>
          <Box
            style={{
              position: 'relative'
            }}
          >
            <img
              alt=""
              src={cross}
              onClick={() => handleClose()}
              className={classes.closeIcon}
            />
            <Typography className={classes.textInputHeading}>
              Edit search
            </Typography>
          </Box>
          <Grid className={classes.editSearchMainBlock}>
            <Grid
              item
              style={{ padding: downXs ? '0 10px 20px' : '0 60px 40px' }}
            >
              <Typography
                variant="h4"
                className={classes.editSearchLabel}
                htmlFor="name"
              >
                Search name
              </Typography>
              <Box position="relative" style={{ marginTop: '11px' }}>
                <input
                  onChange={setValueOfCollection}
                  className={
                    isEditSearchNameError
                      ? `${classes.textFieldInput} ${classes.textFieldError}`
                      : classes.textFieldInput
                  }
                  type="text"
                  name="name"
                  value={saveSerchesName}
                />

                {isEditSearchNameError && (
                  <div className={classes.editSearchNameError}>
                    <span className="errorText">
                      This field cannot be empty
                    </span>
                  </div>
                )}
              </Box>
              <Grid item style={{ marginTop: 16 }}>
                <Typography
                  variant="h4"
                  className={classes.editSearchLabel}
                  htmlFor="name"
                >
                  Applied filters
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center'
                  }}
                >
                  {filtersChips.search_query &&
                    filtersChips.search_query !== ' ' && (
                      <span
                        className={classes.searchTerm}
                        style={{
                          marginTop: 10
                        }}
                      >
                        <span>{filtersChips.search_query}</span>{' '}
                        <CloseIcon
                          className={classes.cancelIcon}
                          onClick={() => handleQuery()}
                        />
                      </span>
                    )}
                  {Object.entries(filtersChips)?.map(([filterName, el]) => {
                    return (
                      <React.Fragment key={filterName}>
                        <EditSearchChips
                          selectedItems={el?.values}
                          filterName={filterName}
                          onClick={handleDelete}
                          count={5}
                          marginTop="sm"
                          btnLabel="See"
                        />
                      </React.Fragment>
                    )
                  })}
                </Box>
              </Grid>
              <Box mt={2} className={classes.btnContainer}>
                <OxbowPrimaryTransparentButton
                  className={classes.contactUsBtn}
                  onClick={() => handleClose()}
                  width="smd"
                  fontSize="md"
                >
                  Cancel
                </OxbowPrimaryTransparentButton>

                <OxbowPrimaryButton
                  className={classes.contactUsBtn}
                  onClick={() => saveChanges()}
                  fonttype="secondary"
                  width="md"
                  fontSize="md"
                >
                  Save changes
                </OxbowPrimaryButton>
              </Box>
            </Grid>
          </Grid>
          <DeleteSavedSearchModal
            setFilterValues={setFilterValues}
            id={id}
            setDeleteModalOpen={setDeleteModalOpen}
            isDeleteModalOpen={isDeleteModalOpen}
            title="Delete saved search"
            text={text()}
            setReloadSavedSearches={setReloadSavedSearches}
          />
        </Box>
      </Fade>
    </Modal>
  )
}
export default EditSearcModal
