import React, { useContext, useState } from 'react'
import _ from 'lodash'

const QueryStringValues = React.createContext()
export const useQueryStringValues = () => useContext(QueryStringValues)

export const QueryStringValuesProvider = ({ children }) => {
  const [itcOnly, setItcOnly] = useState(false)
  const [showInActiveCompanies, setShowInActiveCompanies] = useState(false)
  const [interestedIn, setIterestedIn] = useState(false)
  const [sortedValue, setSortedValue] = useState('Relevance')
  const [sortedLocalValues, setSortedLocalValues] = useState(
    'Company stage (Startups first)'
  )

  // Insights page
  const [page, setPage] = useState(1)
  const [selectedContentType, setSelectedContentType] = useState(
    'All article types'
  )
  const [isFromCollectionPage, setIsFromCollectionPage] = useState(false)
  const [selectedCollectionTag, setSelectedCollectionTag] = useState('')

  const [sortedBy, setSortedBy] = useState('desc')

  const [selectedCategory, setSelectedCategory] = useState('All categories')
  const contentTypes = [
    { value: 'All article types', name: 'All article types' },
    {
      value: 'Bitesize',
      name: 'Bitesize'
    },
    {
      value: 'TechExec',
      name: 'TechExec'
    },
    {
      value: 'Impact25',
      name: 'Oxbow Partners Impact 25'
    },
    {
      value: 'Collection',
      name: 'Oxbow Partners collections'
    },
    {
      value: 'other',
      name: 'Other'
    }
  ]

  const categoriesOptions = [
    { value: '', name: 'All categories' },
    { value: 'Growth', name: 'growth' },
    { value: 'Impact 25', name: 'impact' },
    { value: 'Mergers & Acquisitions', name: 'merges' },
    { value: 'Magellan', name: 'magellan' },
    { value: 'Market Intelligence', name: 'market' },
    { value: 'Operations', name: 'operations' },
    { value: 'Other', name: 'other' },
    { value: 'Oxbow Partners news', name: 'op' },
    { value: 'Technology', name: 'technology' }
  ]
  const sortedOptions = [
    { name: 'Newest first', value: 'desc' },
    { name: 'Oldest first', value: 'asc' }
  ]

  //

  const sortingOptions = [
    {
      name: 'Company name (A - Z)',
      value: {
        field: 'company.name',
        order: 'asc'
      }
    },
    {
      name: 'Company name (Z - A)',
      value: {
        field: 'company.name',
        order: 'desc'
      }
    },
    {
      name: 'Company stage (Startups first)',
      value: {
        field: 'company.stage',
        order: 'asc'
      }
    },
    {
      name: 'Company stage (Large first)',
      value: {
        field: 'company.stage',
        order: 'desc'
      }
    },
    {
      name: 'Year founded (Ascending)',
      value: {
        field: 'company.yearFounded',
        order: 'asc'
      }
    },
    {
      name: 'Year founded (Descending)',
      value: {
        field: 'company.yearFounded',
        order: 'desc'
      }
    },
    {
      name: 'Relevance',
      value: {
        field: 'relevance',
        order: 'desc'
      }
    }
  ]

  const getSortedLocalValues = (selectedCollectionType, userType) => {
    const localySortedValues = []

    Object.keys(...sortingOptions).forEach(key => {
      if (key === 'value') {
        sortingOptions.forEach(i => {
          if (selectedCollectionType === 'admin' && userType !== 'admin') {
            localySortedValues.push({ name: i.name, value: i[key] })
          } else if (i.value !== 'relevance')
            localySortedValues.push({ name: i.name, value: i[key] })
        })
      }
    })
    return localySortedValues
  }

  let sortField = ''
  let sortOrder = ''

  if (interestedIn) {
    sortField = 'company.interestedIn'
    sortOrder = 'desc'
  } else {
    sortField = sortedValue.field
    sortingOptions.find(i => {
      if (i.name === sortedValue || _.isEqual(i.value, sortedValue)) {
        sortField = i.value.field
        sortOrder = i.value.order
      }
    })
  }

  return (
    <QueryStringValues.Provider
      value={{
        itcOnly,
        sortedValue,
        setItcOnly,
        setSortedValue,
        sortingOptions,
        getSortedLocalValues,
        sortField: sortField,
        sortOrder: sortOrder,
        setIterestedIn,
        interestedIn,
        selectedContentType,
        setSelectedContentType,
        contentTypes,
        page,
        setPage,
        selectedCategory,
        setSelectedCategory,
        sortedBy,
        setSortedBy,
        categoriesOptions,
        sortedOptions,
        sortedLocalValues,
        setSortedLocalValues,
        showInActiveCompanies,
        setShowInActiveCompanies,
        isFromCollectionPage,
        setIsFromCollectionPage,
        selectedCollectionTag,
        setSelectedCollectionTag
      }}
    >
      {children}
    </QueryStringValues.Provider>
  )
}
