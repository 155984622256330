import ReactGA from 'react-ga4'
import filterConfig from 'configs/filters.config'
import { history } from 'Router/history'
const trackingId = process.env.REACT_APP_GA4_TAG_ID //eslint-disable-line
let prevSearchTerm = ''
let prevFilters = {}
const filterDetails = {
  ...filterConfig.companyFilters.filters,
  ...filterConfig.productFilters.filters
}

const initialize = userId => {
  console.log('(GA) Initialized, User: ', userId)
  ReactGA.initialize(trackingId);

  history.listen(location => {
    const pageURL = window.location.search.includes('code=')
      ? '/auth0'
      : location.pathname

    ReactGA.set({ page: pageURL }) // Update the user's current page
  })
}

const setUserId = userId => {
  console.log('(GA) Setting User Id...', userId)
  if (userId) {
    ReactGA.set({
      userId: userId
    })
  }
}

const trackFilters = currentFilters => {
  console.log(
    '(GA) currentFilters: ',
    currentFilters,
    'prevFilters: ',
    prevFilters,
    'prevSearchTerm: ',
    prevSearchTerm
  )

  Object.entries(currentFilters).forEach(([k, v]) => {
    if (k === 'key') {
      if (v !== prevSearchTerm) {
        // Search Term
        createEvent({
          category: 'Search',
          action: `Searched '${v}'`,
          label: v
        })
      }
      prevSearchTerm = v
    } else if (Array.isArray(v) && v.length > 0) {
      // Array Filters
      v.forEach(el => {
        if (!(prevFilters && prevFilters[k] && prevFilters[k].includes(el))) {
          createEvent({
            category: 'Filter',
            action: `${
              (filterDetails[k] && filterDetails[k].title) || k
            }: ${el} was Used`,
            label: k
          })
        }
      })
    } else {
      // Object Filters
      Object.entries(v).forEach(([kk, _vv]) => {
        if (!(prevFilters && prevFilters[k] && prevFilters[k][kk])) {
          createEvent({
            category: 'Filter',
            action: `${
              (filterDetails[k] && filterDetails[k].title) || k
            }: ${kk} was Used`,
            label: k
          })
        }
      })
    }
  })

  prevFilters = currentFilters
}

// Helpers

const createEvent = ({
  category,
  action,
  label = '',
  value = 0,
  nonInteraction = false,
  transport = ''
}) => {
  console.log('(GA) Event Created: ', {
    category,
    action,
    label,
    value,
    nonInteraction,
    transport
  })
  ReactGA.event({
    category,
    action,
    label,
    value
    // nonInteraction,
    // transport
  })
}

export default {
  initialize,
  createEvent,
  trackFilters,
  setUserId
}
