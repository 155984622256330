import {Box, makeStyles, Typography} from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  h3: {
    paddingBottom: '16px',
  },
  titleLink: {
    color: theme.palette.text.primary,
    display: 'inline-block',
    fontSize: '18px',
    textDecoration: 'none',
  },
  titleLinkIcon: {
    marginLeft: '4px',
    marginBottom: '12px',
    '& svg': {
      fontSize: '14px',
    },
  },
}))

const LinkTitleContent = ({title, url}) => {
  const classes = useStyles()
  return (
    <a className={classes.titleLink} href={url} target='_blank' rel='noopener noreferrer'>
      {title}
      <Box component='span' className={classes.titleLinkIcon}><OpenInNewIcon/></Box>
    </a>
  )
}

const TextTitleContent = ({title}) => (<Box component='span'>{title}</Box>)

const CaseStudyTitle = ({title, url}) => {
  const classes = useStyles()

  return (
    <Typography variant='h4' classes={{h4: classes.h3}}>
      {url ? <LinkTitleContent title={title} url={url}/> : <TextTitleContent title={title}/>}
    </Typography>
  )
}

CaseStudyTitle.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
}

export default CaseStudyTitle
