import { getAxiosInstance } from 'utils/axios'
import { apiRootURL } from 'utils/api'

const COLLECTIONS_API = `${apiRootURL}collections`

export const list = async () => {
  return getAxiosInstance().get(COLLECTIONS_API)
}

export const get = async collectionId => {
  return getAxiosInstance().get(`${COLLECTIONS_API}/${collectionId}`)
}

export const create = async data => {
  return getAxiosInstance().post(COLLECTIONS_API, data)
}

export const update = async (collectionId, data) => {
  return getAxiosInstance().patch(`${COLLECTIONS_API}/${collectionId}`, data)
}

export const copy = async (collectionId, destination) => {
  return getAxiosInstance().post(`${COLLECTIONS_API}/${collectionId}/copy`, {
    destination
  })
}

export const $delete = async collectionId => {
  return getAxiosInstance().delete(`${COLLECTIONS_API}/${collectionId}`)
}

export const order = async (type, orderedIds) => {
  return getAxiosInstance().post(`${COLLECTIONS_API}/order/${type}`, {
    orderedIds
  })
}

export const addProduct = async (collectionId, productId, data) => {
  return getAxiosInstance().post(
    `${COLLECTIONS_API}/${collectionId}/products/${productId}`,
    data
  )
}

export const removeProduct = async (collectionId, productId) => {
  return getAxiosInstance().delete(
    `${COLLECTIONS_API}/${collectionId}/products/${productId}`,
    {}
  )
}

export default {
  list,
  get,
  create,
  update,
  copy,
  delete: $delete,
  order,
  addProduct,
  removeProduct,
  COLLECTIONS_API
}
