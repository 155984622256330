import React from 'react'
import { Box, Drawer, Typography, makeStyles, Grid } from '@material-ui/core'
import { useAuth0 } from '../../Contexts/AuthContext'
import { useNavContext } from '../../Contexts/NavbarContext'
import NavbarContent from './NavbarContent'
import Logout_icon_white from 'assets/icons/Logout_icon_white.png'

import Profile_icon_white from 'assets/icons/Profile_icon_white.png'
import StyledLink from '../StyledLink'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'
import { userName, userNameAccronym } from 'utils'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    background: 'linear-gradient(130.97deg, #06293D 46.13%, #15425B 75.17%)'
  },
  drawerContainer: {
    width: '100%',
    padding: theme.spacing(22.5, 3.75, 10),
    '& >div:not(:nth-child(2))': {
      textAlign: 'center'
    }
  },
  heading2: {
    color: '#fff',
    fontWeight: 600,
    paddingBottom: 10
  },
  heading3: {
    color: '#fff',
    fontFamily: theme.typography.secondaryFont,
    paddingBottom: 20,
    paddingTop: 10,
    fontSize: 22
  },
  seperator: {
    display: 'block'
  },
  links: {
    fontFamily: theme.typography.secondaryFont,
    fontSize: '18px',
    padding: '14px 0',
    position: 'relative',
    lineHeight: '1.3',
    color: '#fff',
    textDecoration: 'none',
    display: 'block',
    textTransform: 'none'
  },
  styleContainer: {
    width: 200,
    paddingTop: 15,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    '& h6': {
      alignItems: 'center',
      display: 'flex'
    },
    '& a': {
      color: '#fff',
      fontWeight: '400 !important',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  styleLinks: {
    cursor: 'pointer',
    color: '#fff',
    marginRight: 10,
    background: 'none',
    border: 'none',
    marginBottom: 30,
    '& img': {
      marginRight: 20
    }
  },
  linkSeperator: {
    width: '3px',
    height: '3px',
    margin: '5px auto',
    borderRadius: '50%',
    marginRight: 10,
    display: 'inline-block'
  },
  styledText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#fff',
    '& a': {
      color: '#fff'
    }
  },
  buttonDark: {
    backgroundColor: '#625c56',
    fontSize: '1.35rem'
  },
  buttonLight: {
    '& > span > div > span': {
      fontWeight: '500 !important'
    }
  },
  userNameMenu: {
    margin: '20px 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16
  },
  userNameAccronym: {
    backgroundColor: theme.palette.primary.contrastText,
    width: 40,
    height: 40,
    borderRadius: '50%',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 14
  }
}))

// Drawer for mobile devices
export const MobileNavigationDrawer = () => {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0()
  const { personalData } = useCustomisationJourney()
  const { drawerOpen, toggleDrawer } = useNavContext()
  const classes = useStyles()
  return (
    <Drawer
      open={drawerOpen}
      anchor="right"
      onClose={toggleDrawer}
      className={classes.drawer}
      variant="persistent"
      classes={{ paper: classes.paper }}
    >
      <div
        className={classes.drawerContainer}
        role="presentation"
        onKeyDown={toggleDrawer}
      >
        <>
          <Box mb={1.25}>
            <NavbarContent />
          </Box>

          {isAuthenticated && (
            <>
              <Grid container className={classes.userNameMenu}>
                <div className={classes.userNameAccronym}>
                  {userNameAccronym(user.name, userName(personalData))}
                </div>
                <Typography variant="h4" className={classes.styledText}>
                  {userName(personalData) ? userName(personalData) : user.name}
                </Typography>
              </Grid>

              <div className={classes.styleContainer}>
                <Typography
                  className={classes.styleLinks}
                  variant="subtitle2"
                  onClick={toggleDrawer}
                >
                  <StyledLink to="/personal-account" selectedClass="selected">
                    <img src={Profile_icon_white} alt="ProfileIcon" />
                    Account information
                  </StyledLink>
                </Typography>

                <button className={classes.styleLinks} onClick={logout}>
                  <Typography variant="subtitle2">
                    <img src={Logout_icon_white} alt="Logout_icon" />
                    Logout
                  </Typography>
                </button>
              </div>
            </>
          )}

          {!isAuthenticated && (
            <>
              <div className={classes.styleContainer}>
                <button
                  className={classes.styleLinks}
                  onClick={loginWithRedirect}
                >
                  Login
                </button>
                <span className={classes.linkSeperator} />
                <button
                  className={classes.styleLinks}
                  onClick={() =>
                    loginWithRedirect({
                      prompt: 'login',
                      screen_hint: 'signup'
                    })
                  }
                >
                  Signup
                </button>
              </div>
            </>
          )}
        </>
      </div>
    </Drawer>
  )
}
