import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function DocumentTitle ({ history }) {
    const defaultTitle = 'Magellan | Oxbow Partners';
    useEffect(() => {
        switch (true) {
            case /\/search.*/.test(history.location.pathname):
                document.title = `Search - ${defaultTitle}`;
                break;
            case /\/product.*/.test(history.location.pathname) && history.location.state?.breadcrumb?.label != null:
                document.title = `${history.location.state?.breadcrumb?.label} - ${defaultTitle}`;
                break;
            case /\/collections\/personal.*/.test(history.location.pathname):
                document.title = `Favourites - ${defaultTitle}`;
                break;
            case /\/insights$/.test(history.location.pathname):
                document.title = `Insights - ${defaultTitle}`;
                break;
            case /\/insights.+/.test(history.location.pathname) && history.location.state?.insights?.title != null:
                document.title = `${history.location.state.insights.articleType}: ${history.location.state.insights.title} - ${defaultTitle}`;
                break;
            case /\/pricing.*/.test(history.location.pathname):
                document.title = `Pricing - ${defaultTitle}`;
                break;
            case /\/contact-us.*/.test(history.location.pathname):
                document.title = `Contact Us - ${defaultTitle}`;
                break;
            default:
                document.title = defaultTitle;
                break;
        }
    }, [history.location.pathname, history.location.state])
    return (<></>)
}

export default withRouter(DocumentTitle);