import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Fade,
  Typography
} from '@material-ui/core'
import { generatePath, useParams } from 'react-router-dom'
import { getProductData } from 'utils/api'

import { useQuery } from 'react-query'
import { EditProductDetailsContextProvider } from 'components/Contexts/EditProductDetailsContext'

import { getSanitizedProductData } from './utils'

import { BaseLayoutBody } from 'components/Layouts/Base'

import ProductPageHeader from './Components/Header'
import useStyles from './styles'
import Overview from './Components/Tabs/Overview'

import Capabilities from './Components/Tabs/Capabilities'
import ClientsAndActivity from './Components/Tabs/Clients&Activity'
import { sideBarOptions } from './dataHelper'
import { useNavContext } from 'components/Contexts/NavbarContext'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { useCan } from 'rbac/can'
import { useAuth0 } from 'components/Contexts/AuthContext'

export const excludedDataKeys = ['sideBar', 'sys', '_contentful']

const StickyNavMenuContext = React.createContext({})
export const useStickyNavMenuContext = () => useContext(StickyNavMenuContext)

const fetchProductDetails = async (_queryKey, productKey) => {
  const productData = await getProductData({ slug: productKey, id: productKey })

  return {
    productData,
    sanitizedProductData: getSanitizedProductData(productData)
  }
}

const ProductPageContext = React.createContext({})
export const useProductPageContext = () => useContext(ProductPageContext)

export default function NewProductDetails({ history }) {
  const classes = useStyles()
  const { productKey } = useParams()
  const { setCurrId } = useNavContext()
  const { setFilterValues } = useFilterStateContext()
  const { isAuthenticated } = useAuth0();
  const [currentView, setCurrentView] = useState(
    history.location.hash.replace('#', '') || 'overview'
  )
  const [activeMenuItem, setActiveMenuItem] = useState(null)
  const [isFeatureInFull, setFeatureInFull] = useState([])
  const headerRef = useRef(null)

  const NewsInsight = useRef(null)
  const Companydetails = useRef(null)
  const FeaturedIncollections = useRef(null)
  const Seealso = useRef(null)
  const CompanyOverview = useRef(null)
  const WebAPICapabilities = useRef(null)
  const ProductConfiguration = useRef(null)
  const ExistingClients = useRef(null)
  const Location = useRef(null)

  const [inEditMode] = useState(false)
  const {
    isLoading: isLoadingData,
    isError,
    data: { productData } = {},
    isIdle: isProductDetailsRequestIdle
  } = useQuery([`product-details`, productKey], fetchProductDetails, {
    enabled: productKey,
    staleTime: 3600000
  })
  const isFullUser = useCan('content_depth:all')
  const isActive = productData && productData.company.fields.isActive

  useEffect(() => {
    const hash = history.location.hash
    if (!hash) {
      const newPath = generatePath(
        `${history.location.pathname}#:sectionName`,
        {
          sectionName: 'overview'
        }
      )
      history.replace(newPath)
    } else {
      setCurrentView(hash.replace('#', ''))
    }
  }, [history, history.location.hash])

  useEffect(() => {
    if (!isLoadingData && !isProductDetailsRequestIdle) {
      const { pathname, hash } = history.location
      history.replace(`${pathname}${hash}`, {
        breadcrumb: {
          key: 'productName',
          label: productData?.name,
          updateLabel: true
        }
      }) // bcb
    }
  }, [isLoadingData, productData, isProductDetailsRequestIdle, history])

  const _setCurrentView = useCallback(
    sectionName => {
      const newPath = generatePath(
        `${history.location.pathname}#:sectionName`,
        {
          sectionName
        }
      )
      history.replace(newPath)
    },
    [history]
  )

  const dataLength = data => {
    let length = 0
    const arrLength = data.map(item => {
      if (item.dataField) {
        return item.dataField.length
      } else {
        return 0
      }
    })
    length = arrLength.reduce((a, b) => a + b, 0)
    return length
  }

  if (isLoadingData || isProductDetailsRequestIdle || isError || !productData) {
    return (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexGrow: 1,
          minHeight: 0
        }}
      >
        <CircularProgress />
      </Container>
    )
  }

  const componentToRender = () => {
    switch (currentView) {
      case 'overview':
        return (
          <Overview
            renderInEditMode={inEditMode}
            sectionKey={currentView}
            productData={productData}
            isActive={isActive}
            setFilterValues={setFilterValues}
            isFullUser={isFullUser}
            isAuthenticated={isAuthenticated}
          />
        )
      case 'clientsAndActivity':
        return (
          <ClientsAndActivity
            productData={Object.keys(productData).length > 0 && productData}
            mapPOIs={productData.mapPOIs}
            renderInEditMode={inEditMode}
            dataLength={dataLength}
            isActive={isActive}
          />
        )
      case 'capabilities':
        return (
          <Capabilities
            productData={Object.keys(productData).length > 0 && productData}
            isActive={isActive}
            dataLength={dataLength}
          />
        )
      default:
        return <Overview />
    }
  }

  return (
    <ProductPageContext.Provider
      value={{
        productData,
        isLoadingData
      }}
    >
      <StickyNavMenuContext.Provider
        value={{
          setActiveMenuItem,
          activeMenuItem,
          headerRef,
          NewsInsight,
          Companydetails,
          FeaturedIncollections,
          Seealso,
          CompanyOverview,
          WebAPICapabilities,
          ProductConfiguration,
          ExistingClients,
          Location,
          isFeatureInFull,
          setFeatureInFull
        }}
      >
        <EditProductDetailsContextProvider>
          <BaseLayoutBody
            backgroundRightPanel="grey"
            pageHeader={
              <ProductPageHeader
                isActive={isActive}
                history={history}
                productData={productData}
                sidebarOptions={sideBarOptions(isFullUser)}
                productSlug={productData.slug}
                productId={productData.sys.id}
                setCurrentView={_setCurrentView}
                currentView={currentView}
                activeMenuItem={activeMenuItem}
                setActiveMenuItem={setActiveMenuItem}
                toggleCollections={() => {}}
                companyData={productData}
                setCurrId={setCurrId}
              />
            }
            rightPanel={
              <React.Fragment>
                {/* // Product Page: main header */}
                <Fade in>
                  <Box
                    className={classes.mainDataContainer}
                    style={{
                      position: 'relative'
                    }}
                  >
                    {!isActive && (
                      <Typography
                        variant="h5"
                        className={classes.inActiveLabel}
                      >
                        This company is inactive
                      </Typography>
                    )}
                    {componentToRender()}

                    {/* temp removed : Suggest edits */}
                    {/* {isAuthenticated && ( */}
                    {/* <EditModeActions
                      productData={productData}
                      setInEditMode={setInEditMode}
                      isEditModeEnabled={inEditMode}
                      userEmail={user.email}
                    /> */}
                    {/* )} */}
                  </Box>
                </Fade>
              </React.Fragment>
            }
          ></BaseLayoutBody>
        </EditProductDetailsContextProvider>
      </StickyNavMenuContext.Provider>
    </ProductPageContext.Provider>
  )
}
