import { CircularProgress, Container } from '@material-ui/core'
import React from 'react'

export const SuspenseFallbackLoader = () => (
  <Container
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      minHeight: 0
    }}
  >
    <CircularProgress />
  </Container>
)
