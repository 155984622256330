import { useState, useRef, useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import useRect from './useRect'

export function useMeasure() {
  const ref = useRef(null)
  const dimension = useRect(ref)
  // console.log('useMeasure -> dimension', dimension)
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const observerActiveRef = useRef(false)
  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        if (!observerActiveRef.cuurent) observerActiveRef.current = true
        set(entry.target.getBoundingClientRect())
      })
  )
  useLayoutEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ro])
  return [{ ref }, observerActiveRef.current ? bounds : dimension]
}

export default useMeasure
