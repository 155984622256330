export const steps = [
  {
    target: '#filtersContainer',
    content:
      'Welcome! Here are the filters you can apply to narrow down your search according to your requirements.',
    disableBeacon: true,
    placement: 'right',
    disableScrolling: true
  },
  {
    target: '#supplierFilter',
    content: `Let's start by applying this filter. Click to apply this filter.`,
    disableBeacon: true,
    placement: 'bottom',
    spotlightClicks: true,
    disableScrolling: true,
    scrollOffset: 0
  },
  {
    target: '#paginationContainer',
    content: `Here you can see the number of results matching your search.`,
    disableBeacon: true,
    placement: 'bottom-start',
    spotlightPadding: 0
  },
  {
    target: '#searchResultsContainer',
    content: `Here are your filtered search results. Each tile gives you an overview of the product.`,
    disableBeacon: true,
    placement: 'left-start',
    spotlightPadding: 0
  }
]

export const baseConfig = {
  showProgress: true,
  continuous: true,
  showSkipButton: true,
  locale: { last: 'Close' },
  styles: {
    options: {
      primaryColor: 'rgb(46, 83, 142)'
    }
  }
}
