import { makeStyles } from '@material-ui/core'

export const useCheckboxListStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(1)}px 0`,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column'
    }
  },
  labelContainer: {
    width: props => (props.wrapLabel ? '20%' : 'auto'),
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: props => (props.wrapLabel ? '100%' : 'auto'),
      display: 'flex',
      alignItems: 'center'
    }
  },
  optionsAnimatedWrapper: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      width: '100%'
    }
  },
  showMoreBtn: {
    marginTop: '10px',
    fontWeight: 'initial',
    fontSize: '0.7rem',
    color: '#222',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: theme.spacing(2)
    }
  },
  optionsContainer: {
    fontFamily: 'Open Sans',
    display: 'grid',
    gridTemplateColumns: props =>
      Array(props.columns)
        .fill(0)
        .reduce((acc, _) => acc + '1fr ', ' ')
        .trim(),
    gridTemplateRows: 'auto',
    padding: '0px !important',
    gap: '8px',
    marginLeft: theme.spacing(0),
    filter: ({ blurValue }) => (blurValue ? 'blur(4px)' : null),
    cursor: ({ blurValue }) => (blurValue ? 'pointer' : null),
    '& > div': {
      alignItems: 'baseline'
    },
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  viewHiddenOptions: {
    marginTop: 15,
    padding: 0
  }
}))
