import React, { useState } from 'react'
import Chip from 'components/Helpers/Inputs/ChipInput/Chips'
import { Box, makeStyles } from '@material-ui/core'
import { derivedFromProp } from 'utils'
import { Chips } from '../SavedSearches/SavedSearchChips'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  chipsContainer: {
    marginTop: derivedFromProp('marginTop', {
      none: '0px',
      sm: '10px',
      default: '20px'
    }),
    marginBottom: derivedFromProp('marginBottom', {
      xs: '5px',
      sm: '10px',
      default: '0px'
    }),
    margin: derivedFromProp('marginX', {
      sm: '0 10px',
      top: '4px 0 0 0',
      default: 0
    })
  },
  chip: {
    width: 'max-content',
    border: '1px solid #00A3FF',
    borderRadius: '20px',
    alignItems: 'center',
    display: props => (props.inline ? 'inline-flex' : 'flex'),
    height: '28px',
    background: theme.palette.secondary.main,
    transition: 'background 0.3s ease',
    willChange: 'background',
    color: '#fff',
    fontWeight: 400,
    fontSize: '13px',
    padding: theme.spacing(0.7, 1.5),
    '&:hover': {
      background: 'rgba(0, 163, 255, 0.3)',
      color: '#000'
    }
  }
}))

export const ChipsToShow = ({
  handleChipClick,
  handleRegionCheck,
  selectedRegion = [],
  selectedItems = [],
  data,
  isFromJourney,
  marginTop,
  editable,
  type = '',
  onClick,
  onClear,
  count,
  isClickable,
  setCurrId = () => {},
  navTo,
  isFullUser,
  handleClick,
  marginX,
  btnLabel,
  display = 'block',
  countriesData,
  setCheckedCountries = () => {},
  checkedCountries,
  dataDetails,
  setDataDetails
}) => {
  const [lengthToShow, setLengthToShow] = useState(count)
  const [isScrollActive, setScrollActive] = useState(false)
  return (
    <Box
      className="chips-container"
      style={{
        height: isScrollActive ? 125 : 'auto',
        overflow: 'auto',
        display: display
      }}
    >
      {type === 'regionsAndCountries' ? (
        <>
          {selectedRegion.map(i => {
            return (
              <Chips
                key={i.region}
                marginTop={marginTop}
                isFromJourney={true}
                el={i.region}
                clearSearch={() => {
                  handleChipClick(
                    i.children.map(i => i),
                    dataDetails,
                    type,
                    setDataDetails,
                    countriesData
                  )
                  handleRegionCheck(
                    i.children.map(i => i),
                    i.region,
                    countriesData,
                    setCheckedCountries,
                    checkedCountries
                  )
                }}
                editable={editable}
              />
            )
          })}

          {selectedItems.length > 0 && (
            <>
              {selectedItems.map((i, index) => {
                if (index + 1 > Math.min(selectedItems.length, lengthToShow)) {
                  return null
                }
                return (
                  <Chips
                    key={i}
                    marginTop={marginTop}
                    isFromJourney={true}
                    el={i}
                    clearSearch={() => onClick(i)}
                    editable={editable}
                  />
                )
              })}
            </>
          )}

          <ChipsBtns
            data={selectedItems}
            lengthToShow={lengthToShow}
            setLengthToShow={setLengthToShow}
            marginTop={marginTop}
            setScrollActive={setScrollActive}
            count={count}
            btnLabel={btnLabel}
          />
        </>
      ) : (
        <>
          {data?.map((i, index) => {
            if (index + 1 > Math.min(data?.length, lengthToShow)) {
              return null
            }
            return type !== '' ? (
              <Chips
                key={index}
                marginTop={marginTop}
                isFromJourney={isFromJourney}
                el={i}
                clearSearch={() => onClear(i)}
                editable={editable}
              />
            ) : (
              <Chips
                marginTop={marginTop}
                key={index}
                el={i.label ? i.label : i}
                editable={editable}
                isFromJourney={isFromJourney}
              />
            )
          })}
          <ChipsBtns
            inline={true}
            data={data}
            lengthToShow={lengthToShow}
            setLengthToShow={setLengthToShow}
            marginTop={marginTop}
            marginX={marginX}
            setScrollActive={setScrollActive}
            count={count}
            isClickable={isClickable}
            setCurrId={setCurrId}
            navTo={navTo}
            isFullUser={isFullUser}
            handleClick={handleClick}
            btnLabel={btnLabel}
          />
        </>
      )}
    </Box>
  )
}

export const ChipsBtns = ({
  data,
  lengthToShow,
  setLengthToShow,
  marginTop,
  marginBottom,
  marginX,
  setScrollActive = () => {},
  count,
  isClickable = true,
  scrollTo = '',
  setCurrId = () => {},
  navTo = '',
  isFullUser,
  handleClick,
  btnLabel,
  inline = false
}) => {
  const classes = useStyles({ marginTop, marginBottom, marginX, inline })
  let history = useHistory()

  const navTabChange = () => {
    if (!isFullUser) {
      handleClick()
    } else {
      history.push(`${history.location.pathname}#${navTo}`)
      if (scrollTo !== '') {
        setCurrId(scrollTo)
      } else {
        setCurrId('')
      }
    }
  }

  const selectFunc = () =>
    isClickable
      ? setLengthToShow(
          currentCount => currentCount + (data.length - lengthToShow)
        )
      : navTabChange()

  return (
    <>
      {data?.length - lengthToShow > 0 && (
        <Chip
          onClick={() => selectFunc()}
          className={classes.chip}
          color="transparent"
          label={`${btnLabel} ${data?.length - lengthToShow} more`}
        />
      )}
      {data?.length !== count && data?.length - lengthToShow === 0 && (
        <Chip
          color="transparent"
          onClick={() => {
            setLengthToShow(count)
            setScrollActive(false)
          }}
          className={classes.chip}
          label={`Hide ${data.length - count}`}
        />
      )}
    </>
  )
}
