import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: 0
  },
  productFilterSection: {
    paddingBottom: theme.spacing(4),
    position: 'sticky',
    top: 0,
    height: '100vh',
    overflow: 'auto'
  },
  searchResultSection: {
    padding: '10px 20px'
  },
  filterBtnOpen: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  restrictionBlock: {
    '& div': {
      width: '80%'
    },
    '& h6': {
      padding: '27px 0 20px'
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 700
    },
    [theme.breakpoints.down('md')]: {
      '& div': {
        width: '100%'
      }
    }
  },

  freeUserCta: {
    '& h3': {
      margin: '25px 0'
    },
    '& span': {
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 700
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },

  stickyNotes: {
    position: 'absolute',
    right: 64,
    zIndex: 1,
    width: 180,
    border: `1px solid ${theme.palette.primary.primaryBigHovered}`,
    background: 'white',
    '& >div': {
      padding: '18px 10px 25px 25px'
    },

    '& span': {
      cursor: 'pointer',
      fontWeight: 700,
      color: theme.palette.secondary.main
    },

    '& h6': {
      fontWeight: 400
    },
    [theme.breakpoints.down('md')]: {
      right: 16,
      width: 150
    },
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      right: 'unset',
      zIndex: 'unset',
      width: 'auto',
      border: 'none',
      background: 'unset',
      '& >div': {
        padding: '10px 0'
      }
    }
  },

  modalContainer: {
    color: '#000',
    background: '#fff',
    padding: '57px 148px',
    width: 840,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      padding: '25px 25px',
      width: 540
    },
    [theme.breakpoints.down('xs')]: {
      width: 340,
      padding: '10px 25px'
    }
  }
}))
