import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Animated } from 'components/Helpers/AnimatedComponent'

import useMediaQueries from 'utils/customHooks/useMediaQueries'
import LandingAboutUsImg from 'assets/Logos/LandingPage/LandingAboutUsImg.png'
import brk from 'assets/icons/brk.svg'

const useStyles = makeStyles(theme => ({
  articleBlock: {
    padding: '0 140px',
    margin: 'auto',
    width: '100%',
    '& a': {
      color: theme.palette.primary.mainLightBlue
    },
    '& img:first-child': {
      marginRight: 50,
      width: 180,
      height: 180
    },
    '& h6:first-child': {
      marginBottom: 30
    },
    '& img:nth-child(3)': {
      display: 'flex',
      alignSelf: 'flex-end'
    },
    [theme.breakpoints.up(1920)]: {
      width: '100%',
      padding: 0
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 110px'
    },
    [theme.breakpoints.down('sm')]: {
      '& h6': {
        textAlign: 'center'
      },
      '& img:nth-child(3)': {
        marginBottom: 20
      },
      padding: '0 25px',
      '& .brk': {
        alignSelf: 'flex-end'
      },
      '& .aboutUsLogo': {
        alignSelf: 'center !important',
        width: 180,
        height: 180
      },
      '& >div': {
        flexDirection: 'column'
      }
    }
  }
}))

export const MagellanArticle = () => {
  const classes = useStyles()
  const { downSm } = useMediaQueries()
  return (
    <Animated>
      <Grid container wrap="nowrap" className={classes.articleBlock}>
        {!downSm && <img src={LandingAboutUsImg} alt="LandingAboutUs" />}
        <Grid container>
          <Typography variant="subtitle1">
            When InsurTech surfaced in 2015, our clients were eager to hear
            about the different business models emerging across the market. As
            Oxbow Partners became a leading voice in the space, it occurred to
            us that insurance executives required a more centralised way to
            research relevant vendors. We created Magellan to cut through the
            hype, leading you to the technologies that will actually impact your
            business.
          </Typography>
          {downSm && (
            <>
              <img src={brk} alt="brk" className="brk" />
              <img
                src={LandingAboutUsImg}
                alt="LandingAboutUs"
                className="aboutUsLogo"
              />
            </>
          )}
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            Chris Sandilands, Partner,{' '}
            <a
              href="https://oxbowpartners.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Oxbow Partners
            </a>
          </Typography>
        </Grid>

        {!downSm && <img src={brk} alt="brk" />}
      </Grid>
    </Animated>
  )
}
