import React, { useState, useEffect, useRef } from 'react'
import { Slider, makeStyles } from '@material-ui/core'
import RevealSection from '../Reveal'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  textBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(1.5),
    '& .inputContainer': {
      display: 'flex',
      maxWidth: '35%',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '0.6rem',
      overflow: 'hidden',
      '& .unit': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: 'white',
        borderRight: `1px solid ${theme.palette.primary.main}`,
        padding: '8px'
      },
      '& input': {
        flex: 1,
        fontFamily: 'inherit',
        display: 'inline-block',
        minWidth: '30px',
        padding: '0.6rem 0.8rem',
        background: 'white',
        border: 'none',
        outline: 'none'
      }
    }
  }
}))

export const MinMaxSelector = ({
  title,
  min,
  max,
  step = 1,
  name,
  style = {},
  unit,
  value: valueProp,
  onChange
}) => {
  const [value, setValue] = useState(valueProp)
  const [minInputValue, setMinInputValue] = useState(() => value[0] + '')
  const [maxInputValue, setMaxInputValue] = useState(() => {
    const a = value[1] + (value[1] === max ? '+' : '')
    return a
  })
  const lastValidValueRef = useRef({
    min: minInputValue,
    max: maxInputValue
  })
  const classes = useStyles()
  // To update the min and max values, according to the aggregations returned from the API
  useEffect(() => {
    if (value[0] < min || value[1] > max) {
      const newValue = [
        value[0] < min ? min : value[0],
        value[1] > max ? max : value[1]
      ]
      setValue(newValue)
      onChange(newValue)
    }
  }, [min, max, name, value, unit, onChange])

  useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  useEffect(() => {
    lastValidValueRef.current = {
      min: value[0] + '',
      max: value[1] + (value[1] === max ? '+' : '')
    }
    setMinInputValue(value[0] + '')
    setMaxInputValue(value[1] + (value[1] === max ? '+' : ''))
  }, [value, max])

  const handleInputChange = key => e => {
    const value = e.target.value
    if (key === 'min') {
      setMinInputValue(value)
    } else if (key === 'max') {
      setMaxInputValue(value)
    }
  }

  const validateInputValue = (key, value) => {
    if (key === 'min' && value >= min) return true
    if (key === 'max' && value <= max) return true
    return false
  }

  const handleInputBlur = key => e => {
    const inputValue = parseInt(e.target.value)
    let newValue
    if (key === 'min') {
      if (validateInputValue(key, inputValue) && inputValue !== value[0]) {
        newValue = [inputValue, value[1]]
        setValue(newValue)
        onChange(newValue)
      } else {
        setMinInputValue(lastValidValueRef.current.min)
      }
    } else if (key === 'max') {
      if (validateInputValue(key, inputValue) && inputValue !== value[1]) {
        newValue = [value[0], inputValue]
        setValue(newValue)
        onChange(newValue)
      } else {
        setMaxInputValue(lastValidValueRef.current.max)
      }
    }
  }

  const handleChange = (_event, values) => {
    setValue(values)
  }

  const handleChangeCommitted = (_event, values) => {
    setValue(values)
    onChange(values)
  }

  const handleInputKeyPress = key => e => {
    if (e.keyCode === 13) {
      handleInputBlur(key)(e)
    }
  }

  return (
    <RevealSection label={title} icon={<ChevronRightIcon />} style={style}>
      <div className={classes.container}>
        <Slider
          aria-labelledby={title}
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
        />
        <div className={classes.textBoxContainer}>
          <div className="inputContainer">
            {unit && <span className="unit">${unit}</span>}
            <input
              type="text"
              value={minInputValue}
              onChange={handleInputChange('min')}
              onBlur={handleInputBlur('min')}
              onKeyDown={handleInputKeyPress('min')}
              placeholder="Min"
              name="min"
            />
          </div>
          {' - '}
          <div className="inputContainer">
            {unit && <span className="unit">${unit}</span>}
            <input
              type="text"
              value={maxInputValue}
              onChange={handleInputChange('max')}
              onBlur={handleInputBlur('max')}
              onKeyDown={handleInputKeyPress('max')}
              placeholder="Max"
              name="max"
            />
          </div>
        </div>
      </div>
    </RevealSection>
  )
}
