import produce from 'immer'

export const handleRegionCheck = (
  country,
  region,
  data,
  callback,
  checkedCountries
) => {
  const regionIndex = data.map(i => i.region).indexOf(region)
  const isSelected = data
    .map(i => i.children.map(i => i.value))
    [regionIndex].includes(...country)

  const filteredArr = checkedCountries.filter(
    (_, index) => index !== regionIndex
  ) // remove the region from the array

  const addedCountries = [
    ...filteredArr, // remove all regions except the one we are checking
    {
      ...checkedCountries[regionIndex],
      selected: !isSelected,
      children: [...checkedCountries[regionIndex].children, ...country]
    }
  ]

  const updateArr = [
    ...filteredArr,
    {
      ...checkedCountries[regionIndex],
      selected: isSelected,
      children: [...country]
    }
  ]

  if (checkedCountries[regionIndex].children.length !== country.length) {
    callback(updateArr.sort((a, b) => a.region.localeCompare(b.region)))
  } else {
    callback(addedCountries.sort((a, b) => a.region.localeCompare(b.region)))
    if (checkedCountries[regionIndex].children.includes(...country)) {
      // if the country is already selected
      const filteredCountries = checkedCountries[regionIndex].children.filter(
        item => !country.includes(item)
      )

      callback(prev =>
        [
          ...filteredArr,
          { ...prev[regionIndex], children: filteredCountries }
        ].sort((a, b) => a.region.localeCompare(b.region))
      )
    }
  }
}

export const handleCountryCheck = (
  country,
  region,
  countriesData,
  callback,
  checkedCountries
) => {
  const regionIndex = countriesData.map(i => i.region).indexOf(region)

  const filteredArr = checkedCountries.filter(
    (_, index) => index !== regionIndex
  )

  const sortedCountries = [
    ...checkedCountries[regionIndex].children,
    country
  ].sort((a, b) => a.localeCompare(b))

  const addedCountries = [
    ...filteredArr,
    {
      ...checkedCountries[regionIndex],
      children: sortedCountries
    }
  ]
  if (checkedCountries[regionIndex].children.includes(country)) {
    const filteredCountries = checkedCountries[regionIndex].children.filter(
      item => item !== country
    )

    callback(prev =>
      [
        ...filteredArr,
        {
          ...prev[regionIndex],
          children: filteredCountries
        }
      ].sort((a, b) => a.region.localeCompare(b.region))
    )
  } else {
    callback(addedCountries.sort((a, b) => a.region.localeCompare(b.region)))
  }
}

export const updateProduct = (filterName, value, callback) => {
  callback(prev =>
    produce(prev, draftState => {
      draftState[filterName] = value
    })
  )
}

export const handleChipClick = (
  optionsDataId = null,
  dataDetails,
  classType,
  setDataDetails = () => {},
  countriesData
) => {
  // for countries and regions
  if (Array.isArray(optionsDataId)) {
    if (
      optionsDataId.length > 0 &&
      dataDetails[classType]?.includes(...optionsDataId)
    ) {
      const filtered = dataDetails[classType].filter(
        item => !optionsDataId.includes(item)
      )

      setDataDetails(prev =>
        produce(prev, draftState => {
          draftState[classType] = filtered
        })
      )
    } else {
      const filtered = optionsDataId.filter(
        item => !dataDetails[classType]?.includes(item)
      )
      setDataDetails(prev =>
        produce(prev, draftState => {
          draftState[classType] = prev[classType]
            ? [...prev[classType], ...filtered]
            : optionsDataId
        })
      )
    }
  } else {
    let allItems = []
    let allCountriesArr = []
    if (classType === 'regionsAndCountries') {
      const allCountriesData = countriesData.map(i => {
        return i.children
      })

      allCountriesArr = allCountriesData.flat().map(i => i.value)
    } else {
      allItems = countriesData.map(i => {
        return i.value
      })
    }
    const isAllCountriesSelected =
      allItems.length > 0 ? allItems : allCountriesArr
    if (dataDetails[classType]?.length === isAllCountriesSelected.length) {
      updateProduct(classType, [], setDataDetails)
    } else {
      setDataDetails(prev =>
        produce(prev, draftState => {
          draftState[classType] = isAllCountriesSelected
        })
      )
    }
  }
}

export const updateProductDetails = (filterName, value, callback) => {
  callback(prev =>
    produce(prev, draftState => {
      draftState[filterName] = prev[filterName]
        ? [...prev[filterName], ...value]
        : value
    })
  )
}
