import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'

export const useStyles = makeStyles(theme => ({
  root: {
    width: derivedFromProp('tileWidth', {
      md: '80%',
      full: '100%'
    }),
    color: theme.palette.text.secondary,
    borderBottom: derivedFromProp('divider', {
      true: '1px solid #ccc4c0',
      false: 'none'
    }),
    boxShadow: 'none',
    background: 'transparent',
    position: 'relative',
    borderRadius: 0,
    fontFamily: 'Open Sans',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  tileContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'center !important'
    }
  },
  unpublishedText: {
    color: 'black',
    fontWeight: 400,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 20,
    padding: '3px 15px',
    marginRight: 15
  },
  image: {
    backgroundColor: 'white',
    padding: theme.spacing(1),
    height: '100%',
    display: 'grid',
    placeItems: 'center',
    '& img': {
      maxWidth: '100px',
      height: 'auto',
      display: 'block'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  chip: {
    margin: 2
  },
  tags: {
    color: '#CCC4C0',
    fontSize: '28px',
    paddingTop: '15px',
    fontWeight: '600',
    fontFamily: '"Barlow Condensed", sans-serif'
  },

  productName: {
    textAlign: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start'
    }
  },
  tileBody: {
    padding: theme.spacing(1.6),
    paddingLeft: theme.spacing(2.5)
  },
  tileImage: {
    padding: theme.spacing(2),
    paddingRight: ({ column }) => (!column ? 0 : theme.spacing(9))
  },
  bookmarksButton: {
    '& >button:nth-child(1)': {
      margin: '0 10px'
    }
  },
  tileButtonsContainer: {
    width: 'max-content',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start'
    }
  },
  chipButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '0.5rem',
    [theme.breakpoints.down(600)]: {
      paddingTop: theme.spacing(1)
    }
  },
  addTagContainer: {
    marginTop: 3
  },
  tagChipsContainer: {
    display: 'flex'
  },
  tagChipsInner: {
    display: 'flex',
    marginTop: 15
  },
  companyDetails: {
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: '13px',
    width: '100%',
    alignItems: 'center',
    '& span': {
      fontSize: 13,
      fontWeight: 400,
      fontFamily: 'Open Sans'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& span:last-child': {
        marginLeft: '0 !important'
      }
    }
  },
  companyStageDetails: {
    display: 'inline-flex',
    paddingLeft: theme.spacing(2.5),
    alignItems: 'center',
    color: '#666',
    fontWeight: 'bold',
    '& .label': {
      textTransform: 'uppercase',
      fontSize: '11px'
    },
    '& .icon': {
      paddingRight: theme.spacing(1),
      '& svg': {
        fill: '#666',
        width: 'auto',
        height: '15px',
        display: 'block',
        '& *': {
          fill: '#666'
        }
      }
    }
  },
  subTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '& .highlight': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: '14px',
      display: 'inline-block',
      padding: `0 ${theme.spacing(1)}px`,
      '&:first-child': {
        paddingLeft: 0
      }
    }
  },
  buttonBookmarkIcon: {
    height: '15px',
    width: 'auto',
    marginLeft: '3px'
  },
  headerDetailsContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0 !important',
      '& > div': {
        textAlign: 'start'
      }
    }
  },
  productDescription: {
    fontFamily: '"Open Sans", sans-serif',
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '1.5',
    fontSize: '13px'
  },
  productTags: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap'
    }
  },
  chipsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    '& div': {
      borderRadius: '20px',
      color: 'white',
      padding: '4px 15px'
    },
    '& > div:not(:first-child)': {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down(320)]: {
        marginLeft: 0,
        marginTop: theme.spacing(1)
      }
    },
    [theme.breakpoints.down(320)]: {
      flexWrap: 'wrap'
    }
  },
  isCompanyActive: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    color: 'white',
    backgroundColor: 'red',
    padding: '4px 15px',
    borderRadius: '20px',
    marginLeft: theme.spacing(1)
  },
  productTileWebsite: {
    color: '#2193D3',
    fontWeight: 400,
    textDecoration: 'none',
    '& h6': {
      fontWeight: 400
    }
  }
}))

export default useStyles
