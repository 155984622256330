import React from 'react'
import { useStyles } from '../../../components/Helpers/Navbar/useStyles'
import { Link } from 'react-router-dom'
import { ReactComponent as OxbowLogo } from 'assets/Logos/Magellan Logos/magellan-logo-white.svg'
import { Grid } from '@material-ui/core'
import { useAuth0 } from 'components/Contexts/AuthContext'
import {
  OxbowPrimaryButton,
  OxbowPrimaryTransparentButton
} from 'components/Helpers/Buttons'
import StyledLink from 'components/Helpers/StyledLink'

const LandingPageNavBar = ({
  // visibleSection,
  setVisibleSection,
  // landingNavButtons,
  setCurrId
}) => {
  const classes = useStyles()

  const { loginWithRedirect } = useAuth0()

  // const goTo = id => {
  //   if (window.location.pathname !== '/') {
  //     history.push('/')
  //   }
  //   setCurrId(id)
  //   setVisibleSection(id)
  // }

  return (
    <>
      <ul className={classes.toolbarList} style={{ width: '100%' }}>
        <Grid
          container
          style={{
            flexWrap: 'nowrap',
            alignItems: 'center',
            width: '55%'
          }}
        >
          <Link to="/" onClick={() => setCurrId('')}>
            <OxbowLogo alt="OxbowLogo" className={classes.navbarLogo} />
          </Link>
          {/* temp removed */}
          <div className={classes.navbarLinks}>
            {/* {landingNavButtons.map(item => (
              <div
                key={item.id}
                className={clsx(classes.leftMenuItem, {
                  [classes.activeMenuItem]: item.id === visibleSection
                })}
                onClick={() => goTo(item.id)}
              >
                {item.name}
              </div>
            ))} */}
            <li>
              <StyledLink to="/home" selectedClass="selected">
                HOME
              </StyledLink>
            </li>
            <li>
              <StyledLink to="/about-magellan" selectedClass="selected">
                ABOUT MAGELLAN
              </StyledLink>
            </li>
            <li>
              <StyledLink to="/insights" selectedClass="selected">
                INSIGHTS
              </StyledLink>
            </li>
            <li onClick={() => setVisibleSection('')}>
              <StyledLink to="/pricing" selectedClass="selected">
                PRICING
              </StyledLink>
            </li>
          </div>
        </Grid>

        <Grid className={classes.landingBtnContainer}>
          <li>
            <StyledLink
              style={{ marginRight: 20 }}
              to="/contact-us"
              selectedClass="selected"
            >
              Contact Us
            </StyledLink>
          </li>
          <OxbowPrimaryButton
            onClick={() =>
              loginWithRedirect({
                prompt: 'login',
                screen_hint: 'signup'
              })
            }
            className={classes.contactUsBtn}
            width="smd"
            fontSize="md"
            fontWeight="lg"
          >
            SIGN UP
          </OxbowPrimaryButton>
          <OxbowPrimaryTransparentButton
            className={classes.contactUsBtn}
            onClick={() =>
              loginWithRedirect({
                prompt: 'login',
                screen_hint: 'signin'
              })
            }
            width="smd"
            fontSize="md"
            fontWeight="lg"
            style={{ color: 'white' }}
          >
            SIGN IN
          </OxbowPrimaryTransparentButton>
        </Grid>
      </ul>
    </>
  )
}
export default LandingPageNavBar
