import { useEffect, useRef, useState } from 'react'
import { useSpring } from 'react-spring'
import ResizeObserver from 'resize-observer-polyfill'

export function useMeasure() {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  )
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ro])
  return [{ ref }, bounds]
}

export const useTree = () => {
  const [bind, { height: viewHeight }] = useMeasure()
  const firstRenderRef = useRef(true)
  const styles = useSpring({
    from: { height: viewHeight, opacity: 1 },
    to: { height: viewHeight, opacity: 1 },
    immediate: firstRenderRef.current,
    onRest: () => {
      if (firstRenderRef.current && viewHeight !== 0)
        firstRenderRef.current = false
    }
  })

  return [bind, styles]
}
