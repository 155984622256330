import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  insightsMainContent: {
    '& .loader': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      marginTop: 100
    },
    '& .no-results': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px 0',
      '& >h4': {
        fontWeight: 400,
        opacity: 0.5
      }
    },
    [theme.breakpoints.up(1920)]: {
      margin: '0 240px 80px'
    }
  },
  insightsSortingBlock: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    margin: '15px 0 30px',
    alignItems: 'end',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      margin: '15px 0 0'
    }
  },
  insightsSorting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& select': {
      width: 300
    },
    '& [role=combobox]': {
      background: 'transparent',
      '& >span': {
        fontSize: 16,
        fontWeight: 700
      }
    },
    '& span': {
      fontWeight: 700
    },
    '& ul': {
      background: '#1D5778',
      boxShadow: '0px 0px 4px rgba(31, 31, 31, 0.42)',
      border: 'none !important',
      '& li': {
        color: '#fff',
        fontSize: 16,
        fontWeight: 700,
        padding: '3px 0',
        transition: '0.3s',
        '& span': {
          transition: '0.3s',
          '&:not(.selected):hover': {
            color: 'rgba(255, 255, 255, 0.5)',
            transition: '0.3s'
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      alignItems: 'unset',
      flexDirection: 'column'
    }
  }
}))
