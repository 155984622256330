import { makeStyles } from '@material-ui/core'
import { derivedFromProp } from 'utils'

export const useStyle = makeStyles(theme => ({
  root: {
    borderRadius: '6px',
    background: '#fff',
    boxShadow: theme.shadows[4]
  },
  listWrapper: {
    overflowY: 'auto',
    maxHeight: derivedFromProp('maxHeight', {
      md: '25vh',
      default: '50vh'
    }),
    '& .section': {
      '& .list-header': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`
      },
      '& .list': {
        '& li': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: theme.palette.primary.dark,
          fontFamily: 'Open Sans',
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
          cursor: 'pointer',
          transition: 'background 0.3s ease',
          '&:hover': {
            background: '#eee'
          },
          '& span.value': {
            textTransform: 'capitalize',
            fontWeight: 'bold',
            flex: 1,
            whiteSpace: 'wrap',
            overflow: 'hidden',
            paddingRight: theme.spacing(2)
          },
          '& span.type': {
            color: '#888',
            textOverflow: 'ellipsis'
          },
          '&:not(:first-child)': {
            marginLeft: 'unset',
            marginRight: 'unset'
          }
        }
      }
    }
  }
}))
