export const journeyData = () => {
  const isPersonalPage =
    window.location.pathname === '/personal-account' ? true : false

  return [
    {
      step: 1,
      title: '1. Select insurance subclass of interest:',
      article:
        'To give you the best possible experience on Magellan, please complete these five quick questions about the technology companies and trends you are most interested in',
      selectAll: false,
      selectName: isPersonalPage ? 'Select Insurance subclass' : '',
      placeholder: isPersonalPage
        ? 'Enter Insurance subclass'
        : 'Start typing subclass',
      divider: false,
      chipsType: false,
      selectorType: 'basicSubclass'
    },

    {
      step: 2,
      article: false,
      selectAll: false,
      title: '2. Select function of interest:',
      selectName: isPersonalPage ? 'Select Function' : 'or select your own',
      placeholder: isPersonalPage ? 'Enter Function' : 'Start typing function',
      divider: true,
      chipsType: false,
      selectorType: 'primaryFunction'
    },
    {
      step: 3,
      article: false,
      selectAll: false,
      title: 'Step 3. Select Regions and Countries of interest:',
      chipsType: 'regionsAndCountries',
      selectName: isPersonalPage ? 'Select region and country' : '',
      placeholder: isPersonalPage
        ? 'Enter Country name'
        : 'Start typing country',
      divider: false,
      selectorType: 'regionsAndCountries'
    },
    {
      step: 4,
      article: false,
      selectAll: false,
      title: '4. Select Related technologies of interest:',
      selectName: isPersonalPage
        ? 'Select Related technologies'
        : 'or select your own',
      placeholder: isPersonalPage
        ? 'Enter Related technology'
        : 'Start typing related technology',
      divider: true,
      chipsType: false,
      selectorType: 'relatedTechnology'
    },
    {
      step: 5,
      article: false,
      selectAll: false,
      divider: false,
      title: '5. How can Magellan best support you?',
      checkBoxes: [
        'To discover companies relevant to my business goals',

        'To validate whether a vendor has the capabilities my business needs',
        'To learn more about InsurTech trends',
        'To scout emerging InsurTech companies and technologies',
        'To track vendors of interest',

        "To help me identify the InsurTech 'ones to watch' for my areas of interest",
        'To see what my competitors are doing',
        'Help me to develop my technology strategy',
        'Help me to run a technology selection process',
        'Other'
      ],
      selectorType: false,
      chipsType: false
    }
  ]
}
