import React, { useCallback, useEffect, useState } from 'react'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { BaseLayoutBody } from 'components/Layouts/Base'
import { useStyles } from './useStyles'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import { Link } from 'react-router-dom'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import InterestedInCompanies from './InterestedInCompanies'
import FavouritesCompanies from './FavouritesCompanies'
import HomePageNewsAndInsight from './NewsAndInsight'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'
import { CustomizationJourney } from '../CustomisationJourney'
import { ProductBottomBanner } from 'components/Helpers/Banner/ProductBottomBanner'
import { useAuth0 } from 'components/Contexts/AuthContext'
import landingbg from 'assets/Logos/LandingPage/landingbg.png'
import landingbgSM from 'assets/Logos/LandingPage/landingbgSM.png'
import { Animated } from 'components/Helpers/AnimatedComponent'
import InsightsCard from '../Insights/Components/InsightsCard'

import { MagellanArticle } from '../LandingPage/components/MagellanArticle'
import { useNavContext } from 'components/Contexts/NavbarContext'
import SearchBar from 'components/Helpers/SearchBar/searchBar'
import clsx from 'clsx'
import { getInsightsList } from 'utils/api'
import { Tabs } from '../Collections/Favourites/Tabs'
import { BrowseMagellanTab } from './components/BrowseMagellanTab'

const HomePageComponent = ({ history }) => {
  const {
    myFavId,
    myFavCollection,
    collections,
    selectedCollectionTagsFilter
  } = useCollections()

  const { setDemoFormOpen } = useNavContext()
  const { downSm, downMd } = useMediaQueries()
  const {
    customisationJourneyData,
    isJourneyDataLoaded,
    isCustomisationJourneyDone,
    setIsCustomisationJourneyDone,
    setCustomisationJourneyData
  } = useCustomisationJourney()
  const { isAuthenticated } = useAuth0()

  const fullHight = collections ? true : false
  const classes = useStyles({ fullHight })
  const [yourFavouritesData, setYourFavouritesData] = useState([])
  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const [show, setShow] = useState(false)

  const [loading, setLoading] = useState(false)
  const [insightsData, setInsightsData] = useState([])

  const getInsights = useCallback(async () => {
    setLoading(true)

    const params = {
      page: 1,
      pageSize: 6,
      sortOrder: 'desc'
    }

    const { insights } = await getInsightsList(params)

    setInsightsData(insights)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (insightsData.length === 0) {
      getInsights()
    }
  }, [insightsData])

  useEffect(() => {
    if (myFavId && myFavCollection && myFavCollection.products) {
      const res = []
      myFavCollection.products.forEach(product => {
        res.push({
          ...product,
          raw: product
        })
      })
      setYourFavouritesData(res.slice(0, 5))
    }
  }, [myFavId, myFavCollection, selectedCollectionTagsFilter])

  useEffect(() => {
    if (isJourneyDataLoaded) {
      if (isCustomisationJourneyDone || customisationJourneyData.done) {
        setShowCategoryModal(false)
        setShow(false)
      } else {
        setShowCategoryModal(true)
        setShow(true)
      }
    }
  }, [
    customisationJourneyData,
    isJourneyDataLoaded,
    isCustomisationJourneyDone
  ])
  const backgroundImage = {
    backgroundImage: `url(${downSm ? landingbgSM : landingbg})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover'
  }
  // const bg = {
  //   background:
  //     'linear-gradient(130.97deg, rgb(6, 35, 61) 46.13%, rgb(28, 86, 119) 75.17%)',
  //   borderBottomLeftRadius: 50
  // }
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [])

  const searchBarBox = (
    <Box>
      <Box className={classes.searchbarContainer}>
        {isAuthenticated && (
          <>
            <Typography variant="subtitle2">
              Search for specific vendors or products
            </Typography>

            <div>
              <SearchBar
                size="xs"
                width={downSm ? 'default' : 'md'}
                ml="sm"
                maxHeight="md"
                placeholder="Search vendors, e.g. Hyperexponential"
              />
            </div>

            <Link to="/search">
              <OxbowPrimaryButton
                width="max-content"
                style={{
                  margin: '40px 0 25px'
                }}
              >
                <Typography variant="h4">Go to Advanced Search</Typography>
              </OxbowPrimaryButton>
            </Link>
          </>
        )}
      </Box>
    </Box>
  )

  const [activeTab, setActiveTab] = useState(0)
  const searchTabs = [
    {
      title: 'Browse Magellan',
      component: <BrowseMagellanTab />
    },
    {
      title: 'Keyword search',
      component: searchBarBox
    }
  ]

  return (
    <BaseLayoutBody
      pageHeaderBottomLine={false}
      backgroundRightPanel="grey"
      headerStyle={{
        padding: 0
      }}
      borderRadiusOnFade={!isAuthenticated}
      pageHeader={
        <>
          <Grid
            className={clsx(classes.header, {
              authenticated: isAuthenticated
            })}
          >
            <Animated>
              <Grid
                className={clsx(classes.headerContent, {
                  authenticated: isAuthenticated,
                  unauthenticated: !isAuthenticated
                })}
                style={isAuthenticated ? {} : backgroundImage}
              >
                {isAuthenticated ? (
                  <>
                    <Grid container className={classes.homePageTabs}>
                      <Tabs
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        tabs={searchTabs}
                      />
                    </Grid>
                    {searchTabs[activeTab].component}
                  </>
                ) : (
                  <>
                    <Typography variant="h1">
                      The world's leading insurance technology <br />
                      information portal
                    </Typography>
                    <Typography variant="subtitle1">
                      Magellan is a database of over 4,000 vendors with an
                      insurance proposition, <br />
                      brought to you by Oxbow Partners
                    </Typography>
                    {!downSm && searchBarBox}
                  </>
                )}
              </Grid>
            </Animated>
          </Grid>
        </>
      }
      rightPanel={
        <>
          <Grid className={classes.homePageMainContainer}>
            <Box
              className={classes.dividerBlock}
              style={{
                marginBottom: 50
              }}
            >
              <Typography variant="h1">
                {!downSm && <Divider />}
                Vendor news
              </Typography>
            </Box>
            <div className={classes.newsAndInsight}>
              <HomePageNewsAndInsight downSm={downSm} downMd={downMd} />
            </div>
          </Grid>

          <Box className={classes.homePageMainContainer}>
            <Grid className={classes.insightsContainer}>
              <Box className={classes.dividerBlock}>
                <Typography variant="h1">
                  {!downSm && <Divider />}
                  Magellan insights
                </Typography>
              </Box>
              <Box
                style={{
                  position: 'relative',
                  minHeight: '10%',
                  textAlign: 'center',
                  marginBottom: 35
                }}
              >
                <Box>
                  <InsightsCard
                    data={insightsData}
                    history={history}
                    loading={loading}
                  />
                  <Link to="/insights">
                    <OxbowPrimaryButton>
                      <Typography variant="h4">View more</Typography>
                    </OxbowPrimaryButton>
                  </Link>
                </Box>
              </Box>
            </Grid>
            {!isAuthenticated && (
              <Grid className={classes.whyMagellan}>
                <Animated>
                  <Box className={classes.dividerBlock}>
                    <Typography
                      variant="h1"
                      style={{
                        paddingBottom: 35
                      }}
                    >
                      {!downSm && <Divider />}
                      Why Magellan
                    </Typography>
                  </Box>
                </Animated>
                <Box mb={8}>
                  <MagellanArticle />
                </Box>

                <Animated>
                  <Grid
                    container
                    justify="center"
                    style={{
                      marginTop: 50
                    }}
                  >
                    <OxbowPrimaryButton
                      onClick={() => setDemoFormOpen(true)}
                      className={classes.contactUsBtn}
                      fontSize="md"
                    >
                      Request a demo
                    </OxbowPrimaryButton>
                  </Grid>
                </Animated>
              </Grid>
            )}
            {isAuthenticated && (
              <Grid className={classes.yourFavourites}>
                <Box className={classes.dividerBlock}>
                  <Typography variant="h1">
                    {!downSm && <Divider />}
                    Your recommended vendors
                  </Typography>
                </Box>

                <Grid className={clsx(classes.homePageNews)}>
                  <div className={classes.interestedIn}>
                    <InterestedInCompanies />
                  </div>

                  <Box className={classes.yourFavouritesCompanies}>
                    <FavouritesCompanies
                      data={yourFavouritesData}
                      myFavId={myFavId}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}

            <Box className={classes.bottomBanner}>
              <ProductBottomBanner
                border={true}
                btnWidth={isAuthenticated ? 'max-content' : 'unset'}
                btnText={
                  isAuthenticated
                    ? 'Tell us what you are looking for'
                    : 'Create account'
                }
                divider={false}
                title={
                  isAuthenticated
                    ? 'Let our team of researchers do the work for you'
                    : 'Get access to articles about insurance technology <br/> and our database of 4,000+ vendors'
                }
                subTitle={
                  isAuthenticated
                    ? 'Need more help finding the right vendor?'
                    : null
                }
                background="white"
              />
            </Box>
          </Box>

          {isAuthenticated && showCategoryModal ? (
            <CustomizationJourney
              setIsCustomisationJourneyDone={setIsCustomisationJourneyDone}
              customisationJourneyData={customisationJourneyData}
              setCustomisationJourneyData={setCustomisationJourneyData}
              show={show}
              setShow={setShow}
            />
          ) : null}
        </>
      }
      bottomBanner={false}
    />
  )
}
export default HomePageComponent
