import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { getSelectedFilters } from 'components/Pages/SearchResults/Pagination'

import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { OxbowPrimaryButton } from '../Buttons'

import useMediaQueries from 'utils/customHooks/useMediaQueries'
import staticFilterConfig from 'configs/filters.config'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'

import { useStyles } from './styles'
import { FilterSectionWrapper } from './FilterOptions'
import Toggle from 'components/Pages/SearchResults/Pagination/inactiveComapniesSwitcher'
import { useQueryStringValues } from 'components/Contexts/QueryStringContext'

export const FilterOptions = () => {
  const { downXs, downSm } = useMediaQueries()
  const classes = useStyles()
  const { setAllCompaniesPage } = useSearchPaginationContext()
  const {
    filterAggregationState,
    filterConfig,
    filterValues,
    setFilterValues,
    selectedFilters,
    isApplyFiltersBtnVisible,
    setSelectedFilters,
    setFreeUserFilters,
    freeUserFilters,
    sendFreeUserFilters,
    tempFilters,
    setApplyFiltersBtnVisible,
    setFreeUserSelectedFilters,
    isFreeUserFiltersChanged,
    setIsFreeUserFiltersChanged,
    suggestInputsFiltersData,
    isUnlimitedSearches
  } = useFilterStateContext()
  const {
    showInActiveCompanies,
    setShowInActiveCompanies
  } = useQueryStringValues()
  const filterDetails = useMemo(
    () => ({
      ...suggestInputsFiltersData,
      ...staticFilterConfig.categoryFilters.filters
    }),
    [suggestInputsFiltersData]
  )

  const selectedTempFilters = useMemo(() => {
    if (Object.keys(tempFilters).length > 0) {
      return getSelectedFilters(tempFilters, filterDetails)
    }
  }, [filterDetails, tempFilters])

  const sortedFilters = filters =>
    Object.fromEntries(
      Object.entries(filters || {}).map(([key, { values }]) => [
        key,
        {
          values: Array.from(values).sort((a, b) => a.localeCompare(b))
        }
      ])
    )
  const compareTwoStrings = (str1, str2) => {
    if (str1?.length !== str2?.length) {
      return false
    }
    for (let i = 0; i < str1?.length; i++) {
      if (str1[i] !== str2[i]) {
        return false
      }
    }
    return true
  }

  const isSearchTermChanged = useMemo(() => {
    if (Object.keys(tempFilters).length > 0) {
      return !compareTwoStrings(tempFilters?.key, freeUserFilters?.key)
    } else {
      if (freeUserFilters.key !== '') {
        return true
      }
    }
    return false
  }, [tempFilters, freeUserFilters])

  useEffect(() => {
    const isEquall = _.isEqual(
      sortedFilters(selectedFilters),
      sortedFilters(selectedTempFilters)
    )
    if (!isEquall || isSearchTermChanged) {
      setApplyFiltersBtnVisible(true)
    } else {
      setApplyFiltersBtnVisible(false)
    }
  }, [
    selectedFilters,
    isSearchTermChanged,
    selectedTempFilters,
    setApplyFiltersBtnVisible
  ])

  const applyFilters = () => {
    sendFreeUserFilters(freeUserFilters)
    setAllCompaniesPage(1)
    setFreeUserSelectedFilters(selectedFilters)
  }

  const isFreeUserSetFilter = !isUnlimitedSearches
    ? setFreeUserFilters
    : setFilterValues

  const productAndCompany = Object.entries(filterConfig).filter(([k, v]) => {
    if (k === 'companyFilters' || k === 'productFilters') {
      return {
        [k]: v
      }
    }
  })

  const otherAggsObj = Object.fromEntries(productAndCompany)

  const isClearBtnVisible = sectionConfig => {
    let selectedCategory = []
    let isCountriesSelected = []

    if (
      (!selectedFilters || Object.keys(selectedFilters).length === 0) &&
      filterValues &&
      (!filterValues.geographiesWithImplementations ||
      filterValues.geographiesWithImplementations.length === 0)
    )
      return false

    if (
      filterValues &&
      filterValues.geographiesWithImplementations &&
      filterValues.geographiesWithImplementations.length > 0
    ) {
      isCountriesSelected = ['geographiesWithImplementations']
    }

    const selectedFilterCategories = Object.keys(selectedFilters).map(
      filterName => {
        const filterConfig = sectionConfig.filters[filterName]

        if (filterConfig || sectionConfig.id === filterName) {
          return sectionConfig.id
        }
        return false
      }
    )
    const filtered = selectedFilterCategories.filter(i => i)
    selectedCategory = [...new Set(filtered), ...isCountriesSelected]

    return selectedCategory
  }

  const [panels, setPanels] = useState({
    primaryFunction: {
      expanded: true
    },
    basicSubclass: {
      expanded: true
    },
    whereFilters: {
      expanded: true
    },
    other: {
      expanded: true
    }
  })

  const handleChange = panel => {
    setTimeout(() => {
      setPanels({
        ...panels,
        [panel]: {
          ...panels[panel],
          expanded: !panels[panel].expanded
        }
      })
    }, 300)
  }

  return (
    <div
      style={{
        width: downXs ? '90%' : '350px',
        maxWidth: downXs ? 'unset' : '350px'
      }}
    >
      <div className={classes.filterOptions}>
        {/* Rendering other filter sections */}

        {Object.keys(filterConfig).map(sectionId => {
          let sectionConfig = filterConfig[sectionId]
          let sectionAggregationData

          if (
            sectionConfig.id === 'primaryFunction' ||
            sectionConfig.id === 'basicSubclass' ||
            sectionConfig.id === 'productFilters' ||
            sectionConfig.id === 'whereFilters'
          ) {
            sectionAggregationData =
              filterAggregationState['productAggregations']
          }

          return (
            <React.Fragment key={sectionId}>
              {sectionId === 'companyFilters' ||
              sectionId === 'productFilters' ? null : (
                <>
                  <Accordion
                    expanded={panels[sectionConfig.id].expanded}
                    onChange={() => handleChange(sectionConfig.id)}
                    className={classes.accordion}
                  >
                    <Box pb={0} className={classes.filterSectionHeader}>
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <AccordionSummary
                          className={classes.accordionSummary}
                          expandIcon={<ArrowDropDown />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            {sectionConfig.title}
                          </Typography>
                        </AccordionSummary>
                      </Grid>
                    </Box>
                    {!isUnlimitedSearches ? (
                      <FilterSectionWrapper
                        isClearBtnVisible={isClearBtnVisible(sectionConfig)}
                        key={sectionConfig.id}
                        selectedFilters={selectedFilters}
                        isFreeUserFiltersChanged={isFreeUserFiltersChanged}
                        setIsFreeUserFiltersChanged={
                          setIsFreeUserFiltersChanged
                        }
                        setApplyFiltersBtnVisible={setApplyFiltersBtnVisible}
                        {...{
                          sectionConfig,
                          sectionId: sectionConfig.id,
                          selectedCategory: freeUserFilters.category,
                          sectionAggregationData,
                          freeUserFilters,
                          setFilterValues: isFreeUserSetFilter,
                          setSelectedFilters,
                          sendFreeUserFilters,
                          isUnlimitedSearches,
                          setAllCompaniesPage,
                          setFreeUserSelectedFilters
                        }}
                      />
                    ) : (
                      <FilterSectionWrapper
                        key={sectionConfig.id}
                        selectedFilters={selectedFilters}
                        {...{
                          sectionConfig,
                          sectionId: sectionConfig.id,
                          selectedCategory: filterValues.category,
                          sectionAggregationData,
                          filterValues,
                          setFilterValues,
                          isUnlimitedSearches,
                          setAllCompaniesPage
                        }}
                      />
                    )}
                  </Accordion>
                </>
              )}
            </React.Fragment>
          )
        })}

        {otherAggsObj && (
          <Accordion
            expanded={panels.other.expanded}
            onChange={() => handleChange('other')}
            className={classes.accordion}
          >
            <Box pb={0} className={classes.filterSectionHeader}>
              <Grid container alignItems="center" justify="space-between">
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ArrowDropDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Other</Typography>
                </AccordionSummary>
              </Grid>
            </Box>

            <AccordionDetails className={classes.accordionDetails}>
              {Object.keys(otherAggsObj).map(sectionId => {
                let sectionConfig = otherAggsObj[sectionId]

                let sectionAggregationData

                if (sectionConfig.id === 'companyFilters') {
                  sectionAggregationData =
                    filterAggregationState['companyAggregations']
                } else if (sectionConfig.id === 'productFilters') {
                  sectionAggregationData =
                    filterAggregationState['productAggregations']
                }
                return (
                  <React.Fragment key={sectionId}>
                    {!isUnlimitedSearches ? (
                      <FilterSectionWrapper
                        key={sectionConfig.id}
                        selectedFilters={selectedFilters}
                        isFreeUserFiltersChanged={isFreeUserFiltersChanged}
                        setIsFreeUserFiltersChanged={
                          setIsFreeUserFiltersChanged
                        }
                        setApplyFiltersBtnVisible={setApplyFiltersBtnVisible}
                        {...{
                          sectionConfig,
                          sectionId: sectionConfig.id,
                          selectedCategory: freeUserFilters.category,
                          sectionAggregationData,
                          freeUserFilters,
                          setFilterValues: isFreeUserSetFilter,
                          setSelectedFilters,
                          sendFreeUserFilters,
                          isUnlimitedSearches,
                          setAllCompaniesPage,
                          setFreeUserSelectedFilters
                        }}
                      />
                    ) : (
                      <FilterSectionWrapper
                        key={sectionConfig.id}
                        selectedFilters={selectedFilters}
                        {...{
                          sectionConfig,
                          sectionId: sectionConfig.id,
                          selectedCategory: filterValues.category,
                          sectionAggregationData,
                          filterValues,
                          setFilterValues,
                          isUnlimitedSearches,
                          setAllCompaniesPage
                        }}
                      />
                    )}
                  </React.Fragment>
                )
              })}

              <Toggle
                show={showInActiveCompanies}
                setShow={setShowInActiveCompanies}
                label="Show inactive companies"
              />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      {!isUnlimitedSearches && !downSm && isApplyFiltersBtnVisible && (
        <Box
          style={{
            position: 'sticky',
            left: 0,
            bottom: 40
          }}
        >
          <OxbowPrimaryButton
            onClick={() => applyFilters()}
            width="md"
            fontSize="md"
          >
            Apply filters
          </OxbowPrimaryButton>
        </Box>
      )}
    </div>
  )
}

export default FilterOptions
