import { useState } from 'react'
import { useEffect } from 'react'

export const getElementOffset = el => {
  return {
    top: el.offsetTop,
    left: el.offsetLeft
  }
}

const useRect = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [location, setLocation] = useState({ left: 0, top: 0 })
  useEffect(() => {
    if (!ref.current) return
    setDimensions({
      width: ref.current.clientWidth,
      height: ref.current.clientHeight
    })
    setLocation(getElementOffset(ref.current))
  }, [ref])
  return { ...dimensions, ...location }
}

export default useRect
