import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, Divider } from '@material-ui/core'
import { useStyles } from './useStyles'
import { getNewsAndInsight } from 'utils/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import SelectComponent from 'components/Helpers/SelectComponent/SelectComponent'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { Link } from 'react-router-dom'
import { useAuth0 } from 'components/Contexts/AuthContext'
import useMediaQueries from 'utils/customHooks/useMediaQueries'

const HomePageNewsAndInsight = () => {
  const classes = useStyles()
  const { productsBootstrapData } = useFilterStateContext()
  const { downXs } = useMediaQueries()
  const { isAuthenticated } = useAuth0()
  const [filterArticleType, setFilterArticleType] = useState('All news')
  const [newsData, setNewsData] = useState([])
  const [firstRender, setFirstRender] = useState(false)

  const getStandardDate = (datePublished, dateAccuracy) => {
    let finalDate = datePublished
    switch (dateAccuracy) {
      case 'Day':
        finalDate = moment(datePublished).format('LL')
        break
      case 'Month':
        finalDate = moment(datePublished).format('MMMM')
        break
      case 'Year':
        finalDate = moment(datePublished).format('YYYY')
        break
      default:
        break
    }
    return finalDate
  }

  const renderArticleType = articleType => {
    let finalName
    switch (articleType) {
      case 'Vendor':
        finalName = 'Vendor press release and news'
        break
      case 'Industry Publication':
        finalName = 'Industry press articles'
        break
      case 'Case Study':
        finalName = 'Case Study';
        break;
      default:
        finalName = 'Oxbow Partners Content'
        break
    }
    return finalName
  }

  const availableOptions = () => {
    const options = [
      { value: 'All news', name: 'All news' },
      { value: 'Industry Publication', name: 'Industry press articles' },
      { value: 'Vendor', name: 'Vendor press releases and news' },
      { value: 'Case Study', name: 'Case studies' }
    ]
    if (isAuthenticated) {
      options.push({ value: 'Personalized', name: 'Based on my interests' })
    }
    return options
  }

  const pageSize = 50
  const [segment, setSegment] = useState({ start: 0, end: pageSize - 1 })
  const [totalCount, setTotalCount] = useState(1)

  const getData = async () => {
    const articleType =
      filterArticleType !== 'All news' ? filterArticleType : undefined
    let { news, totalCount: total } = await getNewsAndInsight(
      segment.start,
      segment.end,
      articleType
    )
    news = [...newsData, ...news]
    setNewsData(news)
    setTotalCount(total)
  }

  useEffect(() => {
    getData()
  }, [segment.start, segment.end, filterArticleType])

  const fetchMoreData = () => {
    if (!firstRender) {
      setSegment(prev => ({
        start: prev.start + pageSize,
        end: prev.end + pageSize
      }))
    }
  }

  const onFilter = type => {
    setFirstRender(true)
    setFilterArticleType(type)
    setNewsData([])
    setSegment({
      start: 0,
      end: pageSize - 1
    })
  }
  const getTextWidth = text => {
    // re-use canvas object for better performance
    var canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement('canvas'))
    var context = canvas.getContext('2d')

    var metrics = context.measureText(text)
    return metrics.width
  }
  return (
    <div>
      <Grid container justify="space-between">
        <SelectComponent
          styles={{ width: getTextWidth(filterArticleType) + 100 }}
          isFromSearchPage={true}
          spanStyle={{ right: 20, left: 'unset' }}
          inputWidth="none"
          marginTop="none"
          border="none"
          title=" "
          selectoptions={availableOptions()}
          // selectedValue={menuOptions(filterArticleType)}
          selectedValue={filterArticleType}
          setSelectedValue={onFilter}
          selectedValueWeight="bold"
          placeholder="Show"
          positionRight={downXs ? 'default' : true}
        />
      </Grid>

      <Grid container style={{ height: '100%' }}>
        <Box className={classes.scrollBox} style={{ width: '100%' }}>
          <InfiniteScroll
            dataLength={newsData.length}
            next={
              firstRender && newsData.length !== 0
                ? setFirstRender(false)
                : fetchMoreData
            }
            hasMore={newsData.length < totalCount}
            height={800}
            loader={<h4>Loading...</h4>}
            endMessage={<></>}
            scrollThreshold={0.7}
          >
            {newsData?.map((news, index) => {
              let res = []
              if (news && news.productIds) {
                news.productIds.forEach(productId => {
                  res.push({ ...productsBootstrapData[productId] })
                })
              }
              return (
                <div key={index} className={classes.newsInsightMainBlock}>
                  <div className={classes.newsInsightContainer}>
                    <Grid item>
                      <Box>
                        <Link
                          key={`link-${res[0].slug}`}
                          to={{
                            pathname: `/product/${res[0].slug}`
                          }}
                        >
                          <img alt="Product Logo" src={res[0].logo} />
                        </Link>
                      </Box>
                    </Grid>
                    <div className={classes.articleBlock}>
                      <h5 className={classes.articleContainer}>
                        <span>
                          {getStandardDate(
                            news.datePublished,
                            news.dateAccuracy
                          )}
                        </span>
                        <span>{renderArticleType(news.articleType)}</span>
                      </h5>
                      <a
                        href={news.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography
                          variant="h4"
                          className={classes.newsInsightOverviewTileName}
                        >
                          {news.title}
                        </Typography>
                      </a>
                    </div>
                  </div>
                  <Divider variant="fullWidth" />
                </div>
              )
            })}
          </InfiniteScroll>
        </Box>
        <div className={classes.bottomGradient}></div>
      </Grid>
    </div>
  )
}

export default HomePageNewsAndInsight
