import React, { useState, useEffect } from 'react'
import qs from 'query-string'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { MESSAGE_TYPES } from './messageTypes'
import actions from './actions'
import cross from 'assets/icons/cross.svg'
const useStyles = makeStyles(theme => ({
  innerDialog: {
    margin: 20,
    '& h2': {
      fontSize: 36,
      fontFamily: theme.typography.mainFont,
      textTransform: 'uppercase',
      textAlign: 'center'
    },
    '& h6': {
      textAlign: 'center'
    }
  },
  dialogActions: {
    justifyContent: 'center !important'
  },
  dialogActioBtn: {
    fontFamily: theme.typography.secondaryFont,
    fontSize: 16,
    '&:first-child': {
      width: 144
    },
    '&:last-child': {
      background: theme.palette.primary.primaryBig
    }
  },
  closeIcon: {
    position: 'absolute',
    right: 20,
    top: 30,
    cursor: 'pointer',
    width: 12,
    height: 12
  }
}))

export default function Auth0MessageDialog() {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState({})
  const [messageContent, setMessageContent] = useState('')

  const { loginWithRedirect, logout } = useAuth0()

  useEffect(() => {
    const queryParams = qs.parse(window.location.search)
    for (const key of Object.keys(MESSAGE_TYPES)) {
      if (key in queryParams) {
        setIsOpen(true)
        setMessage(MESSAGE_TYPES[key])
        setMessageContent(queryParams[key])
        break
      }
    }
  }, [])

  function implementFunction(functionCalls) {
    return () =>
      functionCalls.forEach(functionCall => callFunction(functionCall))
  }

  function callFunction(functionCall) {
    switch (functionCall.functionName) {
      case actions.SET_IS_OPEN:
        setIsOpen(...functionCall.args)
        break
      case actions.LOGIN_WITH_REDIRECT:
        loginWithRedirect(...functionCall.args)
        break
      case actions.LOGOUT:
        logout(...functionCall.args)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={implementFunction(message.onClose)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.innerDialog}>
        <img
          alt=""
          src={cross}
          onClick={() => implementFunction(message.onClose)}
          className={classes.closeIcon}
        />
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h2">{message.messageTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="textPrimary">
            <Typography variant="subtitle2">{messageContent}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {message?.buttons?.map((button, index) => (
            <button.component
              key={index}
              onClick={implementFunction(button.onClick)}
              variant={button.variant}
              color={button.color}
              className={classes.dialogActioBtn}
            >
              {button.title}
            </button.component>
          ))}
        </DialogActions>
      </div>
    </Dialog>
  )
}
