import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import cross from 'assets/icons/cross.svg'
import { countriesData } from 'components/Pages/CustomisationJourney/countries'
import { useStyles } from '../../useStyles'
import { BtnsBlock } from './BtnsBlock'
import MagellanModal from 'components/Helpers/Modal'
import { updateUserProfile } from 'utils/api'
import { StepsComponent } from 'components/Pages/CustomisationJourney/StepsComponents/StepsComponent'
import { journeyData } from 'components/Pages/CustomisationJourney/journeyData'
import { ChipsToShow } from 'components/Helpers/ChipsToShow'
import _ from 'lodash'

export const countriesAndRegionsCreate = ({
  countriesData,
  dataDetails,
  checkedCountries
}) => {
  let isCheckedRegionAndCountries = []

  countriesData.map(({ region, children }) => {
    let isEq = false
    const countriesAndRegionsSorting = children
      .map(
        country =>
          dataDetails['regionsAndCountries']?.includes(country.value) &&
          country.value
      )
      .filter(Boolean)

    checkedCountries.map(item => {
      if (region === item.region) {
        const arr = children.map(country => country.value)
        isEq = _.isEqual(arr, item.children)
      }
      return null
    })
    isCheckedRegionAndCountries.push({
      region: region,
      selected: isEq,
      children: countriesAndRegionsSorting ? countriesAndRegionsSorting : {}
    })
    return null
  })
  return isCheckedRegionAndCountries
}

export const UserAccountAreasOfInterest = ({
  setIsAreasOfInterestEditModeOpen,
  isEditModeOpened,
  handleActiveBlock,
  customisationJourneyData,
  setCustomisationJourneyData
}) => {
  const classes = useStyles()

  const objToArr = Object.entries(customisationJourneyData)
    .filter(
      ([key, value]) =>
        value.length > 0 ||
        !['insuranceClass', 'insuranceSubclass'].includes(key)
    )
    .map(([key, value]) => ({
      [key]: value
    }))
  const [dataDetails, setDataDetails] = useState({})
  const [checkedCountries, setCheckedCountries] = useState([])
  const [show, setShow] = useState(false)
  const [
    isInsuranceProductDistributorsChecked,
    setInsuranceProductDistributorsChecked
  ] = useState(false)
  const generateBlockTitle = title => {
    switch (title) {
      case 'insuranceClass':
        return 'Insurance class'
      case 'insuranceSubclass':
      case 'basicSubclass':
        return 'Insurance subclass'
      case 'primaryFunction':
        return 'Function'
      case 'insuranceProductDistributors':
        return 'Are you also interested in insurance product distributors, such as Tech MGAs?'
      case 'regionsAndCountries':
        return 'Region and country'
      case 'relatedTechnology':
        return 'Related technologies'
    }
  }
  const generateEmptyArrayMessage = title => {
    switch (title) {
      case 'insuranceClass':
        return 'Add Insurance class of interest'
      case 'insuranceSubclass':
      case 'basicSubclass':
        return 'Add Insurance subclass of interest'
      case 'primaryFunction':
        return 'Add Function of interest'
      case 'regionsAndCountries':
        return 'Add Country of interest'
      case 'relatedTechnology':
        return 'Add Related technologies of interest'
    }
  }

  useEffect(() => {
    setDataDetails(customisationJourneyData)
    setInsuranceProductDistributorsChecked(
      customisationJourneyData.insuranceProductDistributors
    )
  }, [customisationJourneyData, setDataDetails])

  useEffect(() => {
    if (dataDetails['regionsAndCountries']) {
      setCheckedCountries(
        countriesAndRegionsCreate({
          countriesData,
          dataDetails,
          checkedCountries
        })
      )
    }
  }, [dataDetails, countriesData])

  const isCustomisationJourneyDataChanged = useMemo(() => {
    return !_.isEqual(customisationJourneyData, dataDetails)
  }, [dataDetails, customisationJourneyData])

  const handleDiscardChanges = () => {
    if (isCustomisationJourneyDataChanged) {
      setShow(true)
    } else {
      handleActiveBlock(isEditModeOpened)
      setIsAreasOfInterestEditModeOpen(false)
      setShow(false)
    }
  }

  const isInterestsEmpty = () => {
    if (Object.keys(dataDetails).length > 0) {
      const {
        insuranceClass,
        insuranceSubclass,
        basicSubclass,
        primaryFunction,
        regionsAndCountries,
        relatedTechnology
      } = customisationJourneyData
      const isEmpty =
        !insuranceClass.length &&
        !insuranceSubclass.length &&
        !primaryFunction.length &&
        !regionsAndCountries.length &&
        !relatedTechnology.length &&
        !(basicSubclass || []).length

      return isEmpty
    }
  }

  const sendUserFilters = async () => {
    if (Object.keys(dataDetails).length > 0) {
      let userData = {
        ...dataDetails,
        insuranceProductDistributors: isInsuranceProductDistributorsChecked,
        insuranceClass: [],
        insuranceSubclass: []
      }

      try {
        const data = await updateUserProfile(userData)
        setCustomisationJourneyData({
          insuranceClass: data.insurance_class,
          insuranceSubclass: data.insurance_subclass,
          basicSubclass: data.basic_subclass,
          primaryFunction: data.primary_function,
          insuranceProductDistributors: data.insurance_product_distributors,
          regionsAndCountries: data.regions_and_countries,
          relatedTechnology: data.related_technology,
          intentionsOfUse: data.intentions_of_use,
          done: data.done
        })

        setIsAreasOfInterestEditModeOpen(false)
        handleActiveBlock(isEditModeOpened)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const resetMyInterests = async () => {
    let userData = {
      insuranceClass: [],
      insuranceSubclass: [],
      basicSubclass: [],
      primaryFunction: [],
      regionsAndCountries: [],
      relatedTechnology: [],
      insuranceProductDistributors: false
    }

    try {
      const data = await updateUserProfile(userData)
      setCustomisationJourneyData({
        insuranceClass: data.insurance_class,
        insuranceSubclass: data.insurance_subclass,
        basicSubclass: data.basic_subclass,
        primaryFunction: data.primary_function,
        insuranceProductDistributors: data.insurance_product_distributors,
        regionsAndCountries: data.regions_and_countries,
        relatedTechnology: data.related_technology,
        intentionsOfUse: data.intentions_of_use,
        done: data.done
      })
      setCheckedCountries([])
      setIsAreasOfInterestEditModeOpen(false)
      handleActiveBlock(isEditModeOpened)
    } catch (err) {
      console.log(err)
    }
  }

  const filteredSelectedRegions = checkedCountries.filter(i => !i.selected)
  const selectedCountries = filteredSelectedRegions.map(i => i.children).flat()
  const selectedRegion = checkedCountries.filter(i => i.selected)

  if (!dataDetails) return null
  return (
    <Grid container>
      <Box>
        {isEditModeOpened ? (
          <>
            {journeyData().map((data, index) => {
              const step = index + 1

              return (
                <React.Fragment key={data.title}>
                  {data.step === step && step !== 5 && (
                    <StepsComponent
                      key={data.title}
                      checkBoxes={data.checkBoxes}
                      article=""
                      header=""
                      selectAll={data.selectAll}
                      chipsType={data.chipsType}
                      selectorType={data.selectorType}
                      dataDetails={dataDetails}
                      setDataDetails={setDataDetails}
                      selectName={data.selectName}
                      placeholder={data.placeholder}
                      divider={false}
                      isInsuranceProductDistributorsChecked={
                        isInsuranceProductDistributorsChecked
                      }
                      setInsuranceProductDistributorsChecked={
                        setInsuranceProductDistributorsChecked
                      }
                      countriesData={countriesData}
                      otherValue={''}
                      setOtherValue={() => {}}
                      journeyPage={index + 1}
                      error={false}
                      setError={() => {}}
                      otherDataValue={''}
                      checkedCountries={checkedCountries}
                      setCheckedCountries={setCheckedCountries}
                      margin="none"
                      justifyContent="unset"
                      width="full"
                      fontWeight="semibold"
                      alignSelf="flex-start"
                      inputWidth="partly-hidden"
                      isEditModeOpened={isEditModeOpened}
                    />
                  )}
                </React.Fragment>
              )
            })}
          </>
        ) : (
          <>
            {objToArr.map((item, index) => {
              const key = Object.keys(item)[0]

              return (
                <React.Fragment key={index}>
                  <Typography
                    variant="h4"
                    className={classes.accountInformationSubTitle}
                  >
                    {generateBlockTitle(key)}
                  </Typography>
                  <Grid container>
                    {Array.isArray(item[key]) && item[key].length === 0 && (
                      <>
                        <Typography
                          variant="h4"
                          className={classes.accountInformationText}
                        >
                          {generateEmptyArrayMessage(key)}
                        </Typography>
                      </>
                    )}

                    {Array.isArray(item[key]) && key !== 'intentionsOfUse' ? (
                      <Box>
                        <ChipsToShow
                          selectedRegion={selectedRegion}
                          selectedItems={selectedCountries}
                          data={item[key]}
                          isFromJourney={true}
                          marginTop="sm"
                          editable={false}
                          type={key}
                          count={5}
                          btnLabel="See"
                        />
                      </Box>
                    ) : (
                      <>
                        <Typography
                          variant="h4"
                          className={classes.accountInformationText}
                          style={{ opacity: 1 }}
                        >
                          {key === 'insuranceProductDistributors'
                            ? item[key]
                              ? 'Yes'
                              : 'No'
                            : null}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </React.Fragment>
              )
            })}
          </>
        )}
      </Box>
      <MagellanModal show={show} modalClass={classes.accountModal}>
        <Grid container direction="column" align="center">
          <Typography variant="h1">
            Discard changes
            <img alt="" src={cross} onClick={() => setShow(false)} />
          </Typography>
          <Typography variant="subtitle2">
            Are you sure you want to quit without saving changes?
          </Typography>
          <BtnsBlock
            style={{ justifyContent: 'center', margin: '0 auto' }}
            cancelPlaceholder="Cancel"
            savePlaceholder="Discard"
            isDeleteButtonVisible={false}
            handleCancel={() => setShow(false)}
            handleSave={() => {
              setCustomisationJourneyData(prev => ({ ...prev }))
              handleActiveBlock(isEditModeOpened)
              setIsAreasOfInterestEditModeOpen(false)
              setCheckedCountries([])
              setShow(false)
            }}
            alignItems="center"
            alignSelf="center"
          />
        </Grid>
      </MagellanModal>
      {isEditModeOpened ? (
        <BtnsBlock
          cancelPlaceholder="Cancel"
          savePlaceholder="Save changes"
          deletePlaceholder="Reset my interests"
          isDeleteButtonVisible={!isInterestsEmpty()}
          handleDelete={resetMyInterests}
          handleCancel={() => handleDiscardChanges()}
          handleSave={() => {
            sendUserFilters()
          }}
        />
      ) : null}
    </Grid>
  )
}
