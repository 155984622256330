import React, { useMemo, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import MaterialCheckbox from '@material-ui/core/Checkbox'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import classnames from 'classnames'
import RevealSection, { RevealSectionTitleWithCountBadge } from '../Reveal'

import Can from '../../../rbac/can'
import { useNavContext } from '../Navbar'
import ToolTip from '../ToolTip'

const useStyles = makeStyles(() => ({
  checkBoxLabel: {
    cursor: 'pointer'
  }
}))

const CheckBox = ({ value, onChange, disabled }) => {
  const handleChange = e => {
    onChange(e.target.checked)
  }
  return (
    <MaterialCheckbox
      checked={value}
      onChange={handleChange}
      color="primary"
      disabled={disabled}
    />
  )
}
const MemoizedCheckbox = React.memo(CheckBox)

const CheckBoxOption = React.memo(
  ({
    control,
    label,
    value,
    disabled,
    onChange,
    name,
    disabledOnFree,
    index,
    size,
    ...rest
  }) => {
    const classes = useStyles()
    const { setSignupOpen } = useNavContext()

    const [hover, setHover] = useState(false)

    const checkBoxChangeHandler = useCallback(
      value => {
        onChange(name, value)
      },
      [name, onChange]
    )

    const showSignUpModal = useCallback(() => {
      setSignupOpen(true)
    }, [setSignupOpen])

    const CheckBox = React.cloneElement(control, {
      value,
      onChange: checkBoxChangeHandler,
      disabled,
      ...rest
    })

    const CheckBoxDisabled = React.cloneElement(control, {
      value,
      onChange: checkBoxChangeHandler,
      disabled: disabledOnFree ? true : disabled,
      ...rest
    })

    return (
      <Can
        perform="use:all-filters"
        yes={() => (
          <div
            className="checkbox-option"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {CheckBox}
            <span
              className={classnames(
                'checkbox-option-label',
                classes.checkBoxLabel
              )}
              onClick={() => checkBoxChangeHandler(!value)}
              style={{ pointerEvents: disabled ? 'none' : 'all' }}
            >
              {label}
            </span>
          </div>
        )}
        no={() => (
          <ToolTip
            key={label}
            text="Click to access full Magellan content"
            placement={index >= size - 2 ? 'top' : 'bottom'}
            active={hover && disabledOnFree ? disabledOnFree : false}
          >
            <div
              className="checkbox-option"
              onClick={() =>
                disabledOnFree
                  ? showSignUpModal()
                  : checkBoxChangeHandler(!value)
              }
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {CheckBoxDisabled}
              <span
                className={classnames(
                  'checkbox-option-label',
                  classes.checkBoxLabel
                )}
                style={{ pointerEvents: disabled ? 'none' : 'all' }}
              >
                {label}
              </span>
            </div>
          </ToolTip>
        )}
      />
    )
  }
)

const CheckBoxSection = ({
  title,
  options,
  style = {},
  aggData,
  onChange,
  value,
  filterName,
  filterProps = {},
  disabledOnFree
}) => {
  const onChangeHandler = useCallback(
    (filterOption, value) => onChange(filterName, filterOption, value),
    [onChange, filterName]
  )

  const { ignoreDocCount } = filterProps

  const selectedOptions = useMemo(
    () =>
      options.reduce((acc, option) => {
        if (value[option.name]) return acc + 1
        return acc
      }, 0),
    [options, value]
  )

  // If no options are available for a section , return NULL
  if (options.length <= 0) {
    return null
  }

  return (
    <RevealSection
      label={
        <RevealSectionTitleWithCountBadge
          title={title}
          count={selectedOptions}
        />
      }
      icon={<ChevronRightIcon />}
      style={style}
    >
      {options.map(({ label, name: optionName }, index) => {
        const doc_count = aggData[optionName]
          ? aggData[optionName].doc_count
          : 0

        const _label = ignoreDocCount ? label : `${label} (${doc_count})`

        return (
          <CheckBoxOption
            key={label + optionName}
            label={_label}
            disabled={ignoreDocCount ? false : doc_count === 0}
            disabledOnFree={disabledOnFree}
            value={value[optionName]}
            onChange={onChangeHandler}
            control={<MemoizedCheckbox />}
            name={optionName}
            size={options.length}
            index={index}
          />
        )
      })}
    </RevealSection>
  )
}

export const MemoizedCheckoxSection = React.memo(CheckBoxSection)
export default CheckBoxSection
