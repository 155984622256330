import produce from 'immer'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const EditProductDetailsContext = React.createContext()
export const useEditProductDetailsContext = () =>
  useContext(EditProductDetailsContext)

export const EditProductDetailsContextProvider = ({ children }) => {
  const [productDetails, setProductDetails] = useState({})
  const [suggestedData, setSuggestedData] = useState({})
  const [isSuggestionsEditsEnabled, setSuggestionsEditsEnabled] = useState(
    false
  )
  const { location } = useHistory()

  const updateProductDetails = useCallback(
    (key, value, isInitialValue = false) => {
      setProductDetails(oldProductDetails =>
        produce(oldProductDetails, draftState => {
          if (!draftState[key]) {
            draftState[key] = {}
          }
          draftState[key].value = value
          draftState[key].isPristine = isInitialValue
        })
      )
    },
    []
  )

  // Reset the context state on route change
  useEffect(() => {
    setProductDetails({})
  }, [location])

  useEffect(() => {
    const modifiedFields = Object.entries(productDetails).filter(
      ([_entryKey, entry]) => !entry.isPristine
    )
    const suggestedDataString = modifiedFields
      .map(
        ([fieldName, { value }]) => `${fieldName}: ${value.replace(/^,/, '')}`
      )
      .join('\n')

    setSuggestedData(suggestedDataString)
  }, [productDetails])

  return (
    <EditProductDetailsContext.Provider
      value={{
        isSuggestionsEditsEnabled,
        setSuggestionsEditsEnabled,
        productDetails,
        setProductDetails: updateProductDetails,
        suggestedData
      }}
    >
      {children}
    </EditProductDetailsContext.Provider>
  )
}
