import React from 'react'
import { useStyles } from './useStyles'
import {
  OxbowPrimaryButton,
  OxbowPrimaryTransparentButton
} from 'components/Helpers/Buttons'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import analytics from 'utils/analytics'
import StarOutlineIcon from '@material-ui/icons/StarOutline'

export default function BookmarksButton(props) {
  const { productTile, setListRefresh = () => {}, isAddToFavVisible } = props
  const classes = useStyles()
  const {
    addProductToCollectionsForThisUser,
    removeProductFromCollectionsForThisUser,
    myFavId,
    collections
  } = useCollections()

  const add = () => {
    addProductToCollectionsForThisUser({
      productId: props.productID,
      collectionId: myFavId,
      collectionType: 'personal',
      dontRender: true
    })

    analytics.createEvent({
      category: 'ProductDetails',
      action: 'Add Bookmark',
      label: props.productSlug
    })
  }

  const remove = () => {
    removeProductFromCollectionsForThisUser({
      collectionId: myFavId,
      collectionType: 'personal',
      productId: props.productID,
      dontRender: true
    })
    setListRefresh(true)
    analytics.createEvent({
      category: 'ProductDetails',
      action: 'Remove Bookmark',
      label: props.productSlug
    })
  }

  return (
    <>
      {isAddToFavVisible ? (
        <>
          {myFavId &&
          collections &&
          collections[myFavId] &&
          collections[myFavId].products?.find(
            p => p.id === props?.productID
          ) ? (
            <OxbowPrimaryButton
              style={{
                ...props.style,
                justifyContent: !productTile && 'flex-start'
              }}
              classes={{ root: classes.actionButtons }}
              marginright={productTile ? 'sm' : 'md'}
              fonttype="secondary"
              width={productTile ? 'default' : 'max-content'}
              height="md"
              fontSize="sm"
              size={productTile ? 'default' : 'custom'}
              onClick={remove}
              startIcon={<StarOutlineIcon />}
            >
              {productTile ? null : 'Added to favourites'}
            </OxbowPrimaryButton>
          ) : (
            <OxbowPrimaryTransparentButton
              style={{ ...props.style }}
              fonttype="secondary"
              fontSize="sm"
              background="light"
              height="md"
              onClick={add}
              width={productTile ? 'sm' : 'max-content'}
              marginright={productTile ? 'sm' : 'default'}
              classes={{ root: classes.actionButtons }}
              startIcon={<StarOutlineIcon />}
            >
              {productTile ? null : 'Add to favourites'}
            </OxbowPrimaryTransparentButton>
          )}
        </>
      ) : null}
    </>
  )
}
