import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Auth0Provider } from '../components/Contexts/AuthContext'
import { CollectionsProvider } from '../components/Contexts/CollectionAPIContext'
import SearchContextProvider from '../components/Contexts/SearchContext'
import { NavBarContextProvider } from '../components/Helpers/Navbar'
import FilterStateContextProvider from 'components/Contexts/FilterStateContext'
import config from '../configs/auth0.config.js'
import theme from '../theme'
// import CommentoAuthProvider from './CommentoAuthProvider'
import { history } from 'Router/history'
import {
  ReactQueryConfigProvider,
  ReactQueryCacheProvider,
  QueryCache
} from 'react-query'
import { CommentsCountContextProvider } from 'commento-react-material-ui'
import SearchPaginationProvider from 'components/Contexts/SeacrhPaginationContext'
import { QueryStringValuesProvider } from 'components/Contexts/QueryStringContext'
import { CustomisationJourneyProvider } from 'components/Contexts/CustomisationJourneyContext'
import HomePageContextProvider from 'components/Contexts/HomePageContext'
import CollectionTabsContextProvider from 'components/Contexts/CollectionTabsContext'

// A function that routes the user to the right place after login
const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : '/home')
}

const queryCacheConfig = {
  queries: {
    refetchOnWindowFocus: false
  }
}

export const reactQueryCache = new QueryCache()

export default function AppProviders({ children }) {
  return (
    <ReactQueryConfigProvider config={queryCacheConfig}>
      <ReactQueryCacheProvider queryCache={reactQueryCache}>
        <ThemeProvider theme={theme}>
          <Auth0Provider
            domain={config.domain}
            client_id={config.clientId}
            audience={config.audience}
            redirect_uri={window.location.origin.replace(/\.$/, '')}
            onRedirectCallback={onRedirectCallback}
          >
            <NavBarContextProvider>
              <CustomisationJourneyProvider>
                <QueryStringValuesProvider>
                  <SearchPaginationProvider>
                    <FilterStateContextProvider>
                      <SearchContextProvider>
                        <HomePageContextProvider>
                          <CollectionTabsContextProvider>
                            <CollectionsProvider>
                              {/* <CommentoAuthProvider> */}
                              <CommentsCountContextProvider>
                                <CssBaseline />
                                {children}
                              </CommentsCountContextProvider>
                              {/* </CommentoAuthProvider> */}
                            </CollectionsProvider>
                          </CollectionTabsContextProvider>
                        </HomePageContextProvider>
                      </SearchContextProvider>
                    </FilterStateContextProvider>
                  </SearchPaginationProvider>
                </QueryStringValuesProvider>
              </CustomisationJourneyProvider>
            </NavBarContextProvider>
          </Auth0Provider>
        </ThemeProvider>
      </ReactQueryCacheProvider>
    </ReactQueryConfigProvider>
  )
}
