import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()
let lastPath = history.location.pathname
sessionStorage.removeItem('searchscrollpos')
sessionStorage.removeItem('paginationscrollvalue')
history.listen((_location, action) => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setImmediate(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (action === 'POP' || action === 'REPLACE' || !action) {
      return
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0)
  })

  if (
    lastPath === '/search' &&
    history.location.pathname.includes('/product/')
  ) {
    sessionStorage.setItem('searchscrollpos', window.scrollY)
  } else if (
    lastPath.includes('/product/') &&
    !history.location.pathname.includes('/product/') &&
    history.location.pathname !== '/search'
  ) {
    sessionStorage.removeItem('searchscrollpos')
    sessionStorage.removeItem('paginationscrollvalue')
  }

  lastPath = history.location.pathname
})
