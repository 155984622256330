import React, { useEffect, useState, useCallback } from 'react'
import { Box, Grid, Backdrop, Fade, Modal, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import useDeviceType from 'utils/customHooks/useIsMobile'
import { sendContactUsData } from 'utils/api'

import cross from 'assets/icons/cross.svg'
import { useStyles } from '../useStyles'
import ModalLayout from './ModalLayout'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useSearchContext } from 'components/Contexts/SearchContext';
import { SnackBar } from 'components/Pages/Collections/components/Snackbar'
import analytics from 'utils/analytics'

const ContactUsModal = ({ open, setContactUsModalOpen }) => {
  const { isMobileSmall } = useDeviceType()
  const { personalData } = useCustomisationJourney()
  const { user, isAuthenticated } = useAuth0()
  const { isFreeUser } = useSearchContext();

  const classes = useStyles()
  const labelMappings = {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    companyName: 'Company name',
    message: 'How can we help you?'
  }

  const [policy, setPolicy] = useState(false)
  const [policyError, setPolicyError] = useState(false)
  const [greetingOpen, setGreetingOpen] = useState(false)
  const [error, setError] = useState(null)

  const handleChangePolicy = () => {
    setPolicy(prev => !prev)
    setPolicyError(false)
  }
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const contactUsData = {
      ...values,
      submissionType: 2 // Upgrade request
    }

    if (!policy) {
      setPolicyError(true)
    } else {
      setSubmitting(true)
      try {
        await sendContactUsData(contactUsData)
        setError(null)
        setSubmitting(false)
        resetForm()
        setGreetingOpen(true)
        analytics.createEvent({
          category: 'Form Submission',
          action: 'Request premium account',
          label: 'User',
          value: isAuthenticated 
            ? `Logged in (isFreeUser = ${isFreeUser})`
            : 'Non-logged in'
        });
      } catch (error) {
        setError(
          'Error occurred during processing of the contact form submission'
        )
        setSubmitting(false)
      }
    }
  }

  const validateFields = values => {
    const errors = {}

    Object.entries(values).forEach(([key, value]) => {
      if (!value && key !== 'subscribe') {
        errors[key] = 'This is a required field.'
      } else if (
        value &&
        key !== 'companyName' &&
        key !== 'email' &&
        key !== 'message' &&
        !/^[A-Za-z ]+$/i.test(value)
      ) {
        errors[key] = `Please enter a valid ${labelMappings[
          key
        ].toLowerCase()}!`
      } else if (
        value &&
        key === 'companyName' &&
        !/^[A-Z0-9 ]+$/i.test(value)
      ) {
        errors[key] = `Please enter a valid ${labelMappings[
          key
        ].toLowerCase()}!`
      }
    })
    if (!values.email) {
      errors['email'] = 'This is a required field'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Please enter a valid email address!'
    }
    return errors
  }

  const errorMsgContainer = msg => {
    return (
      <>
        <span className="errorText">{msg}</span>
        <span className="hintError">
          <PriorityHigh />
        </span>
      </>
    )
  }
  const closeAll = useCallback(() => {
    setGreetingOpen(false)
    setContactUsModalOpen(false)
  }, [setGreetingOpen, setContactUsModalOpen])

  useEffect(() => {
    if (greetingOpen) {
      setTimeout(() => {
        closeAll()
      }, 3000)
    }
  }, [greetingOpen, closeAll])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null)
      }, 3000)
    }
  }, [error])

  return (
    <>
      {greetingOpen ? (
        <ModalLayout
          open={greetingOpen}
          setOpen={setGreetingOpen}
          closeAll={closeAll}
        >
          <Box className={classes.mainThxModal}>
            <Grid style={{ marginBottom: '15px' }}>
              <Typography component="h2">Thank you</Typography>
              <Typography>
                We appreciate you contacting us and will get to you soon
              </Typography>
            </Grid>
          </Box>
        </ModalLayout>
      ) : (
        <div>
          <Modal
            className={classes.modalContainer}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setContactUsModalOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={open}>
              <Box className={classes.modalMainContainer}>
                <Box className={classes.contactUsMainModalContainer}>
                  <Box className={classes.modalMainContent}>
                    <img
                      alt=""
                      src={cross}
                      onClick={() => setContactUsModalOpen(false)}
                      className={classes.closeIcon}
                    />

                    <Typography component="h2">Contact us</Typography>
                    <Typography style={{ textAlign: 'start' }}>
                      Tell us your technology goal and Magellan will do the hard
                      work for you
                    </Typography>
                  </Box>
                  <div className={classes.contactUsMainComponent}>
                    <Formik
                      initialValues={{
                        firstName: '' || personalData.firstName,
                        lastName: '' || personalData.lastName,
                        email: '' || user.email,
                        companyName: '' || personalData.companyName,
                        message: '',
                        subscribe: false
                      }}
                      validate={validateFields}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting
                      }) => (
                        <>
                          <Form className={classes.mainForm}>
                            <Box className={classes.mainInputContainer}>
                              <Grid container className="mainInputBox">
                                <Grid item>
                                  <Typography
                                    component="label"
                                    variant="h4"
                                    htmlFor="firstName"
                                  >
                                    First name *
                                  </Typography>
                                  <Box position="relative">
                                    <input
                                      className={
                                        errors.firstName &&
                                        touched.firstName &&
                                        classes.inputError
                                      }
                                      required
                                      type="text"
                                      name="firstName"
                                      value={values.firstName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.firstName &&
                                      touched.firstName &&
                                      errorMsgContainer(errors.firstName)}
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    component="label"
                                    variant="h4"
                                    htmlFor="lastName"
                                  >
                                    Last name *
                                  </Typography>
                                  <Box position="relative">
                                    <input
                                      className={
                                        errors.lastName &&
                                        touched.lastName &&
                                        classes.inputError
                                      }
                                      required
                                      type="text"
                                      name="lastName"
                                      value={values.lastName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.lastName &&
                                      touched.lastName &&
                                      errorMsgContainer(errors.lastName)}
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    component="label"
                                    variant="h4"
                                    htmlFor="companyName"
                                  >
                                    Company name *
                                  </Typography>
                                  <Box position="relative">
                                    <input
                                      className={
                                        errors.companyName &&
                                        touched.companyName &&
                                        classes.inputError
                                      }
                                      required
                                      type="text"
                                      name="companyName"
                                      value={values.companyName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.companyName &&
                                      touched.companyName &&
                                      errorMsgContainer(errors.companyName)}
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    component="label"
                                    variant="h4"
                                    htmlFor="email"
                                  >
                                    Work email *
                                  </Typography>
                                  <Box position="relative">
                                    <input
                                      className={
                                        errors.email &&
                                        touched.email &&
                                        classes.inputError
                                      }
                                      required
                                      type="text"
                                      name="email"
                                      value={values.email}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.email &&
                                      touched.email &&
                                      errorMsgContainer(errors.email)}
                                  </Box>
                                </Grid>
                              </Grid>

                              <Grid container direction="column">
                                <Grid
                                  className={classes.textAreaContainer}
                                  item
                                >
                                  <Typography
                                    component="label"
                                    variant="h4"
                                    htmlFor="message"
                                  >
                                    How can we help you? *
                                  </Typography>
                                  <textarea
                                    className={
                                      errors.message &&
                                      touched.message &&
                                      classes.inputError
                                    }
                                    required
                                    value={values.message}
                                    onChange={handleChange}
                                    rows={isMobileSmall ? 25 : 10}
                                    cols={10}
                                    name="message"
                                    onBlur={handleBlur}
                                  ></textarea>
                                </Grid>
                              </Grid>
                            </Box>
                            <Grid container direction="column">
                              <Box className={classes.checkBoxContainer}>
                                <label>
                                  <Field type="checkbox" name="subscribe" />
                                  <span>
                                    Subscribe me to Oxbow Partners insurance
                                    technology emails
                                  </span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    className={
                                      policyError ? classes.inputError : ''
                                    }
                                    checked={policy}
                                    onChange={handleChangePolicy}
                                    name="policy"
                                  />
                                  <span>
                                    I consent to Oxbow Partners storing and
                                    processing my personal data
                                    {/* I agree to Oxbow Partners{' '}
                                  <a
                                    href="https://oxbowpartners.com/privacy-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Privacy Policy
                                  </a> */}
                                    *
                                  </span>
                                </label>
                                {policyError && (
                                  <span className="errorText">
                                    Please check this box if you want to proceed
                                  </span>
                                )}
                              </Box>
                            </Grid>
                            <Box mt={3} style={{ textAlign: 'center' }}>
                              <OxbowPrimaryButton
                                background="md"
                                type="submit"
                                width="max-content"
                                size="md"
                                disabled={isSubmitting}
                                className={classes.contactUsBtn}
                              >
                                {isSubmitting ? 'Please wait ...' : 'Submit'}
                              </OxbowPrimaryButton>
                            </Box>
                          </Form>
                          {error && (
                            <SnackBar
                              opened={error ? true : false}
                              msg={error}
                              res={'error'}
                              isBtnsVisible={false}
                            />
                          )}
                        </>
                      )}
                    </Formik>
                  </div>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      )}
    </>
  )
}
export default ContactUsModal
