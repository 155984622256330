// TODO: this is a temp change to show new insurance classes based on insurance subclasses.
// Once new insurance classes field be present in contentful, get rid of this
import filtersConfig from '../../../configs/filters.config';
const getInsuranceClassesBySubclasses = (productDataBasicSubclasses = []) => {
  const { whatForFilters: { filters } } = filtersConfig;
  const insuranceClasses = [];
  Object.values(filters).forEach((f) => {
    if (f.optionMapping) {
      if (f.optionMapping.some(f => productDataBasicSubclasses.includes(f.value))) {
        insuranceClasses.push(f.label);
      }
    }
  })
  return insuranceClasses;
}

// end TODO

export const moreTabData = productData => {
  const data = []
  let accessAndSupport = []
  let wedApiAndCapabilities = []
  let commercialsTopBlock = []
  let moreDataArticle = []
  let trialing = []
  let commercialsBottomBlock = []
  let moreDataChips = []
  let policies = []
  let services = []

  if (productData.policyAdminReference) {
    accessAndSupport = [
      {
        title: 'Identity and access management capabilities',
        dataField: productData.policyAdminReference.fields.iamCapabilities
      },
      {
        title: 'Multi-currency support',
        dataField: productData.policyAdminReference.fields.multiCurrencySupport
      }
    ]
    wedApiAndCapabilities = [
      {
        title: '',
        dataField: productData.policyAdminReference.fields.webApiCapabilities
      },
      {
        title: 'Web API Technologies Supported',
        dataField:
          productData.policyAdminReference.fields.webApiTechnologiesSupported
      }
    ]
    if (productData.policyAdminReference?.fields.configuration) {
      moreDataArticle = [
        {
          title: 'Claims',
          dataField:
            productData.policyAdminReference?.fields.configuration.claims
        },
        {
          title: 'Workflow',
          dataField:
            productData.policyAdminReference?.fields.configuration.workflow
        },
        {
          title: 'Reporting',
          dataField:
            productData.policyAdminReference?.fields.configuration.reporting
        },
        {
          title: 'User journeys',
          dataField:
            productData.policyAdminReference?.fields.configuration.userJourneys
        },
        {
          title: 'Pricing models',
          dataField:
            productData.policyAdminReference?.fields.configuration.pricingModels
        },
        {
          title: 'Billing and payments',
          dataField:
            productData.policyAdminReference?.fields.configuration
              .billingAndPayments
        },
        {
          title: 'Document generation',
          dataField:
            productData.policyAdminReference?.fields.configuration
              .documentGeneration
        },
        {
          title: 'Product development',
          dataField:
            productData.policyAdminReference?.fields.configuration
              .productDevelopment
        },
        {
          title: 'Country/jurisdiction requirements',
          dataField:
            productData.policyAdminReference?.fields
              .countryjurisdictionRequirements
        },
        {
          title: 'New third party integrations',
          dataField:
            productData.policyAdminReference?.fields.newThirdPartyIntegrations
        }
      ]
    }

    trialing = [
      {
        title: 'Obligation-free trial?',
        dataField: productData.policyAdminReference.fields.obligationFreeTrial
      },
      {
        title: 'Published system documentation?',
        dataField:
          productData.policyAdminReference.fields.publishedSystemDocumentation
      },
      {
        title: 'Published API Specifications?',
        dataField:
          productData.policyAdminReference.fields.publishedApiSpecifications
      }
    ]

    commercialsTopBlock =
      Object.keys(productData.policyAdminReference.fields).length > 0 &&
      productData.policyAdminReference.fields.contractLengths
        ? Object.values(
            productData.policyAdminReference.fields?.contractLengths
          ).filter(
            item =>
              item &&
              item.length > 0 && { title: 'Contract lengths', dataField: item }
          )
        : []

    commercialsBottomBlock = productData.policyAdminReference.fields
      ?.preferredPricingModel
      ? [
          {
            title: 'Preferred pricing model',
            dataField:
              productData.policyAdminReference.fields.preferredPricingModel
          }
        ]
      : []

    moreDataChips = [
      {
        title: 'Distribution models currently supported',
        dataField: productData.distributionModelsCurrentlySupported
      },
      {
        title: 'Product versioning',
        dataField: productData.policyAdminReference.fields.productVersioning
      }
    ]
    policies = {
      title: 'Largest number of policies in force',
      dataField:
        productData.policyAdminReference.fields.largestNumberOfPoliciesInForce
    }
    services = [
      {
        title: 'Delivery services offered',
        dataField:
          productData.policyAdminReference.fields.deliveryServicesOffered
      },
      {
        title: 'Preferred software development methodology',
        dataField:
          productData.policyAdminReference.fields
            .preferredSoftwareDevelopmentMethodology
      },
      {
        title: 'New software versions frequency',
        dataField:
          productData.policyAdminReference.fields.newSoftwareVersionsFrequency
      },

      {
        title: 'Hosting/delivery options',
        dataField:
          productData.policyAdminReference.fields.hostingdeliveryOptions
      },
      {
        title: 'Hosting locations',
        dataField: productData.policyAdminReference.fields.hostingLocations
      }
    ]
  }
  if (productData.costToMarket) {
    commercialsTopBlock = [
      ...commercialsTopBlock,
      {
        title: 'Cost to market',
        dataField: productData.costToMarket
      }
    ]
  }
  if (productData.timeToMarket) {
    commercialsBottomBlock = [
      ...commercialsBottomBlock,
      {
        title: 'Time to market',
        dataField: productData.timeToMarket
      }
    ]
  }
  if (productData.policyAdminReference.fields.gdprRequirements) {
    moreDataChips = [
      ...moreDataChips,
      {
        title: 'GDPR capabilities',
        dataField: productData.policyAdminReference.fields.gdprRequirements
      }
    ]
  }
  if (productData.company.fields.implementationResourceModels) {
    const servicesData = services && services.length > 0 ? services : []
    const obj = {
      title: 'Implementation resource models',
      dataField: productData.company.fields.implementationResourceModels
    }
    //insert an item into an array at a specific index
    Array.prototype.insert = function (index, ...items) {
      servicesData.splice(index, 0, ...items)
    }
    services.insert(2, obj)
  } else {
    services = []
  }
  data.push({
    accessAndSupport,
    wedApiAndCapabilities,
    moreDataArticle,
    trialing,
    commercialsTopBlock,
    commercialsBottomBlock,
    moreDataChips,
    policies,
    services
  })

  return data[0]
}

export const capabilitiesData = productData => {
  const capabilities = [
    {
      title: 'Target clients type',
      dataField: productData.targetClient
    },
    // {
    //   title: 'Insurance class',
    //   dataField: productData.insuranceClass
    // },
    {
      title: 'Insurance class',
      dataField: getInsuranceClassesBySubclasses(productData.basicSubclass),
    },
    {
      title: 'Insurance subclass',
      dataField: productData.basicSubclass
    },
    {
      title: 'Insurance client type',
      dataField: productData.insuranceClientType
    }
  ]
  return capabilities
}

export const sideBarOptions = isFullUser => {
  const sideBarOptions = {
    Overview: {
      key: 'overview',
      sidebarOption: {
        sectionTitle: 'Overview'
      },
      show: true,
      isLocked: false
    },
    Clients: {
      key: 'clientsAndActivity',
      sidebarOption: {
        sectionTitle: 'Clients'
      },
      show: true,
      isLocked: !isFullUser
    },
    Capabilities: {
      key: 'capabilities',
      sidebarOption: {
        sectionTitle: 'Capabilities'
      },
      show: true,
      isLocked: !isFullUser
    }
  }
  return sideBarOptions
}
