import React, { useEffect, useMemo, useState } from 'react'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import {
  OxbowPrimaryButton,
  OxbowPrimaryTransparentButton
} from 'components/Helpers/Buttons'
import MagellanModal from 'components/Helpers/Modal'

import cross from 'assets/icons/cross.svg'
import clsx from 'clsx'
import { useStyles } from './useStyles'
import JourneyAutocomplete from 'components/Pages/CustomisationJourney/JourneyAutocomplete'
import produce from 'immer'
import { ChipsToShow } from 'components/Helpers/ChipsToShow'
import {
  handleChipClick,
  handleCountryCheck,
  handleRegionCheck,
  updateProduct,
  updateProductDetails
} from 'utils/countryAndRegions'

export const CountriesModal = ({
  show,
  setShow,
  data,
  countriesData,
  checkedCountries,
  setCheckedCountries,
  dataDetails,
  classType,
  setWhereList
}) => {
  const classes = useStyles()

  const [modalCheckedCountries, setModalCheckedCountries] = useState([])
  const [modalDataDetails, setModalDataDetails] = useState({
    regionsAndCountries: []
  })

  const checkedCountriesArr = modalCheckedCountries.map(i => i.children)
  const filteredSelectedRegions = modalCheckedCountries.filter(i => !i.selected)
  const allSelectedItems = filteredSelectedRegions.map(i => i.children).flat()
  const selectedRegion = modalCheckedCountries.filter(i => i.selected)

  const generateModalSelectedList = useMemo(() => {
    const selectedList = []
    if (allSelectedItems.length > 0) {
      const arr = allSelectedItems.map(selected => {
        if (selected === "London market & Lloyd's") {
          return {
            name: selected,
            type: 'country',
            region: 'Europe'
          }
        }
        const countryRegion = countriesData.filter(country => {
          return country.children.some(i => i.value === selected)
        })

        return {
          name: selected,
          type: 'country',
          region: countryRegion[0].region
        }
      })
      selectedList.push(...arr)
    }
    if (selectedRegion.length > 0) {
      const arr = selectedRegion.map(i => {
        return {
          name: i.region,
          type: 'region',
          value: i.children,
          region: i.region,
          selected: i.selected
        }
      })
      selectedList.push(...arr)
    }

    return selectedList
  }, [selectedRegion, allSelectedItems, countriesData])

  useEffect(() => {
    setModalCheckedCountries(checkedCountries)
    if (modalDataDetails[classType]?.length === 0) {
      setModalDataDetails(dataDetails)
    }
  }, [dataDetails, checkedCountries, classType])

  const removeSelectedItem = (type, name) => {
    const selectedValue = name ? name : type
    if (modalDataDetails[classType]?.includes(selectedValue)) {
      const filtered = modalDataDetails[classType].filter(
        item => item !== selectedValue
      )
      updateProduct(classType, filtered)
    } else {
      updateProductDetails(classType, [selectedValue], setModalDataDetails)
    }
  }

  const handleItemClick = name => {
    if (modalDataDetails[classType]?.includes(name)) {
      const filtered = modalDataDetails[classType].filter(item => item !== name)

      setModalDataDetails(prev =>
        produce(prev, draftState => {
          draftState[classType] = filtered
        })
      )
    } else {
      updateProductDetails(classType, [name], setModalDataDetails)
    }
  }

  const applyModalFilters = () => {
    setWhereList(prev => [
      ...generateModalSelectedList.filter(
        i => !prev.some(item => item.name === i.name)
      ),
      ...prev
    ])
    setCheckedCountries(modalCheckedCountries)
    setShow(false)
  }

  const discardChanges = () => {
    setCheckedCountries(checkedCountries)
    setShow(false)
  }

  const handleCountryClick = countryName => {
    const countryRegion = countriesData.filter(country => {
      return country.children.some(i => i.value === countryName)
    })

    const region = countryRegion[0].region
    handleItemClick(countryName)
    handleCountryCheck(
      countryName,
      region,
      countriesData,
      setModalCheckedCountries,
      modalCheckedCountries
    )
  }

  return (
    <MagellanModal
      modalClass={classes.modal}
      show={show}
      onClose={() => discardChanges()}
      closeOnBackDropClick
    >
      <Typography variant="h2" align="center" className={classes.modalHeading}>
        Add geography
        <img alt="" src={cross} onClick={() => discardChanges()} />
      </Typography>
      <Typography variant="subtitle2" style={{ marginTop: 18 }}>
        Geography includes countries of implementation
      </Typography>
      <Box mt={3}>
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: 700,
            marginBottom: 10
          }}
        >
          Select Country
        </Typography>
        <Grid container justify="center">
          {data.map((chip, index) => {
            const arr = chip.region && chip.value && chip.value.map(i => i)
            return (
              <React.Fragment key={index}>
                {chip.type === 'country' ? (
                  <Typography
                    key={chip}
                    variant="h6"
                    className={clsx(classes.browseMagChips, {
                      [classes.selected]:
                        allSelectedItems.includes(chip.name) ||
                        modalCheckedCountries.find(
                          i =>
                            i.region === chip.region &&
                            i.children.includes(chip.name)
                        )
                    })}
                    onClick={() => {
                      handleItemClick(chip.name)
                      handleCountryCheck(
                        chip.name,
                        chip.region,
                        countriesData,
                        setModalCheckedCountries,
                        modalCheckedCountries
                      )
                    }}
                  >
                    {chip.name}
                  </Typography>
                ) : (
                  <Typography
                    key={chip}
                    variant="h6"
                    className={clsx(classes.browseMagChips, {
                      [classes.selected]:
                        allSelectedItems.includes(chip) ||
                        modalCheckedCountries.find(
                          i => i.region === chip.region && i.selected
                        )
                    })}
                    onClick={() => {
                      handleChipClick(
                        chip.value.map(i => i),
                        modalDataDetails,
                        classType,
                        setModalDataDetails,
                        countriesData
                      )
                      handleRegionCheck(
                        arr,
                        chip.region,
                        countriesData,
                        setModalCheckedCountries,
                        modalCheckedCountries
                      )
                    }}
                  >
                    {chip.name}
                  </Typography>
                )}
              </React.Fragment>
            )
          })}
        </Grid>
      </Box>

      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.selectorBox}
      >
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          justify="space-between"
          style={{
            margin: '20px 0'
          }}
        >
          <Divider style={{ marginRight: 20 }} />
          <Typography variant="h4">or type your own</Typography>
          <Divider style={{ marginLeft: 20 }} />
        </Grid>

        <JourneyAutocomplete
          productDetails={countriesData}
          setProductDetails={removeSelectedItem}
          id={classType}
          dataDetails={modalDataDetails}
          countriesData={countriesData}
          handleChipClick={handleChipClick}
          placeholder="Enter country name"
          handleCountryCheck={handleCountryCheck}
          handleRegionCheck={handleRegionCheck}
          checkedCountries={checkedCountriesArr}
          checkedCountriesObj={modalCheckedCountries}
          handleClick={handleItemClick}
          setCheckedCountries={setModalCheckedCountries}
          classType={classType}
          setDataDetails={setModalDataDetails}
        />
        <Box className={classes.chipsBox}>
          <ChipsToShow
            handleChipClick={handleChipClick}
            selectedRegion={selectedRegion}
            type={classType}
            dataDetails={modalDataDetails}
            setDataDetails={setModalDataDetails}
            countriesData={countriesData}
            setCheckedCountries={setModalCheckedCountries}
            onClick={handleCountryClick}
            checkedCountries={modalCheckedCountries}
            isFromJourney={true}
            editable={true}
            handleRegionCheck={handleRegionCheck}
            selectedItems={allSelectedItems}
            count={5}
            marginTop="sm"
            btnLabel="See"
          />
        </Box>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.btnBox}
        >
          <OxbowPrimaryButton
            fontSize="md"
            width="md"
            onClick={applyModalFilters}
          >
            Apply selection
          </OxbowPrimaryButton>
          <OxbowPrimaryTransparentButton
            fontSize="md"
            width="smd"
            onClick={() => discardChanges()}
          >
            Cancel
          </OxbowPrimaryTransparentButton>
        </Grid>
      </Grid>
    </MagellanModal>
  )
}
