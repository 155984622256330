import LandingExploreCompanies from 'assets/Logos/LandingPage/LandingExploreCompanies.png'
import LandingResearch from 'assets/Logos/LandingPage/LandingResearch.png'
import DiscoverRelevantVendors from 'assets/Logos/LandingPage/DiscoverRelevantVendors.png'

import Specialists from 'assets/Logos/LandingPage/Specialists.svg'
import Insight from 'assets/Logos/LandingPage/Insight.svg'
import Support from 'assets/Logos/LandingPage/Support.svg'

export const magellanInActionData = [
  {
    header: 'Discover relevant vendors',
    text: [
      'Not sure who the most relevant vendors in your market are?',
      'Magellan Collections allow you to discover vendors by trend, while our insurance-specific search console allows you to develop specific searches.',
      'Combined with our market-leading insights, you’ll find the best vendors with confidence.'
    ],
    img: DiscoverRelevantVendors
  },
  {
    header: 'Research and create shortlists',
    text: [
      'Separate the facts from the hype.',
      'Our insurance-specific insights platform allows you to find out exactly what vendors do, where they are active, and what capabilities they have.',
      'Tap into our team of researchers when you need to speak to an expert.'
    ],
    img: LandingResearch
  },
  {
    header: 'Keep up to date',
    text: [
      'Don’t miss the key tech trends in your market.',
      'Our newsfeed is updated daily with all the latest news on over 4,000 vendors in the insurance market. Create your own shortlists and saved searches to find out what’s going with vendors you care about.',
      'Our data is the deepest in the market, kept fresh by our team of researchers.'
    ],
    img: LandingExploreCompanies
  }
]

export const whyMagellanData = [
  {
    header: 'Insurance-specific',
    text:
      'Magellan is built for the insurance industry. We have developed a proprietary insurance-specific taxonomy that helps you build more targeted searches delivering better results.',
    img: Specialists
  },
  {
    header: 'Curated platform',
    text:
      'Magellan hosts some of the best insurance technology information in the world, including Oxbow Partners analysis, Collections on key insurance trends, and deep insurance-specific data.',
    img: Insight
  },
  {
    header: 'Human support',
    text:
      'Our team of researchers will be happy to help you if you need to go beyond the capabilities of the platform. Reach out for a Curated Search on your chosen topic.',
    img: Support
  }
]
