import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  browseMagellanTab: {
    '& h4, h6': {
      color: 'white'
    },
    '& hr': {
      background: '#fff',
      opacity: 0.5,
      margin: '0 20px',
      width: 1,
      [theme.breakpoints.down('sm')]: {
        width: 155,
        margin: '20px 0'
      }
    },
    '& >h6': {
      marginBottom: 20
    }
  },
  chipsBox: {
    justifyContent: 'flex-start',
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'auto',
    '& .chips-container': {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  modalHeading: {
    position: 'relative',

    fontSize: 24,
    fontWeight: 700,

    '& img': {
      cursor: 'pointer',
      position: 'absolute',
      right: -12,
      top: -20
    }
  },
  magellanChipsBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& .MuiGrid-root.MuiGrid-container': {
        flexDirection: 'inherit'
      }
    },
    '& >div': {
      width: '30%',
      [theme.breakpoints.up(1550)]: {
        width: 420
      },
      [theme.breakpoints.down('sm')]: {
        width: '60%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '& h4': {
      marginBottom: 15
    }
  },
  browseMagChips: {
    transition: '0.3s ease',
    width: 'max-content',
    border: '1px solid #00A3FF',
    background: 'rgba(0, 163, 255, 0.1)',
    borderRadius: 20,
    padding: '2px 18px',
    fontWeight: 400,
    cursor: 'pointer',
    marginRight: 10,
    marginTop: 10,
    height: 28,
    '&:hover': {
      background: 'rgba(0, 163, 255, 0.3)',
      color: '#000'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      marginBottom: 5,
      '&:hover': {
        background: '#35AAEC',
        color: '#fff'
      }
    }
  },
  selected: {
    background: '#35AAEC',
    color: '#fff'
  },
  disabled: {
    color: '#83959f !important',
    border: '1px solid #0468a1',
    pointerEvents: 'none'
  },
  modal: {
    position: 'relative',
    padding: '40px 60px',
    background: '#fff',
    color: '#000',
    width: 750,
    textAlign: 'center',

    '& h1': {
      fontSize: 36,
      lineHeight: '43.2px'
    },
    '& >h6, h4': {
      lineHeight: '24px'
    },
    '& h3': {
      lineHeight: '32px'
    },
    [theme.breakpoints.down(1000)]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px 35px'
    }
  },
  selectorBox: {
    '& hr': {
      width: '33%',
      [theme.breakpoints.down(660)]: {
        width: '25%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '15%'
      }
    }
  },
  btnBox: {
    marginTop: 40,
    '& button:first-child': {
      marginRight: 10,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
        marginRight: 0
      }
    }
  }
}))
