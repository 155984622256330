import React, { useCallback } from 'react'
import TableComponent from './TableComponent'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import analytics from 'utils/analytics'

const FavouritesCompanies = ({ data, myFavId }) => {

  const navigateToFavouritesPage = useCallback(() => {
    analytics.createEvent({
      category: 'Navigation',
      action: 'Clicked',
      label: 'View all Favourites',
    })
  }, []);

  return (
    <>
      <Box>
        <Typography variant="h3">Your favourites</Typography>

        {data && data.length > 0 ? (
          <TableComponent data={data} favourites={true} myFavId={myFavId} />
        ) : (
          <>
            <Grid container direction="column" style={{ marginTop: 25 }}>
              <Grid
                container
                justify="space-between"
                style={{ opacity: 0.5, width: '90%' }}
              >
                <Typography variant="h4">Company</Typography>
              </Grid>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{ textAlign: 'center', height: 200 }}
              >
                <Typography component="p" variant="subtitle2">
                  <Link to="/search">
                    <b>Start searching</b>
                  </Link>{' '}
                  to discover your favourite companies
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Box>
        {data && data.length > 0 ? (
          <>
            <Divider variant="fullWidth" />
            <Link to={`/collections/personal/${myFavId}`} onClick={navigateToFavouritesPage}>
              <OxbowPrimaryButton height="lg" fontSize="md">
                View all favourites
              </OxbowPrimaryButton>
            </Link>
          </>
        ) : null}
      </Box>
    </>
  )
}
export default FavouritesCompanies
