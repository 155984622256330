import React, { useState } from 'react'

import { useStyles } from './useStyles'

import CloseIcon from '@material-ui/icons/Close'
import { ChipsBtns } from '../ChipsToShow'
import { Box } from '@material-ui/core'
export const Chips = ({
  marginTop,
  el,
  filterName,
  isFromJourney,
  style,
  editable,
  clearSearch,
  onClick
}) => {
  const classes = useStyles({ marginTop })

  const isEmptyObj = () => {
    for (let i in el) return false
    return true
  }

  const title = title => {
    switch (title) {
      case 'What':
        return 'Primary function'
      case 'What for':
        return 'Basic subclass'
      case 'Where':
        return 'Countries of implementations'
      default:
        return title
    }
  }

  return (
    <React.Fragment>
      {!isEmptyObj() && el ? (
        <span key={filterName} className={classes.searchTerm} style={style}>
          {/* {i > 0 && <span>&;</span>} */}
          {el && el.title ? (
            <span className={classes.filterCategory}>{title(el.title)} :</span>
          ) : (
            ''
          )}
          {el && typeof el === 'string' ? (
            <span key={el}>{isFromJourney ? el : `"${el}"`}</span>
          ) : (
            <>
              {el &&
                el.values?.map((el1, i1) => (
                  <span key={i1} className="chips">
                    {/* {i1 > 0 ? (
                      <span>,&nbsp;</span>
                    ) : (
                      <span>&nbsp;&nbsp;&nbsp;</span>
                    )} */}
                    {el1}
                  </span>
                ))}
            </>
          )}{' '}
          {editable ? (
            <>
              {typeof el === 'string' ? (
                <CloseIcon
                  className={classes.cancelIcon}
                  onClick={clearSearch}
                />
              ) : (
                <CloseIcon
                  className={classes.cancelIcon}
                  onClick={() => onClick(el)}
                />
              )}
            </>
          ) : null}
        </span>
      ) : null}
    </React.Fragment>
  )
}

export const EditSearchChips = ({
  filterName,
  selectedItems,
  onClick,
  count,
  marginTop,
  btnLabel
}) => {
  const classes = useStyles({ marginTop })
  const [lengthToShow, setLengthToShow] = useState(count)
  return (
    <Box
      className="chips-container"
      style={{
        height: 'auto',
        overflow: 'auto'
      }}
    >
      <>
        {selectedItems?.map((el, index) => {
          if (index + 1 > Math.min(selectedItems?.length, lengthToShow)) {
            return null
          }
          return (
            <React.Fragment key={index}>
              <span key={el} className={classes.searchTerm}>
                {el && <span key={el}>{el}</span>}{' '}
                <CloseIcon
                  className={classes.cancelIcon}
                  onClick={() => onClick(filterName, el)}
                />
              </span>
            </React.Fragment>
          )
        })}

        <ChipsBtns
          data={selectedItems}
          lengthToShow={lengthToShow}
          setLengthToShow={setLengthToShow}
          marginTop={marginTop}
          btnLabel={btnLabel}
          count={count}
        />
      </>
    </Box>
  )
}
