import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg'
import { ReactComponent as JourneyCheckBox } from 'assets/icons/JourneyCheckBox.svg'
import { ReactComponent as CheckboxPartial } from 'assets/icons/CheckboxPartial.svg'
import { ReactComponent as JourneyCheckBoxChecked } from 'assets/icons/JourneyCheckBoxChecked.svg'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  filterContainer: {
    '& >h6': {
      fontWeight: 600
    },
    '& .MuiPaper-root': {
      margin: 0,
      backgroundColor: theme.palette.secondary.mainLightGrey,
      boxShadow: 'none',
      '&:before': {
        display: 'none'
      }
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 'unset',
      paddingLeft: 2
    },
    '& .MuiAccordionSummary-content': {
      margin: '6px 0 0'
    },

    '& li': {
      listStyle: 'none',
      '& .MuiAccordionDetails-root': {
        padding: '0 0 7px 30px'
      }
    }
  }
}))

const IndeterminateSection = ({
  sectionTitle,
  onChange,
  label,
  itemStates,
  setItemStates,
  sectionConfig,
  filterName,
  id,
  expandFilters
}) => {
  const classes = useStyles()

  const [firstRender, setFirstRender] = useState(true)
  const [childAccordionState, setChildAccordionState] = useState({})

  useEffect(() => {
    if (Object.keys(itemStates).length === 0) {
      const state = []
      Object.values(sectionConfig).forEach(item => {
        if (item[filterName]) {
          item[filterName].options.map(i => {
            state.push({
              checked: false,
              value: i.value
            })
          })
        }
      })
      setItemStates(prevState => ({
        ...prevState,
        [filterName]: state
      }))
    }
  }, [itemStates, sectionConfig, setItemStates, filterName])

  const clickHandler = e => {
    const newItems = itemStates[filterName].map(i => {
      if (i.value === e.target.name) {
        return {
          ...i,
          checked: !i.checked
        }
      }
      return i
    })

    setItemStates(prevState => ({
      ...prevState,
      [filterName]: newItems
    }))
    setFirstRender(false)
  }
  const checkedList = useMemo(() => {
    if (Object.keys(itemStates).length > 0 && itemStates[filterName]) {
      return itemStates[filterName].filter(item => item.checked)
    }
    return []
  }, [itemStates, filterName])

  const indeterminateChangeHandler = () => {
    const allChecked = checkedList.length === itemStates[filterName].length
    const newItems = itemStates[filterName].map(item => ({
      ...item,
      checked: !allChecked
    }))
    setItemStates(prevState => ({
      ...prevState,
      [filterName]: newItems
    }))
    setFirstRender(false)
  }

  useEffect(() => {
    if (!firstRender && filterName && itemStates[filterName]) {
      const checkedList = {}

      if (itemStates && Object.keys(itemStates).length > 0) {
        Object.entries(itemStates).forEach(([key, value]) => {
          const filter = sectionConfig.filters[key]
          if (filter && filter.options) {
            const checkedValues = value
              .filter(item => item.checked)
              .map(item => item.value)

            if (checkedValues.length > 0) {
              checkedList[sectionConfig.id] = [
                ...(checkedList[sectionConfig.id] || []),
                ...checkedValues
              ]
            }
          }
        })
      }

      if (checkedList[id]) {
        onChange(
          id,
          checkedList[id].map(i => i)
        )
      } else {
        onChange(id, [])
      }
    }
  }, [itemStates, onChange, id, filterName, firstRender, sectionConfig])

  const generateAccordionState = useCallback(() => {
    const childAccordionState = {}
    Object.entries(sectionConfig.filters).forEach(([key, value]) => {
      if (value.options) {
        childAccordionState[key] = {
          expanded: false
        }
      }
    })
    return childAccordionState
  }, [sectionConfig.filters])

  useEffect(() => {
    setChildAccordionState(prevState => ({
      ...prevState,
      ...generateAccordionState()
    }))
  }, [generateAccordionState, setChildAccordionState])

  useEffect(() => {
    if (Object.keys(expandFilters).length > 0) {
      setTimeout(() => {
        setChildAccordionState(prevState => ({
          ...prevState,
          ...expandFilters
        }))
      }, 100)
    }
  }, [expandFilters])

  const childAccordionStateCheck = useMemo(() => {
    const newState = {}
    Object.entries(itemStates).forEach(([key, value]) => {
      const shouldExpand = value.some(i => i.checked)
      newState[key] = { expanded: shouldExpand }
    })
    return newState
  }, [itemStates])

  useEffect(() => {
    setChildAccordionState(childAccordionStateCheck)
  }, [childAccordionStateCheck])

  return (
    <>
      {Object.keys(itemStates).length > 0 && (
        <Grid className={classes.filterContainer}>
          <Typography variant="subtitle2">{sectionTitle}</Typography>

          <Accordion
            expanded={childAccordionState[filterName]?.expanded || false}
            onChange={() => {
              setChildAccordionState(prevState => ({
                ...prevState,
                [filterName]: {
                  expanded: !childAccordionState[filterName]?.expanded
                }
              }))
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDown />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FormControlLabel
                label={<Typography variant="subtitle2">{label}</Typography>}
                control={
                  <Checkbox
                    icon={<JourneyCheckBox />}
                    checked={
                      checkedList.length === itemStates[filterName]?.length
                    }
                    checkedIcon={<JourneyCheckBoxChecked />}
                    indeterminate={
                      checkedList.length > 0 &&
                      checkedList.length < itemStates[filterName]?.length
                    }
                    indeterminateIcon={<CheckboxPartial />}
                    onChange={() => indeterminateChangeHandler()}
                  />
                }
              />
            </AccordionSummary>

            {itemStates[filterName]?.map(option => {
              return (
                <li key={option.value}>
                  <AccordionDetails className={classes.itemList}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}
                    >
                      <FormControlLabel
                        label={
                          <Typography variant="subtitle2">
                            {option.value}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            checked={option.checked}
                            name={option.value}
                            value={option.value}
                            key={option.label}
                            icon={<JourneyCheckBox />}
                            checkedIcon={<JourneyCheckBoxChecked />}
                            onChange={e => clickHandler(e)}
                          />
                        }
                      />
                    </Box>
                  </AccordionDetails>
                </li>
              )
            })}
          </Accordion>
        </Grid>
      )}
    </>
  )
}

export const MemoizedIndeterminateSection = IndeterminateSection
