import defaultFilterConfig from 'configs/filters.config'

export const getDefaultSearchState = () => {
  const state = {}
  defaultFilterConfig.forEach(section => {
    section.filters.forEach(filter => {
      if (filter.type === 'checkbox') {
        state[filter.name] = {}
        filter.options.forEach(option => {
          state[filter.name][option.name] = false
        })
      } else if (filter.type === 'range') {
        state[filter.name] = {
          min: filter.options.min,
          max: filter.options.max,
          unit: filter.options.unit
        }
      }
    })
  })
  return { key: '', category: 'supplier', ...state }
}

// Utility function to add clear filters CTA button to filter sections
export const getClearFilterSectionState = sectionConfig => {
  const state = {}
  Object.keys(sectionConfig.filters).forEach(filterName => {
    const _filterConfig = sectionConfig.filters[filterName]
    if (_filterConfig.isParent) return
    if (
      sectionConfig.id === 'primaryFunction' ||
      sectionConfig.id === 'basicSubclass'
    ) {
      state[sectionConfig.id] = []
    } else {
      state[_filterConfig.name] = {}
      if (_filterConfig.type === 'checkbox') {
        _filterConfig.options.forEach(option => {
          state[_filterConfig.name][option.name] = false
        })
      } else if (_filterConfig.type === 'range') {
        state[_filterConfig.name] = {
          min: _filterConfig.options.min,
          max: _filterConfig.options.max,
          maxSelected: true
        }
      } else if (_filterConfig.type === 'chips') {
        state[filterName] = []
      }
    }
  })

  return state
}

export const processAggregationsOtherFilters = aggregations => {
  const result = {}

  const otherAggs = Object.entries(aggregations).filter(([k, _]) => {
    return k !== 'basicSubclass' && k !== 'primaryFunction'
  })
  const otherAggsObj = Object.fromEntries(otherAggs)
  Object.keys(otherAggsObj).forEach(key => {
    if (otherAggsObj[key].buckets && otherAggsObj[key].buckets.length === 0) {
      return
    }

    const bucketData = otherAggsObj[key].buckets && otherAggsObj[key].buckets
    bucketData &&
      (result[key] = bucketData.reduce((acc, item) => {
        acc[item.key] = item
        return acc
      }, {}))
  })

  return result
}

export const processAggregationsForFilters = (
  aggregations,
  filterName,
  filterKey
) => {
  const result = {}

  Object.keys(aggregations).forEach(() => {
    if (aggregations[filterKey].buckets.length === 0) {
      return
    }

    const bucketData = aggregations[filterKey].buckets
    result[filterName] = bucketData.reduce((acc, item) => {
      acc[item.key] = item
      return acc
    }, {})
  })

  return result
}

export const processAggregations = (aggregations, filtersConfig) => {
  const result = {}
  Object.keys(aggregations).forEach(filterName => {
    if (
      !filtersConfig[filterName] ||
      aggregations[filterName].buckets.length === 0
    ) {
      return
    }
    const filterType = filtersConfig[filterName].type
    switch (filterType) {
      case 'checkbox':
      case 'chips': {
        const bucketData = aggregations[filterName].buckets
        result[filterName] = bucketData.reduce((acc, item) => {
          acc[item.key] = item
          return acc
        }, {})
        break
      }
      case 'range': {
        const [identifier, ...tokens] = filterName.split('_')
        const camelCaseToken = this.convertTokensToCamelCase(tokens)
        if (result[camelCaseToken]) {
          result[camelCaseToken][identifier] = aggregations[filterName]['value']
          return
        }
        result[camelCaseToken] = {
          [identifier]: aggregations[filterName]['value']
        }
        break
      }
      default:
        break
    }
  })
  return result
}

export const convertFilterAggsToValue = (filterAggs, filterConfig) => {
  let result
  if (filterConfig.type === 'checkbox') {
    result = Object.keys(filterAggs).reduce((acc, filterOption) => {
      acc[filterOption] = false
      return acc
    }, {})
  } else if (filterConfig.type === 'range') {
    result = {
      min: filterConfig.options.min || filterAggs.min,
      max: filterConfig.options.max || filterAggs.max,
      maxSelected: true
    }
  } else if (filterConfig.type === 'chips') {
    result = []
  }
  return result
}

export const convertSortedFilterValuesToConfig = (
  filterConfig,
  filterAggregation,
  filterName
) => {
  const _filterConfig = { ...filterConfig }

  if (filterConfig.ordered && (!_filterConfig.options || _filterConfig.options.length === 0)) {
    Object.keys(filterAggregation[filterName]).forEach(key => {
      
      filterConfig.optionMapping.forEach(item => {
        if (key.toLowerCase() === item.value.toLowerCase()) {
          _filterConfig.options.push({
            value: key,
            label: key,
            order: item.order
          })
        }
      })
    })
  } else {
    const allowed = [...filterConfig.options];
    _filterConfig.options = [];
    Object.keys(filterAggregation[filterName]).forEach(key => {
      if (allowed.includes(key)) {
        _filterConfig.options.push({
          value: key,
          label: key,
          order: allowed.indexOf(key),
        })
      }
    })
  }

  _filterConfig.options = _filterConfig.options.sort(
    (a, b) => a.order - b.order
  )

  return _filterConfig;
}

export const convertFilterValuesToConfig = (
  filterValue,
  filterConfig,
  filterAggregation
) => {
  const _filterConfig = { ...filterConfig }
  // If the filter is of the type range
  if (_filterConfig.type === 'range') {
    // Setting the min and max values
    _filterConfig.options.min = filterValue.min
    _filterConfig.options.max = filterValue.max

    // Adding marks for the filter option, if their is a Unit defined
    if (_filterConfig.options.unit) {
      let unit = _filterConfig.options.unit
      _filterConfig.options.marks = [
        {
          value: filterValue.min,
          label: `${filterValue.min}${unit}`
        },
        {
          value: filterValue.max,
          label: `${filterValue.max}${unit}`
        }
      ]
    }
  }

  // If the filter is of the type checkbox
  else if (_filterConfig.type === 'checkbox') {
    Object.keys(filterValue).forEach(filterOption => {
      _filterConfig.options.push({
        label: _filterConfig.optionMapping
          ? _filterConfig.optionMapping[filterOption].label
          : filterOption,
        name: filterOption
      })
    })
    if (_filterConfig.ordered) {
      const optionsMapping = _filterConfig.optionMapping
      _filterConfig.options = _filterConfig.options.sort(
        (a, b) => optionsMapping[a.name].order - optionsMapping[b.name].order
      )
    }
  } else if (_filterConfig.type === 'chips') {
    _filterConfig.options = []
    Object.keys(filterAggregation).forEach(key => {
      const filtersDataConfig = filterConfig?.optionMapping?.[key]
      const label = filtersDataConfig?.label || key
      const order = filtersDataConfig?.order
      if (filterConfig.title === 'Insurance Client Type' && filtersDataConfig) {
        _filterConfig.options.push({
          value: key,
          label,
          order
        })
      } else {
        _filterConfig.options.push({ value: key, label })
      }
    })
    if (filterConfig.ordered) {
      const { optionMapping } = filterConfig
      _filterConfig.options = _filterConfig.options.sort(
        (a, b) => optionMapping[a.value]?.order - optionMapping[b.value]?.order
      )
    }
  }
  return _filterConfig
}

export const generateParentFilterConfigFromChildConfig = (
  childConfig,
  parentConfig
) => {
  if (childConfig.type !== parentConfig.type) {
    throw Error('Child and Parent Filters have mis-matching filterType')
  }
  if (childConfig.type === 'chips') {
    if (!parentConfig.options) parentConfig.options = []
    parentConfig.options = childConfig.options.reduce((acc, option) => {
      const alreadyPresentOption = acc.find(a => option.value === a.value)

      if (alreadyPresentOption) {
        alreadyPresentOption.filterNames.push(childConfig.name)
      } else {
        acc.push({
          ...option,
          filterNames: [childConfig.name]
        })
      }
      return acc
    }, parentConfig.options)
  }

  return parentConfig
}
