import { makeStyles } from '@material-ui/core'
import { derivedFromProp } from 'utils'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    display: 'flex'
  },
  inputMainContainer: {
    marginTop: derivedFromProp('marginTop', {
      none: 'none',
      default: 10
    }),
    width: derivedFromProp('width', {
      md: '700px',
      default: '95%'
    }),
    marginLeft: derivedFromProp('ml', {
      sm: '10px',
      default: 'none'
    }),
    display: 'flex',
    alignItems: 'center',
    background: 'rgb(255 255 255 / 10%)',
    padding: '12px',
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6
  },
  autoSuggestContainer: {
    position: 'absolute',
    bottom: '-10px',
    transform: 'translateY(100%)',
    width: '100%',
    zIndex: 1
  },
  searchBtn: {
    width: '54px',
    fontSize: '17px',
    height: 40,
    top: 0,
    right: 0,
    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0
    }
  },

  inputContainer: {
    height: 40,
    marginRight: 10,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    background: '#fff',
    borderRadius: '0 6px 0 6px',
    boxShadow: '20px 0 0 #FFF',
    padding: theme.spacing(1),
    paddingLeft: ({ showSearchIcon }) =>
      !showSearchIcon ? theme.spacing(3) : theme.spacing(2),
    '&.disabled': {
      background: '#ddd'
    },
    '& input': {
      display: 'inline-block',
      flex: '1',
      width: '0px',
      outline: 'none',
      border: 'none',
      background: 'transparent',
      fontFamily: 'Open Sans',
      fontSize: 16,
      color: theme.palette.primary.dark,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem'
      },

      '&::placeholder': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        color: theme.palette.primary.main,
        opacity: 0.5
      },
      '&::-ms-clear': {
        display: 'none',
        width: 0,
        height: 0
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #35AAEC !important'
    },
    '& input:focus::placeholder': {
      color: 'transparent !important'
    }
  }
}))
