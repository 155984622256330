import { useMediaQuery, useTheme } from '@material-ui/core'

export const useDeviceType = () => {
  const theme = useTheme()
  const isMobileSmall = useMediaQuery(theme.breakpoints.down('xs'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isBigScreen = useMediaQuery('(min-width:1501px)')
  const isSmallScreen = useMediaQuery('(max-width:1500px)')
  const isBetweenLargeAndSmallScreen = useMediaQuery('(max-width:750px)')
  const isSmallCollections = useMediaQuery('(max-width:1150px)')
  const isLargeScreen = useMediaQuery('(min-width:1920px)')
  return {
    isMobile,
    isMobileSmall,
    isSmallScreen,
    isSmallCollections,
    isBetweenLargeAndSmallScreen,
    isBigScreen,
    isLargeScreen
  }
}

export default useDeviceType
