import 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/sass/style.scss'
import * as serviceWorker from './serviceWorker'
import { setConfig } from 'react-hot-loader'

setConfig({ disableHotRenderer: true })
// NOTE: Disable console logs on production
if (process.env.REACT_APP_ENV.toLowerCase() === 'production') {
  // eslint-disable-next-line no-console
  console.log = () => {}
}

ReactDOM.render(<App />, document.getElementById('root'))
serviceWorker.unregister()
