import React, { useCallback, useEffect } from 'react'
import landingbg from 'assets/Logos/LandingPage/landingbg.png'
import landingbgSM from 'assets/Logos/LandingPage/landingbgSM.png'
import { useStyles } from './useStyles'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import RSA from 'assets/Logos/LandingPage/RSA.png'
import Munich from 'assets/Logos/LandingPage/Munich.png'
import { useNavContext } from 'components/Contexts/NavbarContext'
import { magellanInActionData, whyMagellanData } from './dataHelper'
import DemoForm from './DemoForm'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { BaseLayoutBody } from 'components/Layouts/Base'

import { ModalRequestHeader } from './LandingModal'
import { Animated } from 'components/Helpers/AnimatedComponent'
import { MagellanArticle } from './components/MagellanArticle'

const LandingPage = () => {
  const classes = useStyles()
  const {
    LandingAboutUs,
    LandingInAction,
    LandingWhyMagellan,
    LandingHeaderRef,
    setDemoFormOpen,
    visibleSection,
    landingNavButtons,
    setVisibleSection,
    setThxModalOpen,
    currId,
    modalData
  } = useNavContext()

  const submissionType = 3
  const { downSm } = useMediaQueries()
  const defaultAnimationTime = 500
  const backgroundImageStyles = {
    backgroundImage: `url(${downSm ? landingbgSM : landingbg})`
  }

  const handleScroll = useCallback(() => {
    const getDimensions = ele => {
      if (ele) {
        const { height } = ele?.getBoundingClientRect()
        const offsetTop = ele.offsetTop + 200
        const offsetBottom = offsetTop + height + 40
        return {
          height,
          offsetTop,
          offsetBottom
        }
      } else return {}
    }

    const { height: headerHeight } = getDimensions(LandingHeaderRef?.current)
    if (headerHeight) {
      const scrollPosition = window.scrollY + headerHeight
      const selected = landingNavButtons?.find(({ ref }) => {
        const ele = ref?.current
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele)
          return scrollPosition > offsetTop && scrollPosition < offsetBottom
        }
      })

      if (selected && selected.id !== visibleSection) {
        setVisibleSection(selected.id)
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined)
      }
    }
  }, [LandingHeaderRef, landingNavButtons, setVisibleSection, visibleSection])

  useEffect(() => {
    if (visibleSection !== ' ') {
      if (LandingHeaderRef && LandingHeaderRef.current) {
        handleScroll()
        window.addEventListener('scroll', handleScroll)
        return () => {
          window.removeEventListener('scroll', handleScroll)
        }
      }
    }
  }, [visibleSection, LandingHeaderRef, landingNavButtons, setVisibleSection])

  const scrollToCurrId = () => {
    const el = document.getElementById(currId)
    if (el) {
      const rect = el.getBoundingClientRect()
      if (rect) {
        window.scrollTo({
          top: rect.top + window.scrollY - 100,
          behavior: 'smooth'
        })
      }
    }
  }

  useEffect(() => {
    if (currId) {
      const timeout = setTimeout(() => {
        scrollToCurrId(currId)
      }, [500])
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [currId])

  const animatedBlock = data => {
    const animatedArr = []
    data.map((i, index) => {
      const animationTime = {
        ...i,
        animationTime: defaultAnimationTime + defaultAnimationTime * index
      }
      animatedArr.push(animationTime)
    })
    return animatedArr
  }

  return (
    <BaseLayoutBody
      pageHeaderBottomLine={false}
      parentBodyBg={false}
      headerStyle={{
        padding: 0
      }}
      pageHeader={
        <>
          <Grid className={classes.landingPage} style={backgroundImageStyles}>
            <Animated>
              <Grid className={classes.landingPageMainBlock}>
                <Typography variant="h1">
                  Find the right insurance technology vendors for your
                  organisation
                </Typography>
                <Typography variant="subtitle1">
                  The insurance technology landscape is complex and fragmented.{' '}
                  <br />
                  Get Magellan to find out about 4,000+ insurance-focused
                  vendors, <br /> to research and create shortlists, and keep up
                  to date with insurance technology news
                </Typography>
                <OxbowPrimaryButton
                  onClick={() => setDemoFormOpen(true)}
                  className={classes.contactUsBtn}
                  fontSize="md"
                >
                  Request a demo
                </OxbowPrimaryButton>
              </Grid>
            </Animated>
          </Grid>
        </>
      }
      rightPanel={
        <>
          <Grid className={classes.landingAboutUs} ref={LandingHeaderRef}>
            <Box
              className={classes.landingMainHeaderBox}
              ref={LandingAboutUs}
              id="LandingAboutUs"
            >
              <Animated>
                <Typography variant="h1">
                  {/* {!downSm && <Divider />}
                  About us */}
                </Typography>
              </Animated>
            </Box>
            <MagellanArticle />
          </Grid>

          <Grid
            className={classes.magellanInAction}
            ref={LandingInAction}
            id="LandingInAction"
          >
            <Animated>
              <Grid
                direction="column"
                alignItems="center"
                container
                wrap="nowrap"
                className={classes.landingTrustedBy}
              >
                <Typography variant="h1">We are trusted by</Typography>
                <Grid container>
                  <img src={RSA} alt="RSA" />
                  <img src={Munich} alt="Munich" />
                </Grid>
              </Grid>
              <Grid className={classes.landingMagellanInActionHeader}>
                <Box className={classes.landingMainHeaderBox}>
                  <Typography variant="h1">
                    {!downSm && <Divider />}
                    Magellan in action
                  </Typography>
                </Box>
                <Grid className={classes.landingMagellanInAction}>
                  {animatedBlock(magellanInActionData).map(
                    ({ header, text, img, animationTime }) => (
                      <Grid container key={header}>
                        <Animated>
                          <img src={img} alt={img} />
                        </Animated>
                        <Animated animationTime={animationTime}>
                          <Grid className={classes.textBox}>
                            <Typography variant="h1">{header}</Typography>
                            <Grid container>
                              {text.map((i, index) => (
                                <Typography
                                  key={index}
                                  style={{ lineHeight: '30px' }}
                                  variant="subtitle1"
                                >
                                  {i}
                                </Typography>
                              ))}
                            </Grid>
                          </Grid>
                        </Animated>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Animated>
          </Grid>

          <Grid style={{ marginTop: downSm && 30 }}>
            <Animated>
              <Grid
                direction="column"
                alignItems="center"
                container
                wrap="nowrap"
                className={classes.landingAboutUsBlock}
              >
                <Typography variant="h1">
                  Filter through 4,000 vendors using Advanced Search
                </Typography>
                <OxbowPrimaryButton
                  onClick={() => setDemoFormOpen(true)}
                  className={classes.contactUsBtn}
                  fontSize="md"
                >
                  Request a demo
                </OxbowPrimaryButton>
              </Grid>
            </Animated>

            <Box
              className={classes.landingMainHeaderBox}
              ref={LandingWhyMagellan}
              id="LandingWhyMagellan"
            >
              <Animated>
                <Typography variant="h1">
                  {!downSm && <Divider />}
                  Why Magellan?
                </Typography>
              </Animated>
            </Box>

            <Grid container className={classes.landingWhyMagellanContent}>
              {animatedBlock(whyMagellanData).map(
                ({ header, text, img, animationTime }) => (
                  <Animated key={header} animationTime={animationTime}>
                    <Grid
                      container
                      direction="column"
                      className={classes.landingWhyMagellanBlock}
                    >
                      <img src={img} alt={img} />

                      <Typography variant="h1">{header}</Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ lineHeight: '30px' }}
                      >
                        {text}
                      </Typography>
                    </Grid>
                  </Animated>
                )
              )}
            </Grid>
            <Animated>
              <Grid className={classes.demoContainer}>
                <ModalRequestHeader />
                <DemoForm
                  modalData={modalData}
                  setThxModalOpen={setThxModalOpen}
                  submissionType={submissionType}
                />
              </Grid>
            </Animated>
          </Grid>
        </>
      }
      bottomBanner={false}
    ></BaseLayoutBody>
  )
}

export default LandingPage
