import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import PriorityHigh from '@material-ui/icons/PriorityHigh'

import cross from 'assets/icons/cross.svg'

import { useStyles } from '../../useStyles'
import { BtnsBlock } from './BtnsBlock'
import MagellanModal from 'components/Helpers/Modal'
import { deleteUserProfile } from 'utils/api'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { updateUserProfile } from 'utils/api'
import clsx from 'clsx'
import { useAuth0 } from 'components/Contexts/AuthContext'

export const UserAccountMainInformation = ({
  isEditModeOpened,
  setIsUserAccountMainInformationEditModeOpen,
  handleActiveBlock,
  personalData,
  setPersonalData
}) => {
  const classes = useStyles()
  const { downSm } = useMediaQueries()
  const { user, logout } = useAuth0()
  const [accountDetails, setAccountDetails] = useState(personalData)
  const [isAccountDeleteModalOpen, setAccountDeleteModalOpen] = useState(false)
  const [touched, setTouched] = useState({})
  const [isDeletionConfirmed, setDeletionConfirmed] = useState(false)
  useEffect(() => {
    if (personalData) {
      setAccountDetails(personalData)
    }
  }, [personalData])
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const handleInputValueChange = e => {
    setAccountDetails({
      ...accountDetails,
      [e.target.name]: e.target.value
    })
  }

  const isPersonalDataChanged = useMemo(() => {
    return (
      Object.entries(accountDetails).filter(([key, value]) => {
        return personalData[key] !== value
      }).length > 0
    )
  }, [accountDetails, personalData])

  const handleDiscardChanges = () => {
    if (isPersonalDataChanged) {
      setConfirmModalOpen(true)
    } else {
      setIsUserAccountMainInformationEditModeOpen(false)
      handleActiveBlock(isEditModeOpened)
      setTouched({})
    }
  }

  const errorMsgContainer = msg => {
    return (
      <>
        <span className="errorText">{msg}</span>
        <span className="hintError">
          <PriorityHigh />
        </span>
      </>
    )
  }

  const handleDelete = async () => {
    try {
      setDeletionConfirmed(true)
      const res = await deleteUserProfile()
      if (res.status) {
        logout()
      }
      setDeletionConfirmed(false)
    } catch {
      console.log('error')
      setDeletionConfirmed(false)
    }
  }

  const validateFields = accountDetails => {
    const labelMappings = {
      firstName: 'First name',
      lastName: 'Last name',
      companyName: 'Company name',
      jobTitle: 'Job title',
      phoneNumber: 'Phone number'
    }
    const errors = {}

    Object.entries(accountDetails).forEach(([key, value]) => {
      if (value) {
        if (
          (key === 'firstName' || key === 'lastName') &&
          (value.length > 30 ||
            !/^\s*(([A-Za-z]){2,30}([.,] |[-']| )?)+[A-Za-z]+\.?\s*$/.test(
              value
            ))
        ) {
          errors[key] = `Please enter a valid ${labelMappings[
            key
          ].toLowerCase()}!`
        }
        if (
          (key === 'companyName' || key === 'jobTitle') &&
          (/(.)\1\1/.test(value) ||
            !/^(?!^\s+)(?!^[\d\W ]*$)([\w\S ]{0,60})(?:\S+$)$/.test(value))
        ) {
          errors[key] = `Please enter a valid ${labelMappings[
            key
          ].toLowerCase()}!`
        }

        if (
          key === 'phoneNumber' &&
          !/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/i.test(value)
        ) {
          errors.phoneNumber = 'Please enter a valid work phone number!'
        }
      }
    })

    return errors
  }

  const setInputTouched = e => {
    setTouched({
      ...touched,
      [e.target.name]: true
    })
  }
  const sendUserFilters = async () => {
    try {
      const data = await updateUserProfile(accountDetails)
      setPersonalData({
        companyName: data.company_name,
        firstName: data.first_name,
        jobTitle: data.job_title,
        lastName: data.last_name,
        phoneNumber: data.phone_number
      })

      setIsUserAccountMainInformationEditModeOpen(false)
      handleActiveBlock(isEditModeOpened)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      {downSm ? (
        <Grid container className={classes.accountInformationComponent}>
          <Grid container>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              First name
            </Typography>
            {isEditModeOpened ? (
              <Box position="relative">
                <input
                  onFocus={e => setInputTouched(e)}
                  type="text"
                  name="firstName"
                  value={accountDetails.firstName || ''}
                  onChange={handleInputValueChange}
                />
                {validateFields(accountDetails)?.firstName &&
                  touched.firstName &&
                  errorMsgContainer(validateFields(accountDetails)?.firstName)}
              </Box>
            ) : (
              <Typography
                variant="h4"
                className={clsx(classes.accountInformationText, {
                  [classes.accountInformationTextBlack]: personalData.firstName
                })}
              >
                {personalData.firstName
                  ? personalData.firstName
                  : 'Add first name'}
              </Typography>
            )}
          </Grid>
          <Grid container>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              Last name
            </Typography>
            {isEditModeOpened ? (
              <Box position="relative">
                <input
                  onFocus={e => setInputTouched(e)}
                  type="text"
                  name="lastName"
                  value={accountDetails.lastName || ''}
                  onChange={handleInputValueChange}
                />
                {validateFields(accountDetails)?.lastName &&
                  touched.lastName &&
                  errorMsgContainer(validateFields(accountDetails)?.lastName)}
              </Box>
            ) : (
              <Typography
                variant="h4"
                className={clsx(classes.accountInformationText, {
                  [classes.accountInformationTextBlack]: personalData.lastName
                })}
              >
                {personalData.lastName
                  ? personalData.lastName
                  : 'Add last name'}
              </Typography>
            )}
          </Grid>
          <Grid container>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              Company name
            </Typography>
            {isEditModeOpened ? (
              <Box position="relative">
                <input
                  onFocus={e => setInputTouched(e)}
                  type="text"
                  name="companyName"
                  value={accountDetails.companyName || ''}
                  onChange={handleInputValueChange}
                />
                {validateFields(accountDetails)?.companyName &&
                  touched.companyName &&
                  errorMsgContainer(
                    validateFields(accountDetails)?.companyName
                  )}
              </Box>
            ) : (
              <Typography
                variant="h4"
                className={clsx(classes.accountInformationText, {
                  [classes.accountInformationTextBlack]:
                    personalData.companyName
                })}
              >
                {personalData.companyName
                  ? personalData.companyName
                  : 'Add company name'}
              </Typography>
            )}
          </Grid>
          <Grid container>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              Job title
            </Typography>
            {isEditModeOpened ? (
              <Box position="relative">
                <input
                  onFocus={e => setInputTouched(e)}
                  type="text"
                  name="jobTitle"
                  value={accountDetails.jobTitle || ''}
                  onChange={handleInputValueChange}
                />
                {validateFields(accountDetails)?.jobTitle &&
                  touched.jobTitle &&
                  errorMsgContainer(validateFields(accountDetails)?.jobTitle)}
              </Box>
            ) : (
              <Typography
                variant="h4"
                className={clsx(classes.accountInformationText, {
                  [classes.accountInformationTextBlack]: personalData.jobTitle
                })}
              >
                {personalData.jobTitle
                  ? personalData.jobTitle
                  : 'Add job title'}
              </Typography>
            )}
          </Grid>

          <Grid container>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              Work email
            </Typography>
            <Typography variant="h4" className={classes.accountInformationText}>
              {user.email}
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              Phone number
            </Typography>
            {isEditModeOpened ? (
              <Box position="relative">
                <input
                  onFocus={e => setInputTouched(e)}
                  type="tel"
                  name="phoneNumber"
                  value={accountDetails.phoneNumber || ''}
                  onChange={handleInputValueChange}
                />
                {validateFields(accountDetails)?.phoneNumber &&
                  touched.phoneNumber &&
                  errorMsgContainer(
                    validateFields(accountDetails)?.phoneNumber
                  )}
              </Box>
            ) : (
              <Typography
                variant="h4"
                className={clsx(classes.accountInformationText, {
                  [classes.accountInformationTextBlack]:
                    personalData.phoneNumber
                })}
              >
                {personalData.phoneNumber
                  ? personalData.phoneNumber
                  : 'Add phone number'}
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.accountInformationComponent}>
          <Grid container className={classes.accountInfoBlock}>
            <Grid container>
              <Typography
                variant="h4"
                className={classes.accountInformationSubTitle}
              >
                First name
              </Typography>
              {isEditModeOpened ? (
                <Box position="relative">
                  <input
                    onFocus={e => setInputTouched(e)}
                    type="text"
                    name="firstName"
                    value={accountDetails.firstName || ''}
                    onChange={handleInputValueChange}
                  />
                  {validateFields(accountDetails)?.firstName &&
                    touched.firstName &&
                    errorMsgContainer(
                      validateFields(accountDetails)?.firstName
                    )}
                </Box>
              ) : (
                <Typography
                  variant="h4"
                  className={clsx(classes.accountInformationText, {
                    [classes.accountInformationTextBlack]:
                      personalData.firstName
                  })}
                >
                  {personalData.firstName
                    ? personalData.firstName
                    : 'Add first name'}
                </Typography>
              )}
            </Grid>
            <Grid container>
              <Typography
                variant="h4"
                className={classes.accountInformationSubTitle}
              >
                Work email
              </Typography>
              <Typography
                variant="h4"
                className={classes.accountInformationText}
              >
                {user.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              Last name
            </Typography>
            {isEditModeOpened ? (
              <Box position="relative">
                <input
                  onFocus={e => setInputTouched(e)}
                  type="text"
                  name="lastName"
                  value={accountDetails.lastName || ''}
                  onChange={handleInputValueChange}
                />
                {validateFields(accountDetails)?.lastName &&
                  touched.lastName &&
                  errorMsgContainer(validateFields(accountDetails)?.lastName)}
              </Box>
            ) : (
              <Typography
                variant="h4"
                className={clsx(classes.accountInformationText, {
                  [classes.accountInformationTextBlack]: personalData.lastName
                })}
              >
                {personalData.lastName
                  ? personalData.lastName
                  : 'Add last name'}
              </Typography>
            )}
          </Grid>
          <Grid container className={classes.accountInfoBlock}>
            <Grid container>
              <Typography
                variant="h4"
                className={classes.accountInformationSubTitle}
              >
                Company name
              </Typography>
              {isEditModeOpened ? (
                <Box position="relative">
                  <input
                    onFocus={e => setInputTouched(e)}
                    type="text"
                    name="companyName"
                    value={accountDetails.companyName || ''}
                    onChange={handleInputValueChange}
                  />
                  {validateFields(accountDetails)?.companyName &&
                    touched.companyName &&
                    errorMsgContainer(
                      validateFields(accountDetails)?.companyName
                    )}
                </Box>
              ) : (
                <Typography
                  variant="h4"
                  className={clsx(classes.accountInformationText, {
                    [classes.accountInformationTextBlack]:
                      personalData.companyName
                  })}
                >
                  {personalData.companyName
                    ? personalData.companyName
                    : 'Add company name'}
                </Typography>
              )}
            </Grid>
            <Grid container>
              <Typography
                variant="h4"
                className={classes.accountInformationSubTitle}
              >
                Phone number
              </Typography>
              {isEditModeOpened ? (
                <Box position="relative">
                  <input
                    onFocus={e => setInputTouched(e)}
                    type="tel"
                    name="phoneNumber"
                    value={accountDetails.phoneNumber || ''}
                    onChange={handleInputValueChange}
                  />
                  {validateFields(accountDetails)?.phoneNumber &&
                    touched.phoneNumber &&
                    errorMsgContainer(
                      validateFields(accountDetails)?.phoneNumber
                    )}
                </Box>
              ) : (
                <Typography
                  variant="h4"
                  className={clsx(classes.accountInformationText, {
                    [classes.accountInformationTextBlack]:
                      personalData.phoneNumber
                  })}
                >
                  {personalData.phoneNumber
                    ? personalData.phoneNumber
                    : 'Add phone number'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Typography
              variant="h4"
              className={classes.accountInformationSubTitle}
            >
              Job title
            </Typography>
            {isEditModeOpened ? (
              <Box position="relative">
                <input
                  onFocus={e => setInputTouched(e)}
                  type="text"
                  name="jobTitle"
                  value={accountDetails.jobTitle || ''}
                  onChange={handleInputValueChange}
                />
                {validateFields(accountDetails)?.jobTitle &&
                  touched.jobTitle &&
                  errorMsgContainer(validateFields(accountDetails)?.jobTitle)}
              </Box>
            ) : (
              <Typography
                variant="h4"
                className={clsx(classes.accountInformationText, {
                  [classes.accountInformationTextBlack]: personalData.jobTitle
                })}
              >
                {personalData.jobTitle
                  ? personalData.jobTitle
                  : 'Add job title'}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      <MagellanModal
        show={isAccountDeleteModalOpen}
        modalClass={classes.accountModal}
      >
        <Grid container direction="column" align="center">
          <Typography variant="h1">
            Delete my account
            <img
              alt=""
              src={cross}
              onClick={() => setAccountDeleteModalOpen(false)}
            />
          </Typography>
          <Typography variant="subtitle2">
            Are you sure you want to delete your account?
          </Typography>
          <BtnsBlock
            style={{ justifyContent: 'center', margin: '0 auto' }}
            cancelPlaceholder="Cancel"
            savePlaceholder="Delete"
            isDeleteButtonVisible={false}
            handleCancel={() => setAccountDeleteModalOpen(false)}
            handleSave={() => {
              handleDelete()
            }}
            alignItems="center"
            alignSelf="center"
            disabled={isDeletionConfirmed}
          />
        </Grid>
      </MagellanModal>

      <MagellanModal
        show={isConfirmModalOpen}
        modalClass={classes.accountModal}
      >
        <Grid container direction="column" align="center">
          <Typography variant="h1">
            Discard changes
            <img
              alt=""
              src={cross}
              onClick={() => setConfirmModalOpen(false)}
            />
          </Typography>
          <Typography variant="subtitle2">
            Are you sure you want to quit without saving changes?
          </Typography>
          <BtnsBlock
            style={{ justifyContent: 'center', margin: '0 auto' }}
            cancelPlaceholder="Cancel"
            savePlaceholder="Discard"
            isDeleteButtonVisible={false}
            handleCancel={() => setConfirmModalOpen(false)}
            handleSave={() => {
              handleActiveBlock(isEditModeOpened)
              setAccountDetails(personalData)
              setTouched({})
              setIsUserAccountMainInformationEditModeOpen(false)
              setConfirmModalOpen(false)
            }}
            alignItems="center"
            alignSelf="center"
          />
        </Grid>
      </MagellanModal>

      {isEditModeOpened && (
        <BtnsBlock
          cancelPlaceholder="Cancel"
          savePlaceholder="Save changes"
          deletePlaceholder="Delete my account"
          isDeleteButtonVisible={true}
          disabled={Object.keys(validateFields(accountDetails)).length > 0}
          handleCancel={() => handleDiscardChanges()}
          handleSave={() => sendUserFilters()}
          handleDelete={() => setAccountDeleteModalOpen(true)}
        />
      )}
    </>
  )
}
