export const countriesData = [
  {
    region: 'Africa',
    children: [
      {
        value: 'Algeria'
      },
      {
        value: 'Egypt'
      },
      {
        value: 'Libya'
      },
      {
        value: 'Morocco'
      },
      {
        value: 'Sudan'
      },
      {
        value: 'Tunisia'
      },
      {
        value: 'Western Sahara'
      },

      {
        value: 'Angola'
      },
      {
        value: 'Cameroon'
      },
      {
        value: 'Central African Republic'
      },
      {
        value: 'Chad'
      },
      {
        value: 'Congo'
      },
      {
        value: 'Democratic Republic of the Congo'
      },
      {
        value: 'Equatorial Guinea'
      },
      {
        value: 'Gabon'
      },
      {
        value: 'Sao Tome and Principe'
      },

      {
        value: 'Benin'
      },
      {
        value: 'Burkina Faso'
      },
      {
        value: 'Cabo Verde'
      },
      {
        value: "Côte d'Ivoire"
      },
      {
        value: 'Gambia'
      },
      {
        value: 'Ghana'
      },
      {
        value: 'Guinea'
      },
      {
        value: 'Guinea-Bissau'
      },
      {
        value: 'Liberia'
      },
      {
        value: 'Mali'
      },
      {
        value: 'Mauritania'
      },
      {
        value: 'Niger'
      },
      {
        value: 'Nigeria'
      },
      {
        value: 'Saint Helena'
      },
      {
        value: 'Senegal'
      },
      {
        value: 'Sierra Leone'
      },
      {
        value: 'Togo'
      },

      {
        value: 'Botswana'
      },
      {
        value: 'Lesotho'
      },
      {
        value: 'Namibia'
      },
      {
        value: 'South Africa'
      },
      {
        value: 'Swaziland'
      },

      {
        value: 'Burundi'
      },
      {
        value: 'Comoros'
      },
      {
        value: 'Djibouti'
      },
      {
        value: 'Eritrea'
      },
      {
        value: 'Ethiopia'
      },
      {
        value: 'Kenya'
      },
      {
        value: 'Madagascar'
      },
      {
        value: 'Malawi'
      },
      {
        value: 'Mauritius'
      },
      {
        value: 'Mayotte'
      },
      {
        value: 'Mozambique'
      },
      {
        value: 'Réunion'
      },
      {
        value: 'Rwanda'
      },
      {
        value: 'Seychelles'
      },
      {
        value: 'Somalia'
      },
      {
        value: 'South Sudan'
      },
      {
        value: 'Uganda'
      },
      {
        value: 'United Republic of Tanzania'
      },
      {
        value: 'Zambia'
      },
      {
        value: 'Zimbabwe'
      }
    ]
  },
  {
    region: 'APAC',
    children: [
      {
        value: 'Afghanistan'
      },
      {
        value: 'Bangladesh'
      },
      {
        value: 'Bhutan'
      },
      {
        value: 'India'
      },
      {
        value: 'Iran (Islamic Republic of)'
      },
      {
        value: 'Maldives'
      },
      {
        value: 'Nepal'
      },
      {
        value: 'Pakistan'
      },
      {
        value: 'Sri Lanka'
      },

      {
        value: 'Armenia'
      },
      {
        value: 'Azerbaijan'
      },
      {
        value: 'Bahrain'
      },

      {
        value: 'Georgia'
      },
      {
        value: 'Iraq'
      },
      {
        value: 'Israel'
      },
      {
        value: 'Jordan'
      },
      {
        value: 'Kuwait'
      },
      {
        value: 'Lebanon'
      },
      {
        value: 'Oman'
      },
      {
        value: 'Qatar'
      },
      {
        value: 'Saudi Arabia'
      },
      {
        value: 'State of Palestine'
      },
      {
        value: 'Syrian Arab Republic'
      },
      {
        value: 'Turkey'
      },
      {
        value: 'United Arab Emirates'
      },
      {
        value: 'Yemen'
      },

      {
        value: 'Brunei Darussalam'
      },
      {
        value: 'Cambodia'
      },
      {
        value: 'Indonesia'
      },
      {
        value: "Lao People's Democratic Republic"
      },
      {
        value: 'Malaysia'
      },
      {
        value: 'Myanmar'
      },
      {
        value: 'Philippines'
      },
      {
        value: 'Singapore'
      },
      {
        value: 'Thailand'
      },
      {
        value: 'Timor-Leste'
      },
      {
        value: 'Viet Nam'
      },

      {
        value: 'China'
      },
      {
        value: 'China, Hong Kong Special Administrative Region'
      },
      {
        value: 'China, Macao Special Administrative Region'
      },
      {
        value: "Democratic People's Republic of Korea"
      },
      {
        value: 'Japan'
      },
      {
        value: 'Mongolia'
      },
      {
        value: 'Republic of Korea'
      },

      {
        value: 'Kazakhstan'
      },
      {
        value: 'Kyrgyzstan'
      },
      {
        value: 'Tajikistan'
      },
      {
        value: 'Turkmenistan'
      },
      {
        value: 'Uzbekistan'
      },

      {
        value: 'American Samoa'
      },
      {
        value: 'Cook Islands'
      },
      {
        value: 'French Polynesia'
      },
      {
        value: 'Niue'
      },
      {
        value: 'Samoa'
      },
      {
        value: 'Tokelau'
      },
      {
        value: 'Tonga'
      },
      {
        value: 'Tuvalu'
      },
      {
        value: 'Wallis and Futuna Islands'
      },

      {
        value: 'Australia'
      },
      {
        value: 'New Zealand'
      },

      {
        value: 'Fiji'
      },
      {
        value: 'New Caledonia'
      },
      {
        value: 'Papua New Guinea'
      },
      {
        value: 'Solomon Islands'
      },
      {
        value: 'Vanuatu'
      },

      {
        value: 'Guam'
      },
      {
        value: 'Kiribati'
      },
      {
        value: 'Marshall Islands'
      },
      {
        value: 'Micronesia (Federated States of)'
      },
      {
        value: 'Nauru'
      },
      {
        value: 'Northern Mariana Islands'
      },
      {
        value: 'Palau'
      }
    ]
  },
  {
    region: 'Europe',
    children: [
      {
        value: 'Andorra'
      },
      {
        value: 'Albania'
      },
      {
        value: 'Austria'
      },
      {
        value: 'Åland Islands'
      },
      {
        value: 'Bosnia and Herzegovina'
      },
      {
        value: 'Belgium'
      },
      {
        value: 'Bulgaria'
      },
      {
        value: 'Belarus'
      },
      {
        value: 'Switzerland'
      },
      {
        value: 'Cyprus'
      },
      {
        value: 'Czech Republic'
      },
      {
        value: 'Germany'
      },
      {
        value: 'Denmark'
      },
      {
        value: 'Estonia'
      },
      {
        value: 'Spain'
      },
      {
        value: 'Finland'
      },
      {
        value: 'Faroe Islands'
      },
      {
        value: 'France'
      },
      {
        value: 'United Kingdom'
      },
      {
        value: 'Guernsey'
      },
      {
        value: 'Greece'
      },
      {
        value: 'Croatia'
      },
      {
        value: 'Hungary'
      },
      {
        value: 'Ireland'
      },
      {
        value: 'Isle of Man'
      },
      {
        value: 'Iceland'
      },
      {
        value: 'Italy'
      },
      {
        value: 'Jersey'
      },
      {
        value: 'Liechtenstein'
      },
      {
        value: 'Lithuania'
      },
      {
        value: 'Luxembourg'
      },
      {
        value: 'Latvia'
      },
      {
        value: 'Monaco'
      },
      {
        value: 'Moldova, Republic of'
      },
      {
        value: 'Macedonia, The Former Yugoslav Republic of'
      },
      {
        value: 'Malta'
      },
      {
        value: 'Netherlands'
      },
      {
        value: 'Norway'
      },
      {
        value: 'Poland'
      },
      {
        value: 'Portugal'
      },
      {
        value: 'Romania'
      },
      {
        value: 'Russian Federation'
      },
      {
        value: 'Sweden'
      },
      {
        value: 'Slovenia'
      },
      {
        value: 'Svalbard and Jan Mayen'
      },
      {
        value: 'Slovakia'
      },
      {
        value: 'San Marino'
      },
      {
        value: 'Ukraine'
      },
      {
        value: 'Holy See (Vatican City State)'
      }
    ]
  },
  {
    region: 'North America',
    children: [
      {
        value: 'Bermuda'
      },
      {
        value: 'Canada'
      },
      {
        value: 'Greenland'
      },
      {
        value: 'Saint Pierre and Miquelon'
      },
      {
        value: 'United States of America'
      }
    ]
  },

  {
    region: 'South America',
    children: [
      {
        value: 'Anguilla'
      },
      {
        value: 'Antigua and Barbuda'
      },
      {
        value: 'Aruba'
      },
      {
        value: 'Bahamas'
      },
      {
        value: 'Barbados'
      },
      {
        value: 'British Virgin Islands'
      },
      {
        value: 'Bonaire, Sint Eustatius and Saba'
      },
      {
        value: 'Cayman Islands'
      },
      {
        value: 'Cuba'
      },
      {
        value: 'Curaçao'
      },
      {
        value: 'Dominica'
      },
      {
        value: 'Dominican Republic'
      },
      {
        value: 'Grenada'
      },
      {
        value: 'Guadeloupe'
      },
      {
        value: 'Haiti'
      },
      {
        value: 'Jamaica'
      },
      {
        value: 'Martinique'
      },
      {
        value: 'Montserrat'
      },
      {
        value: 'Puerto Rico'
      },
      {
        value: 'Saint Kitts and Nevis'
      },
      {
        value: 'Saint Lucia'
      },
      {
        value: 'Saint Vincent and the Grenadines'
      },
      {
        value: 'Sint Maarten (Dutch part)'
      },
      {
        value: 'Trinvaluead and Tobago'
      },
      {
        value: 'Turks and Caicos Islands'
      },
      {
        value: 'United States Virgin Islands'
      },

      {
        value: 'Argentina'
      },
      {
        value: 'Bolivia (Plurinational State of)'
      },
      {
        value: 'Brazil'
      },
      {
        value: 'Chile'
      },
      {
        value: 'Colombia'
      },
      {
        value: 'Ecuador'
      },
      {
        value: 'Falkland Islands (Malvinas)'
      },
      {
        value: 'French Guiana'
      },
      {
        value: 'Guyana'
      },
      {
        value: 'Paraguay'
      },
      {
        value: 'Peru'
      },
      {
        value: 'Suriname'
      },
      {
        value: 'Uruguay'
      },
      {
        value: 'Venezuela (Bolivarian Republic of)'
      },

      {
        value: 'Belize'
      },
      {
        value: 'Costa Rica'
      },
      {
        value: 'El Salvador'
      },
      {
        value: 'Guatemala'
      },
      {
        value: 'Honduras'
      },
      {
        value: 'Mexico'
      },
      {
        value: 'Nicaragua'
      },
      {
        value: 'Panama'
      }
    ]
  },

  {
    region: 'United States',
    children: [
      {
        value: 'Alabama'
      },
      {
        value: 'Alaska'
      },
      {
        value: 'American Samoa'
      },
      {
        value: 'Arizona'
      },
      {
        value: 'Arkansas'
      },
      {
        value: 'California'
      },
      {
        value: 'Colorado'
      },
      {
        value: 'Connecticut'
      },
      {
        value: 'Delaware'
      },
      {
        value: 'District Of Columbia'
      },
      {
        value: 'Federated States Of Micronesia'
      },
      {
        value: 'Florida'
      },
      {
        value: 'Georgia'
      },
      {
        value: 'Guam'
      },
      {
        value: 'Hawaii'
      },
      {
        value: 'Idaho'
      },
      {
        value: 'Illinois'
      },
      {
        value: 'Indiana'
      },
      {
        value: 'Iowa'
      },
      {
        value: 'Kansas'
      },
      {
        value: 'Kentucky'
      },
      {
        value: 'Louisiana'
      },
      {
        value: 'Maine'
      },
      {
        value: 'Marshall Islands'
      },
      {
        value: 'Maryland'
      },
      {
        value: 'Massachusetts'
      },
      {
        value: 'Michigan'
      },
      {
        value: 'Minnesota'
      },
      {
        value: 'Mississippi'
      },
      {
        value: 'Missouri'
      },
      {
        value: 'Montana'
      },
      {
        value: 'Nebraska'
      },
      {
        value: 'Nevada'
      },
      {
        value: 'New Hampshire'
      },
      {
        value: 'New Jersey'
      },
      {
        value: 'New Mexico'
      },
      {
        value: 'New York'
      },
      {
        value: 'North Carolina'
      },
      {
        value: 'North Dakota'
      },
      {
        value: 'Northern Mariana Islands'
      },
      {
        value: 'Ohio'
      },
      {
        value: 'Oklahoma'
      },
      {
        value: 'Oregon'
      },
      {
        value: 'Palau'
      },
      {
        value: 'Pennsylvania'
      },
      {
        value: 'Puerto Rico'
      },
      {
        value: 'Rhode Island'
      },
      {
        value: 'South Carolina'
      },
      {
        value: 'South Dakota'
      },
      {
        value: 'Tennessee'
      },
      {
        value: 'Texas'
      },
      {
        value: 'Utah'
      },
      {
        value: 'Vermont'
      },
      {
        value: 'Virgin Islands'
      },
      {
        value: 'Virginia'
      },
      {
        value: 'Washington'
      },
      {
        value: 'West Virginia'
      },
      {
        value: 'Wisconsin'
      },
      {
        value: 'Wyoming'
      }
    ]
  }
]
