import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect
} from 'react'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { useScreenWidth } from 'utils/customHooks/useScreenWidth'
import { useAuth0 } from './AuthContext'

// Navbar Context
const NavBarContext = React.createContext()
export const useNavContext = () => useContext(NavBarContext)
export const NavBarContextProvider = ({ children }) => {
  const { downSm } = useMediaQueries()
  const screenWidth = useScreenWidth()
  const [filtersOpen, setFilterOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isSignupOpen, setSignupOpen] = useState(false)
  const [navHeight, setNavHeight] = useState(0)
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const [myFavCollectionsLink, setMyFavCollectionsLink] = useState(
    '/collections/personal'
  )
  const [showStickyNav, setShowStickyNav] = useState(false)
  // state for collection pagination
  const [oxPrevPage, setOxBowPrevPage] = useState(1)
  const [personalPrevPage, setPersonalPrevPage] = useState(1)
  const [orgPrevPage, setOrgPrevPage] = useState(1)
  const [favouritesPrevpage, setFavouritesPrevPage] = useState(1)
  const [isManageCollectionOpen, setManageCollectionOpen] = useState(null)

  const [isCollectionsSelected, setCollectionsSelected] = useState(false)

  const [prevPage, setPrevPage] = useState('')
  // landing page refs
  const LandingHeaderRef = useRef(null)
  const LandingAboutUs = useRef(null)
  const LandingInAction = useRef(null)
  const LandingWhyMagellan = useRef(null)
  const [visibleSection, setVisibleSection] = useState('')

  const [isDemoFormOpen, setDemoFormOpen] = useState(false)
  const [isThxModalOpen, setThxModalOpen] = useState(false)

  const navbarRef = useRef()

  const [currId, setCurrId] = useState('')
  const [currCollection, setCurrCollection] = useState('')
  const landingNavButtons = [
    { name: 'About us', id: 'LandingAboutUs', ref: LandingAboutUs },
    { name: 'Magellan in action', id: 'LandingInAction', ref: LandingInAction },
    { name: 'Why Magellan', id: 'LandingWhyMagellan', ref: LandingWhyMagellan }
  ]

  const modalData = {
    firstName: 'First name',
    lastName: 'Last name',
    jobTitle: 'Job title',
    companyName: 'Company name',
    email: 'E-mail'
  }
  useEffect(() => {
    setPrevPage(window.location.pathname === '/home' ? 'home' : '')
  }, [window.location.pathname])

  const toggleFilter = () => {
    if (filtersOpen) setFilterOpen(false)
    else setFilterOpen(true)
  }
  const toggleDrawer = () => {
    //If we are in responsive mode then only toggle drawer
    if (downSm) {
      if (drawerOpen) setDrawerOpen(false)
      else setDrawerOpen(true)
    }
  }

  useEffect(() => {
    if (screenWidth > 960 && drawerOpen) {
      setDrawerOpen(false)
    }
  }, [screenWidth, drawerOpen])

  const _setSignUpOpen = useCallback(
    value => {
      if (isAuthenticated) return setSignupOpen(value)
      loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
        prompt: 'login',
        screen_hint: 'signup'
      })
    },
    [isAuthenticated, loginWithRedirect]
  )

  return (
    <NavBarContext.Provider
      value={{
        filtersOpen,
        toggleFilter,
        myFavCollectionsLink,
        setMyFavCollectionsLink,
        drawerOpen,
        toggleDrawer,
        isSignupOpen,
        setSignupOpen: _setSignUpOpen,
        navHeight,
        setNavHeight,
        oxPrevPage,
        setOxBowPrevPage,
        personalPrevPage,
        setPersonalPrevPage,
        orgPrevPage,
        setOrgPrevPage,
        isManageCollectionOpen,
        setManageCollectionOpen,
        isCollectionsSelected,
        setCollectionsSelected,
        landingNavButtons,
        LandingAboutUs,
        LandingInAction,
        LandingWhyMagellan,
        LandingHeaderRef,
        visibleSection,
        setVisibleSection,
        isDemoFormOpen,
        setDemoFormOpen,
        isThxModalOpen,
        setThxModalOpen,
        currId,
        setCurrId,
        modalData,
        showStickyNav,
        setShowStickyNav,
        favouritesPrevpage,
        setFavouritesPrevPage,
        currCollection,
        setCurrCollection,
        prevPage,
        navbarRef
      }}
    >
      {children}
    </NavBarContext.Provider>
  )
}
