import React from 'react'
import { ReactComponent as StartupIcon } from 'assets/icons/compnayscaleicons/startup.svg'
import { ReactComponent as ScaleupIcon } from 'assets/icons/compnayscaleicons/scaleup.svg'
import { ReactComponent as EstablishedIcon } from 'assets/icons/compnayscaleicons/established.svg'
import { ReactComponent as IncumbentIcon } from 'assets/icons/compnayscaleicons/incumbent.svg'

export const getFteValue = fte => {
  const latestYearFTE = fte[0]
  return (
    (latestYearFTE.fteMin
      ? latestYearFTE.fteMin === latestYearFTE.fteMax
        ? ''
        : `${latestYearFTE.fteMin} - `
      : '') + `${latestYearFTE.fteMax} (${latestYearFTE.year})`
  )
}

export const getRevenueValue = revenue => {
  const lastYearRevenue = revenue[0]
  return `${convertToMillion(lastYearRevenue.revenueMin)}m -
            ${convertToMillion(lastYearRevenue.revenueMax)}m (${
    lastYearRevenue.year
  })`
}

export const convertToMillion = value => Math.round(value / 1000000)
export const convertFromMillion = value => value * 1000000

export const capitalize = text => text.charAt(0).toUpperCase() + text.slice(1)

export const handleFromUnitConversion = (value, unit) => {
  switch (unit) {
    case 'M':
      return convertFromMillion(value)

    default:
      return value
  }
}

export const handleToUnitConversion = (value, unit) => {
  switch (unit) {
    case 'M':
      return convertToMillion(value)

    default:
      return value
  }
}

export function converyObjectToArray(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) acc.push(key)
    return acc
  }, [])
}

export function arrHasCommonElements(subArray, array) {
  if (subArray.length === 0) return true;

  for (const item of array) {
    if (subArray.includes(item)) return true;
  }

  return false;
}

// Add new special primary functions into this object for scaling further
const specialPrimaryFunctions = {
  POLICY_ADMIN: 'Policy Admin'
}

export const isSpecialProduct = productData => {
  if (!productData.primaryFunction) return { isSpecial: false }

  Object.entries(specialPrimaryFunctions).forEach(([_, primaryFunction]) => {
    if (productData.primaryFunction.includes(primaryFunction)) {
      return {
        isSpecial: true,
        type: primaryFunction
      }
    }
  })
  return { isSpecial: false }
}

export const convertCamelToSentenceCase = value => {
  var result = value.replace(/([A-Z])/g, ' $1')
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

export const joinTokenWithCamelCase = tokens =>
  tokens.reduce((acc, token, index) => {
    if (index === 0) {
      return acc + token
    }
    return acc + token[0].toUpperCase() + token.slice(1, token.length)
  }, '')

export const getFieldData = (fieldPath, dummyData) => {
  const keys = fieldPath.split('.')
  return keys.reduce((acc, key) => {
    if (!acc) return null
    acc = acc[key]
    return acc
  }, dummyData)
}

export const StageDetailsMapping = {
  stage1: {
    label: 'Start Up',
    icon: <StartupIcon />,
    stageNumber: 1
  },
  stage2: {
    label: 'Scale Up',
    icon: <ScaleupIcon />,
    stageNumber: 2
  },
  stage3: {
    label: 'Established',
    icon: <EstablishedIcon />,
    stageNumber: 3
  },
  stage4: {
    label: 'Large Enterprise',
    icon: <IncumbentIcon />,
    stageNumber: 4
  }
}

export const getStageDetails = stageNumberString =>
  StageDetailsMapping[stageNumberString]

export const derivedFromProp = (propName, propValueMapping) => props => {
  if (!propValueMapping.default) propValueMapping.default = null
  return propValueMapping[props[propName]] || propValueMapping.default
}

export const userNameAccronym = (userName, profileName) => {
  function accronym(name) {
    return name.split('.').join(' ').match(/\b\w/g).join('').toUpperCase()
  }
  if (profileName) {
    return accronym(profileName)
  }
  return accronym(userName)
}

export const userName = data => {
  return Object.entries(data)
    .map(([key, value]) =>
      key === 'firstName' || key === 'lastName' ? value : null
    )
    .filter(Boolean)
    .join(' ')
}
