export default {
  whatFilters: {
    title: 'What',
    id: 'primaryFunction',
    filters: {
      distributionAndCX: {
        name: 'distributionAndCX',
        title: 'Function',
        label: 'Distribution & CX',
        type: 'chips',
        inputLabel: ' ',
        category: ['distributor', 'supplier', 'other'],
        options: [],
        ordered: true,
        optionMapping: [
          { value: 'Customer engagement', order: 1 },
          { value: 'Affinity distribution', order: 2 },
          { value: 'Digital distribution', order: 3 },
          { value: 'Broker management', order: 4 },
          { value: 'Lead generation', order: 5 },
          { value: 'Digital customer engagement', order: 6 },
          { value: 'CRM', order: 7 },
          { value: 'Chatbot', order: 8 },
          { value: 'Customer analytics', order: 9 }
        ]
      },
      pasAndProductBuilders: {
        name: 'pasAndProductBuilders',
        title: '',
        label: 'PAS & Product Builders',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Policy admin system', order: 1 },
          { value: 'Application & product builders', order: 2 }
        ]
      },
      underwriting: {
        name: 'underwriting',
        title: '',
        label: 'Underwriting, Pricing, D&A',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Data & analytics', order: 1 },
          { value: 'Data governance & compliance', order: 2 },
          { value: 'Data source/Enrichment', order: 3 },
          { value: 'Underwriting', order: 4 },
          { value: 'Pricing', order: 5 },
          { value: 'Risk modelling', order: 6 },
          { value: 'Image analytics', order: 7 },
          { value: 'Workbench', order: 8 }
        ]
      },
      operations: {
        name: 'operations',
        title: '',
        label: 'Operations',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Finance & accounting', order: 1 },
          { value: 'Payments', order: 2 },
          { value: 'Workflow & RPA', order: 3 },
          { value: 'Document management / generation', order: 4 },
          { value: 'Business process automation', order: 5 },
          { value: 'Document ingestion', order: 6 },
          { value: 'IT managed services', order: 7 }
        ]
      },
      fraudAndSecurity: {
        name: 'fraudAndSecurity',
        title: '',
        label: 'Fraud & Security',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Fraud management', order: 1 },
          { value: 'Identity, security & KYC', order: 2 }
        ]
      },
      claims: {
        name: 'claims',
        title: '',
        label: 'Claims',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Claims', order: 1 },
          { value: 'Claims platform', order: 2 },
          { value: 'Claims automation', order: 3 },
          { value: 'Remote inspection', order: 4 }
        ]
      }
    }
  },
  whatForFilters: {
    title: 'What for',
    id: 'basicSubclass',
    filters: {
      motor: {
        name: 'motor',
        title: 'Class',
        label: 'Motor',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Commercial Motor & Fleet', order: 1 },
          { value: 'Private Motor', order: 2 }
        ]
      },
      home: {
        name: 'home',
        title: '',
        label: 'Home',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Home Buildings', order: 1 },
          { value: 'Home Contents/Renters', order: 2 },
          { value: 'Landlord', order: 3 }
        ]
      },
      otherRetailPC: {
        name: 'otherRetailPC',
        title: '',
        label: 'Other Retail P&C',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Bike', order: 1 },
          { value: 'Mortgage & Deposit', order: 2 },
          { value: 'Personal Accident', order: 3 },
          { value: 'Personal Liability', order: 4 },
          { value: 'Pet', order: 5 },
          { value: 'Phone, Gadget & Niche Personal Lines', order: 6 },
          { value: 'Travel', order: 7 }
        ]
      },
      commercialPC: {
        name: 'commercialPC',
        title: '',
        label: 'Commercial P&C',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Business Interruption', order: 1 },
          { value: 'Casualty/General Liability', order: 2 },
          { value: 'Catastrophe', order: 3 },
          { value: 'Commercial Combined/Package', order: 4 },
          { value: 'Commercial Property', order: 5 },
          { value: "Employers' Liability/Workers Compensation", order: 6 }
        ]
      },

      specialty: {
        name: 'specialty',
        title: '',
        label: 'Specialty',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Credit & Bond', order: 1 },
          { value: 'Crypto', order: 2 },
          { value: 'Cyber', order: 3 },
          { value: 'D&O', order: 4 },
          { value: 'Engineering & Construction', order: 5 },
          { value: 'Equine & Livestock', order: 6 },
          { value: 'Events & Contingency', order: 7 },
          { value: 'Farm & Agriculture', order: 8 },
          { value: 'Financial Lines & Fidelity', order: 9 },
          { value: 'Fine Art', order: 10 },
          { value: 'K&R', order: 11 },
          { value: 'Legal Protection', order: 12 },
          { value: 'Political Risks', order: 13 },
          { value: 'Professional Indemnity', order: 14 },
          { value: 'Reinsurance', order: 15 },
          { value: 'Warranty', order: 16 },
          { value: 'Terrorism', order: 17 }
        ]
      },
      marineAndTransport: {
        name: 'marineAndTransport',
        title: '',
        label: 'Marine & transport',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Aviation', order: 1 },
          { value: 'Boat/Pleasurecraft', order: 2 },
          { value: 'Cargo', order: 3 },
          { value: 'Drone', order: 4 },
          { value: 'Energy', order: 5 },
          { value: 'Marine Hull', order: 6 },
          { value: 'Marine Liability', order: 7 },
          { value: 'Space', order: 8 }
        ]
      },
      healthAndIncome: {
        name: 'healthAndIncome',
        title: '',
        label: 'Health & income',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Critical Illness', order: 1 },
          { value: 'Dental', order: 2 },
          { value: 'Disability', order: 3 },
          { value: 'Employee Benefits', order: 4 },
          { value: 'Income Protection', order: 5 },
          { value: 'Private Medical', order: 6 },
          { value: 'Specialty Health', order: 7 },
          { value: 'Vision', order: 8 }
        ]
      },
      lifeAndPensions: {
        name: 'lifeAndPensions',
        title: '',
        label: 'Life & pensions',
        type: 'chips',
        inputLabel: '',
        options: [],
        category: ['distributor', 'supplier', 'other'],
        ordered: true,
        optionMapping: [
          { value: 'Annuities', order: 1 },
          { value: 'Funeral', order: 2 },
          { value: 'Pensions', order: 3 },
          { value: 'Term Life', order: 4 }
        ]
      }
    }
  },

  whereFilters: {
    title: 'Where',
    id: 'whereFilters',
    filters: {
      geographiesWithImplementations: {
        title: 'Countries of Implementations',
        name: 'geographiesWithImplementations',
        type: 'chips',
        category: ['distributor', 'supplier', 'other'],
        inputLabel: 'Enter Country name',
        disabledOnFree: true,
        props: {
          clearBtn: false
        },
        options: [],
      },
      londonMarketRelevance: {
        title: 'London Market Relevance',
        name: 'londonMarketRelevance',
        type: 'chips',
        category: ['distributor', 'supplier', 'other'],
        inputLabel: '',
        options: ['Relevant', 'Focussed'],
        props: {
          ignoreDocCount: true,
          clearBtn: true
        }
      }
    }
  },

  productFilters: {
    title: 'Other',
    id: 'productFilters',
    filters: {
      targetClient: {
        title: 'Target Client',
        name: 'targetClient',
        category: ['supplier'],
        inputLabel: 'Enter Target Client',
        type: 'chips',
        options: [],
        disabledOnFree: true,
      },
      distributorType: {
        title: 'Show companies targeting',
        name: 'distributorType',
        category: ['distributor'],
        inputLabel: '',
        type: 'chips',
        options: [],
        props: {
          ignoreDocCount: true,
          clearBtn: true
        }
      },
      singleFunction: {
        title: 'Function',
        name: 'singleFunction',
        type: 'chips',
        inputLabel: 'Enter function name',
        showOptionCount: true,
        category: ['supplier'],
        isParent: true,
        hidden: true,
        children: ['primaryFunction', 'secondaryFunction']
      },
      secondaryFunction: {
        title: 'Secondary Function',
        name: 'secondaryFunction',
        type: 'chips',
        category: ['distributor', 'supplier', 'other'],
        inputLabel: 'Enter Secondary Function',
        showOptionCount: true,
        isChild: true,
        parentName: 'singleFunction'
      },
      insuranceClass: {
        title: 'Insurance Class',
        name: 'insuranceClass',
        category: ['distributor', 'supplier', 'other'],
        type: 'chips',
        hidden: true,
        inputLabel: 'Enter Insurance Class',
        options: []
      },

      insuranceClientType: {
        title: 'Insurance Client Type',
        name: 'insuranceClientType',
        type: 'chips',
        inputLabel: 'Enter Client Type',
        category: ['distributor', 'supplier', 'other'],
        options: [],
        ordered: true,
        optionMapping: {
          'Personal Lines': { label: 'Personal Lines', order: 1 },
          'Micro Commercial': { label: 'Micro Commercial', order: 2 },
          SME: { label: 'SME', order: 3 },
          'Mid-Market': { label: 'Mid-Market', order: 4 },
          Corporate: { label: 'Corporate', order: 5 }
        },
        disabledOnFree: true,
        props: {
          clearBtn: true
        }
      },
      relatedTechnology: {
        title: 'Related Technology',
        name: 'relatedTechnology',
        category: ['distributor', 'supplier', 'other'],
        type: 'chips',
        options: [],
        hidden: false,
        disabledOnFree: true,
        inputLabel: 'Enter Related Technology',
        props: {
          clearBtn: true
        }
      },
      businessModel: {
        title: 'Business Model',
        name: 'businessModel',
        type: 'chips',
        category: ['distributor', 'supplier', 'other'],
        options: [],
        disabledOnFree: true,
        hidden: true
      },

      distributionModelsCurrentlySupported: {
        title: 'Distribution Models Currently Supported',
        name: 'distributionModelsCurrentlySupported',
        hidden: true,
        type: 'chips'
      },
      hostingdeliveryOptions: {
        title: 'Hosting & Delivery Options',
        name: 'hostingdeliveryOptions',
        hidden: true,
        type: 'chips'
      },
      preferredSoftwareDevelopmentMethodology: {
        title: 'Preferred Software Methodology',
        name: 'preferredSoftwareDevelopmentMethodology',
        hidden: true,
        type: 'chips'
      },
      implementationPartners: {
        title: 'Implementation Partners',
        name: 'implementationPartners',
        hidden: true,
        type: 'chips'
      },
      thirdPartyServiceProviders: {
        title: 'Third Party Service Providers',
        name: 'thirdPartyServiceProviders',
        hidden: true,
        type: 'chips'
      },
      clients: {
        title: 'Clients',
        name: 'clients',
        hidden: true,
        type: 'chips'
      }
    }
  },
  companyFilters: {
    title: ' ',
    id: 'companyFilters',
    filters: {
      revenue: {
        title: 'Revenue',
        name: 'revenue',
        category: ['distributor', 'supplier', 'other'],
        type: 'range',
        disabled: true,
        options: {
          max: 1000000000,
          unit: 'M'
        }
      },
      fte: {
        title: 'FTE',
        name: 'fte',
        category: ['distributor', 'supplier', 'other'],
        type: 'range',
        disabled: true,
        options: {
          max: 1000,
          unit: null
        }
      },
      yearFounded: {
        title: 'Year Founded',
        category: ['distributor', 'supplier', 'other'],
        name: 'yearFounded',
        type: 'range',
        disabled: true,
        options: {
          unit: null
        }
      },
      totalInvestment: {
        title: 'Total Investment',
        name: 'totalInvestment',
        category: ['distributor', 'supplier', 'other'],
        type: 'range',
        disabled: true,
        options: {
          min: 1,
          max: 1000,
          unit: 'M'
        }
      },
      companyStage: {
        title: 'Company Stage',
        name: 'companyStage',
        category: ['distributor', 'supplier', 'other'],
        type: 'chips',
        inputLabel: 'Enter Stage',
        options: [],
        ordered: true,
        optionMapping: {
          stage1: { label: 'Start-up', order: 1 },
          stage2: { label: 'Scale', order: 2 },
          stage3: { label: 'Established', order: 3 },
          stage4: { label: 'Large Enterprise', order: 4 }
        },
        props: {
          ignoreDocCount: true,
          clearBtn: true
        },
        disabledOnFree: true
      },
      headquaters: {
        title: 'Headquarters',
        name: 'headquaters',
        type: 'chips',
        category: ['distributor', 'supplier', 'other'],
        inputLabel: 'Enter Country Name',
        disabledOnFree: true,
        props: {
          clearBtn: true
        },
        hidden: false,
        options: [],
      },
      offices: {
        title: 'Offices',
        name: 'offices',
        type: 'chips',
        category: ['distributor', 'supplier', 'other'],
        inputLabel: 'Enter Country Name',
        disabledOnFree: true,
        hidden: false,
        props: {
          clearBtn: true
        },
        options: [],
      }
    }
  },
  categoryFilters: {
    title: 'Category',
    id: 'categoryFilters',
    filters: {
      category: {
        title: 'Category',
        type: 'checkbox',
        name: 'category',
        options: [
          { name: 'supplier', label: 'Supplier' },
          { name: 'distributor', label: 'Distributors' },
          { name: 'other', label: 'Other' }
        ]
      }
    }
  }
}
