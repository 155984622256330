import { makeStyles } from '@material-ui/core'
import { derivedFromProp } from 'utils'

export const useStyles = makeStyles(theme => ({
  savedSerchesMainBox: {
    overflow: 'hidden',
    zIndex: '99 !important',
    top: '232px !important',
    '& .MuiBackdrop-root': {
      top: '265px'
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiBackdrop-root': {
        top: '250px'
      },
      top: '223px !important'
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiBackdrop-root': {
        top: '250px'
      },
      top: '245px !important'
    }
  },
  mainTable: {
    backgroundColor: 'white',
    border: '2px solid #0E90D9',
    borderTop: 'none !important',
    padding: 10,
    '& .MuiIconButton-label': {
      '& svg': {
        width: 10,
        height: 10
      }
    },
    '& .MuiTableCell-head': {
      borderBottom: 'none'
    }
  },

  mainTableRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      lineHeight: 0
    },
    '& .MuiTableCell-body': {
      borderBottom: 'none !important'
    },
    '& .MuiTableCell-head': {
      borderBottom: 'none !important'
    },
    '& .tableArrow': {
      width: 10,
      paddingRight: 0
    }
  },
  collapseIcon: {
    marginRight: 10
  },
  tableDeleteBtn: {
    color: '#0E90D9 !important',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '-10px',
    cursor: 'pointer',
    width: '12px',
    height: '12px'
  },
  editIcon: {
    width: 16,
    height: 16,
    marginLeft: '13px',
    color: '#0E90D9 !important'
  },
  tableName: {
    color: '#0E90D9 !important',
    cursor: 'pointer',
    display: 'flex !important',
    alignItems: 'center'
  },
  savedSearchName: {
    fontSize: 16
  },
  titleName: {
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0'
    }
  },
  savedSerchesArticleBox: {
    overflowX: 'auto',
    width: '100%',
    height: '100%',
    outline: 'none'
  },
  triangleLeft: {
    content: '""',
    display: 'block',
    width: 0,
    height: 0,
    borderTop: '9px solid #0E90D9 !important',
    borderLeft: '9px solid transparent !important',
    zIndex: 2,
    borderRight: '9px solid transparent !important',
    position: 'absolute',
    bottom: -18,
    right: '25%',
    top: 0,
    '& .inner-triangle': {
      position: 'relative',
      top: '-22px !important',
      left: '-20px !important',
      width: 0,
      height: 0,
      borderTop: '20px solid #fff !important',
      borderLeft: '20px solid transparent !important',
      borderRight: '20px solid transparent !important'
    },

    [theme.breakpoints.up(1700)]: {
      right: '30% '
    },
    [theme.breakpoints.down('md')]: {
      right: '20%',
      '& .inner-triangle': {
        position: 'relative',
        top: '-9px !important',
        left: '-6px !important',

        borderTop: '6px solid #fff !important',
        borderLeft: '6px solid transparent !important',
        borderRight: '6px solid transparent !important'
      }
    },
    [theme.breakpoints.down('xs')]: {
      left: 59,
      '& .triangle-left': {
        left: 59
      }
    }
  },

  editSearchBox: {
    display: 'flex'
  },
  editSearchContentBox: {
    maxWidth: '80%',
    backgroundColor: 'white',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'max-content',
      width: '90%',
      padding: '0 10px'
    }
  },
  editSearchMainBlock: {
    height: '67%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  editSearchLabel: {
    fontWeight: 600
  },
  textInputHeading: {
    margin: '30px 0 ',
    textAlign: 'center',
    fontSize: '36px',
    color: 'black',
    fontWeight: 500,
    lineHeight: '43px',
    fontFamily: '"Barlow Condensed", sans-serif',
    '& >img': {
      position: 'absolute',
      top: '15px',
      right: '15px',
      cursor: 'pointer'
    }
  },
  btnContainer: {
    marginTop: '33px',
    display: 'flex',
    '& button:first-child': {
      marginRight: '22px'
    },
    '& button': {
      width: '205px'
    },
    '& p': {
      fontFamily: '"Barlow Condensed", sans-serif ',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      height: '100px',
      justifyContent: 'space-between !important',
      alignItems: 'center',
      '& button:first-child': {
        marginRight: 0
      }
    }
  },
  textFieldInput: {
    position: 'relative',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '16px',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    outline: 'none',
    height: '40px',
    border: '1px solid #C8C8C8',
    width: '350px',

    [`& input`]: {
      padding: '5px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  searchTerm: {
    marginTop: derivedFromProp('marginTop', {
      none: '0px',
      sm: '10px',
      default: '20px'
    }),
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '4px 12px',
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    margin: '5px 5px 5px 0',
    borderRadius: '20px',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px'
  },
  filterCategory: {
    color: '#fff'
  },
  cancelIcon: {
    height: '17px',
    width: '10px',
    marginLeft: '7px',
    cursor: 'pointer'
  },
  deleteSavedSearch: {
    display: 'flex',
    '& .savedSerchesConfirmBox': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      padding: '0 40px 50px 40px',
      minWidth: '40%',
      height: 'max-content',
      backgroundColor: 'white',
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        padding: '0 10px 20px 10px'
      },
      '& >img': {
        position: 'absolute',
        top: '15px',
        right: '15px',
        cursor: 'pointer'
      },
      '& .textHeading': {
        textAlign: 'center',
        fontSize: '18px'
      }
    }
  },
  textFieldError: {
    border: '1px solid red !important'
  },
  editSearchNameError: {
    '& .errorText': {
      color: 'white',
      backgroundColor: 'red',
      fontSize: 12,
      padding: 5
    }
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px'
  },
  deleteLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(136, 136, 136, 0.519)'
  }
}))
