import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    inherit: {
      main: '#fff'
    },
    primary: {
      dark: '#000',
      lighterDark: '#f4f0ec',
      darkMain: '#3b3734',
      main: '#242424',
      btnLight: '#E6F6FF',
      mainDarkBlue: '#073751',
      mainBlue: '#0085FF',

      mainLightBlue: '#0E90D9',
      primaryBig: '#52B9F2',
      primaryBigHovered: '#24AEFB',

      light: '#615A55',
      lighter: '#ccc4c0',
      contrastText: '#fff',
      lightGrey: '#D4D3D1',
      lighterGrey: '#F2F0EF',
      lightBlue: '#19e',
      lighterWhite: '#484340',
      blue: '#0085FF',
      grey: '#808080'
    },
    text: {
      primary: '#000',
      secondary: '#fff'
    },
    secondary: {
      light: '#cabc94',
      main: '#35AAEC',
      dark: '#8f7c47',
      mainLightGrey: '#F8F8FA',
      backGroundLightGrey: '#F2F0EF',
      mainDarkGrey: '#DADADA',
      transparentBtnHover: '#AEDFFC'
    },
    error: {
      main: red[400]
    },
    background: {
      default: '#fff'
    },
    tertiary: {
      main: '#4A5D8C'
    }
  },
  typography: {
    mainFont: 'Barlow Condensed',
    fontFamily: [
      'Barlow Condensed',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    secondaryFont: 'Open Sans',

    body1: {
      fontFamily: 'Open Sans',
      fontWeight: '400'
    },
    body2: {
      fontFamily: 'Open Sans'
    },
    subtitle1: {
      fontFamily: 'Open Sans',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: 'unset'
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: 'unset'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          lineHeight: 1.6
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1400,
      xl: 1920
    }
  }
})

theme.typography.h1 = {
  fontSize: '50px',
  fontWeight: '600',
  fontFamily: theme.typography.mainFont,
  lineHeight: '60px'
}

theme.typography.h2 = {
  fontSize: '30px',
  fontWeight: '500'
}

theme.typography.h3 = {
  fontSize: '24px',
  fontWeight: '700'
}

theme.typography.h4 = {
  fontSize: '16px',
  fontWeight: '700'
}

theme.typography.h5 = {
  fontSize: '14px',
  fontWeight: '600'
}

theme.typography.h6 = {
  fontSize: '13px',
  fontWeight: '700'
}

export default theme
