import { useState, useEffect } from 'react'
import Sifter from 'sifter'
import _ from 'lodash'

export default function useAutoComplete({
  data,
  searchQuery,
  resultsToReturn = 8
}) {
  const [result, setResult] = useState([])

  useEffect(() => {
    const addResultType = (arr, type) => {
      return arr.map(a => {
        return {
          ...a,
          type
        }
      })
    }
    const sortByScore = (a, b) => {
      let keyA = a.score,
        keyB = b.score
      if (keyA < keyB) return 1
      if (keyA > keyB) return -1
      return 0
    }

    let sifter = new Sifter(data)
    let resultProduct = sifter.search(searchQuery, {
      fields: ['name'],
      sort: [{ field: 'name', direction: 'asc' }],
      limit: resultsToReturn
    })
    let resultCompany = sifter.search(searchQuery, {
      fields: ['companyName'],
      sort: [{ field: 'companyName', direction: 'asc' }],
      limit: resultsToReturn
    })
    let finalResult = []
    let resultProductItems = addResultType(resultProduct.items, 'Product')
    let resultCompanyItems = addResultType(resultCompany.items, 'Company')
    let mergedArrays = [
      ..._.cloneDeep(resultProductItems),
      ..._.cloneDeep(resultCompanyItems)
    ]
    mergedArrays = mergedArrays.sort(sortByScore)
    if (mergedArrays && mergedArrays.length) {
      mergedArrays.forEach(i => {
        let newData = { ...data[i.id] }
        newData.type = i.type
        finalResult.push(newData)
      })
    }
    let set = new Set()
    let finalMerged = []
    finalResult.forEach(obj => {
      const isProductEntry = obj.type === 'Product'
      const suggestionStringValue = (isProductEntry
        ? obj.name
        : obj.companyName
      ).trim()
      if (!set.has(suggestionStringValue)) {
        set.add(suggestionStringValue)
        finalMerged.push(obj)
      }
    })

    setResult(finalMerged.slice(0, resultsToReturn))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  return [result]
}
