import { Box, Icon, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import { ProductBottomBanner } from 'components/Helpers/Banner/ProductBottomBanner'
import Footer from 'components/Helpers/Footer'
import { Navbar } from 'components/Helpers/Navbar'
import { LandingMobileDrawer } from 'components/Pages/LandingPage/LandingDrawer'
import { FreeUserStickyNotes } from 'components/Pages/SearchResults/SearchResults'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { history } from 'Router/history'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { useNavContext } from '../Contexts/NavbarContext'
import { MobileNavigationDrawer } from '../Helpers/Navbar/MobileDrawer'
import { useStyles } from './useStyles'
import SearchBar from 'components/Helpers/SearchBar/searchBar'
import { SearchPageHeader } from 'components/Pages/SearchResults'

export function BaseLayout({ loading, children }) {
  const [isNavSmall, setIsNavSmall] = useState(false)
  const classes = useStyles()
  const handleNavSizeChange = useCallback(({ isSmall }) => {
    setIsNavSmall(isSmall)
  }, [])

  const context = useNavContext()

  const navbarRef = useRef()
  useEffect(() => {
    context.navbarRef.current = navbarRef.current
  }, [navbarRef, context])

  const pathame = window.location.pathname
  const [isSearchBarEnabled, setSearchBarEnabled] = useState(false)
  const [paddingBottom, setPaddingBottom] = useState(0)
  const { downSm: isMobileDevice } = useMediaQueries()
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    if (!isMobileDevice && isAuthenticated) {
      switch (pathame) {
        case '/search':
          setSearchBarEnabled(false)
          setPaddingBottom(0)
          break
        default:
          setSearchBarEnabled(false)
          break
      }
    } else {
      setSearchBarEnabled(false)
    }
  }, [pathame, isAuthenticated, isMobileDevice])

  if (loading) return null
  return (
    <>
      <div className={classes.root}>
        {loading ? null : (
          <div
            className={clsx(classes.navbarWrapper, { small: isNavSmall })}
            ref={navbarRef}
          >
            <div className={classes.navbarBg}></div>
            <Navbar history={history} onNavSizeChange={handleNavSizeChange} />
          </div>
        )}
        {isSearchBarEnabled && (
          <Box
            className={clsx(classes.animatedSearchBar, {
              [classes.animatedSearchBarExit]: isSearchBarEnabled
            })}
          >
            <Box
              className={classes.searchbarContainer}
              style={{
                paddingBottom
              }}
            >
              <div className={classes.searchBarBox}>
                <SearchBar
                  size="xs"
                  hideSearchButton
                  showSearchIcon
                  placeholder={'Search e.g cyber risk analytics'}
                />

                <SearchPageHeader />
              </div>
            </Box>
          </Box>
        )}
        {children}
      </div>
      <Footer />
    </>
  )
}

const BaseLayoutBodyContext = React.createContext({})
export const useBaseLayoutBodyContext = () => useContext(BaseLayoutBodyContext)

const CloseButton = props => {
  const classes = useStyles()
  return (
    <IconButton className={classes.closeButton} {...props}>
      <Icon>cancel</Icon>
    </IconButton>
  )
}
export const BaseLayoutBody = ({
  pageHeaderBottomLine,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  style,
  pageHeader,
  leftPanel,
  rightPanel,
  enableLeftSideNav = false,
  bottomBanner = true,
  padding = 'default',
  backgroundHeader,
  backgroundRightPanel,
  leftPanelPadding,
  cancelButton = true,
  leftPanelBorder = false,
  headerStyle = null,
  borderRadiusOnFade = false,
  parentBodyBg = true
}) => {
  const {
    drawerOpen,
    isManageCollectionOpen,
    toggleDrawer,
    landingNavButtons,
    setDemoFormOpen,
    setCurrId,
    setNavHeight
  } = useNavContext()
  const {
    freeUserFilters,
    sendFreeUserFilters,
    isFreeUser,
    isApplyFiltersBtnVisible,
    setFreeUserSelectedFilters,
    selectedFilters
  } = useFilterStateContext()
  const { isAuthenticated } = useAuth0()
  const { setAllCompaniesPage } = useSearchPaginationContext()
  const headerRef = useRef()

  useEffect(() => {
    setNavHeight(headerRef.current.offsetHeight)
  }, [headerRef, setNavHeight])

  const isProductPage = history.location.pathname.includes('product')
  const { downSm } = useMediaQueries()
  const classes = useStyles({
    isMobileDevice: downSm,
    pageHeaderBottomLine,
    borderBottomLeftRadius,
    isManageCollectionOpen,
    padding,
    backgroundHeader,
    isProductPage,
    backgroundRightPanel,
    borderBottomRightRadius,
    leftPanelPadding,
    leftPanelBorder,
    parentBodyBg
  })
  const [leftbarOpen, setLeftbarOpen] = useState(false)

  const isSearchPage = window.location.pathname.includes('search')

  const closeLeftBar = useCallback(() => {
    setLeftbarOpen(false)
  }, [])

  const { navbarRef } = useNavContext()

  const calculateTopPosition = useCallback(() => {
    if (!headerRef || !headerRef.current || !navbarRef || !navbarRef.current) {
      return
    }
    const navbarHeight = navbarRef.current.offsetHeight
    const extraHeight = headerRef.current.offsetHeight
    const topPosition = (extraHeight - navbarHeight) * -1
    headerRef.current.style.top = topPosition + 'px'
  }, [headerRef, navbarRef])

  const fadeOutOnScroll = useCallback(() => {
    if (!headerRef || !headerRef.current || !navbarRef || !navbarRef.current) {
      return
    }
    const elementHeight =
      headerRef.current.offsetHeight - navbarRef.current.offsetHeight - 20 // Substract additional 20px to finish fadeout faster
    const scrollTop = document.querySelector('#root').scrollTop
    const ratio = Math.min(1, (elementHeight - scrollTop) / elementHeight)

    let opacity = 1
    if (scrollTop > 0) {
      opacity = ratio
      if (downSm && ratio >= 0.5) {
        opacity = 1
      }
    }

    headerRef.current.children.item(0).style.opacity = opacity
    if (ratio <= 0) {
      navbarRef.current.children.item(0).classList.add('opaque')
    } else {
      navbarRef.current.children.item(0).classList.remove('opaque')
    }
    if (borderRadiusOnFade) {
      const radiusRatio = Math.min(1, ratio > 0 ? 1 - ratio : 1)
      headerRef.current.children.item(0).style[
        'border-bottom-left-radius'
      ] = `${50 * radiusRatio}px`
      headerRef.current.style['border-bottom-left-radius'] = `${
        50 * radiusRatio
      }px`
      headerRef.current.style.overflow = 'hidden'
    }
  }, [headerRef, navbarRef, borderRadiusOnFade, downSm])

  useEffect(() => {
    calculateTopPosition()
    fadeOutOnScroll()
    document.querySelector('#root').addEventListener('scroll', fadeOutOnScroll)
    document
      .querySelector('#root')
      .addEventListener('DOMContentLoaded', calculateTopPosition)
    return () => {
      document
        .querySelector('#root')
        .removeEventListener('scroll', fadeOutOnScroll)
      document
        .querySelector('#root')
        .removeEventListener('DOMContentLoaded', calculateTopPosition)
    }
  }, [calculateTopPosition, fadeOutOnScroll])

  useEffect(() => {
    calculateTopPosition()
    const observer = new ResizeObserver(calculateTopPosition)
    const element = headerRef.current

    observer.observe(element)
    return () => {
      observer.unobserve(element)
      observer.disconnect()
    }
  }, [headerRef, calculateTopPosition])

  return (
    <BaseLayoutBodyContext.Provider
      value={{
        leftbarOpen,
        setLeftbarOpen,
        navbarRef,
        headerRef
      }}
    >
      {downSm && isFreeUser && isSearchPage && (
        <Box
          style={{
            position: 'sticky',
            zIndex: 99,
            bottom: 0,
            textAlign: 'center',
            width: '100%',
            background:
              'linear-gradient(130.97deg, rgb(6, 35, 61) 46.13%, rgb(28, 86, 119) 75.17%)'
          }}
        >
          {isFreeUser && isApplyFiltersBtnVisible && (
            <Box
              style={{
                padding: '25px 0 10px'
              }}
            >
              <OxbowPrimaryButton
                onClick={() => {
                  sendFreeUserFilters(freeUserFilters)
                  setAllCompaniesPage(1)
                  setFreeUserSelectedFilters(selectedFilters)
                }}
                width="md"
                fontSize="md"
              >
                Apply filters
              </OxbowPrimaryButton>
            </Box>
          )}
          <FreeUserStickyNotes />
        </Box>
      )}
      <div
        className={clsx(classes.parentBody, {
          [classes.contentShift]: drawerOpen,
          [classes.defaultGreyBg]: parentBodyBg
        })}
      >
        <div
          className={classes.pageHeader}
          style={pageHeader?.props}
          ref={headerRef}
        >
          <div
            className={classes.pageHeaderExtra}
            style={headerStyle ? headerStyle : {}}
          >
            {!!pageHeader && pageHeader}
          </div>
        </div>
        <div
          className={classes.body}
          style={{
            ...style
          }}
        >
          {leftPanel && (
            <div
              className={clsx(classes.leftPanel, {
                leftSideNav: enableLeftSideNav && downSm
              })}
            >
              {leftPanel}
              {cancelButton && enableLeftSideNav && downSm && (
                <CloseButton onClick={closeLeftBar} />
              )}
            </div>
          )}
          <div
            className={clsx(classes.rightPanel, {
              slideRight: downSm && leftbarOpen
            })}
          >
            {rightPanel}

            <Box>
              {bottomBanner && isProductPage && (
                <ProductBottomBanner
                  btnText="Tell us what you are looking for"
                  title="Let our team of researchers do the work for you"
                  subTitle="Need more help finding the right vendor?"
                  btnWidth="max-content"
                  marginBottom={false}
                  divider={true}
                />
              )}
            </Box>
          </div>
        </div>
      </div>
      {downSm && isAuthenticated ? (
        <MobileNavigationDrawer />
      ) : (
        <LandingMobileDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          landingNavButtons={landingNavButtons}
          setDemoFormOpen={setDemoFormOpen}
          setCurrId={setCurrId}
        />
      )}
    </BaseLayoutBodyContext.Provider>
  )
}

export default BaseLayout
