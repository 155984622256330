import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  productTileRoot: {
    alignItems: 'inherit',
    fontFamily: theme.typography.secondaryFont,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  imageWrapper: {
    marginRight: 10,
    '& img': {
      width: 70,
      height: 70,
      verticalAlign: 'middle',
      objectFit: 'contain',
      padding: '0 5px',
      border: '1px solid #C8C8C8',
      borderRadius: '50%'
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  chipsWrapper: {
    lineHeight: 1,
    fontSize: '0.8rem',
    display: 'flex',
    '& .chip': {
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(1, 1.5),
      borderRadius: '20px',
      height: '28px',
      fontSize: '13px',
      fontWeight: 400,
      alignItems: 'center',
      display: 'flex'
    },
    '&:last-child': {
      marginBottom: '4px'
    }
  },
  companyName: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 400
  },
  productName: {
    fontFamily: theme.typography.secondaryFont,
    marginBottom: '10px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 1,
    cursor: 'pointer',
    color: '#2193D3'
  },
  isCompanyActive: {
    marginBottom: '10px',
    height: 25,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    color: 'white',
    backgroundColor: 'red',
    padding: '4px 15px',
    borderRadius: '20px',
    marginLeft: theme.spacing(1)
  },
  clipTextY: {
    fontWeight: 400,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  }
}))
