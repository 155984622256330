import React, { useEffect, useMemo, useState } from 'react'
import { Typography, CircularProgress } from '@material-ui/core'
import TableComponent from './TableComponent'
import { useHomePageContext } from 'components/Contexts/HomePageContext'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'

const InterestedInCompanies = () => {
  const { searchHomePageResults } = useHomePageContext()
  const { customisationJourneyData } = useCustomisationJourney()
  const [loading, setLoading] = useState(false)

  const objToArr = obj => {
    return Object.keys(obj).map(key => obj[key])
  }

  const data = useMemo(() => {
    const resultsToDisplay = (insuranceProductDistributors, results) => {
      if (!insuranceProductDistributors) {
        return Object.values(results).filter(
          i => !i.companyType.includes('Distributor')
        )
      } else {
        return objToArr(results)
      }
    }

    let interestedInCompanies = []
    if (Object.keys(customisationJourneyData).length > 0) {
      const personalData = resultsToDisplay(
        customisationJourneyData.insuranceProductDistributors,
        searchHomePageResults
      )
      interestedInCompanies = [...personalData]
    }

    return interestedInCompanies
  }, [customisationJourneyData, searchHomePageResults])

  useEffect(() => {
    if (data.length === 0) {
      setLoading(true)
    } else setLoading(false)
  }, [data])

  return (
    <>
      <Typography variant="h3">Trending companies</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableComponent data={data} favourites={false} />
      )}
    </>
  )
}

export default InterestedInCompanies
