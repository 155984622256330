/* eslint-disable no-use-before-define */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useAutocomplete from '@material-ui/lab/useAutocomplete'
import CheckIcon from '@material-ui/icons/Check'

import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { CanV2 } from '../../../../rbac/can'
import Chip from 'components/Helpers/Inputs/ChipInput/Chips'

import clsx from 'clsx'
import { Tag } from './Tag'
import { useStyles } from './useStyles'

import SearchIcon from '@material-ui/icons/Search'
import { findValueByFirstLetter } from 'utils/sortingFunctions'
import { capitalize } from 'utils'
import { OxbowTextButton } from 'components/Helpers/Buttons'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import CountriesChipInput from './CountriesChipInput'

const processComplexFilterValue = (values, childrenFilterNames) => {
  const result = childrenFilterNames.reduce((acc, filterName) => {
    acc[filterName] = []
    return acc
  }, {})
  values.forEach(({ label, filterNames }) => {
    filterNames.forEach(filterName => {
      result[filterName].push(label)
    })
  })
  return result
}

const handleChangeInParentFilterValue = (
  values,
  childrenFilterNames,
  reason,
  onChange
) =>
  Object.entries(
    processComplexFilterValue(values, childrenFilterNames)
  ).forEach(([filterName, filterValue]) => {
    onChange(filterName, filterValue)
  })

const handleChangeInBaseFilterValue = (name, selectedOptions, onChange) =>
  onChange(
    name,
    selectedOptions.map(option => option.value)
  )

export function ChipInput({
  label,
  inputPlaceholder,
  options,
  onChange,
  value = [],
  name,
  isParentFilter = false,
  childrenFilterNames = null,
  inputBorder = false,
  clearBtn,
  clearOtherFilters
}) {
  const { selectedFilters } = useFilterStateContext()
  const classes = useStyles({ inputBorder })
  const [inputValue, setInputValue] = useState('')
  const [open, toggleOpen] = useState(false)

  const filteredOptionData = options?.sort((a, b) =>
    a?.value?.localeCompare(b?.value)
  )
  const sortedInsuranceClientTypeByOrder = options.sort(
    (a, b) => a.order - b.order
  )
  const wrapperRef = useRef(null)
  const isClearBtnVisible = useMemo(() => {
    return Object.keys(selectedFilters).includes(name)
  }, [selectedFilters, name])
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        open &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        toggleOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [toggleOpen, wrapperRef, open])

  const filteredResults = findValueByFirstLetter(filteredOptionData, inputValue)

  const isInsuranceClientType =
    label === 'Insurance Client Type'
      ? sortedInsuranceClientTypeByOrder
      : filteredResults
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    value: chips,
    focused,
    groupedOptions,
    setAnchorEl
  } = useAutocomplete({
    id: `${name}-chip-input`,
    multiple: true,
    open: open,
    options: isInsuranceClientType,
    value: value?.map(a => options?.find(option => option?.value === a)),
    getOptionLabel: option => option.label,
    onOpen: () => toggleOpen(true),
    onChange: (_, values, reason) => {
      if (reason === 'select-option') setInputValue('')
      if (isParentFilter)
        handleChangeInParentFilterValue(
          values,
          childrenFilterNames,
          reason,
          onChange
        )
      else {
        handleChangeInBaseFilterValue(name, values, onChange)
      }
    },
    onInputChange: (_, value, reason) => {
      if (reason === 'input') setInputValue(value)
    },
    inputValue
  })
  const [lengthToShow, setLengthToShow] = useState(5)

  return (
    <div>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography className={classes.filtersTitle} variant="h4">
          {capitalize(label.toLowerCase())}
        </Typography>
        {clearBtn && isClearBtnVisible && (
          <Grid item>
            <OxbowTextButton
              onClick={() => clearOtherFilters(name)}
              size="large"
              classes={{
                root: classes.clearBtnRoot
              }}
            >
              <Typography variant="h5">Clear</Typography>
            </OxbowTextButton>
          </Grid>
        )}
      </Box>
      <div
        style={{
          marginTop: '10px'
        }}
      >
        {label === 'Countries of Implementations' && <CountriesChipInput />}
      </div>

      {label !== 'Countries of Implementations' && (
        <div {...getRootProps()}>
          <div
            ref={setAnchorEl}
            style={{
              display:
                label === 'Target Client' ||
                label === 'Show companies targeting' ||
                label === 'Insurance Class' ||
                label === 'Insurance Client Type' ||
                label === 'Company Stage' ||
                label === 'London Market Relevance'
                  ? 'none'
                  : 'flex'
            }}
            className={clsx(classes.inputContainer, { focused })}
          >
            <input
              {...getInputProps()}
              placeholder={
                chips.length === 0
                  ? capitalize(inputPlaceholder.toLowerCase())
                  : ''
              }
              autoComplete={'no'}
            />
            <SearchIcon fontSize="small" />
          </div>

          <>
            {label === 'Related Technology' ||
            // label === 'Countries of Implementations' ||
            label === 'Headquarters' ||
            label === 'Offices' ? (
              <>
                {groupedOptions.length > 0 && (
                  <CanV2>
                    {() => {
                      return (
                        <div ref={wrapperRef}>
                          <ul
                            style={{ border: '1px solid #ccc' }}
                            open={open}
                            {...getListboxProps()}
                            className={`${classes.listBox} ${classes.hidden}`}
                            data-tip={`${name}-chip-input`}
                            data-for={`${name}-chip-input`}
                          >
                            {groupedOptions.map((option, index) => {
                              const itemProps = getOptionProps({
                                option,
                                index
                              })

                              return (
                                <li
                                  {...itemProps}
                                  key={option.label}
                                  id={index}
                                >
                                  <CheckIcon />

                                  <span className="labelContainer">
                                    {option.label}
                                  </span>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    }}
                  </CanV2>
                )}
              </>
            ) : (
              <>
                {options.length > 0 && (
                  <Box
                    onMouseEnter={() => toggleOpen(true)}
                    onMouseLeave={() => toggleOpen(false)}
                  >
                    <ul
                      open={open}
                      {...getListboxProps()}
                      className={classes.listBox}
                      data-tip={`${name}-chip-input`}
                      data-for={`${name}-chip-input`}
                    >
                      {options.map((option, index) => {
                        if (option.label.split(' ').length > 1) {
                          option.label = capitalize(option.label.toLowerCase())
                        }
                        const itemProps = getOptionProps({ option, index })
                        return (
                          <li {...itemProps} key={option.label} id={index}>
                            <input
                              style={{
                                color: 'transparent',
                                position: 'absolute',
                                zIndex: '-1',
                                border: 'none'
                              }}
                              name={option.value}
                              type="checkbox"
                              value={option.value}
                              key={option.label}
                            />

                            <CheckIcon />

                            <span className="labelContainer">
                              {option.label}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </Box>
                )}
              </>
            )}
          </>
        </div>
      )}
      {label !== 'Countries of Implementations' && (
        <>
          {label === 'Show companies targeting' ||
          label === 'Target Client' ||
          label === 'Insurance Class' ||
          label === 'Insurance Client Type' ||
          label === 'Company Stage' ||
          label === 'London Market Relevance' ? null : (
            <div className={classes.chipsContainer}>
              {chips.map((option, index) => {
                if (index + 1 > Math.min(chips.length, lengthToShow)) {
                  return null
                }
                return (
                  <Tag
                    label={option?.label}
                    key={option?.label}
                    {...getTagProps({ index })}
                  />
                )
              })}
              <>
                {chips?.length - lengthToShow > 0 && (
                  <div className={classes.btn}>
                    <Chip
                      onClick={() => {
                        setLengthToShow(chips?.length)
                      }}
                      className={classes.chip}
                      color="transparent"
                      label={`See ${chips?.length - lengthToShow} more`}
                    />
                  </div>
                )}
                {chips?.length !== 5 && chips?.length - lengthToShow === 0 && (
                  <div className={classes.btn}>
                    <Chip
                      color="transparent"
                      onClick={() => {
                        setLengthToShow(5)
                      }}
                      className={classes.chip}
                      label={`Hide ${chips.length - 5} less`}
                    />
                  </div>
                )}
              </>
            </div>
          )}
        </>
      )}
      {label === 'Countries of Implementations' && (
        <Divider className={classes.filtersMenuDivider} />
      )}
    </div>
  )
}

export const MemoizedChipInput = React.memo(ChipInput)
