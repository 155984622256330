import React from 'react'
import { useStyles } from './useStyles'
import { Grid, Modal, Box, Typography } from '@material-ui/core'
import cross from 'assets/icons/cross.svg'
import DemoForm from './DemoForm'

export const ModalRequestHeader = ({
  headerTitle = '',
  headerTypography = '',
  setDemoFormOpen,
  children
}) => {
  const classes = useStyles()
  return (
    <>
      <Box textAlign="center">
        {children ? (
          <img
            src={cross}
            alt="cross"
            className={classes.cross}
            onClick={() => setDemoFormOpen(false)}
          />
        ) : (
          <Typography variant="h1">
            Navigate the insurance technology landscape with Magellan
          </Typography>
        )}

        <Typography variant="subtitle1" className={classes.requestTitle}>
          {headerTitle ? headerTitle : 'Request a demo'}
        </Typography>
        {headerTypography ? (
          <Typography variant="subtitle2">{headerTypography}</Typography>
        ) : null}
      </Box>
      {children ? children : null}
    </>
  )
}

const LandingModal = ({
  emailInputWidth,
  checkBoxContainerPosition,
  submitBtnText,
  modalData,
  userName,
  userEmail,
  userNickName,
  headerTitle,
  headerTypography,
  setDemoFormOpen,
  isThxModalOpen,
  setThxModalOpen,
  isDemoFormOpen,
  btnWidth,
  isFreeUser,
  submissionType,
  setMoreSearchesThxOpen,
  modalWindowWidth = 'unset',
  modalWindowPadding,
  setThxModal
}) => {
  const classes = useStyles({
    modalWindowWidth,
    modalWindowPadding
  })

  return (
    <div className={classes.demoFormContainer}>
      <Modal
        className={classes.modalMainContainer}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isDemoFormOpen || isThxModalOpen}
        onClose={() => {
          setDemoFormOpen(false)
          setThxModalOpen(false)
        }}
        closeAfterTransition
        BackdropProps={{
          timeout: 500
        }}
      >
        <div className={classes.modalContainer}>
          {isThxModalOpen ? (
            <Box textAlign="center">
              <img
                src={cross}
                alt="cross"
                className={classes.cross}
                onClick={() => {
                  setThxModalOpen(false)
                  setDemoFormOpen(false)
                }}
              />
              <Grid>
                <Typography
                  variant="subtitle1"
                  className={classes.requestTitle}
                >
                  Thank you
                </Typography>
                <Typography variant="subtitle2">
                  We appreciate you contacting us and will get to you soon
                </Typography>
              </Grid>
            </Box>
          ) : (
            <ModalRequestHeader
              headerTitle={headerTitle}
              headerTypography={headerTypography}
              setDemoFormOpen={setDemoFormOpen}
            >
              <DemoForm
                emailInputWidth={emailInputWidth}
                checkBoxContainerPosition={checkBoxContainerPosition}
                submitBtnText={submitBtnText}
                modalData={modalData}
                userName={userName}
                userEmail={userEmail}
                userNickName={userNickName}
                setThxModalOpen={setThxModalOpen}
                setDemoFormOpen={setDemoFormOpen}
                btnWidth={btnWidth}
                isFreeUser={isFreeUser}
                submissionType={submissionType}
                setMoreSearchesThxOpen={setMoreSearchesThxOpen}
                setThxModal={setThxModal}
              />
            </ModalRequestHeader>
          )}
        </div>
      </Modal>
    </div>
  )
}
export default LandingModal
