import { makeStyles } from '@material-ui/core'
import { OxbowDarkButton } from 'components/Helpers/Buttons'
import React from 'react'
import ReactCookieConsent from 'react-cookie-consent'
const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans'
  },
  button: {
    background: ` ${theme.palette.secondary.main} !important`,
    borderRadius: '0 6px 0 6px !important',
    margin: `${theme.spacing(1)}px !important`,
    color: `${theme.palette.primary.contrastText} !important`
  }
}))
export function CookieConsent() {
  const classes = useStyles()
  return (
    <ReactCookieConsent
      location={window.innerWidth < 900 ? 'bottom' : 'top'}
      buttonText="Accept"
      containerClasses={classes.root}
      style={{
        zIndex: 1000000
      }}
      buttonClasses={classes.button}
      buttonComponent={OxbowDarkButton}
    >
      Our website uses cookies. Read more about the individual cookies we use
      and how to recognise them.{' '}
      <a
        href="https://oxbowpartners.com/cookies/"
        style={{ color: '#f4f4f4' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Find out more
      </a>
    </ReactCookieConsent>
  )
}

export default CookieConsent
