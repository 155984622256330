import React, { useMemo, useEffect, useState, useRef } from 'react'
import { Grid, Typography, Divider } from '@material-ui/core'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import staticFilterConfig from '../../../../configs/filters.config'
import { capitalize, converyObjectToArray } from 'utils'
// import { getClearFilterSectionState } from 'utils/FilterBootstrapUtils'
import { useStyles } from './useStyles'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import SelectComponent from 'components/Helpers/SelectComponent/SelectComponent'
import { useQueryStringValues } from 'components/Contexts/QueryStringContext'
import { OxbowPrimaryTransparentButton } from 'components/Helpers/Buttons'
import { saveNewSearch } from 'utils/api'
import clsx from 'clsx'
import { useOutsideClick } from 'utils/customHooks/useOutsideClickRef'
import { useSearchContext } from 'components/Contexts/SearchContext'
import { MagellanTooltip } from 'components/Helpers/MagellanTooltip'

export const getSelectedFilters = (rawFilterValues, filterDetails) => {
  let _selectedFilters = {}
  for (let filterName in rawFilterValues) {
    try {
      const _filterConfig = filterDetails[filterName]
      const _filterValue = rawFilterValues[filterName]

      // for Arrays
      if (Array.isArray(_filterValue)) {
        if (_filterConfig && _filterValue.length > 0) {
          if (_filterConfig.isChild) {
            const parentName = _filterConfig.parentName
            if (!_selectedFilters[parentName])
              _selectedFilters[parentName] = {
                title: filterDetails[parentName].title,
                values: _filterValue,
                field: parentName
              }
            else
              _selectedFilters[parentName].values = [
                ...new Set([
                  ..._selectedFilters[parentName].values,
                  ..._filterValue
                ])
              ]
          } else {
            _selectedFilters[filterName] = {
              title: _filterConfig.title,
              values: _filterConfig.optionMapping
                ? _filterValue.map(
                    option => _filterConfig.optionMapping[option].label
                  )
                : _filterValue,
              field: filterName
            }
          }
        }
      }
      // for Objects
      else {
        if (_filterConfig) {
          const tf = []
          Object.entries(_filterValue).forEach(([kk, vv]) => {
            if (vv) {
              const valueLabel = _filterConfig.options.find(
                el => el.name === kk
              )?.label
              tf.push(valueLabel)
            }
          })
          if (tf.length > 0) {
            _selectedFilters[filterName] = {
              title: _filterConfig.title,
              values: tf,
              field: filterName,
              rawValues: _filterValue
            }
          }
        }
      }
    } catch (error) {
      /*eslint-disable */
      console.log(error)
    }
  }

  return _selectedFilters
}

export const Pagination = ({ resultsLength, searchTerm, rawFilterValues }) => {
  const classes = useStyles()
  const {
    // setFilterValues,
    setSelectedFilters,
    // filterConfig,
    setSavedSearchModalOpen,
    filterValues,
    setReloadSavedSearches,
    setApplyFiltersBtnVisible,
    freeUserSaveThisSearchBtn,
    setFreeUserSaveThisSearchBtn,
    isFreeUser,
    freeUserFilters,
    freeUserSelectedFilters,
    setFreeUserSelectedFilters,
    isFreeUserFiltersChanged,
    setIsFreeUserFiltersChanged,
    suggestInputsFiltersData,
    isFilterSelected
  } = useFilterStateContext()
  const { downXs } = useMediaQueries()
  const {
    setExcludeIds,
    freeUserSearchLimits,
    isSavedSearchApplyed,
    setSavedSearchApply,
    isSearchEnabled
  } = useSearchContext()
  const { sortedValue, setSortedValue, sortingOptions } = useQueryStringValues()
  // const { setAllCompaniesPage } = useSearchPaginationContext()
  const savedSearch = 'Saved search'
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(true)
  const [isSavedSuccessfully, setSavedSuccessfully] = useState(false)
  const [selectedFilterValue, setSelectedFilterValue] = useState('')
  const [saveThisSearchTitle, setSaveThisSearchTitle] = useState(
    'Save this search'
  )
  const filterDetails = useMemo(
    () => ({
      ...suggestInputsFiltersData,
      ...staticFilterConfig.categoryFilters.filters
    }),
    [suggestInputsFiltersData, staticFilterConfig]
  )
  const selectStyles = {
    width: '280px '
  }
  const selectedFilters = useMemo(() => {
    return getSelectedFilters(rawFilterValues, filterDetails)
  }, [filterDetails, rawFilterValues])
  useEffect(() => {
    if (!isFreeUser) {
      setFreeUserSelectedFilters(selectedFilters)
    }
    setSelectedFilters(selectedFilters)
  }, [selectedFilters, setSelectedFilters, isFreeUser])

  const selectedFiltersLength = useMemo(() => {
    if (!isFreeUser) {
      return Object.entries(selectedFilters).length
    } else {
      return Object.values(freeUserSelectedFilters).reduce((acc, el) => {
        if (el.values) {
          return acc + el.values.length
        } else {
          return acc + el.length
        }
      }, 0)
      // return Object.keys(freeUserSelectedFilters).length + freeUserSelectedFilters?.geographiesWithImplementations?.length
    }
  }, [selectedFilters, freeUserSelectedFilters, isFreeUser])

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, setSavedSuccessfully)

  const generetedName = useMemo(() => {
    const dateObj = new Date()
    const date = dateObj
      .toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/['/']/g, '')
    const minutes = dateObj.getMinutes()
    const hour = dateObj.getHours()

    let acronym = ''

    if (acronym === '' && selectedFilters) {
      Object.entries(selectedFilters).map(([filterName, el]) => {
        if (el.title && filterName) {
          const getFirstLetters = el.title
            .split(/\s/)
            .reduce((res, word) => (res += word.slice(0, 1)), '')
            .toUpperCase()
          const selectedFilter = el.values[0]
          setSelectedFilterValue(selectedFilter)
          const filterValue = selectedFilter?.replace(/[' ']/g, '_')
          const createdNewName = `${getFirstLetters}_${filterValue}`
          acronym = createdNewName
        }
      })
    }

    return `${date}_${hour}${minutes}_${searchTerm ? searchTerm : acronym}`
  }, [selectedFilters])
  const { key: searchKey, ...filters } = isFreeUser
    ? freeUserFilters
    : filterValues

  if (filters.category) {
    filters.companyType = converyObjectToArray(filters.category).map(capitalize)
  } else {
    filters.companyType = []
  }

  const saveThisSearchHandler = async () => {
    const dataValues = {
      name: generetedName,
      filters,
      search_query: searchTerm
    }

    try {
      const { data } = await saveNewSearch(dataValues)
      if (data) {
        setSaveThisSearchTitle(savedSearch)
        setFreeUserSaveThisSearchBtn(true)
        setDisabledBtn(true)
        setSavedSuccessfully(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSave = () => {
    setSavedSuccessfully(false)
    setSavedSearchModalOpen(true)
    setReloadSavedSearches(true)
  }

  useEffect(() => {
    if (isFreeUser && isFreeUserFiltersChanged) {
      setFreeUserSelectedFilters(selectedFilters)
      setIsFreeUserFiltersChanged(false)
      setApplyFiltersBtnVisible(true)
    }
  }, [isFreeUserFiltersChanged, selectedFilters, isFreeUser])

  useEffect(() => {
    if (
      searchTerm === '' &&
      selectedFiltersLength === 0 &&
      !isFreeUserFiltersChanged
    ) {
      setApplyFiltersBtnVisible(false)
      setDisabledBtn(true)
    } else {
      setDisabledBtn(false)
      setSavedSearchApply(false)
    }
  }, [
    searchTerm,
    selectedFiltersLength,
    isFreeUserFiltersChanged,
    setSavedSearchApply
  ])

  useEffect(() => {
    if (isSavedSearchApplyed) {
      setDisabledBtn(true)
      setFreeUserSaveThisSearchBtn(true)
      setSaveThisSearchTitle(savedSearch)
    }
  }, [isSavedSearchApplyed])

  useEffect(() => {
    if (downXs && selectedFiltersLength) {
      setTooltipOpen(true)
    }
  }, [selectedFiltersLength, downXs])

  const getTextWidth = text => {
    const canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')

    context.font = '16px Arial'
    const metrics = context.measureText(text)

    return metrics.width
  }
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid container className={classes.searchResultSection}>
        <Grid className="collection-header">
          <Grid container className="collection-header__main-box">
            {isFilterSelected && isSearchEnabled ? (
              <>
                <Typography variant="h4" className={classes.paginationTitle}>
                  {resultsLength} Result{resultsLength !== 1 && 's'}
                </Typography>
                <div
                  style={{ position: 'relative' }}
                  onMouseEnter={() =>
                    setTooltipOpen(
                      searchTerm || selectedFiltersLength ? true : false
                    )
                  }
                  onMouseLeave={() => setTooltipOpen(false)}
                >
                  {isFreeUser ? (
                    <OxbowPrimaryTransparentButton
                      fontSize="sm"
                      className={clsx(classes.saveSearchBtn, {
                        [classes.saveSearchBtnDisabled]: freeUserSearchLimits
                          ? freeUserSaveThisSearchBtn
                          : true
                      })}
                      onClick={() => saveThisSearchHandler()}
                    >
                      {saveThisSearchTitle}
                    </OxbowPrimaryTransparentButton>
                  ) : (
                    <OxbowPrimaryTransparentButton
                      fontSize="sm"
                      className={clsx(classes.saveSearchBtn, {
                        [classes.saveSearchBtnDisabled]: disabledBtn
                      })}
                      onClick={() => saveThisSearchHandler()}
                    >
                      {saveThisSearchTitle}
                    </OxbowPrimaryTransparentButton>
                  )}

                  {isTooltipOpen && !disabledBtn ? (
                    <MagellanTooltip
                      left={true}
                      right={false}
                      trianglePosition={downXs ? 'right' : 'left'}
                      padding="default"
                      top={downXs ? 'xs' : 'default'}
                      onClick={() => setTooltipOpen(false)}
                    >
                      <Typography
                        style={{
                          fontWeight: 400
                        }}
                        variant="h6"
                      >
                        <b>Want to revisit this search?</b>{' '}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 400
                        }}
                        variant="h6"
                      >
                        Save{' '}
                        <b>{searchTerm ? searchTerm : selectedFilterValue}</b>{' '}
                        for quick access to these results
                      </Typography>
                    </MagellanTooltip>
                  ) : (
                    ''
                  )}

                  {isSavedSuccessfully ? (
                    <MagellanTooltip
                      left={true}
                      right={false}
                      trianglePosition={downXs ? 'right' : 'left'}
                      padding="default"
                      top={downXs ? 'xs' : 'default'}
                      wrapperRef={wrapperRef}
                    >
                      <Typography
                        style={{
                          fontWeight: 400
                        }}
                        variant="h6"
                      >
                        Search saved in your{' '}
                        <span
                          style={{ color: '#0E90D9', cursor: 'pointer' }}
                          onClick={() => handleSave()}
                        >
                          Saved searches list.
                        </span>
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 400
                        }}
                        variant="h6"
                      >
                        Now you have a quick access to these results
                      </Typography>
                    </MagellanTooltip>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 600,
                  opacity: 0.5
                }}
              >
                Search Results
              </Typography>
            )}
          </Grid>
          <Grid
            className="collection-header__sorting-container"
            style={{ opacity: resultsLength <= 1 && '0.5' }}
          >
            <SelectComponent
              isDisabled={
                !isFilterSelected ||
                (isFreeUser && freeUserSearchLimits === 0) ||
                resultsLength <= 1
                  ? true
                  : false
              }
              mobileSelectStyle={downXs ? 'none' : 'default'}
              marginTop="none"
              spanStyle={{ right: 20, left: 'unset' }}
              styles={
                (selectStyles, { width: getTextWidth(sortedValue) + 100 })
              }
              isFromSearchPage={downXs ? 'none' : true}
              inputWidth="none"
              border="none"
              title=" "
              selectoptions={sortingOptions}
              selectedValue={sortedValue}
              setSelectedValue={setSortedValue}
              positionRight="default"
            />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 10 }} />
      </Grid>
    </Grid>
  )
}
