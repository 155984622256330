import React, { useState } from 'react'
import { useTree } from './useAnimationTree'
import { ReactComponent as OPlogo } from 'assets/Logos/ContentLogos/OPlogo.svg'
import { makeStyles, Box, Grid, Divider, Typography } from '@material-ui/core'
import moment from 'moment'

import { animated } from 'react-spring'
import { useNavContext } from 'components/Contexts/NavbarContext'
import { useAuth0 } from 'components/Contexts/AuthContext'
import analytics from 'utils/analytics'

export const useStyles = makeStyles(theme => ({
  headerRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    textTransform: 'uppercase',
    fontWeight: 300,
    fontSize: '25px'
  },
  newsInsightOverviewTileName: {
    color: 'black',
    marginTop: '14px',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  newsInsightOverviewTileDescription: {
    color: theme.palette.primary.light,
    marginBottom: '15px',
    marginTop: '10px',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  },
  articleType: {
    color: theme.palette.primary.grey
  },
  articleDate: {
    color: theme.palette.primary.grey
  },
  iconSvg: {
    width: 70,
    height: 70,
    verticalAlign: 'middle',
    objectFit: 'contain',
    padding: '0 5px',
    border: '1px solid #C8C8C8',
    borderRadius: '50%'
  },
  articleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  },
  newsInsightMainBlock: {
    marginBottom: '35px'
  },
  newsInsightContainer: {
    display: 'flex',
    marginBottom: '15px'
  }
}))

const getStandardDate = (datePublished, dateAccuracy) => {
  let finalDate = datePublished
  switch (dateAccuracy) {
    case 'Day':
      finalDate = moment(datePublished).format('LL')
      break
    case 'Month':
      finalDate = moment(datePublished).format('MMMM')
      break
    case 'Year':
      finalDate = moment(datePublished).format('YYYY')
      break
  }
  return finalDate
}

const renderSVG = (articleType, classes, thumbnail) => {
  let finalSvg
  switch (articleType) {
    case 'Vendor':
    case 'Industry Publication':
      finalSvg = (
        <img src={thumbnail} alt="thumbnail" className={classes.iconSvg} />
      )
      break

    default:
      finalSvg = <OPlogo />
      break
  }
  return finalSvg
}

const renderArticleType = articleType => {
  let finalName
  switch (articleType) {
    case 'Vendor':
      finalName = 'Vendor press release and news'
      break
    case 'Industry Publication':
      finalName = 'Industry press articles'
      break
    case 'Case Study':
      finalName = 'Case Study';
      break;
    default:
      finalName = 'Oxbow Partners content'
      break
  }
  return finalName
}

const NewsAndInsight = ({ config, data }) => {
  const [lengthToShow] = useState(3)

  const { setSignupOpen } = useNavContext()
  const { isAuthenticated } = useAuth0()
  const classes = useStyles()
  const [bind, { opacity, height }] = useTree()
  const thumbnail = data.company.fields.logo.fields.file.url

  const getStyledContent = config => {
    let content = config.content
    if (!config.content || !config.content.length) content = []
    content = content.sort(
      (a, b) =>
        moment(b.datePublished).format('YYYYMMDD') -
        moment(a.datePublished).format('YYYYMMDD')
    )

    return (
      <>
        {content.map((news, index) => {
          return (
            <div key={index} className={classes.newsInsightMainBlock}>
              <div
                className={classes.newsInsightContainer}
                onClick={() => {
                  analytics.createEvent({
                    category: 'ProductDetails',
                    action: 'NewsInsight link clicked',
                    label: news.link
                  })
                  isAuthenticated
                    ? window.open(news.link, '_blank')
                    : setSignupOpen(true)
                }}
              >
                <Grid item>
                  <Box
                    style={{
                      marginRight: 10
                    }}
                  >
                    {renderSVG(news.articleType, classes, thumbnail)}
                  </Box>
                </Grid>
                <div style={{ width: '80%' }}>
                  <div className={classes.articleContainer}>
                    <Typography variant="h6" className={classes.articleDate}>
                      {getStandardDate(news.datePublished, news.dateAccuracy)}
                    </Typography>
                    <Typography variant="h6" className={classes.articleType}>
                      {renderArticleType(news.articleType)}
                    </Typography>
                  </div>

                  <Typography
                    variant="h4"
                    style={{ cursor: 'pointer' }}
                    className={classes.newsInsightOverviewTileName}
                  >
                    {news.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.newsInsightOverviewTileDescription}
                  >
                    {news.summary}
                  </Typography>
                </div>
              </div>
              {index === Math.min(content.length, lengthToShow) - 1 ? null : (
                <Divider variant="fullWidth" style={{ height: '1px' }} />
              )}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div>
      <animated.div style={{ opacity, height: height, overflow: 'hidden' }}>
        <div {...bind}>{getStyledContent(config)}</div>
      </animated.div>
    </div>
  )
}

export default NewsAndInsight
