import React, { useContext, useState, useEffect, useMemo } from 'react'
import { getInitialFilterAggregations } from 'utils/api'
import { useCallback } from 'react'
import initialFilterConfig from 'configs/filters.config'
import {
  convertFilterAggsToValue,
  convertFilterValuesToConfig,
  processAggregations,
  generateParentFilterConfigFromChildConfig,
  processAggregationsForFilters,
  convertSortedFilterValuesToConfig
} from 'utils/FilterBootstrapUtils'
import { useAuth0 } from './AuthContext'
import { useQuery } from 'react-query'
import {
  initUrlParamsRedirect,
  syncFromUrl,
  trackFiltersInit,
  syncToUrl
} from 'components/Helpers/filterUrlSync'
import analytics from '../../utils/analytics'
import { reactQueryCache } from 'Providers/AppProviders'
import { compress, decompress } from 'lz-string'
import moment from 'moment'
import { useCan } from 'rbac/can'
import { useQueryStringValues } from './QueryStringContext'
/**
 *
 * @param {moment.Moment} expiryDate
 * @param {Date} currentDate
 */
const isCacheExpired = (expiryDate, currentDate) => {
  const date = moment(currentDate)
  return date.isAfter(expiryDate)
}

const allCountries = []
const convertProductValuesToConfig = (values, config, aggregations) => {
  const productSectionConfig = config['productFilters']
  const generatedFilterConfig = {}

  Object.keys(productSectionConfig.filters).forEach(filterName => {
    const filterValues = values[filterName]
    const filterConfig = productSectionConfig.filters[filterName]

    // Do not inlcude the filter in the filter config if they value for the filter in undefined
    if (!filterValues) return

    // FilterConfig for parent filters is populaed when their child filters are processed and hence we do not process parent filters directly
    if (filterConfig.disabled || filterConfig.isParent) return

    generatedFilterConfig[filterName] = convertFilterValuesToConfig(
      filterValues,
      filterConfig,
      aggregations[filterName]
    )

    // Populating the Parent filter config if the current filter is a child filter
    if (generatedFilterConfig[filterName].isChild) {
      const parentFilterName = generatedFilterConfig[filterName].parentName
      const parentFilterConfig = productSectionConfig.filters[parentFilterName]
      generatedFilterConfig[
        parentFilterName
      ] = generateParentFilterConfigFromChildConfig(
        generatedFilterConfig[filterName],
        parentFilterConfig
      )
    }
  })

  productSectionConfig.filters = generatedFilterConfig
  return productSectionConfig
}

const convertCompanyValuesToConfig = (values, config, aggregations) => {
  const companySectionConfig = config['companyFilters']
  let generatedFilterConfig = {}

  Object.keys(companySectionConfig.filters).forEach(filterName => {
    const filterValues = values[filterName]
    const filterConfig = companySectionConfig.filters[filterName]

    // Do not inlcude the filter in the filter config if they value for the filter in undefined
    if (!filterValues) return

    if (filterConfig.disabled) return
    generatedFilterConfig[filterName] = convertFilterValuesToConfig(
      filterValues,
      filterConfig,
      aggregations[filterName]
    )
  })

  // added 170+ countries to the select
  const headquatersData = generatedFilterConfig['headquaters']
  const officesData = generatedFilterConfig['offices']
  if (allCountries && allCountries.length) {
    if (headquatersData) {
      generatedFilterConfig['headquaters'] = {
        ...headquatersData,
        options: allCountries
      }
    }
    if (officesData) {
      generatedFilterConfig['offices'] = {
        ...officesData,
        options: allCountries
      }
    }
  }

  companySectionConfig.filters = generatedFilterConfig
  return companySectionConfig
}

// convert where values to config
const convertWhereValuesToConfig = (values, config, aggregations) => {
  const whereSectionConfig = config['whereFilters']
  let generatedFilterConfig = {}

  Object.keys(whereSectionConfig.filters).forEach(filterName => {
    const filterConfig = whereSectionConfig.filters[filterName]

    if (filterConfig.disabled) return;

    generatedFilterConfig[filterName] = convertSortedFilterValuesToConfig(
      filterConfig,
      aggregations[filterName],
      filterName,
    )
    if (
      generatedFilterConfig[filterName]?.name ===
        'geographiesWithImplementations' &&
      !allCountries.length
    ) {
      allCountries.push(...generatedFilterConfig[filterName]?.options)
    }
  })

  whereSectionConfig.filters = generatedFilterConfig;
  return whereSectionConfig
}

const convertWhatValuesToConfig = (values, config, aggregations) => {
  const whatSectionConfig = config['whatFilters']
  let generatedFilterConfig = {}

  Object.keys(whatSectionConfig.filters).forEach(filterName => {
    const filterValues = values[filterName]
    const filterConfig = whatSectionConfig.filters[filterName]

    if (!filterValues) return

    if (filterConfig.disabled) return
    generatedFilterConfig[filterName] = convertSortedFilterValuesToConfig(
      filterConfig,
      aggregations[filterName],
      filterName
    )
  })

  whatSectionConfig.filters = generatedFilterConfig

  return whatSectionConfig
}

const convertWhatForValuesToConfig = (values, config, aggregations) => {
  const whatForSectionConfig = config['whatForFilters']
  let generatedFilterConfig = {}

  Object.keys(whatForSectionConfig.filters).forEach(filterName => {
    const filterValues = values[filterName]
    const filterConfig = whatForSectionConfig.filters[filterName]

    if (!filterValues) return

    if (filterConfig.disabled) return

    generatedFilterConfig[filterName] = convertSortedFilterValuesToConfig(
      filterConfig,
      aggregations[filterName],
      filterName
    )
  })

  whatForSectionConfig.filters = generatedFilterConfig
  return whatForSectionConfig
}

const convertCompanyAggsToValues = aggregations => {
  const result = {}
  Object.keys(aggregations).forEach(filterName => {
    const filterConfig = initialFilterConfig.companyFilters.filters[filterName]
    if (filterConfig) {
      result[filterName] = convertFilterAggsToValue(
        aggregations[filterName],
        filterConfig
      )
    }
  })
  return result
}
const convertProductAggsToValues = aggregations => {
  const result = {}
  Object.keys(aggregations).forEach(filterName => {
    const filterConfig = initialFilterConfig.productFilters.filters[filterName]
    if (filterConfig) {
      result[filterName] = convertFilterAggsToValue(
        aggregations[filterName],
        filterConfig
      )
    }
  })
  return result
}
const convertProductAggsToWhatValues = (aggregations, key) => {
  const result = {}
  Object.keys(aggregations).forEach(() => {
    const filterConfig = initialFilterConfig.whatFilters.filters[key]
    if (filterConfig) {
      result[key] = convertFilterAggsToValue(aggregations[key], filterConfig)
    }
  })

  return result
}

const convertProductAggsToWhereValues = (aggregations, key ) => {
  const result = {}
  Object.keys(aggregations).forEach(() => {
    const filterConfig = initialFilterConfig.whatFilters.filters[key]
    if (filterConfig) {
      result[key] = convertFilterAggsToValue(aggregations[key], filterConfig)
    }
  })

  return result
}

const processCompanyAggregations = aggregations =>
  processAggregations(aggregations, initialFilterConfig.companyFilters.filters)

const processProductAggregations = aggregations =>
  processAggregations(aggregations, initialFilterConfig.productFilters.filters)

const processWhereAggregations = aggregations =>
  processAggregations(aggregations, initialFilterConfig.whereFilters.filters)

const processFilterAggregations = (aggregations, key, filterKey) =>
  processAggregationsForFilters(aggregations, key, filterKey)

const FilterStateContext = React.createContext(null)
export const useFilterStateContext = () => useContext(FilterStateContext)

export default function FilterStateContextProvider({ children }) {
  const { loginRedirectCompleted, isAuthenticated } = useAuth0()

  const isFreeUser = !useCan('use:all-filters')
  const isFullUser = useCan('content_depth:all')
  const isUnlimitedSearches = useCan('use:unlimited-searches')

  const { showInActiveCompanies } = useQueryStringValues()
  const [filterAggregationState, setFilterAggregationState] = useState({})
  const [filterValues, setFilterValues] = useState({})
  const [freeUserFilters, setFreeUserFilters] = useState({})
  const [freeUserSaveThisSearchBtn, setFreeUserSaveThisSearchBtn] = useState(
    true
  )

  const [filterConfig, setFilterConfig] = useState(initialFilterConfig)

  const [itemStates, setItemStates] = useState({})

  const [productsBootstrapData, setProductsBootstrapData] = useState({})
  const [bootstrappingFilters, setBootstrappingFilters] = useState(true)

  const [selectedFilters, setSelectedFilters] = useState({})
  const [isFreeUserFiltersChanged, setIsFreeUserFiltersChanged] = useState(
    false
  )
  const [freeUserSelectedFilters, setFreeUserSelectedFilters] = useState({})
  const [filtersFromUrlParams, setFiltersFromUrlParams] = useState()
  const [urlQPFromFilterValues, setUrlQPFromFilterValues] = useState()
  const [syncFromUrlTaskDone, setSyncFromUrlTaskDone] = useState(false)
  const [filtersInitialised, setFiltersInitialised] = useState(false)
  const [activeFilters, setActiveFilters] = useState({})
  const [defaultFilters, setDefaultFilters] = useState({})

  const [allCountriesData, setAllCountriesData] = useState({})
  const [isSavedSearchModalOpen, setSavedSearchModalOpen] = useState(false)
  const [reloadSavedSearches, setReloadSavedSearches] = useState(false)
  const [savedSearchesData, setSavedSearchesData] = useState(null)
  const [savedSearchesLoading, setSavedSearchesLoading] = useState(false)
  const [isSearchRequestModalOpen, setSearchRequestModalOpen] = useState(false)
  const [isApplyFiltersBtnVisible, setApplyFiltersBtnVisible] = useState(false)
  const [similarProductFilters, setSimilarProductFilters] = useState(false)
  const [checkedCountries, setCheckedCountries] = useState([])

  const [categories, setCategories] = useState({
    category: {
      distributor: false
    }
  })

  const [homePageFilters, setHomePageFilters] = useState({})

  const {
    isLoading: isBootstrapDataLoading,
    data: initialFilterAggregations,
    isError: errorLoadingInitialAggregations,
    isIdle: isBootstrappingIdle
  } = useQuery('filterBootstrapData', getInitialFilterAggregations, {
    Authorization: 'Baerer null',
    // enabled: isAuthenticated,
    onSuccess: data => {
      const stringifiedData = JSON.stringify(data)
      const date = moment(new Date()).add(1, 'h')
      localStorage.setItem('FILTER_BOOSTRAP_DATA_EXPIRY', date.toISOString())
      localStorage.setItem('FILTER_BOOSTRAP_DATA', compress(stringifiedData))
    }
  })

  useEffect(() => {
    const expiryDate = localStorage.getItem('FILTER_BOOSTRAP_DATA_EXPIRY')
    const parsedExpiryDate = expiryDate && moment(expiryDate)
    if (!parsedExpiryDate || isCacheExpired(parsedExpiryDate, new Date()))
      return
    const cachedData = localStorage.getItem('FILTER_BOOSTRAP_DATA')
    if (cachedData) {
      const decompressedData = JSON.parse(decompress(cachedData))
      if (decompressedData) {
        reactQueryCache.setQueryData('filterBootstrapData', decompressedData)
      }
    }
  }, [])

  const handleChangeInSearchAggregations = useCallback(
    (searchAggregations, key) => {
      if (!searchAggregations || !searchAggregations[key]) return
      let processedAggs
      if (key === 'companyAggregations') {
        processedAggs = processCompanyAggregations(searchAggregations[key])
      } else processedAggs = processProductAggregations(searchAggregations[key])

      if (searchAggregations && searchAggregations[key]) {
        if (processedAggs.geographiesWithImplementations) {
          setAllCountriesData(processedAggs.geographiesWithImplementations)
        }
        setFilterAggregationState(prev => ({
          ...prev,
          [key]: {
            ...processedAggs
          }
        }))
      }
    },
    []
  )

  const getProductDetailsFromIds = ids => {
    let finalResult = {}
    ids.forEach(id => {
      finalResult[id] = { ...productsBootstrapData[id] }
    })
    return finalResult
  }
  useEffect(() => {
    if (isBootstrapDataLoading || isBootstrappingIdle)
      return setBootstrappingFilters(true)
    if (errorLoadingInitialAggregations) return setBootstrappingFilters(false)

    setProductsBootstrapData(prevProductBootstrapData => {
      if (
        !prevProductBootstrapData ||
        !Object.values(prevProductBootstrapData).length
      ) {
        return initialFilterAggregations.productsBootstrapData
      }
      return prevProductBootstrapData
    })

    handleChangeInSearchAggregations(
      initialFilterAggregations,
      'companyAggregations'
    )
    handleChangeInSearchAggregations(
      initialFilterAggregations,
      'productAggregations'
    )

    const processedCompanyAggs = processCompanyAggregations(
      initialFilterAggregations['companyAggregations']
    )
    const processedProductAggs = processProductAggregations(
      initialFilterAggregations['productAggregations']
    )

    const processedWhereAggs = processWhereAggregations(
      initialFilterAggregations['productAggregations']
    )

    let whatFilterValues = {}
    let whatFilters = {}
    let objWhatFilters = {}
    if (filterConfig.whatFilters.filters) {
      let processedWhatAggs = {}
      Object.keys(filterConfig.whatFilters.filters).forEach(key => {
        processedWhatAggs = processFilterAggregations(
          initialFilterAggregations['productAggregations'],
          key,
          'primaryFunction'
        )
        whatFilterValues = convertProductAggsToWhatValues(
          processedWhatAggs,
          key
        )
        objWhatFilters[key] = whatFilterValues
        whatFilters[key] = processedWhatAggs
      })
    }
    

    let whatForFilterValues = {}
    let whatForFilters = {}
    let objWhatForFilters = {}
    if (filterConfig.whatForFilters.filters) {
      let processedWhatForAggs = {}
      Object.keys(filterConfig.whatForFilters.filters).forEach(key => {
        processedWhatForAggs = processFilterAggregations(
          initialFilterAggregations['productAggregations'],
          key,
          'basicSubclass'
        )
        whatForFilterValues = convertProductAggsToWhatValues(
          processedWhatForAggs,
          key
        )
        objWhatForFilters[key] = whatForFilterValues
        whatForFilters[key] = processedWhatForAggs
      })
    }

    // Setting initial filter values
    const companyFilterValues = convertCompanyAggsToValues(processedCompanyAggs)
    const productFilterValues = convertProductAggsToValues(processedProductAggs)

    let whereFilterValues = {}
    let whereFilters = {}
    let objWhereFilters = {}

    if (filterConfig.whereFilters.filters) {
      let processedWhereAggs = {}
      Object.keys(filterConfig.whereFilters.filters).forEach(key => {
        processedWhereAggs = processFilterAggregations(
          initialFilterAggregations['productAggregations'],
          key,
          key
        )

        whereFilterValues = convertProductAggsToWhereValues(
          processedWhereAggs,
          key
        )
        objWhereFilters[key] = whereFilterValues
        whereFilters[key] = processedWhereAggs
      })
    }


    // const whereFilterValues = convertProductAggsToWhereValues(
    //   processedWhereAggs
    // )
    function convertToValues(obj) {
      let arr = []
      Object.values(obj).map(i => {
        arr = [...arr, ...Object.values(i)]
      })
      arr = arr.filter(i => i.length > 0)
      return arr
    }

    const newFilterValues = {
      ...companyFilterValues,
      ...productFilterValues,
      ...whereFilterValues,
      primaryFunction: convertToValues(objWhatFilters),
      basicSubclass: convertToValues(objWhatForFilters),
      isActive: showInActiveCompanies,
      key: '',
      category: { supplier: false, distributor: false, other: false }
    }
    setFilterValues(newFilterValues)
    setDefaultFilters(newFilterValues)
    setFreeUserFilters(newFilterValues)
    // Setting Filter Config to render filters
    setFilterConfig(prevConfig => ({
      whatFilters: convertWhatValuesToConfig(
        objWhatFilters,
        prevConfig,
        whatFilters
      ),

      whatForFilters: convertWhatForValuesToConfig(
        objWhatForFilters,
        prevConfig,
        whatForFilters
      ),

      whereFilters: convertWhereValuesToConfig(
        whereFilterValues,
        prevConfig,
        whereFilters
      ),

      productFilters: convertProductValuesToConfig(
        productFilterValues,
        prevConfig,
        processedProductAggs
      ),
      companyFilters: convertCompanyValuesToConfig(
        companyFilterValues,
        prevConfig,
        processedCompanyAggs
      )
    }))

    // Finish bootstrapping filters
    setBootstrappingFilters(false)
  }, [
    handleChangeInSearchAggregations,
    isBootstrapDataLoading,
    isBootstrappingIdle,
    initialFilterAggregations,
    errorLoadingInitialAggregations,
    filterConfig.whatFilters,
    filterConfig.whatForFilters,
    filterConfig.whereFilters,
    showInActiveCompanies,
  ])

  useEffect(() => {
    setFilterValues({
      ...filterValues,
      category: isUnlimitedSearches ? {supplier: true, distributor: false, other: false} : { supplier: false, distributor: false, other: false },
    })
  }, [isUnlimitedSearches]);

  useEffect(() => {
    analytics.trackFilters(activeFilters)
  }, [activeFilters])

  useEffect(() => {
    if (isUnlimitedSearches) {
      trackFiltersInit({
        filterValues,
        syncFromUrlTaskDone,
        setFiltersInitialised
      })
      syncToUrl(filterValues, syncFromUrlTaskDone, setActiveFilters)
    }
  }, [filterValues, isUnlimitedSearches, syncFromUrlTaskDone]) //eslint-disable-line

  useEffect(() => {
    if (!isUnlimitedSearches) {
      trackFiltersInit({
        filterValues: freeUserFilters,
        syncFromUrlTaskDone,
        setFiltersInitialised
      })
      syncToUrl(freeUserFilters, syncFromUrlTaskDone, setActiveFilters)
    }
  }, [freeUserFilters, isUnlimitedSearches, syncFromUrlTaskDone]) //eslint-disable-line

  const suggestInputsFiltersData = useMemo(() => {
    function convertFiltersToArr(config) {
      let filters = {
        name: config.id,
        title: config.title,
        options: []
      }
      Object.values(config.filters).forEach(i => {
        if (filters.options) {
          filters.options = [...filters.options, ...i.options]
        } else {
          filters.options = [...i.options]
        }
      })
      return filters
    }
    const productFilters = {
      ...filterConfig.productFilters.filters,
      primaryFunction: convertFiltersToArr(filterConfig.whatFilters),
      basicSubclass: convertFiltersToArr(filterConfig.whatForFilters)
    }
    const filters = {
      ...filterConfig.companyFilters.filters,
      ...productFilters,
      ...filterConfig.whereFilters.filters
    }
    return filters
  }, [filterConfig])

  useEffect(() => {
    if (isAuthenticated && filtersInitialised) {
      if (!isUnlimitedSearches) {
        syncFromUrl({
          filtersInitialised,
          filtersFromUrlParams,
          syncFromUrlTaskDone,
          setSyncFromUrlTaskDone,
          filterValues: freeUserFilters,
          setFilterValues: setFreeUserFilters
        })
      } else {
        syncFromUrl({
          filtersInitialised,
          filtersFromUrlParams,
          syncFromUrlTaskDone,
          setSyncFromUrlTaskDone,
          filterValues,
          setFilterValues
        })
      }
    }
  }, [
    filtersFromUrlParams,
    filtersInitialised,
    isUnlimitedSearches,
    freeUserFilters,
    isAuthenticated,
    filterValues,
    syncFromUrlTaskDone
  ]) //eslint-disable-line

  useEffect(() => {
    initUrlParamsRedirect(setFiltersFromUrlParams, loginRedirectCompleted)
  }, [loginRedirectCompleted])

  const [tempFilters, setTempFilters] = useState({})

  const sendFreeUserFilters = filters => {
    setFreeUserSaveThisSearchBtn(false)
    setFilterValues(prev => ({
      ...prev,
      ...filters
    }))
    setTempFilters(prev => ({
      ...prev,
      ...filters
    }))

    setFreeUserFilters(filters)
    setApplyFiltersBtnVisible(false)
  }
  const isFilterSelected = useMemo(() => {
    return Object.values(filterValues).some(value => {
      if (typeof value === 'string') {
        return value.length > 0
      } else if (Array.isArray(value)) {
        return value.length > 0
      } else if (typeof value === 'object') {
        return Object.values(value).some(i => i === true)
      } else {
        return false
      }
    })
  }, [filterValues])

  return (
    <FilterStateContext.Provider
      value={{
        filterAggregationState,
        bootstrappingFilters,
        productsBootstrapData,
        getProductDetailsFromIds,
        filterValues,
        filterConfig,
        setFilterValues: !isUnlimitedSearches
          ? setFreeUserFilters
          : setFilterValues,
        sendFreeUserFilters,
        handleChangeInSearchAggregations,
        selectedFilters,
        setSelectedFilters,
        urlQPFromFilterValues,
        setUrlQPFromFilterValues,
        filtersFromUrlParams,
        setFiltersFromUrlParams,
        syncFromUrlTaskDone,
        setSyncFromUrlTaskDone,
        isSavedSearchModalOpen,
        setSavedSearchModalOpen,
        setSavedSearchesData,
        savedSearchesData,
        savedSearchesLoading,
        setSavedSearchesLoading,
        allCountriesData,
        reloadSavedSearches,
        setReloadSavedSearches,
        defaultFilters,
        setApplyFiltersBtnVisible,
        freeUserFilters,
        setFreeUserFilters,
        freeUserSaveThisSearchBtn,
        setFreeUserSaveThisSearchBtn,
        isFreeUser,
        isFullUser,
        tempFilters,
        isSearchRequestModalOpen,
        setSearchRequestModalOpen,
        isApplyFiltersBtnVisible,
        isUnlimitedSearches,
        freeUserSelectedFilters,
        setFreeUserSelectedFilters,
        isFreeUserFiltersChanged,
        setIsFreeUserFiltersChanged,
        similarProductFilters,
        setSimilarProductFilters,
        suggestInputsFiltersData,
        itemStates,
        setItemStates,
        setTempFilters,
        isFilterSelected,
        checkedCountries,
        setCheckedCountries,
        homePageFilters,
        setHomePageFilters,
        categories,
        setCategories
      }}
    >
      {children}
    </FilterStateContext.Provider>
  )
}
