import { makeStyles } from '@material-ui/core'
import { derivedFromProp } from 'utils'

export const useStyles = makeStyles(theme => ({
  inputContainer: {
    height: 40,
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: theme.spacing(0.3, 1),
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    marginTop: 15,
    border: derivedFromProp('inputBorder', {
      true: '1px solid #c8c8c8',
      false: 'none'
    }),
    '& input': {
      fontSize: 16,
      height: '30px',
      boxSizing: 'border-box',
      padding: '4px 6px',
      width: 0,
      minWidth: '30px',
      flexGrow: 1,
      border: 0,
      margin: 0,
      outline: 0,
      fontFamily: 'Open Sans',
      '&::placeholder': {
        fontFamily: 'inherit'
      }
    }
  },
  clearBtnRoot: {
    color: theme.palette.primary.lightBlue,
    fontWeight: 600,
    textTransform: 'unset',
    // marginBottom: 10,
    '&:hover': {
      color: theme.palette.primary.lightBlue
    }
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '20px',
    marginBottom: 0
  },
  btn: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: 'fit-content',
    alignItems: 'center',
    height: '24px',
    margin: '9px 10px 9px 0',
    lineHeight: '22px',
    borderRadius: '20px',
    boxSizing: 'content-box',
    padding: '3px 5px 3px 5px',
    outline: 0,
    overflow: 'hidden',
    color: 'black !important',
    background: 'rgba(0, 163, 255, 0.1)',
    border: '1px solid #00A3FF',
    '& >div': {
      background: 'transparent !important',
      '&:hover': {
        background: 'transparent !important'
      }
    }
  },
  hidden: {
    width: '100%',
    padding: theme.spacing(2),
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.secondary.mainLightGrey,
    overflow: 'auto',
    maxHeight: '250px',
    zIndex: 1,
    color: 'black',
    margin: '0 !important'
  },
  listBox: {
    margin: '15px 0 18px',
    '& li': {
      margin: '12px 0 6px',
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 400,
      padding: '6px 0 0',
      display: 'flex',
      alignItems: 'center',

      '& span.estimatedCount': {
        paddingLeft: '6px'
      },

      '& span.category': {
        display: 'inline-block',
        fontWeight: 'bold',
        color: '#555',
        fontSize: '10px',
        padding: '0 6px'
      },

      '& svg': {
        color: 'transparent',
        backgroundColor: 'white',
        fontSize: 16,
        border: '1px solid #c8c8c8',
        marginRight: '5px',
        width: 18,
        height: 18
      }
    },

    '& li[aria-selected="true"]': {
      '& svg': {
        color: theme.palette.primary.primaryBig,
        fontSize: 16,
        width: 18,
        height: 18,
        border: `1px solid ${theme.palette.primary.primaryBig}`
      }
    },

    '& li[data-focus="true"]': {
      cursor: 'pointer'
    }
  },
  lockIcon: {
    color: 'black !important',
    border: 'none !important',
    backgroundColor: 'transparent !important'
  },
  filtersMenuDivider: {
    margin: '20px 0'
  }
}))
