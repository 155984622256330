import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import Skeleton from '@material-ui/lab/Skeleton'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import useAutoComplete from './../../../utils/customHooks/useAutocomplete'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { arrHasCommonElements } from 'utils'
// import { useNavContext } from '../Navbar'
import { CanV2 } from 'rbac/can'
import { useStyle } from './useStyle'
import { findValueByFirstLetter } from 'utils/sortingFunctions'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'

const getParentFilterAggregations = (
  parentFilterConfig,
  sectionAggregationData
) => {
  const allKeys = parentFilterConfig?.children?.reduce(
    (acc, childFilterName) => {
      const childFilterAggregationData = sectionAggregationData[childFilterName]

      const processedChildFilterAgg = childFilterAggregationData
        ? Object.keys(childFilterAggregationData).map(key => ({
            key,
            filterName: childFilterName
          }))
        : []

      return [...acc, ...processedChildFilterAgg]
    },
    []
  )

  const aggregations = allKeys.reduce((acc, { key, filterName }) => {
    if (acc[key]) {
      acc[key].doc_count += sectionAggregationData[filterName][key].doc_count
    } else {
      acc[key] = {
        key,
        doc_count: sectionAggregationData[filterName][key].doc_count
      }
    }
    return acc
  }, {})

  return aggregations
}

const processComplexFilterValue = (values, childrenFilterNames) => {
  const result = childrenFilterNames.reduce((acc, filterName) => {
    acc[filterName] = []
    return acc
  }, {})
  values.forEach(({ label, filterNames }) => {
    filterNames.forEach(filterName => {
      result[filterName].push(label)
    })
  })
  return result
}

export default function SearchAutoSuggest({
  searchQuery,
  immediateSearchQuery,
  setInputValue,
  onFilterSelect,
  maxHeight
}) {
  const classes = useStyle({ maxHeight })
  const {
    filterConfig,
    setFilterValues,
    filterAggregationState,
    productsBootstrapData,
    allCountriesData
  } = useFilterStateContext()
  const { setAllCompaniesPage } = useSearchPaginationContext()

  const [matchingFilters, setMatchingFilters] = useState(null)
  // const isAllowed = useCan('content_depth:all')
  // const { setSignupOpen } = useNavContext()
  // const [entities, setEntities] = useState(null)
  const [loadingEntities, setLoadingEntities] = useState(true)
  const [search, setSearch] = useState('')
  const isMounted = useRef(true)

  const [productsDropdown, setProductsDropdown] = useState([])

  let [finalResults] = useAutoComplete({
    data: productsDropdown,
    searchQuery: search,
    autoSuggestFields: ['name', 'companyName'],
    resultsToReturn: 8
  })

  // Rebuild the react-tooltip whenever the component gets rerendered
  useEffect(() => ReactTooltip.rebuild())

  // const showSignUpModal = useCallback(() => {
  //   setSignupOpen(true)
  // }, [setSignupOpen])

  useEffect(() => {
    setProductsDropdown(Object.values(productsBootstrapData))
  }, [productsBootstrapData])
  //for using history in the search results for pushing product entity types to details page
  const history = useHistory()

  const filterList = useMemo(() => {
    return [
      ...Object.values(filterConfig.companyFilters.filters),
      ...Object.values(filterConfig.productFilters.filters)
    ].filter(({ isChild }) => !isChild)
  }, [filterConfig])
  if (filterAggregationState.companyAggregations.headquaters) {
    filterAggregationState.companyAggregations.headquaters = allCountriesData
  }
  if (filterAggregationState.companyAggregations.offices) {
    filterAggregationState.companyAggregations.offices = allCountriesData
  }
  const filterAggList = useMemo(() => {
    return {
      ...filterAggregationState.productAggregations,
      ...filterAggregationState.companyAggregations
    }
  }, [filterAggregationState])

  useEffect(
    () => () => {
      isMounted.current = false
    },
    []
  )

  const setCheckBoxFilter = useCallback(
    (filterName, keyName, value) => {
      onFilterSelect()
      setAllCompaniesPage(1)
      setFilterValues(prev => ({
        ...prev,
        [filterName]: {
          ...prev[filterName],
          [keyName]: value
        }
      }))
    },
    [setFilterValues, onFilterSelect, setAllCompaniesPage]
  )

  const setChipInputFilter = useCallback(
    (filterName, value) => {
      onFilterSelect()
      setAllCompaniesPage(1)
      setFilterValues(prev => {
        if (!prev[filterName]) {
          return {
            ...prev,
            [filterName]: [value]
          }
        }
        if (prev[filterName] && prev[filterName].includes(value)) return prev
        return {
          ...prev,
          [filterName]: [...prev[filterName], value]
        }
      })
    },
    [setFilterValues, onFilterSelect, setAllCompaniesPage]
  )

  const setParentChipFilterValue = useCallback(
    (filterName, value) => {
      onFilterSelect()
      setAllCompaniesPage(1)
      setFilterValues(prev => {
        if (!prev[filterName]) {
          return {
            ...prev,
            [filterName]: [...value]
          }
        }
        if (prev[filterName] && arrHasCommonElements(value, prev[filterName]))
          return prev

        return {
          ...prev,
          [filterName]: [...prev[filterName], ...value]
        }
      })
    },
    [setFilterValues, onFilterSelect, setAllCompaniesPage]
  )

  const getFilterClickHandler = filterData => {
    const {
      type,
      filterName,
      keyName,
      label,
      isParent,
      filterConfig,
      option
    } = filterData
    if (type === 'checkbox') {
      return () => setCheckBoxFilter(filterName, keyName, true)
    }
    if (type === 'chips') {
      if (isParent) {
        return () => {
          const childFilterValues = processComplexFilterValue(
            [option],
            filterConfig.children
          )
          Object.entries(childFilterValues).forEach(
            ([filterName, filterValue]) => {
              setParentChipFilterValue(filterName, filterValue)
            }
          )
        }
      }
      return () => {
        if (history.location.pathname !== '/search') history.push(`/search`)
        setChipInputFilter(filterName, label)
      }
    }
  }

  useEffect(() => {
    if (!immediateSearchQuery) return
    const lowerCaseSearchQuery = immediateSearchQuery.toLowerCase()
    const matchingFilters = filterList
      .reduce((acc, filter) => {
        if (filter.type === 'checkbox')
          filter.options.forEach(({ label, name: optionName }) => {
            const isMatching =
              label.toLowerCase().includes(lowerCaseSearchQuery) &&
              filterAggList[filter?.name][optionName]?.doc_count !== 0

            if (isMatching)
              acc.push({
                label,
                filterName: filter?.name,
                filterTitle: filter?.title,
                optionName: optionName,
                type: filter?.type
              })
          })
        // Chip Input
        if (filter?.type === 'chips') {
          let filterAggregations = filterAggList[filter?.name]
          if (filter?.isParent) {
            filterAggregations = getParentFilterAggregations(
              filter,
              filterAggList
            )
          }
          const options = filter.options ? Object.values(filter?.options) : []

          options.forEach(option => {
            const label = option.label
            const isMatching =
              label.toLowerCase().includes(lowerCaseSearchQuery) &&
              filterAggregations?.[label]?.doc_count

            if (isMatching)
              acc.push({
                label,
                filterName: filter?.name,
                filterTitle: filter?.title,
                type: filter?.type,
                isParent: filter?.isParent,
                filterConfig: filter,
                option
              })
          })
        }
        acc = findValueByFirstLetter(acc, lowerCaseSearchQuery)
        return acc
      }, [])
      .slice(0, 8)

    if (isMounted.current) setMatchingFilters(matchingFilters)
  }, [immediateSearchQuery, filterList, filterAggList])

  useEffect(() => {
    if (!searchQuery) return
    const searchByName = true
    const sortedResults = findValueByFirstLetter(
      Object.values(productsBootstrapData),
      searchQuery,
      searchByName
    )

    setProductsDropdown(sortedResults)
    setLoadingEntities(false)
    setSearch(searchQuery)
  }, [searchQuery, productsBootstrapData])

  return (
    <div className={classes.root}>
      {/* Check if the user has enough permissions for the full auto-suggestion feature */}
      <CanV2 perform="use:full-autosuggest">
        {() => (
          <>
            {/* <Portal>
              {!isAllowedToUseFullAutosuggest && (
                // Tooltip for autosuggest component in searchBar for users who do not
                // have permissions for full-autosuggest
                <ReactTooltip
                  id="categoryAndTagsAutoSuggestToolTip"
                  className="customTooltip"
                >
                  Click here to request access to this feature
                </ReactTooltip>
              )}
            </Portal> */}
            <div className={classes.listWrapper}>
              {loadingEntities && (
                <div className="section">
                  <h3 className="list-header">Products & Companies</h3>
                  <ul className="entity-list list">
                    {[...Array(4)].map((_, index) => (
                      <li key={index}>
                        <span className="value">
                          <Skeleton
                            variant="rect"
                            width={150}
                            height={15}
                            animation="wave"
                            style={{ background: 'rgb(115 115 115 / 11%)' }}
                          />
                        </span>
                        <span className="type">
                          <Skeleton
                            variant="rect"
                            width={50}
                            height={15}
                            animation="wave"
                            style={{ background: 'rgb(115 115 115 / 11%)' }}
                          />
                        </span>
                      </li>
                    ))}
                    <Box py={1}></Box>
                  </ul>
                </div>
              )}
              {!loadingEntities && finalResults.length > 0 && (
                <div className="section">
                  <h3 className="list-header">Products & Companies</h3>
                  <ul className="entity-list list">
                    {finalResults.map((entity, index) => (
                      <li
                        key={entity.name + entity.type + index}
                        onClick={() => {
                          //if entity type is Product then directly redirect to the details page else search
                          if (entity && entity.type === 'Product') {
                            // for history added useHistory import from react-dom-router
                            setInputValue('')
                            history.push(`/product/${entity.slug}`)
                          } else {
                            setInputValue(entity.companyName)
                            // if (isAllowed) {
                            //   setInputValue(entity.companyName)
                            // } else {
                            //   setSignupOpen(true)
                            // }
                          }
                        }}
                      >
                        <span className="value">
                          {entity.type === 'Product'
                            ? entity.name
                            : entity.companyName}
                          {/* {!isAllowed && entity.type !== 'Product' && (
                            <LockIcon
                              color="white"
                              style={{ marginLeft: '2px', paddingTop: '5px' }}
                              fontSize="small"
                            />
                          )} */}
                        </span>
                        <span className="type">{entity?.type}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {matchingFilters && matchingFilters.length > 0 && (
                <div
                  className="section"
                  data-tip="categoryAndTagsAutoSuggestToolTip"
                  data-for="categoryAndTagsAutoSuggestToolTip"
                  // onClick={() =>
                  //   !isAllowedToUseFullAutosuggest ? setSignupOpen(true) : null
                  // }
                >
                  <h3 className="list-header">Categories & Tags</h3>
                  <ul className="filter-list list">
                    {matchingFilters.map(
                      ({
                        type,
                        filterName,
                        optionName,
                        label,
                        filterTitle,
                        ...rest
                      }) => (
                        <li
                          key={label + filterName}
                          // onClick={
                          //   isAllowedToUseFullAutosuggest
                          //     ? getFilterClickHandler({
                          //         type,
                          //         filterName,
                          //         keyName: optionName,
                          //         label,
                          //         ...rest
                          //       })
                          //     : null
                          // }
                          onClick={getFilterClickHandler({
                            type,
                            filterName,
                            keyName: optionName,
                            label,
                            ...rest
                          })}
                        >
                          <span className="value">
                            {label}
                            {/* {!isAllowed && (
                              <LockIcon
                                color="white"
                                style={{ marginLeft: '2px', paddingTop: '5px' }}
                                fontSize="small"
                              />
                            )} */}
                          </span>
                          <span className="type">{filterTitle}</span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          </>
        )}
      </CanV2>
    </div>
  )
}
