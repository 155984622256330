import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    Typography
} from '@material-ui/core';
import useStyles from '../../../styles';
import { OxbowPrimaryButton } from 'components/Helpers/Buttons/index.js';
import { useAuth0 } from 'components/Contexts/AuthContext';
import { submitProductDetailsSuggestions } from 'utils/api';
import SuggestionsList from './SuggestionsList';
import { productsSuggestions } from 'utils/api';

const SuggestionsSubmit = ({ productData }) => {
    const classes = useStyles();

    const [ attachments, setAttachments ] = useState(null);
    const [ suggestions, setSuggestions ] = useState([]);
    const [ submitLoading, setSubmitLoading ] = useState(false);

    const { user } = useAuth0();
    const product = productData.sys.id;


    const getProductSuggestions = async (productId) => {
        const response = await productsSuggestions(productId);
        const productSuggestions = response.data;
        setSuggestions(productSuggestions);
    }

    const handleSubmit = async (formData, actions) => {
        setSubmitLoading(true);
        const fd = new FormData();
        fd.append('product', product);
        fd.append('userEmail', user.email);
        fd.append('suggestions', formData.suggestions);
        fd.append('userName', formData.userName);
        fd.append('subject', formData.subject);

        if (attachments) {
            for (const file of attachments) {
                fd.append('files', file)
            }
        }

        await submitProductDetailsSuggestions(fd);
        await getProductSuggestions(productData.sys.id);

        setAttachments([]);
        actions.resetForm({
            suggestions: '',
            attachment: null,
            userName: '',
            subject: '',
        })
        setSubmitLoading(false);
    }

    useEffect(() => {
        getProductSuggestions(productData.sys.id);
    }, []);

    const onChangeAttachment = (event) => {
        setAttachments(Array.from(event.target.files));
    }

    return (
        <Grid>
            <Formik
                initialValues={{
                    suggestions: '',
                    attachment: null,
                    userName: '',
                    subject: '',
                }}
                onSubmit={handleSubmit}
            >
                {({handleChange, values}) => (
                    <>
                        <div className={classes.suggestionForm}>
                            <Form>
                                <Box className={classes.mainInputContainer}>
                                    <Typography variant="h3" style={{marginBottom: '7px'}}>Feedback form</Typography>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                component="label"
                                                variant="h4"
                                                htmlFor="userName"
                                            >
                                                User Name
                                            </Typography>
                                            <Box position="relative">
                                                <input name="userName" rows="4" cols="50" value={values.userName} onChange={handleChange} required></input>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                component="label"
                                                variant="h4"
                                                htmlFor="subject"
                                            >
                                                Subject 
                                            </Typography>
                                            <Box position="relative">
                                                <input name="subject" rows="4" cols="50" value={values.subject} onChange={handleChange} required></input>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                component="label"
                                                variant="h4"
                                                htmlFor="suggestions"
                                            >
                                                Key takeaways
                                            </Typography>
                                            <Box position="relative">
                                                <textarea name="suggestions" rows="4" cols="50" value={values.suggestions} onChange={handleChange} required></textarea>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                component="label"
                                                variant="h4"
                                                htmlFor="attachment"
                                            >
                                                Attachment
                                            </Typography>
                                            <Box position="relative">
                                                <input type="file" name="attachment" onChange={onChangeAttachment} multiple></input>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box mt={3} style={{ textAlign: 'center' }}>
                                        <OxbowPrimaryButton
                                            background="md"
                                            type="submit"
                                            width="max-content"
                                            size="md"
                                            className={classes.contactUsBtn}
                                            isLoading={submitLoading}
                                        >
                                            Submit Suggestion
                                        </OxbowPrimaryButton>
                                    </Box>
                                </Box>
                            </Form>

                            <Grid className={classes.suggestionList}>
                                <SuggestionsList suggestions={suggestions}></SuggestionsList>
                            </Grid>
                        </div>
                    </>
                )}
            </Formik>
        </Grid>
    )
}

export default SuggestionsSubmit;
