import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  contactUsMainContainer: {
    width: '100%',
    background: '#fff',
    height: '100%',
    padding: '0 100px 15px',
    '& .thxContainer': {
      margin: '180px 0 100px 0',
      textAlign: 'center'
    },
    '& a': {
      textDecoration: 'none'
    },
    [theme.breakpoints.up(1920)]: {
      padding: theme.spacing(0, 35)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6, 0, 4)
    },

    [theme.breakpoints.down(1160)]: {
      padding: '0 10px 0 48px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px'
    }
  },
  contactUsBtn: {
    padding: '10px 35px 10px 35px',
    height: '45px',
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputContainer: {
    height: '40px',
    marginTop: '11px',
    alignItems: 'center',
    backgroundColor: '#fff',
    display: 'flex',
    position: 'relative',
    '& select': {
      zIndex: 2,
      backgroundColor: 'transparent'
    },
    '& span': {
      fontSize: '16px',
      position: 'absolute',
      left: '10px'
    },
    '& >svg': {
      position: 'absolute',
      right: 0,
      zIndex: 1
    }
  },
  hidden: {
    width: '100%',
    marginTop: '2px',
    padding: theme.spacing(2),
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.primary.lighterDark,
    overflow: 'auto',
    maxHeight: '250px',
    zIndex: 3,
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  listBox: {
    backgroundColor: 'white',
    '& li': {
      fontSize: '16px',
      padding: theme.spacing(1, 0),
      display: 'flex',
      alignItems: 'center',
      '& span': {
        flexGrow: 1
      }
    },

    '& li[data-focus="true"]': {
      cursor: 'pointer'
    }
  },
  contactUsTitle: {
    padding: '25px 0 0 0',
    fontFamily: '"Barlow Condensed", sans-serif ',
    [theme.breakpoints.down('sm')]: {
      padding: '10px'
    }
  },
  mainInputContainer: {
    '& input, textarea, select': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
      boxShadow: 'unset',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '-webkit-border-radius': 'unset',
      appearance: 'none'
    },
    '& .MuiGrid-item': {
      marginRight: '10px !important'
    },
    scrollbarColor: 'white',
    '& .mainInputBox >div': {
      position: 'relative',
      marginTop: '11px',
      marginBottom: '8px',
      marginRight: '20px',
      '& .errorText': {
        marginTop: '5px',
        color: 'red',
        fontFamily: 'Open Sans',
        fontSize: '13px'
      },
      '& .hintError': {
        zIndex: 9999999,
        width: '40px',
        height: '39px',
        backgroundColor: 'red',
        position: 'absolute',
        top: '0',
        right: '0',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& >svg': {
          height: '18px'
        }
      }
    },
    '& label, option': {
      fontWeight: 600
    },
    '& input, select': {
      marginTop: '11px',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      outline: 'none',
      height: '40px',
      border: '1px solid #C8C8C8',
      display: 'block',
      width: '260px'
    },
    '& textarea': {
      padding: '10px',
      outline: 'none',
      border: '1px solid #C8C8C8'
    }
  },

  inputError: {
    border: '1px solid red !important'
  },
  contactHeaderText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: '17px'
  },
  textAreaContainer: {
    '& button': {
      textTransform: 'unset',
      color: 'black',
      fontFamily: 'Open Sans',
      margin: '18px 10px 18px 0 ',
      cursor: 'pointer',
      fontSize: '13px',
      width: 'max-content',
      height: '30px',
      borderRadius: '20px',
      padding: '0 10px',
      border: '1px solid #00A3FF',
      backgroundColor: 'rgba(0, 163, 255, 0.1)',
      transition: '0.3s ease',

      '&:hover': {
        background: 'rgba(0, 163, 255, 0.3)'
      },
      '&.Mui-selected': {
        background: theme.palette.secondary.main,
        color: 'white',
        '&:hover': {
          background: 'rgba(0, 163, 255, 0.3)',
          color: 'black'
        }
      }
    },
    '& textarea': {
      marginTop: '11px',
      width: '800px'
    },
    [theme.breakpoints.down('sm')]: {
      '& button:last-child': {
        marginBottom: '10px '
      },

      '& textarea': {
        width: '100%'
      },
      '& button': {
        margin: '9px 10px 9px 0 '
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    interestsContainer: {
      width: '300px'
    }
  },

  checkBoxContainer: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'column',
    '& .errorText': {
      color: 'red',
      fontFamily: 'Open Sans',
      fontSize: '13px'
    },
    '& label': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0 0 ',
      fontSize: '16px',
      '& input': {
        height: '18px',
        width: '18px',
        marginRight: '13px'
      }
    }
  },
  contactusFooter: {
    marginTop: '50px',
    marginBottom: '230px',
    justifyContent: 'space-between',
    width: '75%',
    alignItems: 'center',
    '& :nth-child(2n+2)': {
      cursor: 'pointer'
    },
    '& p': {
      fontSize: '16px',
      padding: '0 10px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      height: '200px'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '50px'
    }
  }
}))
