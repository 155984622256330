import { makeStyles } from '@material-ui/core'

export const useEditModeStyles = makeStyles(_theme => ({
  input: {
    padding: `${_theme.spacing(1)}px ${_theme.spacing(1)}px`,
    outline: 'none',
    border: '1px solid #7777',
    display: 'block',
    width: '100%',
    height: '40px',
    flex: 1,
    overflowX: 'scroll',
    resize: 'none'
  },
  wordsLimits: {
    position: 'absolute',
    bottom: 0,
    right: 15,
    color: 'rgb(128, 128, 128)',
    fontSize: 13
  },
  textArea: {
    fontFamily: 'Open Sans',
    fontSize: '13px',
    padding: `${_theme.spacing(1)}px ${_theme.spacing(1)}px`,
    outline: 'none',
    border: '1px solid #C8C8C8',
    display: 'block',
    width: '100%',
    flex: 1
  },
  textInput: {
    padding: `${_theme.spacing(1)}px ${_theme.spacing(1)}px`,
    outline: 'none',
    height: '40px',
    border: '1px solid #C8C8C8',
    display: 'block',
    flex: 1,
    width: '100%',
    [_theme.breakpoints.up('lg')]: {
      width: '350px'
    },
    [_theme.breakpoints.down('sm')]: {
      width: '350px'
    }
  },
  shortTextInput: {
    width: 'unset',
    padding: `${_theme.spacing(1)}px ${_theme.spacing(1)}px`,
    outline: 'none',
    height: '40px',
    border: '1px solid #C8C8C8',
    display: 'block',
    flex: 1
  }
}))

export default useEditModeStyles
