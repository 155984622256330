import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { OxbowTransparentButton } from 'components/Helpers/Buttons'
import InfoIcon from 'assets/icons/InfoIcon.png'
import ErrorIcon from 'assets/icons/ErrorIcon.png'
import SuccessIcon from 'assets/icons/SuccessIcon.png'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { derivedFromProp } from 'utils'

const useStyles = makeStyles(theme => ({
  root: {
    color: 'black',
    zIndex: 9999,
    '& img': {
      width: 30,
      height: 30
    },
    '& h6': {
      color: 'black',
      textTransform: 'initial',
      paddingRight: 20
    },
    '& .MuiAlert-icon': {
      display: 'none'
    },
    '& .MuiAlert-message': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiAlert-action': {
      display: derivedFromProp('res', {
        success: 'block',
        default: 'none'
      }),
      '& svg': {
        fill: 'rgba(0, 0, 0, 0.2)'
      }
    },
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  infoBlock: {
    width: 500,
    borderRadius: 0,
    padding: '6px 30px !important',
    '& h6': {
      paddingRight: 0
    },
    '& .info, .MuiAlert-filledSuccess': {
      background: '#EDF9FF',
      border: '1px solid #00A3FF'
    },
    '& .success': {
      background: '#EFFFEA',
      border: '1px solid #22AA00'
    },
    '& .error': {
      background: '#FFEAEA',
      border: '1px solid #FF0000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '97%'
    }
  }
}))
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
export const SnackBar = ({
  saveEditedChanges,
  opened,
  res,
  setSnackBarOpened,
  discardChanges,
  setUpdatedCollectionRes = () => {},
  setDataLoaded = () => {},
  msg = '',
  isBtnsVisible = true
}) => {
  const classes = useStyles({ res })

  const [message, setMessage] = useState(
    'This collection has unpublished changes'
  )
  const [icon, setIcon] = useState(InfoIcon)

  const [availableHandlers, setAvailableHandlers] = useState([])

  const handleClose = reason => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpened(false)
  }

  const handleCloseBtn = () => {
    setUpdatedCollectionRes(' ')
    setDataLoaded(false)
    setSnackBarOpened(false)
  }

  const infoBtns = [
    {
      title: 'Discard',
      onClick: () => {
        discardChanges()
        handleClose()
      }
    },
    {
      title: 'Publish changes',
      onClick: saveEditedChanges
    }
  ]
  const errorBtns = [
    {
      title: 'Dismiss',
      onClick: () => {
        discardChanges()
        handleClose()
      }
    },
    {
      title: 'Retry',
      onClick: saveEditedChanges
    }
  ]

  useEffect(() => {
    switch (res) {
      case 'success':
        setIcon(SuccessIcon)
        setMessage('Changes were published successfully')

        break
      case 'error':
        setIcon(ErrorIcon)
        setMessage('An error has occurred. Changes were not published')
        setAvailableHandlers(errorBtns)
        break
      default:
        setIcon(InfoIcon)
        setMessage('This collection has unpublished changes')
        setAvailableHandlers(infoBtns)
    }
  }, [res])

  return (
    <div className={classes.root}>
      <Snackbar open={opened} className={classes.infoBlock}>
        <Alert
          className={clsx({
            [res]: res
          })}
        >
          <img src={icon} alt="icon" />

          <Grid
            container
            justify="center"
            style={{
              paddingLeft: 30,
              position: 'relative'
            }}
          >
            {res === 'success' && (
              <CloseIcon
                onClick={handleCloseBtn}
                style={{
                  position: 'absolute',
                  right: '-10px',
                  top: '-15px',
                  width: 15,
                  color: 'rgba(0, 0, 0, 0.2)',
                  cursor: 'pointer'
                }}
              />
            )}
            <Typography variant="subtitle2">{msg ? msg : message}</Typography>
            {res !== 'success' && isBtnsVisible ? (
              <Grid container justify="center">
                {availableHandlers.length > 0 &&
                  availableHandlers.map((item, index) => (
                    <OxbowTransparentButton
                      padding="sm"
                      key={index}
                      onClick={item.onClick}
                    >
                      <Typography variant="h4">{item.title}</Typography>
                    </OxbowTransparentButton>
                  ))}
              </Grid>
            ) : null}
          </Grid>
        </Alert>
      </Snackbar>
    </div>
  )
}
