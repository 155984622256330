import { fade, makeStyles, Portal } from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useChain, useTransition } from 'react-spring'
import { animated } from 'react-spring'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    display: 'grid',
    placeItems: 'center',
    zIndex: 1000000000000
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: fade('rgba(196, 196, 196, 0.66)', 0.4)
  },
  modal: {
    background: fade(theme.palette.primary.main, 0.9),
    padding: theme.spacing(4, 6),
    zIndex: 2
  }
}))

const translateToModalStyles = ({ opacity, x, ...rest }) => {
  return {
    opacity,
    transform: x.interpolate([0, 1], [0.8, 1]).interpolate(v => `scale(${v})`),
    ...rest
  }
}

export function MagellanModal({
  modalClass,
  backDropClass,
  rootClass,
  children,
  closeOnBackDropClick = false,
  show: showProp = true,
  onClose
}) {
  const [show, setShow] = useState(showProp)
  const classes = useStyles()
  const modalTransitionRef = useRef()
  const backDropTransitionRef = useRef()
  const modalTransition = useTransition(show, null, {
    ref: modalTransitionRef,
    from: { opacity: 0, x: 0 },
    enter: { opacity: 1, x: 1 },
    leave: { opacity: 0 }
  })
  const backdropTransition = useTransition(show, null, {
    ref: backDropTransitionRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, delay: 500 }
  })

  useChain(
    show
      ? [backDropTransitionRef, modalTransitionRef]
      : [modalTransitionRef, backDropTransitionRef],
    [0, 0.2]
  )

  useEffect(() => {
    setShow(showProp)
  }, [showProp])

  const closeModal = useCallback(() => {
    if (onClose) onClose()
    setShow(false)
  }, [setShow, onClose])

  return (
    <Portal>
      <div
        className={clsx(classes.root, rootClass)}
        style={{ pointerEvents: show ? 'all' : 'none' }}
      >
        {backdropTransition.map(
          ({ item: showBackdrop, props: styles }, index) =>
            showBackdrop && (
              <React.Fragment key={index}>
                <animated.div
                  className={clsx(classes.backdrop, backDropClass)}
                  style={styles}
                  onClick={closeOnBackDropClick ? closeModal : null}
                ></animated.div>
              </React.Fragment>
            )
        )}

        {modalTransition.map(
          ({ item: ShowModal, props: styles }, index) =>
            ShowModal && (
              <React.Fragment key={index}>
                <animated.div
                  className={clsx(classes.modal, modalClass)}
                  style={translateToModalStyles(styles)}
                >
                  {children}
                </animated.div>
              </React.Fragment>
            )
        )}
      </div>
    </Portal>
  )
}

export default MagellanModal
