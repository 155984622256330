import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'

export const useStyles = makeStyles(theme => ({
  profileHeader: {
    background: theme.palette.primary.lighterGrey,
    borderBottomRightRadius: '50px',
    padding: '0 100px',
    border: 0,
    boxSizing: 'border-box'
  },
  accountInfMainTitle: {
    fontWeight: 500,
    paddingTop: 5,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  accountInformationTitle: {
    '& h2': {
      fontFamily: 'Barlow Condensed',
      fontSize: 36
    },
    '& >div': {
      '&:first-child': {
        padding: '30px 0 26px 0'
      },
      '&:not(:first-child)': {
        padding: '15px 0 0 0'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& >div': {
        '&:first-child': {
          padding: '20px 0 26px 0 !important'
        }
      }
    }
  },

  editBtn: {
    alignSelf: 'flex-end',
    marginLeft: 15
  },
  btnsBox: {
    padding: '30px 0 10px 0',
    justifyContent: 'space-between',
    width: '100%',

    '& >div': {
      width: 'unset'
    },
    '& button span div': {
      display: 'flex',
      alignItems: 'center'
    },
    '& button:first-child': {
      marginRight: 10
    },
    '& svg': {
      marginRight: 10
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& >div': {
        flexDirection: 'column'
      },
      '& button:nth-child(2)': {
        marginTop: 20
      },
      '& >button:last-child': {
        marginTop: 50,
        padding: '10px 0'
      },
      alignItems: derivedFromProp('alignItems', {
        default: 'unset',
        center: 'center'
      }),

      '& button:first-child': {
        marginRight: 0,
        alignSelf: derivedFromProp('alignSelf', {
          default: 'unset',
          center: 'center'
        })
      }
    }
  },
  headerDivider: {
    width: 260
  },
  componentDivider: {
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15
    }
  },
  accountInformationComponent: {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',

    '& .errorText': {
      color: 'red'
    },
    '& .hintError': {
      top: '0',
      color: 'white',
      right: '0',
      width: 40,
      height: 40,
      display: 'flex',
      zIndex: '9',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'red',
      [theme.breakpoints.down('sm')]: {
        right: '0',
        left: 260
      }
    },

    '& .MuiTableCell-root': {
      padding: '16px 0',
      fontSize: 16,
      borderBottom: 'none',
      lineHeight: 'unset',
      '& h4:first-child': {
        paddingBottom: 5
      }
    },
    '& >div': {
      width: 260,
      padding: '15px 0',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%'
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    '& input': {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      outline: 'none',
      height: '40px',
      border: '1px solid #C8C8C8',
      display: 'block',
      width: 260
    }
  },
  accountInfoBlock: {
    '& >div': {
      flexDirection: 'column',
      paddingBottom: 20,
      [theme.breakpoints.down('sm')]: {}
    }
  },
  accountInformation: {
    padding: '0 100px 15px',
    [theme.breakpoints.up(1920)]: {
      padding: theme.spacing(0, 35, 0)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6, 0, 4)
    },

    [theme.breakpoints.down(1160)]: {
      padding: '0 10px 0 48px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 20px'
    }
  },
  accountInformationSubTitle: {
    fontWeight: 600,
    paddingBottom: 10,
    paddingTop: 15
  },
  accountInformationText: {
    fontWeight: 400,
    opacity: 0.5,
    paddingBottom: 6
  },
  accountInformationTextBlack: {
    color: '#000',
    opacity: 1
  },
  accountInfContactData: {},
  accountModal: {
    position: 'relative',
    paddingTop: 50,
    background: '#fff',
    color: '#000',
    width: 600,
    '& h1': {
      fontSize: 36,
      lineHeight: '43.2px'
    },
    '& h6': {
      lineHeight: '27px',
      fontSize: 18,
      padding: '30px 0 20px'
    },
    '& img': {
      position: 'absolute',
      top: 25,
      right: 25,
      cursor: 'pointer'
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },

  inActiveBlock: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  toolbarList: {
    width: '100%',
    listStyleType: 'none',
    display: 'flex',
    '& li': {
      cursor: 'pointer',
      padding: '18px 0',
      '& a': {
        textDecoration: 'none',
        color: 'black',
        fontSize: 16,
        fontWeight: '600 !important',
        paddingBottom: '18px',
        borderBottom: `1px solid transparent`
      }
    },
    '& li a:hover': {
      borderBottom: `1px solid ${theme.palette.primary.mainLightBlue}`,
      color: theme.palette.primary.mainLightBlue,
      fontWeight: '700 !important'
    },
    '& li a.selected': {
      borderBottom: `1px solid ${theme.palette.primary.mainLightBlue}`,
      fontWeight: '700 !important',
      color: theme.palette.primary.mainLightBlue
    },
    '& li:not(:first-child)': {
      marginLeft: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(3)
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0)
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& li:not(:first-child)': {
        margin: 0
      }
    }
  }
}))
