/*
 *
 * Auth0 React Wrapper:
 * This is a set of custom React hooks that enable you to work with the Auth0 SDK in a more idiomatic way,
 * providing functions that allow the user to log in, log out, and information such as whether the user is logged in.
 * These hooks can be integrated into various components that make up the app.
 *
 */

import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { initiateAxiosInstance } from 'utils/axios'
import Cookies from 'js-cookie'
import analytics from 'utils/analytics'
const jwtDecode = require('jwt-decode')

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext({})
export const useAuth0 = () => useContext(Auth0Context)

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [authToken, setAuthToken] = useState('')
  const [userIdToken, setUserIdToken] = useState(null)
  const [auth0Client, setAuth0] = useState(null)
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [isOrganisationMember, setIsOrganisationMember] = useState(null)
  const [orgData, setOrgData] = useState(null)
  const [userPermissions, setUserPermissions] = useState([])
  const [loginRedirectCompleted, setLoginRedirectCompleted] = useState()

  const tokenNamespace = 'https://magellan.oxbowpartners.com/details'

  function initializeOrgData(userInfo) {
    try {
      const userOrganizationData = userInfo[tokenNamespace]['organisation']
      if (!userOrganizationData) {
        setIsOrganisationMember(false)
        return
      }
      setIsOrganisationMember(true)
      setOrgData(userOrganizationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const postLoginSetup = async auth0 => {
    const user = await auth0.getUser()
    let token = await auth0.getTokenSilently()
    const decodedToken = jwtDecode(token)
    const { __raw: userIdToken } = await auth0.getIdTokenClaims()
    const perms = [
      ...decodedToken?.permissions?.slice(),
      ...decodedToken[tokenNamespace]?.permissions.slice()
    ]
    setUserPermissions(perms)
    initiateAxiosInstance({ authToken: token })
    setUserIdToken(userIdToken)
    setAuthToken(token)
    initializeOrgData(user)
    setUser(user)
    setIsAuthenticated(true)
    analytics.setUserId(user?.sub)
  }

  useEffect(() => {
    const initAuth0 = async () => {
      setLoading(true)
      try {
        const auth0 = await createAuth0Client({
          ...initOptions,
          cacheLocation: 'localstorage'
        })

        setAuth0(auth0)
        if (window.location.search.includes('code=')) {
          const { appState } = await auth0.handleRedirectCallback()
          onRedirectCallback(appState)
          setLoginRedirectCompleted(appState)
        }
        const isAuthenticated = await auth0.isAuthenticated()
        if (isAuthenticated) {
          await postLoginSetup(auth0)
        } else {
          Cookies.remove('commentoCommenterToken')
        }
        setLoading(false)
      } catch (error) {
        Cookies.remove('commentoCommenterToken')
        setLoading(false)
      }
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    await postLoginSetup(auth0Client)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    // const user = await auth0Client.getUser()
    setLoading(false)
    await postLoginSetup(auth0Client)
  }

  const getUser = () => {
    let userDetails = {}
    if (user) {
      userDetails = { ...user }
      userDetails.name = user.name === user.email ? user.nickname : user.name
    }

    return userDetails
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user: getUser(),
        userPermissions,
        popupOpen,
        loading,
        loginWithPopup,
        handleRedirectCallback,
        orgData,
        isOrganisationMember,
        authToken,
        userIdToken,
        loginRedirectCompleted,
        setLoginRedirectCompleted,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        loginWithRedirect: (...p) => {
          const screenHint = p && p[0] && p[0]['screen_hint']
          // console.log('fsl screenHint:', screenHint)
          analytics.createEvent({
            category: 'Auth',
            action:
              screenHint === 'signup'
                ? 'SignUp Button Clicked'
                : 'Login Button Clicked',
            label: screenHint === 'signup' ? 'SignUp' : 'Login'
          })
          auth0Client.loginWithRedirect(...p)
        },
        logout: (...p) => {
          // To resolve the same commento user issue on different magellan accounts
          analytics.createEvent({
            category: 'Auth',
            action: 'Logout Button Clicked',
            label: 'Logout'
          })
          Cookies.remove('commentoCommenterToken')
          sessionStorage.clear()
          auth0Client.logout({
            ...p,
            returnTo: `${window.location.origin}`
          })
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
