import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, {useState} from 'react'
import {withStyles} from '@material-ui/core/styles'

import Chip from '../../../../../Helpers/Chip'

const StyledChip = withStyles(theme => ({
  root: {
    background: theme.palette.primary.mainDarkBlue,
    borderColor: theme.palette.primary.mainDarkBlue,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '& .MuiChip-icon': {
      order: 1,
    }
  },
  clickable: {
    '&:hover': {
      background: theme.palette.primary.mainDarkBlue,
    },
    '&:focus': {
      background: theme.palette.primary.mainDarkBlue,
    },
  },
  label: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(Chip)

const StyledExpandMoreIcon = withStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
  }
}))(ExpandMoreIcon)

const StyledExpandLessIcon = withStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
  }
}))(ExpandLessIcon)

const CaseStudyChip = ({ plural }) => {
  const [isExtended, setIsExtended] = useState(false)

  const handleClick = () => {
    setIsExtended(isExtended => !isExtended)
  }

  return <StyledChip
    clickable
    icon={isExtended ? <StyledExpandLessIcon/> : <StyledExpandMoreIcon/>}
    label={plural ? 'Case Studies' : 'Case Study'}
    onClick={handleClick}
  />
}

export default CaseStudyChip
