import React from 'react'
import { ReactComponent as PassPoints } from 'assets/icons/PassPoints.svg'
import { Grid, Typography } from '@material-ui/core'
import { BtnsBlock } from './BtnsBlock'
import { resetPassword } from 'utils/api'

export const UserAccountPassword = ({
  isEditModeOpened,
  setIsPasswordEditModeOpen,
  handleActiveBlock
}) => {
  const handleResetPassword = async () => {
    try {
      const windowReference = window.open()
      const response = await resetPassword()
      if (response.uri) {
        // fix for safari
        windowReference.location.href = response.uri
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container>
      {isEditModeOpened ? (
        <>
          <Typography variant="subtitle2">
            If you want to change password, please follow with changing password
            process by clicking button below
          </Typography>
          <BtnsBlock
            cancelPlaceholder="Cancel"
            savePlaceholder="Proceed with change password"
            isDeleteButtonVisible={false}
            handleCancel={() => {
              handleActiveBlock(isEditModeOpened)
              setIsPasswordEditModeOpen(false)
            }}
            handleSave={() => handleResetPassword()}
          />
        </>
      ) : (
        <Typography variant="subtitle2">
          <PassPoints />
        </Typography>
      )}
    </Grid>
  )
}
