import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useCheckboxListItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    opacity: props => (props.selected ? 1 : 0.56)
  },

  labelContainer: {
    flex: 1,
    fontSize: '16px'
  },
  icon: {
    width: 'auto',
    height: '20px',
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  checkedDot: {
    display: 'block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.primaryBig
  }
}))

export const CheckboxListItem = props => {
  const { label, selected } = props
  const classes = useCheckboxListItemStyles({ selected })

  return (
    <div className={classes.root}>
      <Box mr={1.5} className={classes.checkedDot}></Box>
      <div className={classes.labelContainer}>{label}</div>
    </div>
  )
}
