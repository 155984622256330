import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
// import { useNavContext } from 'components/Contexts/NavbarContext'

export default function StyledLink({
  children,
  to,
  selectedClass,
  startPage,
  style,
  // collection,
  selected = false,
  ...rest
}) {
  // const { isCollectionsSelected } = useNavContext()

  const isRouteMatching = useRouteMatch(to)

  return (
    <Link
      style={{
        ...style,
        textDecoration: 'none',
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
      }}
      to={to}
      className={clsx({
        [selectedClass]:
          // (collection && isCollectionsSelected) ||
          selected || startPage || isRouteMatching
      })}
      {...rest}
    >
      {children}
    </Link>
  )
}
