import React, { useContext, useState, useEffect, useRef, useMemo } from 'react'

import { useFilterStateContext } from './FilterStateContext'
import { useQuery } from 'react-query'
import { useAuth0 } from './AuthContext'
import { useQueryStringValues } from './QueryStringContext'
import { useCustomisationJourney } from './CustomisationJourneyContext'
import { fetchSearchResults } from './SearchContext'

const HomePageContext = React.createContext()
export const useHomePageContext = () => useContext(HomePageContext)

export default function HomePageContextProvider({ children }) {
  const [searchHomePageResults, setSearcHomePagehResults] = useState({})
  const [loadingResults, setLoadingResults] = useState(false)
  const [searchResultsLength, setSearchResultsLength] = useState(0)
  const {
    filterValues,
    handleChangeInSearchAggregations,
    bootstrappingFilters,
    defaultFilters
  } = useFilterStateContext()
  const { sortField, sortOrder } = useQueryStringValues()
  const { isAuthenticated } = useAuth0()
  const firstRenderRef = useRef(true)

  const [excludeIds, setExcludeIds] = useState([])
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false)
  const { customisationJourneyData } = useCustomisationJourney()

  const [newFilters, setNewFilters] = useState({})

  useEffect(() => {
    if (Object.keys(customisationJourneyData).length > 0) {
      const isArrayLength = Object.values(customisationJourneyData).filter(
        i => i && i.length > 0
      )
      if (isArrayLength.length > 0) {
        setNewFilters({
          ...defaultFilters,
          insuranceClass: customisationJourneyData.insuranceClass,
          insuranceSubclass: customisationJourneyData.insuranceSubclass,
          primaryFunction: customisationJourneyData.primaryFunction,
          geographiesWithImplementations:
            customisationJourneyData.regionsAndCountries,
          relatedTechnology: customisationJourneyData.relatedTechnology
        })
      }
    }
  }, [defaultFilters, customisationJourneyData])

  const isFilterEmpty = useMemo(() => {
    let isFilterEmpty = []
    if (newFilters && Object.entries(newFilters).length > 0) {
      Object.values(newFilters).forEach(i => {
        if (Array.isArray(i)) {
          if (i.length > 0) {
            isFilterEmpty.push(...i)
          }
        }
      })
      return isFilterEmpty.length > 0 ? false : true
    }
  }, [newFilters])

  const appliedFilter = isFilterEmpty ? defaultFilters : newFilters

  const trendingQueryStringParameters = {
    page: 1,
    pageSize: 5,
    isFor: 'trend'
  }

  const defaultQueryStringParameters = {
    ...trendingQueryStringParameters,
    sortField: 'company.interestedIn',
    sortOrder: 'desc'
  }

  const params = isFilterEmpty
    ? defaultQueryStringParameters
    : trendingQueryStringParameters

  const {
    isLoading: isHomePageResultsLoading,
    isError,
    error,
    data: searchResultsResponse,
    isIdle: isSearchResultsRequestIdle
    //} = useMemo(() => ({ isLoading: false, isIdle: false,  data: { products:[], aggregations:{}, totalResults: 1 } }), []);
  } = useQuery([appliedFilter, params], fetchSearchResults, {
    enabled: isAuthenticated && filterValues && !bootstrappingFilters
  })

  useEffect(() => {
    if (isHomePageResultsLoading || isSearchResultsRequestIdle)
      return setLoadingResults(true)
    if (isError) return setLoadingResults(false)

    const { products, totalResults } = searchResultsResponse

    if (totalResults === 0) {
      setNewFilters({ ...defaultFilters })
    }

    setSearchResultsLength(totalResults)
    setSearcHomePagehResults(() => {
      const productDocs = products.reduce((acc, product) => {
        acc[product.id] = product
        return acc
      }, {})
      return productDocs
    })
    setLoadingResults(false)
  }, [
    handleChangeInSearchAggregations,
    isHomePageResultsLoading,
    isError,
    error,
    searchResultsResponse,
    isSearchResultsRequestIdle,
    sortField,
    sortOrder,
    defaultFilters
  ])

  useEffect(() => {
    firstRenderRef.current = false
  }, [])

  return (
    <HomePageContext.Provider
      value={{
        searchHomePageResults,
        setSearcHomePagehResults,
        loadingResults,
        searchResultsLength,
        excludeIds,
        setExcludeIds,
        isHomePageResultsLoading,
        contactUsModalOpen,
        setContactUsModalOpen
      }}
    >
      {children}
    </HomePageContext.Provider>
  )
}
