import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { BaseLayoutBody } from 'components/Layouts/Base'
import { useStyles } from './useStyles'
import StyledLink from 'components/Helpers/StyledLink'
import { UserAccountInformation } from './UserInformation/UserAccountInformation'
import { useCustomisationJourney } from 'components/Contexts/CustomisationJourneyContext'

export const ProfileHeader = () => {
  const classes = useStyles()
  return (
    <div className={classes.profileHeader}>
      <Typography variant="h1" className={classes.accountInfMainTitle}>
        Your profile
      </Typography>

      <ul className={classes.toolbarList}>
        <li>
          <StyledLink to="/personal-account" selectedClass="selected">
            Account information
          </StyledLink>
        </li>
      </ul>
    </div>
  )
}

const UserAccount = () => {
  const {
    customisationJourneyData,
    setCustomisationJourneyData,
    personalData,
    setPersonalData
  } = useCustomisationJourney()

  const createPath = () => {
    const path = window.location.pathname.split('/')
    return path[path.length - 1]
  }

  const componentToRender = () => {
    switch (createPath()) {
      case 'personal-account':
        return (
          <UserAccountInformation
            customisationJourneyData={customisationJourneyData}
            setCustomisationJourneyData={setCustomisationJourneyData}
            personalData={personalData}
            setPersonalData={setPersonalData}
          />
        )
    }
  }
  return (
    <BaseLayoutBody
      parentBodyBg="#F2F0EF"
      rightPanel={
        <>
          <ProfileHeader />
          <Grid>{componentToRender()}</Grid>
        </>
      }
      bottomBanner={false}
    ></BaseLayoutBody>
  )
}

export default UserAccount
