import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Typography,
  Grid,
  Divider
} from '@material-ui/core'
import staticFilterConfig from 'configs/filters.config'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import EditIcon from '@material-ui/icons/Edit'
import { useStyles } from './useStyles'
import EditSearcModal from './EditSearcModal'
import DeleteSavedSearchModal from './DeleteSavedSearchModal'
import { useSearchContext } from 'components/Contexts/SearchContext'
import { ChipsToShow } from '../ChipsToShow'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'

export function MobileTable(props) {
  const classes = useStyles()
  const {
    row,
    setFilterValues,
    setReloadSavedSearches,
    savedSearchesData,
    text,
    getSelectedFilters,
    getAppliedFilters,
    setSavedSearchModalOpen,
    appliedFiltersOpen,
    isFullUser,
    maxCheckedFiltersLength
  } = props
  const [open, setOpen] = useState(false)
  const { suggestInputsFiltersData } = useFilterStateContext()
  const { setExcludeIds } = useSearchContext()
  const [isEditSearchOpen, setEditSearchOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const [filtersChips, setFiltersChips] = useState({})
  const [allResData, setAllResData] = useState({})

  useEffect(() => {
    if (savedSearchesData && savedSearchesData.length > 0) {
      setAllResData(savedSearchesData)
    }
  }, [savedSearchesData])

  const deleteSavedSearch = () => {
    setDeleteModalOpen(true)
  }
  const filterDetails = useMemo(
    () => ({
      ...suggestInputsFiltersData,

      ...staticFilterConfig.categoryFilters.filters
    }),
    [suggestInputsFiltersData]
  )

  const getSelectedFiltersData = useMemo(() => {
    const filters = getSelectedFilters(allResData, filterDetails)
    return filters
  }, [allResData, filterDetails, getSelectedFilters])

  useEffect(() => {
    const newSelectedFilters =
      allResData.search_query === ''
        ? { ...getSelectedFiltersData }
        : {
            ...getSelectedFiltersData,
            search_query: allResData.search_query
          }

    setFiltersChips(newSelectedFilters)
  }, [allResData.search_query, getSelectedFiltersData])

  const getNewResultsData = () => {
    if (allResData) {
      setExcludeIds(allResData?.products_snapshot?.ids)
    }
    setSavedSearchModalOpen(false)
  }

  return (
    <Grid key={row.name}>
      <Box>
        <Typography variant="h4" className={classes.titleName}>
          Search
        </Typography>
        <Box style={{ display: 'flex' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              open
                ? setOpen(false)
                : appliedFiltersOpen(row.id, open, setOpen, setAllResData)
            }
          >
            {open ? (
              <ArrowForwardIosIcon
                className={classes.collapseIcon}
                style={{ transform: 'rotate(90deg)' }}
              />
            ) : (
              <ArrowForwardIosIcon className={classes.collapseIcon} />
            )}
          </IconButton>
          <div className={classes.tableName} style={{ width: '200px' }}>
            <Typography
              onClick={() => getAppliedFilters(row.id, setAllResData)}
              className={classes.savedSearchName}
            >
              {row.name}
            </Typography>
            {isFullUser && open ? (
              <EditIcon
                className={classes.editIcon}
                onClick={() => {
                  setEditSearchOpen(true)
                }}
              />
            ) : (
              ''
            )}
          </div>
        </Box>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.collapseBox}
        >
          <Box pl={0} pr={0}>
            <Typography variant="h4" gutterBottom className={classes.titleName}>
              Applied filters
            </Typography>
            <Box
              mt={1}
              mb={2}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center'
              }}
            >
              {filtersChips.search_query && filtersChips.search_query !== ' ' && (
                <span
                  className={classes.searchTerm}
                  style={{
                    marginTop: 10
                  }}
                >
                  <span>{filtersChips.search_query}</span>{' '}
                </span>
              )}
              {Object.entries(filtersChips)?.map(([filterName, el]) => {
                return (
                  <React.Fragment key={filterName}>
                    <ChipsToShow
                      data={el?.values}
                      isFromJourney={true}
                      count={5}
                      marginTop="sm"
                      btnLabel="See"
                    />
                  </React.Fragment>
                )
              })}
            </Box>
          </Box>
        </Collapse>
        <div align="left" style={{ width: '200px' }}>
          <Typography variant="h4" className={classes.titleName}>
            Total results
          </Typography>
          <Typography>{row.total_results}</Typography>
        </div>
        <div align="left" style={{ width: '200px' }}>
          <Typography variant="h4" className={classes.titleName}>
            New results
          </Typography>
          <Typography
            onClick={() =>
              row.new_results > 0 ? getNewResultsData(row.id) : null
            }
            style={{
              cursor: open && 'pointer',
              pointerEvents: !open && 'none',
              color: open && row.new_results > 0 && '#0E90D9'
            }}
          >
            {row.new_results}
          </Typography>
        </div>
        <div
          align="left"
          style={{ width: '200px' }}
          className={classes.tableDeleteBtn}
          onClick={() => deleteSavedSearch(row.name)}
        >
          <Typography>Delete</Typography>
        </div>
        <Divider style={{ margin: '20px 0' }} />
      </Box>
      <DeleteSavedSearchModal
        setFilterValues={setFilterValues}
        setReloadSavedSearches={setReloadSavedSearches}
        id={row.id}
        setDeleteModalOpen={setDeleteModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        title="Delete saved search"
        text={text()}
      />

      <EditSearcModal
        getAppliedFilters={getAppliedFilters}
        isEditSearchOpen={isEditSearchOpen}
        setEditSearchOpen={setEditSearchOpen}
        name={row.name}
        id={row.id}
        setFilterValues={setFilterValues}
        filtersChips={filtersChips}
        setFiltersChips={setFiltersChips}
        setAllResData={setAllResData}
        allResData={allResData}
        setOpen={setOpen}
        setReloadSavedSearches={setReloadSavedSearches}
        maxCheckedFiltersLength={maxCheckedFiltersLength}
      />
    </Grid>
  )
}
