import { makeStyles } from '@material-ui/core'
import { derivedFromProp } from 'utils'

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    flexGrow: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: derivedFromProp('isMobileDevice', {
      default: 'row',
      true: 'column'
    }),
    position: 'relative',
    zIndex: 1,

    overflow: derivedFromProp('isMobileDevice', {
      default: null,
      true: 'hidden'
    }),
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 0
    }
  },
  leftPanel: {
    background: `${theme.palette.secondary.mainLightGrey} !important`,
    display: 'flex',
    justifyContent: derivedFromProp('isMobileDevice', {
      default: 'flex-end',
      true: 'flex-start'
    }),
    borderRight: derivedFromProp('leftPanelBorder', {
      default: 'none',
      true: '1px solid #c8c8c8'
    }),
    padding: derivedFromProp('leftPanelPadding', {
      none: '0 !important',
      default: theme.spacing(3, 6),
      xs: theme.spacing(3, 0, 3, 6),
      sm: theme.spacing(3, 0, 6, 12.5)
    }),
    '&.leftSideNav': {
      padding: theme.spacing(6, 3, 6, 3),
      position: 'absolute',
      width: '75%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1
    },
    [theme.breakpoints.up(1920)]: {
      padding: derivedFromProp('leftPanelPadding', {
        sm: theme.spacing(3, 0, 6, 35)
      })
    },
    [theme.breakpoints.down('md')]: {
      background: theme.palette.primary.darkMain,
      padding: derivedFromProp('leftPanelPadding', {
        sm: theme.spacing(3, 0, 6, 4),
        default: theme.spacing(3, 2.5, 3, 2.5)
      })
    },
    [theme.breakpoints.down(1160)]: {
      padding: derivedFromProp('leftPanelPadding', {
        sm: theme.spacing(3, 0, 6, 6)
      })
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: derivedFromProp('leftPanelPadding', {
        padding: theme.spacing(0),
        default: theme.spacing(3, 2.5, 6, 2.5)
      }),
      '&.leftSideNav': {
        width: '30%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '&.leftSideNav': {
        // width: '70%',
        overflow: 'auto',
        width: '100%'
      }
    }
  },
  navbarWrapper: {
    position: 'fixed',
    width: '100%',
    left: 0,
    top: 0,
    zIndex: 1300,
    '&.small': {
      boxShadow: theme.shadows[8]
    }
  },
  navbarBg: {
    background:
      'linear-gradient(130.97deg, rgb(6, 35, 61) 46.13%, rgb(28, 86, 119) 75.17%)',
    opacity: 0,
    transition: 'opacity 200ms linear',
    position: 'absolute',
    // width: '100%',
    height: '100%',
    display: 'block',
    right: '3px',
    left: 0,
    borderBottomLeftRadius: '50px',
    '&.invisible': {
      display: 'none'
    },
    '&.opaque': {
      opacity: 1
    }
  },
  rightPanel: {
    flex: 1,
    minHeight: 0,
    transition: 'transform 225ms linear',
    zIndex: 2,
    background: derivedFromProp('isProductPage', {
      default: theme.palette.background.default,
      true: '#F8F8FA'
    }),
    backgroundColor: derivedFromProp('backgroundRightPanel', {
      default: theme.palette.background.default,
      grey: '#F8F8FA'
    }),
    '&.slideRight': {
      transform: 'translateX(75%)',
      [theme.breakpoints.down('sm')]: {
        transform: 'translateX(30%)'
      },
      [theme.breakpoints.down('xs')]: {
        transform: 'translateX(100%)'
      }
    }
  },
  headerSpacer: {
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 50
    }
  },
  pageHeader: {
    borderBottomLeftRadius: derivedFromProp('borderBottomLeftRadius', {
      default: '50px',
      false: '0'
    }),
    borderBottomRightRadius: derivedFromProp('borderBottomRightRadius', {
      true: '50px',
      false: '0',
      default: '0'
    }),
    background: derivedFromProp('backgroundHeader', {
      default:
        'linear-gradient(130.97deg, rgb(6, 35, 61) 46.13%, rgb(28, 86, 119) 75.17%)',
      grey: theme.palette.primary.lighterGrey
    }),
    // padding: '90px 100px 0',
    display: 'flex',
    zIndex: 2,
    position: 'sticky',
    flexDirection: 'column',
    border: 0,
    boxSizing: 'border-box'
  },
  pageHeaderExtra: {
    [theme.breakpoints.up(1920)]: {
      padding: '100px 280px 0'
    },

    [theme.breakpoints.down(1920)]: {
      padding: '100px 100px 0'
    },

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(13.5, 6, 0, 4)
    },

    [theme.breakpoints.down(1160)]: {
      padding: theme.spacing(13.5, 2, 0, 6)
    },
    [theme.breakpoints.down('sm')]: {
      padding: derivedFromProp('padding', {
        default: theme.spacing(13.5, '20px', 0, 2),
        secondary: theme.spacing(13.5, '20px', 4, 2)
      })
    },
    [theme.breakpoints.down('xs')]: {
      zIndex: derivedFromProp('isManageCollectionOpen', {
        true: 2,
        false: 1,
        default: 2
      })
    }
  },
  parentBody: {
    transform: 'translateX(0)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1)',
    position: 'relative',
    zIndex: 0,
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  contentShift: {
    transform: 'translateX(-300px)'
  },
  defaultGreyBg: {
    background: props =>
      props.parentBodyBg && typeof props.parentBodyBg == 'string'
        ? props.parentBodyBg
        : '#F8F8FA !important'
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    color: '#35AAEC'
  }
}))
