import React, { useEffect, useRef } from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Portal,
  Typography
} from '@material-ui/core'

import moment from 'moment'

import { goBackComponent } from 'components/Pages/NewProductDetails/Components/Header'
import clsx from 'clsx'
import useRect from 'utils/customHooks/useRect'
import { useNavContext } from 'components/Contexts/NavbarContext'
import ExportCSV from 'components/Helpers/ExportCSV'
import { useStyles } from './useStyles'

const StickyNavBar = ({ classes, goBack, productName }) => {
  return (
    <Grid
      container
      className={clsx(classes.sectionNavigationContainer, 'sticky')}
    >
      {goBackComponent(classes, goBack)}
      <Box>
        <Grid container pr={0} className="navItems">
          <Typography
            variant="h4"
            style={{
              margin: '0 0 10px'
            }}
          >
            {productName}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  )
}
const InsightsHeader = ({
  postData,
  isAuthenticated,
  loading,
  showStickyNavBar,
  setShowStickyNavBar,
  history
}) => {
  const { navHeight } = useNavContext()

  const classes = useStyles({ showStickyNavBar, navHeight })
  const sectionNavigationElRef = useRef()
  const dateToDisplay = moment(
    postData && postData.datePublished
      ? postData.datePublished
      : postData?.collection_snapshot?.created_at
  ).format('LL')

  const { top: sectionNavigationTopOffset } = useRect(sectionNavigationElRef)
  useEffect(() => {
    const scrollEventListener = function () {
      let scrollPosition = window.scrollY
      if (
        scrollPosition + navHeight >= sectionNavigationTopOffset + 60 &&
        !showStickyNavBar
      ) {
        setShowStickyNavBar(true)
      } else if (
        showStickyNavBar &&
        scrollPosition + navHeight < sectionNavigationTopOffset + 60
      ) {
        setShowStickyNavBar(false)
      }
    }
    document.addEventListener('scroll', scrollEventListener, { passive: true })
    return () => document.removeEventListener('scroll', scrollEventListener)
  }, [
    sectionNavigationTopOffset,
    navHeight,
    showStickyNavBar,
    setShowStickyNavBar
  ])
  return (
    <>
      <Grid
        ref={sectionNavigationElRef}
        container
        justify="space-between"
        alignItems="center"
        style={{
          paddingBottom: 50
        }}
      >
        {loading || !postData || Object.keys(postData).length === 0 ? (
          <Container
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <CircularProgress color="inherit" />
          </Container>
        ) : (
          <>
            <Box>
              {(postData.nameOfArticle || postData.name) && (
                <Typography
                  variant="h3"
                  style={{
                    paddingBottom: 10,
                    fontSize: 24
                  }}
                >
                  {postData.nameOfArticle || postData.name}
                </Typography>
              )}
              {(postData.articleAuthor ||
                postData.dateToDisplay ||
                postData.type) && (
                <Grid container>
                  <Typography variant="h6">
                    {postData.type === 'admin'
                      ? 'Oxbow Partners collection'
                      : postData.articleAuthor}
                  </Typography>
                  &nbsp; &nbsp;
                  <Typography variant="h6">{dateToDisplay}</Typography>
                </Grid>
              )}
            </Box>
            {isAuthenticated && postData.type === 'admin' && (
              <div className={classes.bookmarkSettings}>
                <ExportCSV
                  productIdsOfSelectedCollection={postData.products || []}
                  selectedCollection={postData}
                  collectionNameState={postData.name}
                />
              </div>
            )}
          </>
        )}
      </Grid>

      {showStickyNavBar && (
        <Portal>
          <StickyNavBar
            goBack={() => history.goBack()}
            classes={classes}
            productName={postData.nameOfArticle || postData.name}
          />
        </Portal>
      )}
    </>
  )
}

export const MemoizedInsightsHeader = React.memo(InsightsHeader)
