import { useEffect, useMemo, useState } from 'react'

import { excludedDataKeys } from './index'
import { useCan } from 'rbac/can'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import { useAuth0 } from 'components/Contexts/AuthContext'

export const getSanitizedProductData = productData =>
  Object.entries(productData)
    .map(([sectionKey, sectionData]) => {
      if (excludedDataKeys.includes(sectionKey)) {
        return null
      }

      let shouldRender = false
      sectionData.content &&
        Object.values(sectionData.content).forEach(val => {
          if (
            val !== null ||
            val !== undefined ||
            (Array.isArray(val) && val.length !== 0) ||
            (typeof val === 'object' &&
              val.content &&
              ((typeof val.content === 'object' &&
                Object.values(val.content).length > 0) ||
                val.content !== undefined ||
                val.content !== null ||
                val.content !== ''))
          ) {
            shouldRender = true
          }
        })
      if (!shouldRender) {
        return null
      }
      return [sectionKey, sectionData]
    })
    .filter(item => item !== null)
    .reduce((acc, [sectionKey, sectionData]) => {
      acc[sectionKey] = sectionData
      return acc
    }, {})

export function formatSidebarOptions(productData, inEditMode, isProductLocked) {
  let finalOptions = {}
  Object.entries(productData)
    .filter(([_, sectionData]) => inEditMode || !sectionData.isContentMissing)
    .forEach(([sectionKey, sectionData]) => {
      const isLocked = sectionKey === 'overview' ? false : isProductLocked
      finalOptions[sectionData.sectionTitle] = {
        key: sectionKey,
        sidebarOption: sectionData,
        show: true,
        isLocked
      }
    })
  return finalOptions
}

export const sanitizeUrl = url => {
  if (url.indexOf('https://') !== -1) {
    url = url.slice(url.indexOf('https://') + 'https://'.length)
  }
  if (url.indexOf('http://') !== -1) {
    url = url.slice(url.indexOf('http://') + 'http://'.length)
  }
  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }
  return url
}

export const useIsProductLocked = ({ isLoadingData, productData }) => {
  const { specialAccessibleProducts } = useCollections()
  const specialAccessibleProductsIds = useMemo(() => {
    if (specialAccessibleProducts && specialAccessibleProducts.length > 0) {
      return specialAccessibleProducts.map(product => product.prodId)
    }
    return null
  }, [specialAccessibleProducts])

  const { orgData } = useAuth0()
  const [isUserVendorProduct, setIsUserVendorProduct] = useState(false)
  const [isUserCollectionProduct, setIsUserCollectionProduct] = useState(false)
  const isFullUser = useCan('content_depth:all')

  const userHasFullAccess = useMemo(
    () => isFullUser || isUserVendorProduct || isUserCollectionProduct,
    [isFullUser, isUserCollectionProduct, isUserVendorProduct]
  )

  useEffect(() => {
    if (isLoadingData) return
    if (productData) {
      const companyId =
        productData?._contentful?._source?.fields?.company?.sys?.id
      if (companyId && companyId === orgData?.description)
        setIsUserVendorProduct(true)
      else setIsUserVendorProduct(false)
    }
  }, [isLoadingData, productData, setIsUserVendorProduct, orgData])

  useEffect(() => {
    if (isLoadingData) return
    if (
      productData &&
      specialAccessibleProductsIds &&
      specialAccessibleProductsIds.length > 0
    ) {
      const productId = productData?._contentful?._id
      if (productId && specialAccessibleProductsIds.includes(productId)) {
        setIsUserCollectionProduct(true)
      } else setIsUserCollectionProduct(false)
    }
  }, [
    isLoadingData,
    productData,
    specialAccessibleProductsIds,
    setIsUserCollectionProduct
  ])

  return !userHasFullAccess
}
