import React, { useMemo } from 'react'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { ProductBottomBanner } from 'components/Helpers/Banner/ProductBottomBanner'
import { Link } from 'react-router-dom'
import ProductTile from 'components/Helpers/ProductTile/ProductTile'
import { useQueryStringValues } from 'components/Contexts/QueryStringContext'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ChipsToShow } from 'components/Helpers/ChipsToShow'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

import { PostPageHeader } from './PostPageHeader'
import Chip from 'components/Helpers/Inputs/ChipInput/Chips'

import { useStyles } from './useStyles'

const InsightsMainBlock = ({
  postData,
  collectionType,
  history,
  isAuthenticated,
  loginWithRedirect
}) => {
  const classes = useStyles()
  const {
    setSelectedCollectionTag,
    setIsFromCollectionPage
  } = useQueryStringValues()

  const setArticleType = articleType => {
    setIsFromCollectionPage(true)
    setSelectedCollectionTag(articleType)
    // setSelectedContentType(articleType)
    // setSortedBy('desc')
    history.push('/insights')
  }

  const Bold = ({ children }) => (
    <Typography variant="h4" component="p" className="bold">
      {children}
    </Typography>
  )

  const Text = ({ children }) => (
    <Typography variant="subtitle2" component="h5" className="align-center">
      {children}
    </Typography>
  )

  const HyperLink = ({ href, children }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="hyperlink"
    >
      {children}
    </a>
  )

  const articleOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
      [INLINES.HYPERLINK]: node => (
        <HyperLink href={node.data.uri}>{node.content[0].value}</HyperLink>
      )
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    }
  }
  const descriptionComponent = (
    <>
      {collectionType === 'oxbow-partners' ? (
        <Typography
          variant="subtitle2"
          dangerouslySetInnerHTML={{ __html: postData?.description }}
        />
      ) : (
        <>
          {Object.keys(postData)?.length > 0 &&
            documentToReactComponents(postData, articleOptions)}
        </>
      )}
    </>
  )

  const productTilesComponent = useMemo(() => {
    const isCollection = collectionType === 'oxbow-partners'
    const relevantProducts = isCollection
      ? postData?.products
      : postData?.relevantMagellanProducts

    if (!relevantProducts?.length) {
      return null
    }
    return relevantProducts?.map((item, index) => {
      const isLastTile = index === relevantProducts?.length - 1
      const isFirstTile = index === 0
      return item ? (
        <Box mb={isLastTile ? 0 : 1} mt={isFirstTile ? 2 : 0} key={item.id}>
          <ProductTile
            fromSearchPage={false}
            productId={item.id}
            name={item.name || 'No name provided'}
            shortDescription={item.description || 'No short description'}
            slug={item.slug}
            logoUrl={item?.logo || item?.company?.logo}
            fields={item}
            rootStyles={{
              borderBottom: isLastTile && 'none'
            }}
            divider={true}
          />
        </Box>
      ) : null
    })
  }, [postData, collectionType])

  const relatedProductsLength =
    collectionType === 'oxbow-partners'
      ? postData?.products?.length
      : Object.keys(postData)?.length &&
        postData.relevantMagellanProducts?.length

  const tags = useMemo(() => {
    const categories = postData?.categories || []
    const tagsAndCategories = postData?.tagsAndCategories || []
    return [...categories, ...tagsAndCategories]
  }, [postData])

  return (
    <>
      <Grid container className={classes.singlePostPageArticle}>
        <div className={classes.chips}>
          <Chip
            label={postData.opArticleType ? postData.opArticleType : ''}
            onClick={() => setArticleType(postData.opArticleType)}
          />
        </div>

        <ChipsToShow
          data={tags}
          isFromJourney={true}
          marginTop="none"
          editable={false}
          count={5}
          isClickable={true}
          btnLabel="See"
          display="flex"
          marginX="default"
        />

        <PostPageHeader data={postData} isAuthenticated={isAuthenticated}>
          {isAuthenticated || postData.opArticleType != 'Collection' ? (
            <Box className={classes.markdown}>{descriptionComponent}</Box>
          ) : (
            <Box className={classes.slicedMarkdown}>{descriptionComponent}</Box>
          )}
        </PostPageHeader>
      </Grid>
      {isAuthenticated || postData.opArticleType != 'Collection' ? (
        <>
          {productTilesComponent?.length > 0 && (
            <Box className={classes.relatedProducts}>
              <div>
                <Grid container justify="space-between">
                  <Typography variant="h3">
                    View companies featured in this{' '}
                    {postData.opArticleType === 'Collection' ||
                    collectionType === 'oxbow-partners'
                      ? 'collection'
                      : 'article'}{' '}
                    ({relatedProductsLength})
                  </Typography>
                </Grid>
                <Divider
                  style={{
                    marginTop: 30
                  }}
                />
                {productTilesComponent}
              </div>
            </Box>
          )}

          <Box className={classes.singlePostPageRelatedContainer}>
            <Typography variant="h3">
              {postData.opArticleType === 'Collection' ||
              collectionType === 'oxbow-partners'
                ? 'Other collections to explore'
                : 'Related articles'}
            </Typography>
            <Grid container className={classes.singlePostPageRelatedBlock}>
              <>
                {postData.relatedArticles?.map(item => (
                  <Link to={`/insights/${item.id}`} key={item.id}>
                    <Grid
                      container
                      className={classes.singlePostPageRelatedArticle}
                    >
                      <img src={item.tileImage} alt="post" />
                      <Typography variant="subtitle2">
                        {item.nameOfArticle}
                      </Typography>
                    </Grid>
                  </Link>
                ))}
              </>
            </Grid>
          </Box>
          <Box
            style={{
              backgroundColor: 'white',
              paddingTop: 40
            }}
          >
            <ProductBottomBanner
              btnText="Tell us what you are looking for"
              subTitle="Need more help finding the right vendor?"
              title="Let our team of researchers do the work for you"
              background="white"
              btnWidth="max-content"
              marginBottom={false}
              divider={true}
            />
          </Box>
        </>
      ) : (
        <Box className={classes.unauthorizedTitle}>
          <Typography variant="h3">
            <span
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    targetUrl: window.location.pathname
                  },
                  prompt: 'login',
                  screen_hint: 'signin'
                })
              }
            >
              Sign in
            </span>{' '}
            to read full article
          </Typography>
        </Box>
      )}
    </>
  )
}
export const MemoizedInsightsMainBlock = React.memo(InsightsMainBlock)
