import {Divider, List, makeStyles, Typography} from '@material-ui/core'
import React from 'react'

import ClientListItem from "./ClientListItem"

const useStyles = makeStyles(() => ({
  noDataText: {
    fontWeight: 400,
    opacity: 0.5
  }
}))
export const ExistingClientList = ({productData}) => {
  const classes = useStyles()

  const isProductData = productData?.detailedClientInformation?.length > 0
  if (isProductData) {
    return (
      <List>
        {productData?.detailedClientInformation?.map((product, key) => (
          <React.Fragment key={key}>
            {key !== 0 && <Divider variant="fullWidth" component="li"/>}
            <ClientListItem product={product}/>
          </React.Fragment>
        ))}
      </List>
    )
  }

  return (
    <Typography variant="h4" className={classes.noDataText}>
      No relevant content
    </Typography>
  )
}