import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'
export const useStyles = makeStyles(theme => ({
  mainThxModal: {
    padding: '30px 30px 50px',
    '& p': {
      margin: '0 !important'
    }
  },
  contactUsMainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  pricingHeaderTitle: {
    margin: '35px 0 70px',
    textAlign: 'start',
    maxWidth: '70%',
    color: '#fff',
    fontWeight: 700,

    [theme.breakpoints.down('xs')]: {
      margin: '15px 10px 19px',

      '& :first-child': {
        lineHeight: '54px',
        fontSize: '45px'
      }
    }
  },

  pricingMainBox: {
    textAlign: 'start',
    margin: '10px',
    padding: '45px 0 0 0',
    backgroundColor: '#F8F8FA',
    maxWidth: '400px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #CCC',
    '& img': {
      width: '38px',
      height: '47px'
    },
    [theme.breakpoints.up(1920)]: {
      margin: '20px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '310px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      maxWidth: '400px'
    }
  },
  article: {
    padding: '27px 60px 40px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& >h3': {
      textAlign: 'center',
      marginBottom: '27px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '27px 25px 40px'
    }
  },
  articleContainer: {
    display: 'flex',
    '& h4': {
      fontWeight: 400,
      marginBottom: '15px'
    }
  },
  fieldName: {
    fontWeight: 500,
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '35px'
    }
  },
  dot: {
    padding: '9px 19px 0 0',
    '&::before': {
      display: 'block',
      content: "''",
      width: '10px',
      height: '10px',
      background: theme.palette.primary.primaryBig,
      borderRadius: '50%'
    }
  },
  tellUsText: {
    margin: 'auto',
    '& >h3': {
      textAlign: 'center'
    }
  },
  pricingBtn: {
    marginTop: 'auto',
    width: '100%'
  },
  modalContainer: {
    overflow: 'auto'
  },

  modalMainContainer: {
    height: 'max-content',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    background: '#fff',
    textAlign: 'center',
    '& h2': {
      margin: '30px 0 10px 0',
      lineHeight: '43px',
      fontSize: '36px',
      fontWeight: 500,
      fontFamily: '"Barlow Condensed", sans-serif',
      [theme.breakpoints.down('md')]: {
        margin: '20px 0 10px 0'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '26px'
      }
    },
    '& p': {
      margin: '13px 0 15px 0',
      fontSize: '18px',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    '& a': {
      textDecoration: 'none'
    },

    [theme.breakpoints.down('xs')]: {
      width: '400px'
    }
  },

  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    width: '12px',
    height: '12px'
  },

  contactUsMainModalContainer: {
    padding: '0 35px 58px 50px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 40px 50px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px 30px 50px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 30px 10px 30px'
    }
  },
  modalMainContent: {
    position: 'relative'
  },
  inputError: {
    border: '1px solid red !important',
    backgroundColor: '#FFF4F4'
  },
  mainInputContainer: {
    width: '550px',
    '& input, textarea': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
      boxShadow: 'unset',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '-webkit-border-radius': 'unset',
      appearance: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px'
      }
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    scrollbarColor: 'white',
    '& .mainInputBox': {
      justifyContent: 'space-between',
      '&>div': {
        textAlign: 'start',
        position: 'relative',
        marginTop: '11px',
        marginBottom: '8px',
        '& .errorText': {
          color: 'red',
          fontFamily: 'Open Sans',
          fontSize: '12px'
        },
        '& .hintError': {
          zIndex: 9,
          width: '40px',
          height: '39px',
          backgroundColor: 'red',
          position: 'absolute',
          top: '0',
          right: '0',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& >svg': {
            height: '18px'
          },
          [theme.breakpoints.down('md')]: {
            height: '35px'
          }
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
          marginBottom: 3
        }
      }
    },
    '& label, option': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    },
    '& input': {
      marginTop: '11px',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      outline: 'none',
      height: '40px',
      border: '1px solid #C8C8C8',
      display: 'block',
      width: derivedFromProp('inputWidth', {
        true: '360px',
        default: '260px'
      }),
      [theme.breakpoints.down('md')]: {
        height: '35px'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '5px'
      }
    },
    '& textarea': {
      padding: '10px',
      outline: 'none',
      border: '1px solid #C8C8C8'
    }
  },
  textAreaContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    height: '150px',
    '& label': {
      marginBottom: '11px'
    },
    '& textarea': {
      maxHeight: '120px'
    },
    [theme.breakpoints.down('md')]: {
      height: '150px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100px'
    }
  },

  checkBoxContainer: {
    textAlign: 'start',
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '11px',
    '& .errorText': {
      color: 'red',
      fontFamily: 'Open Sans',
      fontSize: '13px'
    },
    '& label': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0 0 ',
      fontSize: '16px',
      '& input': {
        height: '18px',
        width: '18px',
        marginRight: '13px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& label': {
        padding: '0'
      }
    }
  },

  contactUsBtn: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  },

  //Carousel styles

  pricingMainContainer: {
    margin: '50px 0',
    justifyContent: 'center',
    '& >:first-child': {
      borderBottomLeftRadius: 50
    },
    '& > button': {
      marginTop: 'auto'
    },
    '& >:last-child': {
      borderTopRightRadius: 50,
      '& button': {
        marginTop: 'initial'
      }
    },
    '& >:nth-child(2)': {
      border: '2px solid #00A3FF'
    },

    '& a': {
      textDecoration: 'none'
    },
    '& .carousel-root .carousel .control-dots': {
      '& .dot': {
        backgroundColor: theme.palette.primary.primaryBig
      },
      // [theme.breakpoints.down('xs')]: {
      //   width: '66% !important'
      // },
      [theme.breakpoints.down('sm')]: {
        top: 0,
        bottom: 'auto'
      }
    },
    '& .slider-wrapper ul': {
      '& >:nth-child(2)>div': {
        border: '2px solid #00A3FF'
      },
      '>:nth-child(3) h2': {
        color: '#00A3FF'
      }
    },
    [theme.breakpoints.up(1920)]: {
      margin: '20px'
    }
    // [theme.breakpoints.down('sm')]: {
    //   margin: '50px 0 100px'
    // }
  }
}))
