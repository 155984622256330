import React from 'react'
import { makeStyles } from '@material-ui/core'
import Anonymous from 'assets/Logos/ContentLogos/Anonymous.svg'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    lineHeight: 1,
    padding: 0,
    '&:not(:first-child)': {
      paddingLeft: '20px !important'
    },
    '&:last-child': {
      padding: '0 20px !important'
    },
    '@supports not (aspect-ratio: 1/1)': {
      position: 'relative'
    },
    '& img': {
      background: 'white',
      display: 'block',
      width: '60px',
      height: '60px',
      objectFit: 'contain',
      borderRadius: '50%',
      border: '1px solid #DDD'
    }
  },
  label: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '12px',
    width: '100%',
    whiteSpace: 'normal'
  }
}))

export default function IconLabel(props) {
  const {
    logo,
    icon,

    column = true,
    gutter,
    size,
    rootStyles = {},
    blurValue,
    children,
    index
  } = props
  const displayGraphicIsIamge = !!logo
  const hasGraphic = displayGraphicIsIamge || !!icon
  const classes = useStyles({ column, hasGraphic, gutter, size })

  return (
    <div
      key={index}
      className={classes.root}
      style={{ ...rootStyles, padding: !hasGraphic ? 0 : null }}
    >
      {displayGraphicIsIamge ? (
        <img
          className="clientsLogo"
          src={blurValue ? '/dummy-product-logo.jpg' : logo}
          alt="dummy-product-logo"
        />
      ) : (
        <img className="clientsLogo" src={Anonymous} alt="" />
      )}

      {!hasGraphic || children}
    </div>
  )
}
