import React from 'react'
import ArrowLeftBlue from 'assets/icons/ArrowLeftBlue.png'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { derivedFromProp } from 'utils'

const useStyles = makeStyles(theme => ({
  header: {
    color: '#fff',
    '& h1': {
      fontWeight: 700
    },
    '& h6': {
      fontWeight: 600,
      fontSize: 12
    }
  },
  backToBtn: {
    cursor: 'pointer',
    margin: derivedFromProp('margin', {
      xs: '0 0 30px',
      default: '20px 0 30px'
    }),
    color: theme.palette.primary.mainLightBlue,
    width: '200px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& h5': {
      fontWeight: 700
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
      marginTop: '10px'
    }
  }
}))

const MainHeader = ({ backBtn, margin, children }) => {
  const classes = useStyles({ margin })

  const goToPrevPage = () => {
    window.history.back()
  }
  return (
    <div className={classes.header}>
      {backBtn && (
        <Box mb={1.5} className={classes.backToBtn} onClick={goToPrevPage}>
          <img alt="" src={ArrowLeftBlue} />
          <Typography variant="h5">Back to previous page</Typography>
        </Box>
      )}
      {children}
    </div>
  )
}
export default MainHeader
