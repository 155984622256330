import { derivedFromProp } from 'utils'

const { makeStyles } = require('@material-ui/core')

export const useStyles = makeStyles(theme => ({
  singlePostPage: {
    background: '#F8F8FA',
    height: '100%',
    '& a': {
      textDecoration: 'none'
    }
  },
  productPageMainHeader: {
    animation: `$animation 400ms ${theme.transitions.easing.easeInOut}`,

    '&.relative': {
      animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
      transform: 'translateY(-50%)'
    },
    '& h3': {
      color: '#fff'
    }
  },
  sectionNavigationContainer: {
    borderBottomLeftRadius: 50,
    flexDirection: 'column',
    '& p, h4, button': {
      color: '#fff'
    },
    '& .navItems': {
      alignItems: 'baseline',
      '& h4': {
        margin: '0 55px 0 40px',
        [theme.breakpoints.down('md')]: {
          paddingBottom: 0
        }
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        '&__items': {
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }
      }
    },
    '&.sticky': {
      animation: `$animation 400ms ${theme.transitions.easing.easeInOut}`,
      padding: '24px 100px 0',
      position: 'fixed',
      width: '100%',
      left: 0,
      top: 0,
      paddingTop: props => props.navHeight - 20,
      zIndex: 2,
      background: 'linear-gradient(130.97deg, #06233D 46.13%, #1C5677 75.17%)',
      [theme.breakpoints.up(1920)]: {
        padding: '24px 280px 0'
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 6, 0, 4)
      },

      [theme.breakpoints.down(1160)]: {
        padding: '24px 10px 0 48px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      flexDirection: 'column',
      height: '100%',
      '&.sticky': {
        padding: theme.spacing(0, 3),
        height: 'auto'
      }
    }
  },
  backToBtn: {
    cursor: 'pointer',
    margin: derivedFromProp('margin', {
      xs: '0 0 30px',
      default: '20px 0 20px'
    }),
    color: theme.palette.primary.mainLightBlue,
    width: '200px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& h5': {
      fontWeight: 700
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
      marginTop: '10px'
    }
  },
  '@keyframes animation': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-50%)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  '@keyframes myEffectExit': {
    '0%': {
      transform: 'translateY(0)',
      opacity: 1
    },
    '100%': {
      transform: 'translateY(-50%)',
      opacity: 0
    }
  },
  bookmarkSettings: {
    '& .MuiButton-label': {
      display: 'flex !important',
      justifyContent: 'space-around !important'
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: 20
    }
  },
  singlePostPageArticle: {
    padding: '32px 100px 50px',
    [theme.breakpoints.up(1920)]: {
      padding: '32px 280px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '32px 48px 40px 32px'
    },
    [theme.breakpoints.down(1160)]: {
      padding: '32px 20px 40px 32px'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '32px 20px 32px 16px'
    }
  },
  relatedProducts: {
    background: '#fff',
    padding: '32px 100px',
    position: 'relative',
    '& >div': {
      width: '80%'
    },
    '& >h3': {
      fontWeight: 600,
      marginBottom: 30
    },
    [theme.breakpoints.up(1920)]: {
      padding: '32px 280px'
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 6),
      '& >div': {
        width: '100%'
      }
    },
    [theme.breakpoints.down(1160)]: {
      padding: theme.spacing(4, 2)
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing('32px', '20px')
    }
  },
  unauthorizedBlock: {
    '& h3': {
      fontWeight: 400
    },
    '& span': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      fontWeight: 700
    }
  },
  unauthorizedTitle: {
    position: 'absolute',
    padding: '32px 100px 50px',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.21) -37.06%, #FEFDFC 31.47%)',
    top: 220,
    left: 0,
    right: 0,
    bottom: 0,

    '& span': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      fontWeight: 700
    },

    '& >h3': {
      fontWeight: 400
    },
    [theme.breakpoints.up(1920)]: {
      padding: theme.spacing(4, 35, 0)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 6, 0, 4)
    },
    [theme.breakpoints.down(1160)]: {
      padding: theme.spacing(4, 2, 0, 6)
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2.5, 4, 2)
    }
  },
  singlePostPageRelatedContainer: {
    padding: '50px 100px 70px',
    backgroundColor: '#F8F8FA',
    [theme.breakpoints.up(1920)]: {
      padding: '50px 280px 60px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '50px 48px 60px 32px'
    },
    [theme.breakpoints.down(1160)]: {
      padding: '50px 16px 50px 58px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px 32px'
    },

    '& h3': {
      marginBottom: 30
    },
    '& h6': {
      fontWeight: 600
    }
  },
  singlePostPageRelatedBlock: {
    justifyContent: 'space-between',
    '& a': {
      textDecoration: 'none',
      color: '#000',
      '&:not(:last-child)': {
        marginRight: 40
      }
    },
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      flexWrap: 'wrap',
      '& a': {
        marginBottom: 30,
        '&:not(:last-child)': {
          marginRight: 0
        }
      }
    }
  },
  singlePostPageRelatedArticle: {
    maxWidth: 400,
    flexWrap: 'nowrap',
    '& img': {
      width: 115,
      height: 65,
      marginRight: 40,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'inherit'
    }
  },
  markdown: {
    lineHeight: '28px',
    '& h1, h2:first-child': {
      margin: '0 0 20px'
    },
    '& h1, h2': {
      margin: '20px 0'
    },
    '& ol, & ul': {
      paddingLeft: 20,
      fontSize: '16px !important'
    },
    '& p, h2, h3, h4, h5, h6, span, a, strong': {
      fontSize: '16px !important'
    }
  },
  slicedMarkdown: {
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5,
    overflow: 'hidden',
    '& p, h2, h3, h4, h5, h6, span, a, strong': {
      fontSize: '16px !important'
    },
    '& ol, & ul': {
      fontSize: '16px !important'
    }
  },
  chips: {
    '& >div': {
      fontSize: 13,
      marginRight: 10,
      borderRadius: 20,
      height: 28,
      border: '1px solid #00A3FF',
      background: 'rgba(0, 163, 255, 0.1)',
      transition: 'all 0.3s ease',
      color: '#000',
      '&:hover': {
        background: 'rgba(0, 163, 255, 0.3) !important',
        color: '#000',
        transition: 'all 0.3s ease'
      }
    }
  }
}))
