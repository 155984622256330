import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainPaginationContainer: {
    display: 'flex',
    '& li:last-child div >button': {
      flexDirection: 'row-reverse',
      display: 'flex'
    }
  },
  paginationBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      textTransform: 'capitalize',
      margin: '0 10px',
      [theme.breakpoints.down('xs')]: {
        margin: '0 7px'
      }
    }
  }
}))
