import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'
export const useStyles = makeStyles(theme => ({
  inputContainer: {
    height: '40px',
    marginTop: derivedFromProp('marginTop', {
      none: '0px',
      default: '11px'
    }),
    alignItems: 'center',
    backgroundColor: '#fff',
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    border: derivedFromProp('border', {
      none: 'none',
      default: '1px solid #C8C8C8'
    }),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset'
    },
    '& select': {
      zIndex: 2,
      backgroundColor: 'transparent'
    },
    '& span': {
      fontSize: '16px',
      position: derivedFromProp('isFromSearchPage', {
        true: 'unset',
        false: 'absolute'
      }),
      left: derivedFromProp('mobileSelectStyle', {
        none: 0,
        default: 10
      }),
      right: derivedFromProp('mobileSelectStyle', {
        none: 0,
        default: 0
      })
    },
    '& svg': {
      position: 'absolute',
      right: 0,
      zIndex: 1,
      top: '10px'
    }
  },
  inputContainerBlock: {
    zIndex: 9,
    width: derivedFromProp('inputWidth', {
      none: '0px',
      sm: '25%',
      default: '40%'
    }),
    [theme.breakpoints.down('sm')]: {
      width: derivedFromProp('isFromSearchPage', {
        true: '20%',
        false: '100%'
      })
    },
    [theme.breakpoints.down('xs')]: {
      width: derivedFromProp('isFromSearchPage', {
        none: '0%',
        true: '10%',
        false: '100%'
      })
    }
  },
  sortByContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingRight: 24,
    '& h4': {
      paddingRight: 10
    }
  },
  hidden: {
    marginTop: '2px',
    padding: theme.spacing(2),
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.primary.lighterDark,
    overflow: 'auto',
    maxHeight: '250px',
    zIndex: 3,
    color: 'black',
    right: derivedFromProp('positionRight', {
      true: '-120px',
      default: '0'
    }),
    width: derivedFromProp('isFromSearchPage', {
      true: 'max-content',
      false: '100%',
      default: 'max-content'
    }),

    [theme.breakpoints.down('sm')]: {
      zIndex: 11,
      width: derivedFromProp('isFromSearchPage', {
        true: 'max-content',
        false: '100%'
      })
    }
  },
  listBox: {
    backgroundColor: 'white',
    '& li': {
      fontSize: '16px',
      padding: theme.spacing(1, 0),
      display: 'flex',
      alignItems: 'center',
      '& span': {
        flexGrow: 1
      }
    },

    '& li[data-focus="true"]': {
      cursor: 'pointer'
    }
  },
  newsBlockTitle: {
    paddingBottom: 30,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0
    }
  },
  companyTypeContainer: {
    width: derivedFromProp('selectorWidth', {
      true: 225,
      false: 'unset',
      default: 'unset'
    }),
    [theme.breakpoints.down('xs')]: {
      width: derivedFromProp('selectorWidth', {
        true: 150,
        false: 'inherit',
        default: 'unset'
      })
    },
    marginTop: derivedFromProp('marginTop', {
      default: '15px',
      none: '0px'
    }),
    '& input, textarea, select': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
      boxShadow: 'unset',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '-webkit-border-radius': 'unset',
      appearance: 'none'
    },
    '& .MuiGrid-item': {
      marginRight: '10px !important'
    },
    scrollbarColor: 'white',
    '& .mainInputBox >div': {
      position: 'relative',
      marginTop: '11px',
      marginBottom: '8px',
      marginRight: '20px',
      '& .errorText': {
        marginTop: '5px',
        color: 'red',
        fontFamily: 'Open Sans',
        fontSize: '13px'
      },
      '& .hintError': {
        zIndex: 9999999,
        width: '40px',
        height: '39px',
        backgroundColor: 'red',
        position: 'absolute',
        top: '0',
        right: '0',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& >svg': {
          height: '18px'
        }
      }
    },
    '& label, option': {
      fontWeight: 600
    },
    '& input, select': {
      marginTop: '11px',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      outline: 'none',
      height: '40px',
      border: '1px solid #C8C8C8',
      display: 'block',
      width: derivedFromProp('selectorWidth', {
        true: 'unset',
        false: 350,
        default: 'unset'
      }),
      [theme.breakpoints.down('xs')]: {
        width: '100% '
      }
    },
    '& textarea': {
      padding: '10px',
      outline: 'none',
      border: '1px solid #C8C8C8'
    }
  },
  selectedValue: {
    left: 'unset',
    display: 'flex',
    fontWeight: derivedFromProp('selectedValueWeight', {
      default: 400,
      bold: 700
    })
  },
  inputError: {
    border: '1px solid red !important'
  },
  contactHeaderText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: '17px'
  },
  textAreaContainer: {
    '& button': {
      textTransform: 'unset',
      color: '#484340',
      fontFamily: 'Open Sans',
      margin: '18px 10px 18px 0 ',
      cursor: 'pointer',
      fontSize: '13px',
      width: 'max-content',
      height: '30px',
      borderRadius: '20px',
      padding: '0 10px',
      border: '1px solid #484340',
      '&.Mui-selected': {
        backgroundColor: '#484340',
        color: 'white'
      }
    },
    '& button:hover': {
      color: 'black !important'
    },
    '& textarea': {
      marginTop: '11px',
      width: '800px'
    },
    [theme.breakpoints.down('sm')]: {
      '& button:last-child': {
        marginBottom: '10px '
      },

      '& textarea': {
        width: '100%'
      },
      '& button': {
        margin: '9px 10px 9px 0 '
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    interestsContainer: {
      width: '300px'
    }
  },
  option: {
    '&.selected': {
      opacity: 0.5,
      pointerEvents: 'none'
    },
    '&.disabled': {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  checkBoxContainer: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'column',
    '& .errorText': {
      color: 'red',
      fontFamily: 'Open Sans',
      fontSize: '13px'
    },
    '& label': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 0 0 ',
      fontSize: '16px',
      '& input': {
        height: '18px',
        width: '18px',
        marginRight: '13px'
      }
    }
  },
  contactusFooter: {
    marginTop: '50px',
    marginBottom: '230px',
    justifyContent: 'space-between',
    width: '75%',
    alignItems: 'center',
    '& :nth-child(2n+2)': {
      cursor: 'pointer'
    },
    '& p': {
      fontSize: '16px',
      padding: '0 10px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      height: '200px'
    }
  }
}))

export default useStyles
