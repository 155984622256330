import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { initUrlParams } from 'components/Helpers/filterUrlSync'
import BaseLayout from 'components/Layouts/Base'
import React, { Suspense, useEffect } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import CookieConsent from './CookieConsent'
import { history } from './history'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { SuspenseFallbackLoader } from './SuspenseFallbackLoader'
import ITCPreAuth from 'components/Pages/ITCPreAuth'
import ContactUsComponent from 'components/Helpers/ContactUS'
import PricingPage from 'components/Pages/PricingPage'
import HomePageComponent from 'components/Pages/HomePage/HomePage'
import LandingPage from 'components/Pages/LandingPage'
import { useAuth0 } from 'components/Contexts/AuthContext'
import UserAccount from 'components/Pages/UserAccount'
import NewProductDetails from 'components/Pages/NewProductDetails'
import Insights from 'components/Pages/Insights'
import InsightsSinglePost from 'components/Pages/Insights/Components/InsightsSinglePost'
import DocumentTitle from 'components/Helpers/DocumentTitle'

// DYNAMIC IMPORTS
const Collections = React.lazy(() => import('components/Pages/Collections'))
// const CollectionsHome = React.lazy(() =>
//   import('components/Pages/CollectionsHome')
// )
const SearchHome = React.lazy(() =>
  import('components/Pages/SearchResults/index')
)

export default function App() {
  const { setFiltersFromUrlParams } = useFilterStateContext()
  const { isAuthenticated, loading } = useAuth0()
  useEffect(() => {
    initUrlParams(setFiltersFromUrlParams)
  }, [setFiltersFromUrlParams])
  if (loading) return null
  return (
    <Router history={history}>
      <CookieConsent />
      <DocumentTitle />
      <BaseLayout loading={loading}>
        <Suspense fallback={<SuspenseFallbackLoader />}>
          <Switch>
            <Route exact path="/">
              {!isAuthenticated ? (
                //  <Redirect  to="/about-magellan" component={LandingPage} />
                <Redirect to="/home" component={HomePageComponent} />
              ) : (
                <Redirect to="/home" component={HomePageComponent} />
              )}
            </Route>
            <PublicRoute component={ITCPreAuth} path="/itc-auth" />
            {/** /itc-auth?jwt=<JWT> */}
            <PrivateRoute component={SearchHome} path="/search" />

            <PublicRoute component={HomePageComponent} path="/home" />
            <PublicRoute component={LandingPage} path="/about-magellan" />

            <PrivateRoute
              component={Collections}
              path="/collections/:collectionType/:collectionId"
            />
            {/* <PrivateRoute
              component={CollectionsHome}
              path="/collections/:collectionType"
            /> */}
            <PrivateRoute component={UserAccount} path="/personal-account" />

            <PublicRoute component={PricingPage} path="/pricing" />

            <PublicRoute component={InsightsSinglePost} path="/insights/:id" />
            <PublicRoute component={Insights} path="/insights" />

            <PublicRoute component={ContactUsComponent} path="/contact-us" />
            <PublicRoute
              component={NewProductDetails}
              path="/product/:productKey"
            />
          </Switch>
        </Suspense>
      </BaseLayout>
    </Router>
  )
}
