import React from 'react'
import { makeStyles, Typography, Grid, Box } from '@material-ui/core'
import { OxbowTextButton } from '../Buttons'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import Toggle from 'components/Pages/SearchResults/Pagination/inactiveComapniesSwitcher'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1)
  },
  filterSection: {
    marginBottom: theme.spacing(0),
    paddingBottom: 0
  },
  filterSectionHeading: {
    color: 'black'
  },
  filtersContainer: {
    paddingTop: 0,
    '&>div.filter': {
      '& h4': {
        fontWeight: 600
      }
    }
  },
  filterSectionHeader: {
    position: 'relative'
  },
  clearBtn: {
    position: 'absolute',
    right: 0,
    top: 2,
    zIndex: 99
  },
  clearBtnRoot: {
    color: theme.palette.primary.lightBlue,
    fontWeight: 600,
    textTransform: 'unset',
    '&:hover': {
      color: theme.palette.primary.lightBlue
    }
  }
}))

export function FilterSection({
  title,
  children,
  onClear,
  rootContainerStyles,
  filterContainerStyles,
  isClearBtnDisabled,
  sectionConfig,
  isDistributorsChecked,
  toggleHandler
}) {
  const classes = useStyles()
  const matches = useMediaQueries()
  return (
    <div className={classes.filterSection} style={rootContainerStyles}>
      {sectionConfig.title === 'What' && (
        <Toggle
          show={isDistributorsChecked}
          setShow={e => toggleHandler(e)}
          label="Show distributors"
        />
      )}
      {title !== ' ' ? (
        <>
          <Box pb={0} className={classes.filterSectionHeader}>
            <Grid container alignItems="center" justify="space-between">
              {onClear && !isClearBtnDisabled && title !== 'Other' && (
                <Grid item className={classes.clearBtn}>
                  <OxbowTextButton
                    onClick={onClear}
                    size="large"
                    classes={{
                      root: classes.clearBtnRoot
                    }}
                  >
                    <Typography variant="h5">Clear</Typography>
                  </OxbowTextButton>
                </Grid>
              )}
            </Grid>
          </Box>
          <div
            className={classes.filtersContainer}
            style={filterContainerStyles}
          >
            {children}
          </div>
        </>
      ) : (
        <>
          <Box pb={0} className={classes.filterSectionHeader}>
            <Grid
              container
              alignItems="flex-end"
              justify={matches.downMd ? 'space-between' : 'flex-start'}
            >
              <Grid item>
                <Grid container alignItems="flex-end">
                  <Grid item>
                    {title && (
                      <Typography
                        variant={'h3'}
                        display="block"
                        color="secondary"
                        className={classes.filterSectionHeading}
                      >
                        {title}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <div
            className={classes.filtersContainer}
            style={filterContainerStyles}
          >
            {children}
          </div>
        </>
      )}
    </div>
  )
}

export const MemoizedFilterSection = React.memo(FilterSection)
