import { Box } from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import _ from 'lodash'
import { useCan } from 'rbac/can'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getStageDetails } from 'utils'
import { OxbowPrimaryTransparentButton } from '../Buttons'
import Chip from '../Inputs/ChipInput/Chips'
import { useStyles } from './styles'
import { getSubtitle } from './SubTitle'
import { getTileSubtitle } from './utils'
import BookmarksButton from 'components/Helpers/BookmarksButton'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import useDeviceType from 'utils/customHooks/useIsMobile'
import { sanitizeUrl } from 'components/Pages/NewProductDetails/utils'
import { useNavContext } from '../Navbar'

export const ProductTile = ({
  column,
  product,
  removeFromCollection,
  collectionName,
  name,
  shortDescription,
  logoUrl,
  slug,
  fields,
  isAdmin,
  adminProduct,
  fromSearchPage,
  productCollectionTags,
  selectedCollectionTags,
  selectedCollection,
  productId,
  dataPresent,
  rootStyles,
  isProductUnPublished,
  divider,
  setListRefresh,
  isAddToFavVisible = true
}) => {
  if (dataPresent !== false) {
    fields.dataPresent = true
  } else {
    fields.dataPresent = false
  }
  const tileWidth = isAdmin && adminProduct ? 'md' : 'full'
  const classes = useStyles({
    column,
    isAdmin,
    collectionType: selectedCollection?.type,
    divider,
    tileWidth
  })
  const { bootstrappingCollections } = useCollections()
  const { isAuthenticated } = useAuth0()
  const { isMobile } = useDeviceType()
  const { setCurrCollection } = useNavContext()
  const [companyName, headQuaters] = getTileSubtitle(fields)
  const [, setSelectedTagsOfThisProduct] = useState(productCollectionTags)
  const [, setSelectedTagsOfThisProductState] = useState(selectedCollectionTags)
  const [productCollectionTagsState, setproductCollectionTagsState] = useState(
    productCollectionTags
  )
  const isFullUser = useCan('content_depth:all')

  const companyStageDetails = getStageDetails(fields?.company?.stage)
  const isCompanyActive = fields?.company?.isActive
  const companyYearFounded = fields?.company?.yearFounded
  const productTypeTag = getSubtitle(fields)

  useEffect(() => {
    if (selectedCollection?.collectionId) {
      setCurrCollection(window.location.pathname)
    } else {
      setCurrCollection('')
    }
  }, [selectedCollection, setCurrCollection])

  useEffect(() => {
    if (!_.isEqual(productCollectionTags, productCollectionTagsState)) {
      setSelectedTagsOfThisProduct(productCollectionTags)
      setproductCollectionTagsState(productCollectionTags)
    }
  }, [productCollectionTags]) //eslint-disable-line

  useEffect(() => {
    setSelectedTagsOfThisProductState(selectedCollectionTags)
  }, [selectedCollectionTags])

  //cutting short description to 250 chars or below
  let cutSortDescTo250Chars = sDescription => {
    let end = 250
    if (sDescription.length >= end) {
      if (sDescription[end] !== ' ') {
        while (sDescription.length > end && sDescription[end] !== ' ') {
          // this will ensure that if 250th char is in the mid of word then find next space and then cut;
          end++
        }
      }
      sDescription = sDescription.substring(0, end) + '...'
    }

    return sDescription
  }

  const bookmarksButtons = (
    <>
      {(!fromSearchPage || fromSearchPage) && (
        <Grid item p={0} style={{ justifyContent: 'flex-end' }}>
          {!bootstrappingCollections ? (
            <Box className={classes.bookmarksButton}>
              {product ? (
                fromSearchPage ? (
                  <BookmarksButton
                    productID={productId}
                    productSlug={slug}
                    fullWidth
                  />
                ) : !adminProduct ? (
                  <>
                    <OxbowPrimaryTransparentButton
                      width="sm"
                      fontSize="md"
                      fonttype="secondary"
                      size="md"
                      height="md"
                      onClick={removeFromCollection}
                      startIcon={<RemoveCircleOutlineIcon />}
                    />
                  </>
                ) : isAdmin ? (
                  <>
                    <OxbowPrimaryTransparentButton
                      width="sm"
                      fontSize="md"
                      height="md"
                      fonttype="secondary"
                      onClick={removeFromCollection}
                      startIcon={<RemoveCircleOutlineIcon />}
                    />
                    {collectionName !== 'My Favourites' && (
                      <OxbowPrimaryTransparentButton
                        width="sm"
                        fontSize="md"
                        height="md"
                        marginright="sm"
                        cursor="move"
                        startIcon={<DragIndicatorIcon />}
                      />
                    )}
                  </>
                ) : null
              ) : null}
            </Box>
          ) : (
            <Skeleton variant="rect" width={60} height={15} animation="wave" />
          )}{' '}
        </Grid>
      )}
    </>
  )

  const BookmarksContainer = (
    <Grid
      container
      alignItems="center"
      spacing={2}
      style={{ justifyContent: isFullUser && 'flex-end' }}
      className={classes.tileButtonsContainer}
    >
      {isProductUnPublished && (
        <Typography variant="h6" className={classes.unpublishedText}>
          Unpublished
        </Typography>
      )}
      {isAuthenticated && collectionName !== 'My Favourites' ? (
        <BookmarksButton
          style={{ width: '30px', height: '30px' }}
          productTile={true}
          productID={productId}
          productSlug={slug}
          fullWidth
          setListRefresh={setListRefresh}
          isAddToFavVisible={isAddToFavVisible}
        />
      ) : (
        ''
      )}
      {bookmarksButtons}
    </Grid>
  )

  return (
    <Paper
      className={classes.root}
      style={{ ...rootStyles, padding: isMobile ? '0 0 36px' : 'inherit' }}
    >
      <Box pb={2} pt={1}>
        <Grid
          className={classes.tileContainer}
          container
          spacing={1}
          direction="row"
          alignItems="center"
        >
          <Grid item>
            <ButtonBase
              className={classes.image}
              to={
                fields.dataPresent
                  ? {
                      pathname: `/product/${slug}`,
                      state: {
                        breadcrumb: { key: 'productName', label: name }
                      }
                    }
                  : {
                      hash: '#'
                    }
              }
              component={Link}
            >
              <img
                alt="Product Logo"
                src={logoUrl || '/dummy-product-logo.jpg'}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs style={{ width: column ? '100%' : 'auto' }}>
            <Box
              ml={isMobile ? 7.5 : 3}
              mt={column ? 3 : 0}
              className={classes.headerDetailsContainer}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link
                  to={
                    fields.dataPresent
                      ? {
                          pathname: `/product/${slug}`,
                          state: {
                            breadcrumb: { key: 'productName', label: name }
                          }
                        }
                      : {
                          hash: '#'
                        }
                  }
                  className={classes.productName}
                >
                  <Grid container alignItems="center">
                    <Typography variant="h3">{name}</Typography>
                    {!isCompanyActive && (
                      <Typography
                        variant="h6"
                        className={classes.isCompanyActive}
                      >
                        Inactive
                      </Typography>
                    )}
                  </Grid>
                </Link>
                {!isMobile ? BookmarksContainer : null}
              </Box>

              <Box mb={1} className={classes.companyDetails}>
                {fields.dataPresent && companyName === name ? (
                  <>
                    <span>{headQuaters}.</span>
                    <span style={{ marginLeft: '10px' }}>
                      Founded {companyYearFounded}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      By {companyName}, {headQuaters}.
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                      Founded {companyYearFounded}
                    </span>
                  </>
                )}
              </Box>
              <Box mb={1.3}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={fields?.company?.website || '#'}
                  className={classes.productTileWebsite}
                >
                  <Typography variant="h6">
                    {fields?.company?.website &&
                      sanitizeUrl(fields.company.website)}
                  </Typography>
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box pb={3}>
        <Typography variant="body1" className={classes.productDescription}>
          {cutSortDescTo250Chars(shortDescription)}
        </Typography>
        <Grid container spacing={1} className={classes.productTags}>
          <Box mb={0.5} className={classes.chipsContainer}>
            {productTypeTag && <Chip color="default">{productTypeTag}</Chip>}

            {companyStageDetails && (
              <Chip label={companyStageDetails.label} color="default" />
            )}
          </Box>
          {isMobile && isAuthenticated && collectionName !== 'My Favourites' ? (
            <div
              style={{
                display: 'flex'
              }}
            >
              <BookmarksButton
                style={{ width: '30px', height: '30px' }}
                productTile={true}
                productID={productId}
                productSlug={slug}
                fullWidth
                isAddToFavVisible={isAddToFavVisible}
              />
              {bookmarksButtons}
            </div>
          ) : (
            ''
          )}
          {isMobile && collectionName === 'My Favourites' && (
            <>{BookmarksContainer}</>
          )}
        </Grid>
      </Box>
    </Paper>
  )
}

export default React.memo(ProductTile)
