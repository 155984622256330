export const getKeyColorCode = key => {
  const colorCodes = {
    primaryFunction: '#9C466F',
    targetClient: '#287A8F',
    insuranceClass: '#428C41',
    secondaryFunction: '#725487',
    insuranceClientType: '#9DB03E',
    distributorType: '#CC7931',
    insuranceSubclass: '#EBBC00',
    businessModel: '#AB4E3E'
  }
  return colorCodes[key]
}

export const createMergedArrayFromKeys = (container, keys) => {
  return keys.reduce((acc, key) => {
    if (!container[key]) return acc
    const colorCodedChips = container[key].map(chip => ({
      value: chip,
      color: getKeyColorCode(key)
    }))
    return [...acc, ...colorCodedChips]
  }, [])
}

export const getTileSubtitle = fields => {
  const companyName = fields.company && fields.company && fields.company.name
  const headQuaters =
    fields.company &&
    fields.company &&
    fields.company.hq &&
    fields.company.hq[0]
  return [companyName, headQuaters]
}
