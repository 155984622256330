import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import { useAutocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { findValueByFirstLetter } from 'utils/sortingFunctions'
import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg'
import { ReactComponent as JourneyCheckBox } from 'assets/icons/JourneyCheckBox.svg'

import { ReactComponent as CheckboxPartial } from 'assets/icons/CheckboxPartial.svg'
import { ReactComponent as JourneyCheckBoxChecked } from 'assets/icons/JourneyCheckBoxChecked.svg'
import { Tag } from 'components/Helpers/Inputs/ChipInput/Tag'
import { useStyles } from './useStyles'
import { useOutsideClick } from 'utils/customHooks/useOutsideClickRef'
import SearchIcon from '@material-ui/icons/Search'

const JourneyAutocomplete = ({
  productDetails,
  setProductDetails,
  id,
  dataDetails,
  countriesData,
  handleChipClick,
  placeholder,
  handleCountryCheck,
  handleRegionCheck,
  checkedCountries,
  handleClick,
  alignSelf,
  inputWidth,
  checkedCountriesObj,
  setCheckedCountries = () => {},
  classType,
  setDataDetails = () => {},
  searchIcon = false
}) => {
  const classes = useStyles({ alignSelf, inputWidth })

  const [chipsData, setChipsData] = useState([])
  const [inputValue, setInputValue] = useState('')
  const { suggestInputsFiltersData } = useFilterStateContext()
  const [isSelectorOpened, setSelectorOpen] = useState(false)
  const [isCountriesInputFocused, setCountriesInputFocused] = useState(false)
  const [countriesInputValue, setCountriesInputValue] = useState('')

  const isCountriesSelectorFocused = !isSelectorOpened
    ? setCountriesInputFocused
    : setSelectorOpen

  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, isCountriesSelectorFocused)

  useEffect(() => {
    if (productDetails[id]) {
      const value = productDetails[id].value
      setChipsData(
        typeof value === 'string'
          ? productDetails[id].value.split(',')
          : [value]
      )
    } else setChipsData([])
  }, [productDetails, id])

  const handleChangeInBaseFilterValue = selectedOptions => {
    const selected = selectedOptions.map(i => i?.value).filter(i => i)
    const inputDataValues = [...chipsData, ...selected].toString()
    setProductDetails(id, inputDataValues)
  }

  const optionsData = () => {
    if (id === 'regionsAndCountries') {
      return countriesData.map(i => i.children)
    }
    if (suggestInputsFiltersData && id) {
      return suggestInputsFiltersData[id]?.options?.map(i => i)
    } else return []
  }

  const filteredOptionData = optionsData()
    ?.filter(val => !chipsData.includes(val.value))
    ?.sort((a, b) => a.value?.localeCompare(b?.value))

  const removeItem = option => {
    setProductDetails(id, chipsData.filter(i => i !== option).toString())
  }

  const filteredResults = findValueByFirstLetter(
    filteredOptionData,
    inputValue,
    false
  )

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    focused,
    groupedOptions,
    setAnchorEl,
    getTagProps
  } = useAutocomplete({
    id: `${id}-chip-input`,
    multiple: true,
    options: filteredResults,
    open: isSelectorOpened,
    value: chipsData.map(a =>
      productDetails[id]?.value?.split(',').find(option => option.value === a)
    ),
    getOptionLabel: option => option.value,
    onOpen: () => setSelectorOpen(true),
    onChange: (_, values, reason) => {
      if (reason === 'select-option') {
        setInputValue('')
      }
      handleChangeInBaseFilterValue(values)
    },
    onInputChange: (_, value, reason) => {
      if (reason === 'input') setInputValue(value)
    },
    inputValue
  })

  const handleInputValueChange = useCallback(
    e => {
      setCountriesInputValue(e.target.value)
    },
    [setCountriesInputValue]
  )
  const sortedCountries = countriesData
    .map(i => i.children)
    .map(i => i.sort((a, b) => a.value.localeCompare(b.value)))

  const filteredCountries = findValueByFirstLetter(
    sortedCountries,
    countriesInputValue,
    false,
    true
  )

  const countriesAndRegions = useMemo(() => {
    if (countriesInputValue) {
      const filtered = filteredCountries.map(i => i.value)
      let newArr = []
      countriesData.map(({ region, children }) => {
        const countriesAndRegionsSorting = children

          .map(country => filtered.includes(country.value) && country)
          .filter(Boolean)
        if (countriesAndRegionsSorting.length > 0) {
          newArr.push({
            region,
            children: countriesAndRegionsSorting
          })
        }
        return null
      })

      return newArr
    } else return countriesData
  }, [countriesData, countriesInputValue, filteredCountries])
  const generateRegionIndex = region => {
    const regionIndex = countriesData.map(i => i.region).indexOf(region)
    return regionIndex
  }

  return (
    <Box
      ref={wrapperRef}
      className={classes.mainSelectorBox}
      style={{ width: id === 'regionsAndCountries' && '100%' }}
    >
      {id === 'regionsAndCountries' ? (
        <div
          className={clsx(classes.inputContainer)}
          style={{ border: '1px solid #C8C8C8', padding: '2.4px 8px' }}
        >
          <input
            onChange={e => handleInputValueChange(e)}
            onFocus={() => setCountriesInputFocused(true)}
            placeholder={placeholder}
            autoComplete={''}
            style={{ border: 'none' }}
            value={countriesInputValue || ''}
          />
          {searchIcon && <SearchIcon fontSize="small" />}
        </div>
      ) : (
        <div {...getRootProps()}>
          <div
            open={isSelectorOpened}
            ref={setAnchorEl}
            className={clsx(classes.inputContainer, { focused })}
            style={{ border: '1px solid #C8C8C8' }}
          >
            <input
              {...getInputProps()}
              placeholder={placeholder}
              autoComplete={'no'}
              style={{ border: 'none' }}
            />
          </div>
        </div>
      )}

      <div className={isCountriesInputFocused ? classes.mainScrollBox : null}>
        {isCountriesInputFocused && id === 'regionsAndCountries' ? (
          <>
            {countriesAndRegions.map((region, regionIndex) => {
              const arr = region.children.map(i => i.value)

              return (
                <React.Fragment key={regionIndex}>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ArrowDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormControlLabel
                        label={
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 600, marginLeft: 13 }}
                          >
                            {region.region}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            icon={<JourneyCheckBox />}
                            checked={
                              checkedCountries[regionIndex]?.includes(
                                ...region.children.map(i => i.value),
                                generateRegionIndex(region.region)
                              ) || false
                            }
                            checkedIcon={<JourneyCheckBoxChecked />}
                            indeterminate={
                              checkedCountries[
                                generateRegionIndex(region.region)
                              ]?.length > 0 &&
                              checkedCountries[
                                generateRegionIndex(region.region)
                              ]?.length !== region.children.length
                            }
                            indeterminateIcon={<CheckboxPartial />}
                            onChange={() => {
                              handleChipClick(
                                region.children.map(i => i.value),
                                dataDetails,
                                classType,
                                setDataDetails,
                                countriesData
                              )
                              handleRegionCheck(
                                arr,
                                region.region,
                                countriesData,
                                setCheckedCountries,
                                checkedCountriesObj
                              )
                            }}
                          />
                        }
                      />
                    </AccordionSummary>
                    {region.children.length > 0 && (
                      <div style={{ position: 'relative' }}>
                        <div className={classes.selectContainer}>
                          <ul
                            {...getListboxProps()}
                            className={`${classes.listBox} `}
                            data-tip={`${id}-chip-input`}
                            data-for={`${id}-chip-input`}
                          >
                            {region.children.map(option => {
                              const countryChecked = checkedCountries[
                                generateRegionIndex(region.region)
                              ]?.includes(option.value)
                              return (
                                <li
                                  key={option.value}
                                  onClick={() => {
                                    handleClick(option.value)
                                    handleCountryCheck(
                                      option.value,
                                      region.region,
                                      countriesData,
                                      setCheckedCountries,
                                      checkedCountriesObj
                                    )
                                    setCountriesInputValue('')
                                  }}
                                >
                                  <AccordionDetails
                                    className={classes.itemList}
                                  >
                                    <input
                                      style={{
                                        color: 'transparent',
                                        position: 'absolute',
                                        zIndex: '-1',
                                        border: 'none'
                                      }}
                                      name={option.value}
                                      type="checkbox"
                                      value={option.value}
                                      key={option.label}
                                    />

                                    {countryChecked ? (
                                      <JourneyCheckBoxChecked />
                                    ) : (
                                      <JourneyCheckBox />
                                    )}

                                    <Typography
                                      variant="subtitle2"
                                      style={{ paddingLeft: 12 }}
                                    >
                                      {option.value}
                                    </Typography>
                                  </AccordionDetails>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </Accordion>
                </React.Fragment>
              )
            })}
          </>
        ) : (
          <>
            {groupedOptions.length > 0 && (
              <div style={{ position: 'relative' }}>
                <div className={classes.selectContainer}>
                  <ul
                    style={{ border: '1px solid #ccc' }}
                    {...getListboxProps()}
                    className={`${classes.listBox} ${classes.hidden}`}
                    data-tip={`${id}-chip-input`}
                    data-for={`${id}-chip-input`}
                  >
                    {groupedOptions.map((option, index) => {
                      const itemProps = getOptionProps({ option, index })
                      const checked = dataDetails?.includes(option.value)

                      return (
                        <li {...itemProps} key={option.value} id={index}>
                          <input
                            style={{
                              color: 'transparent',
                              position: 'absolute',
                              zIndex: '-1',
                              border: 'none'
                            }}
                            name={option.value}
                            type="checkbox"
                            value={option.value}
                            key={option.label}
                          />

                          {checked ? (
                            <JourneyCheckBoxChecked />
                          ) : (
                            <JourneyCheckBox />
                          )}

                          <Typography
                            variant="subtitle2"
                            style={{ paddingLeft: 12 }}
                          >
                            {option.value}
                          </Typography>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className={classes.chipsContainer}>
        {productDetails[id]?.value ? (
          <>
            {chipsData
              .filter(i => i !== '')
              .map((option, index) => (
                <Tag
                  label={option}
                  key={option}
                  {...getTagProps({ index })}
                  onClick={() => removeItem(option)}
                />
              ))}
          </>
        ) : (
          ''
        )}
      </div>
    </Box>
  )
}

export default JourneyAutocomplete
