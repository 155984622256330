import {Accordion, AccordionDetails, AccordionSummary, Grid} from '@material-ui/core'
import React from 'react'
import {withStyles} from '@material-ui/core/styles'

import CaseStudy from './CaseStudy'
import CaseStudyChip from './CaseStudyChip'
import PropTypes from "prop-types";

const StyledAccordionDetails = withStyles(() => ({
  root: {
    padding: '8px 0',
  },
}))(AccordionDetails)

const StyledAccordionSummary = withStyles(() => ({
  content: {
    display: 'inline',
  },
  root: {
    display: 'inline',
  },
}))(AccordionSummary)

const StyledUseCaseGridItem = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.lighterGrey,
    borderRadius: '4px',
    marginBottom: '8px',
    marginTop: '4px',
    padding: '8px 24px',
  },
}))(Grid)

const CaseStudies = ({ caseStudies }) => {
  caseStudies = caseStudies.filter(caseStudy => caseStudy.articleUrl);

  return (
    <Accordion>
      <StyledAccordionSummary
        aria-controls='case-studies'
        aria-label='Expand/Collapse Case Studies'
        expandIcon={null}
      >
        <CaseStudyChip plural={caseStudies.length > 1}/>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <Grid container>
          {caseStudies.map((caseStudy, index) => (
            <StyledUseCaseGridItem item key={index} xs={12}>
              <CaseStudy caseStudy={caseStudy}/>
            </StyledUseCaseGridItem>
          ))}
        </Grid>
      </StyledAccordionDetails>
    </Accordion>
  )
}

CaseStudies.propTypes = {
  caseStudies: PropTypes.arrayOf(
    PropTypes.shape({
      articleTitle: PropTypes.string,
      articleUrl: PropTypes.string,
      countries: PropTypes.arrayOf(PropTypes.string),
      datePublished: PropTypes.string,
      keyTakeaways: PropTypes.arrayOf(PropTypes.string),
      relatedTechnologies: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
}

export default CaseStudies
