import React, { useCallback, useEffect, useMemo } from 'react'
import { makeStyles, Grid, Box, Typography, fade } from '@material-ui/core'
import useDeviceType from 'utils/customHooks/useIsMobile'
import useEditModeStyles from './EditModeUtils/styles'
import { useEditProductDetailsContext } from 'components/Contexts/EditProductDetailsContext'

const useStyles = makeStyles(theme => ({
  linkedInLogo: {
    marginLeft: theme.spacing(1),
    width: 21,
    height: 21,
    display: 'inline-grid',
    placeItems: 'center',
    borderRadius: '50%',
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.mainLightBlue,
    transition: 'background 0.3s ease',
    '&:hover': {
      backgroundColor: fade('#1686B0', 1)
    },
    '& i': {
      color: 'white'
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '21px',
    padding: '0 0 10px'
  },
  description: {
    fontSize: '16px',
    lineHeight: '21px',
    paddingBottom: 15,
    width: 300
  },
  keyPeopleBlock: {
    width: '100%'
  },
  keyPeople: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& a': {
        margin: '0 0 10px 0'
      }
    }
  }
}))

const IS_EDITABLE = true

export default function GridSection(props) {
  const { renderInEditMode, blurValue } = props
  if (renderInEditMode) {
    if (!blurValue && IS_EDITABLE) return <EditableGridSection {...props} />
  }
  return <_GridSection {...props} />
}

const EditableGridSection = props => {
  const { content, id, isEditableWithoutValue } = props
  const editModeStyles = useEditModeStyles()
  const { setProductDetails, productDetails } = useEditProductDetailsContext()
  const contentString = useMemo(() => {
    if (content)
      return content
        .map(({ name, position, info, linkedinProfile }) =>
          [
            `Name: ${name}`,
            `Position: ${position}`,
            `Description: ${info}`,
            `Linked URL: ${linkedinProfile}`
          ].join('\n')
        )
        .join('\n\n')
    else if (isEditableWithoutValue) return ''
  }, [content, isEditableWithoutValue])

  useEffect(() => {
    if (contentString) setProductDetails(id, contentString, true)
  }, [contentString, id, setProductDetails])

  const handleInputValueChange = useCallback(
    e => {
      setProductDetails(id, e.target.value)
    },
    [id, setProductDetails]
  )
  // KEY PEOPLE
  return (
    <textarea
      type="text"
      value={productDetails[id]?.value || ''}
      onChange={handleInputValueChange}
      id={id}
      rows={40}
      className={editModeStyles['textArea']}
    />
  )
}

export function _GridSection(props) {
  const { content } = props
  const classes = useStyles(props)
  const { isMobile } = useDeviceType()
  return (
    <Box className={classes.keyPeopleBlock}>
      {content.map((el, i) => (
        <Box pb={2} key={i}>
          <Grid container direction={'row'}>
            <Grid item xs>
              <Box pt={isMobile ? 0.25 : 0} className={classes.keyPeople}>
                <Typography variant="body1" className={classes.title}>
                  {el.name}, {el.position}
                </Typography>
                {el.linkedinProfile && (
                  <a
                    href={el.linkedinProfile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.linkedInLogo}
                  >
                    <i className="fab fa-linkedin-in"></i>{' '}
                  </a>
                )}
              </Box>
              <Typography variant="body1" className={classes.description}>
                {el.info}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  )
}
