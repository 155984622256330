import React from 'react'
import { useSpring, animated } from 'react-spring'
import {
  Button as MaterialButton,
  makeStyles,
  useTheme
} from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(_theme => ({
  loader: {
    '&.MuiCircularProgress-colorPrimary': {
      color: 'white'
    }
  },
  loaderContainer: {
    zIndex: 2,
    background: _theme.palette.primary.main,
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

// eslint-disable-next-line no-unused-vars
export default function Button({ isLoading, children, size, ...props }) {
  const [showLoader, setShowLoader] = React.useState(false)
  const classes = useStyles()
  const theme = useTheme()

  React.useEffect(() => {
    if (isLoading) {
      setShowLoader(true)
    }

    // Show loader a bits longer to avoid loading flash
    if (!isLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 400)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isLoading, showLoader])

  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 })
  const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 })

  return (
    <MaterialButton {...props}>
      {showLoader && (
        <animated.div style={fadeOutProps} className={classes.loaderContainer}>
          <CircularProgress
            size={18}
            classes={{
              root: classes.loader
            }}
          />
        </animated.div>
      )}
      <animated.div style={fadeInProps}>
        {children}
        {props.count ? (
          <b style={{ color: theme.palette.secondary.main }}>
            <> {props.count}</>
          </b>
        ) : (
          ''
        )}
      </animated.div>
    </MaterialButton>
  )
}
