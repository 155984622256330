import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import {
  OxbowPrimaryButton,
  OxbowPrimaryTransparentButton,
  OxbowTransparentButton
} from 'components/Helpers/Buttons'
import { useStyles } from '../../useStyles'
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIcon.svg'

export const BtnsBlock = ({
  cancelPlaceholder,
  savePlaceholder,
  deletePlaceholder = '',
  handleCancel,
  handleSave,
  isDeleteButtonVisible,
  handleDelete,
  style,
  alignItems = 'default',
  alignSelf = 'default',
  disabled
}) => {
  const classes = useStyles({ alignItems, alignSelf })
  return (
    <Grid container className={classes.btnsBox} style={style}>
      <Grid container>
        <OxbowPrimaryTransparentButton
          onClick={handleCancel}
          width="max-content"
          fontSize="md"
          padding="lg"
        >
          {cancelPlaceholder}
        </OxbowPrimaryTransparentButton>
        <OxbowPrimaryButton
          width="max-content"
          fontSize="md"
          size="xl"
          onClick={handleSave}
          disabled={disabled}
        >
          {savePlaceholder}
        </OxbowPrimaryButton>
      </Grid>
      {isDeleteButtonVisible ? (
        <OxbowTransparentButton fontSize="md" onClick={handleDelete}>
          <DeleteIcon />
          <Typography variant="h4">{deletePlaceholder}</Typography>
        </OxbowTransparentButton>
      ) : null}
    </Grid>
  )
}
