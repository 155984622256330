import React from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { OxbowPrimaryButton } from '../Buttons'
import { useHomePageContext } from 'components/Contexts/HomePageContext'
import { derivedFromProp } from 'utils'
import { useAuth0 } from 'components/Contexts/AuthContext'

const useStyles = makeStyles(theme => ({
  vDivider: {
    width: 45,
    height: 2,
    background: '#0E90D9',
    margin: '20px auto 20px auto',
    transform: 'rotate(90deg)'
  },
  productBottomBanner: {
    border: derivedFromProp('border', {
      true: '1px solid #D4D3D1',
      false: 'none',
      default: 'none'
    }),
    background: derivedFromProp('background', {
      white: '#fff',
      default: '#F8F8FA'
    }),
    marginBottom: derivedFromProp('marginBottom', {
      false: '0px',
      default: '50px'
    }),
    height: 300,
    justifyContent: 'space-evenly',
    textAlign: 'center',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: 40,
    '& h3': {
      fontWeight: 600
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  }
}))

export const ProductBottomBanner = ({
  border,
  btnText,
  divider,
  title,
  subTitle,
  background,
  marginBottom,
  btnWidth
}) => {
  const classes = useStyles({ background, border, marginBottom })
  const { setContactUsModalOpen } = useHomePageContext()
  const { loginWithRedirect } = useAuth0()

  const handleBtnClick = () => {
    if (btnText === 'Create account') {
      loginWithRedirect({
        screen_hint: 'signup',
        prompt: 'login'
      })
    } else {
      setContactUsModalOpen(true)
    }
  }

  return (
    <>
      {divider && <Divider className={classes.vDivider} />}
      <Grid container className={classes.productBottomBanner}>
        {subTitle && <Typography variant="h3">{subTitle}</Typography>}
        {title && (
          <Typography
            variant="h1"
            dangerouslySetInnerHTML={{ __html: title }}
          ></Typography>
        )}
        {btnText && (
          <OxbowPrimaryButton
            style={{ marginTop: 30 }}
            fontSize="md"
            width={btnWidth}
            // padding="md"
            // height="lg"
            onClick={handleBtnClick}
            // size="md"
          >
            {btnText}
          </OxbowPrimaryButton>
        )}
      </Grid>
    </>
  )
}
