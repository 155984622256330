import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'
import { Chips } from 'components/Helpers/SavedSearches/SavedSearchChips'
import EllipseDot from 'assets/Logos/ContentLogos/EllipseDot.svg'
import { capabilitiesData } from '../../../dataHelper.js'
import useStyles from '../../../styles'
import More from '../More/index.js'
import useMediaQueries from 'utils/customHooks/useMediaQueries.js'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SuggestionsSubmit from './SuggestionsSubmit.js'

export const DotedTypography = ({ field }) => {
  const useStyles = makeStyles(() => ({
    dotedTypography: {
      display: 'flex',
      alignItems: 'baseline',
      fontWeight: 400,
      paddingBottom: 12
    },
    doted: {
      marginRight: 12
    }
  }))
  const classes = useStyles()
  return (
    <Typography key={field} className={classes.dotedTypography} variant="h4">
      <img src={EllipseDot} alt="dot" className={classes.doted} />
      {field.label}
    </Typography>
  )
}

const Capabilities = ({ productData, isActive, dataLength }) => {
  const classes = useStyles()
  const { downSm, downXs } = useMediaQueries()
  const wrapperRef = useRef(null)

  const [height, setHeight] = useState(0)
  const [expanded, setExpanded] = useState('panel1')

  const handleChange = (panel, func) => (_, isExpanded) => {
    func(isExpanded ? panel : false)
  }
  const isTechnologiesMatches = () => {
    const { technology, relatedTechnology, businessTrends } = productData
    const capabilities = capabilitiesData(productData)

    const getMatchingItems = (arr1, arr2) =>
      arr1.filter(item => arr2.includes(item))

    if (relatedTechnology) {
      if (businessTrends) {
        capabilities.unshift({
          title: 'Business trends',
          dataField: getMatchingItems(businessTrends, relatedTechnology)
        })
      }
      if (technology) {
        capabilities.unshift({
          title: 'Technologies',
          dataField: getMatchingItems(technology, relatedTechnology)
        })
      }
    }

    return capabilities
  }
  const isMoreTabVisible = useMemo(() => {
    let isMoreTabVisible = false
    if (productData) {
      const {
        policyAdminReference,
        costToMarket,
        distributionModelsCurrentlySupported,
        timeToMarket,
        company: { implementationResourceModels }
      } = productData

      if (
        policyAdminReference ||
        costToMarket ||
        (distributionModelsCurrentlySupported && timeToMarket) ||
        implementationResourceModels
      ) {
        isMoreTabVisible = true
      } else {
        isMoreTabVisible = false
      }
    }

    return isMoreTabVisible
  }, [productData])
  useEffect(() => {
    if (wrapperRef.current && wrapperRef.current.clientHeight) {
      setHeight(wrapperRef.current.clientHeight)
    }
  }, [wrapperRef.current])

  const capabilitiesTab = (
    <>
      <Grid container direction="column" ref={wrapperRef}>
        {dataLength(isTechnologiesMatches()) > 0 ? (
          <>
            {isTechnologiesMatches().map(({ title, dataField }, i) => (
              <React.Fragment key={i}>
                {dataField && (
                  <Box key={title}>
                    <Typography
                      style={{
                        margin: '10px 0'
                      }}
                      variant="h5"
                    >
                      {title}
                    </Typography>
                    <>
                      <Grid container>
                        {dataField?.map((fields, i) => (
                          <Chips
                            key={i}
                            isFromJourney={true}
                            el={fields.label ? fields.label : fields}
                            editable={false}
                            marginTop="none"
                          />
                        ))}
                      </Grid>
                    </>
                  </Box>
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <Typography variant="h4" className={classes.noDataText}>
            No relevant content
          </Typography>
        )}
      </Grid>
    </>
  )

  return (
    <Grid
      className={clsx({
        [classes.inActive]: !isActive
      })}
    >
      <Grid container className={classes.mainProductPageContainer}>
        <Grid className={classes.productPageInfoBlock}>
          {downXs ? (
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1', setExpanded)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Capabilities</Typography>
              </AccordionSummary>
              {capabilitiesTab}
            </Accordion>
          ) : (
            <>
              <Typography variant="h3">Capabilities</Typography>{' '}
              {capabilitiesTab}
            </>
          )}
        </Grid>
        <Grid
          container
          className={classes.productChipsBlock}
          style={{ justifyContent: 'unset', minHeight: 300 }}
        >
          <Box>
            <Typography variant="h3">Primary function</Typography>
            <Grid
              container
              style={{
                padding: '20px 0'
              }}
            >
              {productData.primaryFunction ? (
                productData.primaryFunction.map((fields, i) => (
                  <Typography
                    key={i}
                    style={{
                      fontWeight: 400
                    }}
                    variant="h4"
                  >
                    {fields}
                  </Typography>
                ))
              ) : (
                <Typography variant="h4" className={classes.noDataText}>
                  No relevant content
                </Typography>
              )}
            </Grid>
          </Box>

          <Box
            style={{
              width: '100%'
            }}
          >
            <Typography variant="h3">Secondary functions</Typography>
            <Grid
              container
              direction="column"
              style={{
                flexWrap: 'nowrap',
                overflow: 'auto',
                maxHeight: downXs ? '100%' : 280,
                paddingTop: 20,
                height: downSm ? 'unset' : height - 100
              }}
            >
              {productData.secondaryFunction ? (
                productData.secondaryFunction.map((field, i) => (
                  <DotedTypography key={i} field={field} />
                ))
              ) : (
                <Typography variant="h4" className={classes.noDataText}>
                  No additional functions
                </Typography>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {isMoreTabVisible && (
        <More
          productData={productData}
          dataLength={dataLength}
          isActive={isActive}
        />
      )}
      <Grid>
        <SuggestionsSubmit productData={productData}></SuggestionsSubmit>
      </Grid>
    </Grid>
  )
}
export default Capabilities
