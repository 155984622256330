import React from 'react'
import { Drawer, makeStyles, Grid, Typography } from '@material-ui/core'
import { useAuth0 } from 'components/Contexts/AuthContext'
import {
  OxbowPrimaryButton,
  OxbowPrimaryTransparentButton
} from 'components/Helpers/Buttons'
import StyledLink from 'components/Helpers/StyledLink'

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    background: 'linear-gradient(130.97deg, #06293D 46.13%, #15425B 75.17%)',
    transform: 'none',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
  },
  drawerContainer: {
    textAlign: 'center',
    width: '100%',
    margin: '140px auto'
  },

  styledText: {
    textTransform: 'uppercase',
    paddingBottom: 30,
    color: '#fff',
    '& a': {
      color: '#fff',
      textDecoration: 'none'
    }
  },
  landingBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 15,
    '& button:first-of-type': {
      marginBottom: 30
    }
  }
}))

// Drawer for mobile devices
export const LandingMobileDrawer = ({ drawerOpen, toggleDrawer }) => {
  const classes = useStyles()
  const { loginWithRedirect } = useAuth0()

  return (
    <Drawer
      open={drawerOpen}
      anchor="right"
      onClose={toggleDrawer}
      variant="persistent"
      classes={{ paper: classes.paper }}
    >
      <div
        className={classes.drawerContainer}
        role="presentation"
        onKeyDown={toggleDrawer}
      >
        <>
          <Typography
            className={classes.styledText}
            variant="h4"
            onClick={toggleDrawer}
          >
            <StyledLink to="/home" selectedClass="selected">
              HOME
            </StyledLink>
          </Typography>
          <Typography
            className={classes.styledText}
            variant="h4"
            onClick={toggleDrawer}
          >
            <StyledLink to="/about-magellan" selectedClass="selected">
              ABOUT MAGELLAN
            </StyledLink>
          </Typography>
          <Typography
            className={classes.styledText}
            variant="h4"
            onClick={toggleDrawer}
          >
            <StyledLink to="/insights" selectedClass="selected">
              INSIGHTS
            </StyledLink>
          </Typography>
          <Typography
            className={classes.styledText}
            variant="h4"
            onClick={toggleDrawer}
          >
            <StyledLink to="/pricing" selectedClass="selected">
              PRICING
            </StyledLink>
          </Typography>

          <Grid className={classes.landingBtnContainer}>
            <OxbowPrimaryButton
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    targetUrl: window.location.pathname
                  },
                  prompt: 'login',
                  screen_hint: 'signup'
                })
              }
              className={classes.contactUsBtn}
              width="smd"
              fontSize="md"
            >
              SIGN UP
            </OxbowPrimaryButton>
            <OxbowPrimaryTransparentButton
              className={classes.contactUsBtn}
              onClick={() =>
                loginWithRedirect({
                  prompt: 'login',
                  screen_hint: 'signin'
                })
              }
              width="smd"
              fontSize="md"
              style={{ color: 'white' }}
            >
              SIGN IN
            </OxbowPrimaryTransparentButton>
          </Grid>
        </>
      </div>
    </Drawer>
  )
}
