import React, { useRef, useState } from 'react'
import { useNavContext } from '../Navbar'
import { Fade, Box, makeStyles, Typography, Grid } from '@material-ui/core'
import { Formik } from 'formik'
import { signUpUser } from 'utils/api'
import ErrorIcon from '@material-ui/icons/Error'
import { useAuth0 } from 'components/Contexts/AuthContext'
import MagellanModal from '../Modal'
import { OxbowPrimaryButton } from '../Buttons'
import cross from 'assets/icons/cross.svg'
const GreetingModal = React.forwardRef(function Modal({ onClose }, ref) {
  return (
    <Fade in>
      <div ref={ref}>
        <Box className="modal-overlay">
          <Grid
            style={{ marginBottom: '15px' }}
            className="thankyou-modal modal"
          >
            <span className="modal-close-button" onClick={onClose}>
              <span></span>
            </span>
            <Typography variant="h1">Thank you</Typography>
            <Typography variant="subtitle2">
              We appreciate you contacting us and will get to you soon
            </Typography>
            <span className="modal-close-button" onClick={onClose}>
              <span></span>
            </span>
          </Grid>
        </Box>
      </div>
    </Fade>
  )
})

const labelMappings = {
  fname: 'First Name',
  lname: 'Last Name',
  email: 'E-mail',
  company: 'Company'
}

const useStyles = makeStyles(theme => ({
  tile: {
    maxWidth: 250
  },
  modal: {
    padding: '30px 30px 50px',
    width: '65%',
    textAlign: 'center',
    color: 'black',
    backgroundColor: 'white',
    '& h3': {
      color: 'black'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: theme.spacing(5, 3)
    }
  },
  heading: {
    fontWeight: 'bold'
  },
  button: {
    '& .MuiButton-label': {
      textTransform: 'uppercase',
      fontSize: '1.3rem'
    }
  },
  buttonWrapper: {
    textAlign: 'center'
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    width: '12px',
    height: '12px'
  }
}))

export default function SignUpModal() {
  const { user } = useAuth0()
  const classes = useStyles()
  const { setSignupOpen, isSignupOpen: open } = useNavContext()
  let initialFormValues = {
    email: '',
    fname: '',
    lname: '',
    company: ''
  }

  if (user) {
    initialFormValues.email = user.email || ''

    if (user.name && user.name.trim().length > 0) {
      const n = user.name.split(' ')
      if (n.length === 1) {
        initialFormValues.fname = user.name
      } else if (n.length > 1) {
        initialFormValues.lname = n[n.length - 1]
        delete n[n.length - 1]
        initialFormValues.fname = n.join(' ').trim()
      }
    }
    const ns = 'https://magellan.oxbowpartners.com/details'
    initialFormValues.company =
      (user[ns] && user[ns].organisation && user[ns].organisation.name) || ''
  }

  const [greetingOpen, setGreetingOpen] = useState(false)
  const signUpFormRef = useRef()

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)

    try {
      await signUpUser(values)
      setSubmitting(false)
      resetForm()
      // setSignupOpen(false)
      setGreetingOpen(true)
    } catch (error) {
      setSubmitting(false)
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const validateFields = values => {
    const errors = {}
    const mandatoryValues = ['fname', 'email']
    mandatoryValues.forEach(key => {
      if (!values[key]) errors[key] = `Please enter the ${labelMappings[key]}`
    })

    // Check for invalid email
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }

  return (
    <>
      {greetingOpen ? (
        <GreetingModal
          onClose={() => {
            setGreetingOpen(false)
            setSignupOpen(false)
          }}
        />
      ) : (
        <MagellanModal
          modalClass={classes.modal}
          closeOnBackDropClick
          show={open}
          onClose={() => setSignupOpen(false)}
        >
          <img
            alt=""
            src={cross}
            onClick={() => setSignupOpen(false)}
            className={classes.closeIcon}
          />
          <Typography
            color="textSecondary"
            variant="h3"
            align="center"
            classes={{ root: classes.heading }}
          >
            This content is only available for Premium users
          </Typography>
          <Box mt="15px">
            <Typography variant="subtitle2">
              To upgrade your subscription, allowing you full access to 4,000+
              company profiles and filtering capabilities, please enquire below
            </Typography>
          </Box>

          <Formik
            initialValues={initialFormValues}
            validate={validateFields}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              submitForm
            }) => (
              <>
                <Box mt={4}>
                  <OxbowPrimaryButton
                    padding="md"
                    width="max-content"
                    size="xl"
                    onClick={() => submitForm()}
                    isLoading={isSubmitting}
                  >
                    Enquire about Magellan Premium
                  </OxbowPrimaryButton>
                </Box>
                <form
                  action=""
                  onSubmit={handleSubmit}
                  ref={signUpFormRef}
                  style={{ display: 'none' }}
                >
                  <input
                    type="text"
                    placeholder="First name"
                    name="fname"
                    value={values.fname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.fname && touched.fname && (
                    <span className="hint error">
                      <ErrorIcon /> {errors.fname}
                    </span>
                  )}

                  <input
                    type="text"
                    placeholder="Last name"
                    name="lname"
                    value={values.lname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.lname && touched.lname && (
                    <span className="hint error">
                      <ErrorIcon />
                      {errors.lname}
                    </span>
                  )}
                  <input
                    type="text"
                    placeholder="Company"
                    name="company"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.company && touched.company && (
                    <span className="hint error">
                      <ErrorIcon />
                      {errors.company}
                    </span>
                  )}
                  <input
                    type="text"
                    placeholder="E-mail (company address)"
                    name="email"
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <span className="hint error">
                      <ErrorIcon />
                      {errors.email}
                    </span>
                  )}
                  <Box mt={2} style={{ textAlign: 'center' }}>
                    <button
                      className="btn btn-primary btn-block"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Please wait ...' : 'Submit'}
                    </button>
                  </Box>
                </form>
              </>
            )}
          </Formik>
        </MagellanModal>
      )}
    </>
  )
}
