import { Box, makeStyles, Typography } from '@material-ui/core'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'

import SearchBar from 'components/Helpers/SearchBar/searchBar'
import { BaseLayoutBody } from 'components/Layouts/Base'
import React, { useCallback, useEffect } from 'react'
import { SuspenseFallbackLoader } from 'Router/SuspenseFallbackLoader'
import { useMediaQueries } from 'utils/customHooks/useMediaQueries'
import SearchResults from './SearchResults'
import SavedSerchesMainComponent from 'components/Helpers/SavedSearches'
import { getSavedSearches } from 'utils/api'
import clsx from 'clsx'
import { useSearchContext } from 'components/Contexts/SearchContext'
import { FreeRestrictions } from './components/FreeRestrictions'
import FilterOptions from 'components/Helpers/Filters/FilterOptionsComponent'
import { useAuth0 } from 'components/Contexts/AuthContext'

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '4rem',
    color: 'white',
    textTransform: 'uppercase'
  },
  searchbarHeaderRoot: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  searchbarHeaderContainer: {
    width: '60%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '50px',
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: 0,
      width: '100%',
      paddingBottom: 0
    },
    '& >div': {
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto 25px'
      }
    }
  },

  searchbarContainer: {
    flex: 1,
    paddingTop: '30px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: ' 100%'
    }
  },
  customnBtn: {
    textTransform: 'uppercase',
    fontSize: '20px'
  },
  customnBtnChecked: {
    fontWeight: '600'
  },
  savedSearchesBtn: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'block',
    position: 'relative',
    transition: 'opacity 0.4s ease',
    willChange: 'opacity',
    '& h4': {
      color: 'white',
      transition: 'border-bottom 0.3s ease, color 0.3s ease',
      border: '2px solid transparent',
      '&:hover': {
        color: theme.palette.primary.mainLightBlue
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      textAlign: 'center',
      margin: '25px 0'
    }
  },
  savedSearchesBtnActive: {
    '& >h4': {
      color: theme.palette.secondary.main
    }
  },
  filtersContainer: {
    marginTop: 20,
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      '&>div ': {
        alignItems: 'flex-start',
        paddingLeft: 0
      }
    }
  },
  restrictionComponent: {
    marginTop: '-8px',
    width: '100%',
    height: '90vh',
    padding: theme.spacing(5, 8, 8, 8),
    background:
      'linear-gradient(180deg, rgba(242, 242, 241, 0.216) 0%, rgba(242, 242, 241, 0.9) 42.71%)',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 2)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  }
}))

export const SearchPageHeader = () => {
  const classes = useStyles()
  const {
    isSavedSearchModalOpen,
    setSavedSearchModalOpen,
    setSavedSearchesData,
    setSavedSearchesLoading,
    reloadSavedSearches,
    setReloadSavedSearches
  } = useFilterStateContext()
  const { setExcludeIds } = useSearchContext()

  const getSavedSearchesData = useCallback(async () => {
    const data = await getSavedSearches()
    setSavedSearchesData(data)
  }, [setSavedSearchesData])

  useEffect(() => {
    ;(async () => {
      if (reloadSavedSearches) {
        //temp removed
        // setSavedSearchesLoading(true)
        await getSavedSearchesData()
        setReloadSavedSearches(false)
        // setSavedSearchesLoading(false)
      }
    })()
    return () => {}
  }, [getSavedSearchesData, reloadSavedSearches, setReloadSavedSearches])

  const savedSearchesModalOpen = async () => {
    if (!isSavedSearchModalOpen) {
      setSavedSearchModalOpen(true)
      setSavedSearchesLoading(true)
      try {
        await getSavedSearchesData()
        setSavedSearchesLoading(false)
      } catch (error) {
        setSavedSearchesLoading(false)
      }
    }
    setSavedSearchModalOpen(!isSavedSearchModalOpen)
  }

  useEffect(() => {
    if (window.location) {
      setExcludeIds([])
    }
  }, [setExcludeIds])

  return (
    <div className={classes.searchbarHeaderRoot}>
      <div className={classes.searchbarHeaderContainer}>
        <Box className={classes.searchbarContainer}>
          <SearchBar />
        </Box>
        <Box
          className={clsx(classes.savedSearchesBtn, {
            [classes.savedSearchesBtnActive]: isSavedSearchModalOpen
          })}
          onClick={() => {
            savedSearchesModalOpen()
            setExcludeIds([])
          }}
        >
          <Typography variant="h4">Saved searches</Typography>
        </Box>
      </div>
    </div>
  )
}

export function SearchHome() {
  const { isAuthenticated } = useAuth0()
  const classes = useStyles()
  const {
    bootstrappingFilters,
    isSavedSearchModalOpen,
    isFreeUser,
    isFilterSelected,
    isUnlimitedSearches
  } = useFilterStateContext()

  const { freeUserData } = useSearchContext()

  const matches = useMediaQueries()
  if (bootstrappingFilters) return <SuspenseFallbackLoader />

  return (
    <BaseLayoutBody
      leftPanel={isAuthenticated ? <FilterOptions /> : <></>}
      leftPanelPadding="xs"
      leftPanelBorder={true}
      isFreeUser={isFreeUser}
      rightPanel={
        <>
          <Box
            px={matches.downMd ? 2 : 8}
            pb={!isUnlimitedSearches ? 0 : matches.downMd ? 5 : 8}
            pt={matches.downSm ? 0 : 3}
            style={{
              width: '100%'
            }}
          >
            <SearchResults />
            {isSavedSearchModalOpen ? <SavedSerchesMainComponent /> : ''}
          </Box>
          {!isFilterSelected ||
            (!isUnlimitedSearches && freeUserData && (
              <Box className={classes.restrictionComponent}>
                <FreeRestrictions />
              </Box>
            ))}
        </>
      }
      pageHeader={<SearchPageHeader />}
      enableLeftSideNav={true}
    ></BaseLayoutBody>
  )
}

export default SearchHome
