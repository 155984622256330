import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 'bold'
  },

  userName: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  }
}))

export default function ProfileBadge({ children, isAuthenticated, loading }) {
  const classes = useStyles()

  if (!isAuthenticated || loading) return null

  return <div className={classes.root}>{children}</div>
}
