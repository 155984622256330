import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../components/Contexts/AuthContext'
import { Container, CircularProgress } from '@material-ui/core'
const PrivateRoute = ({ component: Component, path, location, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: location.pathname + location.search },
        prompt: 'login',
        screen_hint: 'signup'
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, location])

  const render = props =>
    !loading ? (
      <Component {...props} />
    ) : (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          minHeight: 0
        }}
      >
        <CircularProgress />
      </Container>
    )

  return <Route path={path} render={render} {...rest} />
}

export default PrivateRoute
