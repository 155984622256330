import { makeStyles } from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: 'fit-content',
    color: 'white',
    alignItems: 'center',
    height: '24px',
    margin: '9px 10px 9px 0',
    lineHeight: '22px',
    background: theme.palette.secondary.main,
    borderRadius: '20px',
    boxSizing: 'content-box',
    padding: '3px 5px 3px 13px',
    outline: 0,
    overflow: 'hidden',

    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: theme.palette.primary.contrastText
    },

    '& svg': {
      display: 'inline-block',
      color: theme.palette.primary.contrastText,
      fontSize: '13px',
      height: 'auto',
      cursor: 'pointer',
      padding: '4px'
    }
  }
}))

export const Tag = ({ label, onDelete, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} {...rest}>
      <CloseIcon onClick={onDelete} />
      <span>{label}</span>
    </div>
  )
}
