import React, { createContext, useContext, useState } from 'react'
import { useAuth0 } from './AuthContext'

const SearchPaginationContext = createContext()
export const useSearchPaginationContext = () =>
  useContext(SearchPaginationContext)

export const SearchPaginationProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()

  const [allCompaniesPage, setAllCompaniesPage] = useState(1)
  const [itcPage, setItcPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  if (!pageSize && isAuthenticated) return null
  return (
    <SearchPaginationContext.Provider
      value={{
        allCompaniesPage,
        setAllCompaniesPage,
        pageSize,
        setPageSize,
        itcPage,
        setItcPage
      }}
    >
      {children}
    </SearchPaginationContext.Provider>
  )
}
export default SearchPaginationProvider
