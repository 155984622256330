import { produce } from 'immer'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  arrHasCommonElements,
  converyObjectToArray,
  handleFromUnitConversion,
  handleToUnitConversion
} from 'utils'
import _ from 'lodash'
import { getClearFilterSectionState } from 'utils/FilterBootstrapUtils'
import { FilterSection, MinMaxSelector } from '.'

import { MemoizedCheckoxSection } from '../Inputs/CheckboxOxbow'
import { MemoizedChipInput } from '../Inputs/ChipInput'
import { countriesData } from 'components/Pages/CustomisationJourney/countries'

import { Divider } from '@material-ui/core'

import { MemoizedIndeterminateSection } from './IndeterminateSection'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import { countriesAndRegionsCreate } from 'components/Pages/UserAccount/UserInformation/components/AreasOfInterest'

const createFilter = (
  filterConfig,
  filterName,
  filterAggsData,
  filterValue,
  onChangeHandler,
  clearOtherFilters
) => {
  switch (filterConfig.type) {
    case 'checkbox':
      return (
        <MemoizedCheckoxSection
          filterName={filterName}
          name={filterConfig.name}
          title={filterConfig.title}
          options={filterConfig.options}
          aggData={filterAggsData}
          value={filterValue}
          onChange={onChangeHandler}
          filterProps={filterConfig.props || {}}
          disabledOnFree={filterConfig.disabledOnFree}
        />
      )

    case 'range': {
      const unit = filterConfig.options.unit
      let [min, minValue] = unit
        ? [
            handleToUnitConversion(filterConfig.options.min, unit),
            handleToUnitConversion(filterValue.min, unit)
          ]
        : [filterConfig.options.min, filterValue.min]
      let [max, maxValue] = unit
        ? [
            handleToUnitConversion(filterConfig.options.max, unit),
            handleToUnitConversion(filterValue.max, unit)
          ]
        : [filterConfig.options.max, filterValue.max]
      return (
        <MinMaxSelector
          name={filterConfig.name}
          title={filterConfig.title}
          min={min}
          max={max}
          marks={filterConfig.options.marks}
          unit={unit}
          value={[minValue, maxValue]}
          onChange={onChangeHandler}
        />
      )
    }

    case 'chips':
      return (
        <MemoizedChipInput
          onChange={onChangeHandler}
          inputPlaceholder={filterConfig.inputLabel}
          label={filterConfig.title}
          options={filterConfig.options}
          name={filterConfig.name}
          showOptionCount={filterConfig.showOptionCount}
          optionCountData={filterAggsData}
          value={filterValue}
          isParentFilter={filterConfig.isParent}
          childrenFilterNames={
            filterConfig.isParent ? filterConfig.children : null
          }
          disabledOnFree={filterConfig.disabledOnFree}
          inputBorder={true}
          clearBtn={filterConfig?.props?.clearBtn}
          clearOtherFilters={clearOtherFilters}
        />
      )

    default:
      break
  }
}

export const FilterSectionWrapper = ({
  sectionConfig,
  selectedCategory,
  sectionAggregationData,
  freeUserFilters,
  setFilterValues,
  filterValues,
  isUnlimitedSearches,
  setAllCompaniesPage,
  selectedFilters,
  setFreeUserSelectedFilters,
  isFreeUserFiltersChanged,
  setIsFreeUserFiltersChanged,
  setApplyFiltersBtnVisible
}) => {
  const {
    itemStates,
    setItemStates,
    setCheckedCountries,
    categories,
    setCategories
  } = useFilterStateContext()
  const [firstRender, setFirstRender] = useState(true)
  const fValues = !isUnlimitedSearches ? freeUserFilters : filterValues
  const clearFilters = useCallback(() => {
    if (!isUnlimitedSearches) {
      setIsFreeUserFiltersChanged(true)
    }
    setAllCompaniesPage(1)
    setFilterValues(prev => ({
      ...prev,
      ...getClearFilterSectionState(sectionConfig)
    }))
  }, [
    setFilterValues,
    sectionConfig,
    setAllCompaniesPage,
    isUnlimitedSearches,
    setIsFreeUserFiltersChanged
  ])

  const clearOtherFilters = useCallback(
    name => {
      if (!isUnlimitedSearches) {
        setIsFreeUserFiltersChanged(true)
      }
      setAllCompaniesPage(1)
      setFilterValues(prev => ({
        ...prev,
        [name]: []
      }))
    },
    [
      isUnlimitedSearches,
      setAllCompaniesPage,
      setFilterValues,
      setIsFreeUserFiltersChanged
    ]
  )

  const isClearBtnVisible = useMemo(() => {
    const hasSelectedFilters =
      selectedFilters && Object.keys(selectedFilters).length > 0

    const hasSelectedGeographies =
      fValues &&
      fValues.geographiesWithImplementations &&
      fValues.geographiesWithImplementations.length > 0

    if (!hasSelectedFilters && !hasSelectedGeographies) {
      return false
    }

    const filterCategories = [
      ...(hasSelectedFilters
        ? Object.keys(selectedFilters)
            .map(filterName => {
              const filterConfig = sectionConfig.filters[filterName]
              return filterConfig || sectionConfig.id === filterName
                ? sectionConfig.id
                : undefined
            })
            .filter(Boolean)
        : []),
      ...(hasSelectedGeographies ? ['geographiesWithImplementations'] : [])
    ]
    return filterCategories
  }, [selectedFilters, sectionConfig, fValues])

  const MinMaxChangeHandler = useCallback(
    (filterName, currMin, currMax, filterConfig) => {
      setAllCompaniesPage(1)
      setFilterValues(prev => {
        const { max: maxValue, unit } = filterConfig.options
        let min = handleFromUnitConversion(currMin, unit)
        let max = handleFromUnitConversion(currMax, unit)
        return produce(prev, draftState => {
          draftState[filterName] = {
            min,
            max,
            maxSelected: max === maxValue
          }
        })
      })
    },
    [setFilterValues, setAllCompaniesPage]
  )
  const CheckBoxChangeHandler = useCallback(
    (filterName, filterOption, value) => {
      if (isUnlimitedSearches) {
        setAllCompaniesPage(1)
      }

      setFilterValues(prev =>
        produce(prev, draftState => {
          draftState[filterName][filterOption] = value
        })
      )
    },
    [setFilterValues, setAllCompaniesPage, isUnlimitedSearches]
  )
  const ChipInputChangeHandler = useCallback(
    (filterName, value) => {
      if (isUnlimitedSearches) {
        setAllCompaniesPage(1)
      }
      setFilterValues(prev =>
        produce(prev, draftState => {
          draftState[filterName] = value
        })
      )
    },
    [setFilterValues, setAllCompaniesPage, isUnlimitedSearches]
  )

  const getParentFilterAggregations = useCallback(
    parentFilterConfig => {
      const allKeys = parentFilterConfig.children.reduce(
        (acc, childFilterName) => {
          const childFilterAggregationData =
            sectionAggregationData[childFilterName]

          const processedChildFilterAgg = childFilterAggregationData
            ? Object.keys(childFilterAggregationData).map(key => ({
                key,
                filterName: childFilterName
              }))
            : []

          return [...acc, ...processedChildFilterAgg]
        },
        []
      )

      const aggregations = allKeys.reduce((acc, { key, filterName }) => {
        if (acc[key]) {
          acc[key].doc_count +=
            sectionAggregationData[filterName][key].doc_count
        } else {
          acc[key] = {
            key,
            doc_count: sectionAggregationData[filterName][key].doc_count
          }
        }
        return acc
      }, {})

      return aggregations
    },
    [sectionAggregationData]
  )

  const getParentFilterValue = useCallback(
    parentConfig => {
      const parentFilterValue = parentConfig.children.reduce(
        (acc, childrenName) => {
          if (!isUnlimitedSearches) {
            return [...acc, ...freeUserFilters[childrenName]]
          } else {
            return [...acc, ...filterValues[childrenName]]
          }
        },
        []
      )
      return [...new Set(parentFilterValue)]
    },
    [filterValues, freeUserFilters, isUnlimitedSearches]
  )

  const checkedItems = useMemo(() => {
    if (itemStates && Object.keys(itemStates).length > 0) {
      const checkedList = []
      Object.values(itemStates).forEach(filter => {
        filter.map(option => {
          if (option.checked) {
            checkedList.push(option.value)
          }
        })
      })
      return checkedList
    }
    return []
  }, [itemStates])

  const clearItemStateValues = filters => {
    let obj = {}
    Object.entries(itemStates).forEach(([key, value]) => {
      Object.keys(filters).forEach(i => {
        if (key === i) {
          value.map(j => {
            j.checked = false
          })
          obj = {
            ...obj,
            [key]: value
          }
        }
      })
    })
    setItemStates(prev => ({
      ...prev,
      ...obj
    }))
  }

  useEffect(() => {
    const setUpdatedItemStates = () => {
      let newItemStates = {}
      const filterValues = fValues[sectionConfig.id]
      const stateIds = Object.keys(sectionConfig.filters)
      Object.entries(itemStates).forEach(([key, value]) => {
        if (stateIds.includes(key)) {
          const modified = value.map(v => ({
            ...v,
            checked: filterValues.includes(v.value)
          }))
          if (!_.isEqual(value, modified)) {
            newItemStates[key] = modified
          }
        }
      })
      setItemStates(prev => ({
        ...prev,
        ...newItemStates
      }))
    }

    if (firstRender && Object.keys(itemStates).length > 0 && fValues) {
      setFirstRender(false)
      setUpdatedItemStates()
    }
  }, [sectionConfig, firstRender, itemStates, setItemStates, fValues])

  const [expandFilters, setExpandFilters] = useState({})

  const expandCurrentFilters = filters => {
    const expandedFilters = {}
    Object.keys(filters).forEach(k => {
      expandedFilters[k] = {
        expanded: false
      }
    })
    setExpandFilters(expandedFilters)
  }

  const filterOptions = useMemo(() => {
    return Object.keys(sectionConfig.filters)
      .filter(
        filterName =>
          !(
            sectionConfig.filters[filterName].hidden ||
            sectionConfig.filters[filterName].isChild
          )
      )
      .filter(filterName => {
        const selectedCategories = converyObjectToArray(selectedCategory)
        if (selectedCategories.length === 0) {
          if (sectionConfig.filters[filterName].category.length === 3) {
            return true
          }
          return false
        }

        return arrHasCommonElements(
          converyObjectToArray(selectedCategory),
          sectionConfig.filters[filterName].category
        )
      })
      .map(filterName => {
        const filterConfig = sectionConfig.filters[filterName]

        let onChangeHandler
        if (filterConfig.type === 'checkbox') {
          onChangeHandler = CheckBoxChangeHandler
        } else if (filterConfig.type === 'range') {
          onChangeHandler = MinMaxChangeHandler
        } else if (filterConfig.type === 'chips') {
          onChangeHandler = ChipInputChangeHandler
        }
        const filterAggregations = filterConfig.isParent
          ? getParentFilterAggregations(filterConfig)
          : sectionAggregationData[filterName]

        const filterValue = filterConfig.isParent
          ? getParentFilterValue(filterConfig)
          : !isUnlimitedSearches
          ? freeUserFilters[filterName]
          : filterValues[filterName]

        return (
          <div key={filterName} className="filter">
            {sectionConfig.title === 'What' ||
            sectionConfig.title === 'What for' ? (
              <MemoizedIndeterminateSection
                filterConfig={filterConfig}
                onChange={onChangeHandler}
                label={filterConfig.label}
                sectionTitle={filterConfig.title}
                options={filterConfig.options}
                sectionConfig={sectionConfig}
                filterName={filterConfig.name}
                id={sectionConfig.id}
                itemStates={itemStates}
                setItemStates={setItemStates}
                // filterValues={filterValues}
                filterValues={
                  !isUnlimitedSearches ? freeUserFilters : filterValues
                }
                checkedItems={checkedItems}
                expandFilters={expandFilters}
              />
            ) : (
              <>
                {createFilter(
                  filterConfig,
                  filterName,
                  filterAggregations,
                  filterValue,
                  onChangeHandler,
                  clearOtherFilters
                )}
              </>
            )}
          </div>
        )
      })
  }, [
    CheckBoxChangeHandler,
    ChipInputChangeHandler,
    MinMaxChangeHandler,
    freeUserFilters,
    filterValues,
    getParentFilterAggregations,
    getParentFilterValue,
    sectionAggregationData,
    sectionConfig,
    selectedCategory,
    isUnlimitedSearches,
    itemStates,
    setItemStates,
    checkedItems,
    clearOtherFilters,
    expandFilters
  ])
  useEffect(() => {
    if (!isUnlimitedSearches && isFreeUserFiltersChanged) {
      setFreeUserSelectedFilters(selectedFilters)
      setIsFreeUserFiltersChanged(false)
      setApplyFiltersBtnVisible(true)
    }
  }, [
    isFreeUserFiltersChanged,
    selectedFilters,
    isUnlimitedSearches,
    setFreeUserSelectedFilters,
    setIsFreeUserFiltersChanged,
    setApplyFiltersBtnVisible
  ])

  const clearFilterBlock = () => {
    if (!isUnlimitedSearches) {
      setIsFreeUserFiltersChanged(true)
    }
    clearItemStateValues(sectionConfig.filters)
    expandCurrentFilters(sectionConfig.filters)
    clearFilters()

    if (sectionConfig.id === 'whereFilters') {
      setCheckedCountries(
        countriesAndRegionsCreate({
          countriesData,
          dataDetails: 'regionsAndCountries',
          checkedCountries: []
        })
      )
    }
  }
  const distributorsCategory = categories.category.distributor

  const toggleHandler = value => {
    if (value) {
      clearFilterBlock()
    }
    setCategories(prev => ({
      ...prev,
      category: {
        ...(prev?.category || {}),
        distributor: value,
        supplier: !value,
      }
    }))
    setFilterValues(prev => ({
      ...prev,
      category: {
        ...(prev?.category || {}),
        distributor: value,
        supplier: !value,
      }
    }))
  }
  return (
    <FilterSection
      title={sectionConfig.title}
      isClearBtnDisabled={
        !isClearBtnVisible || !isClearBtnVisible.includes(sectionConfig.id)
      }
      key={sectionConfig}
      onClear={() => clearFilterBlock()}
      isClearBtnVisible={isClearBtnVisible}
      sectionConfig={sectionConfig}
      isDistributorsChecked={distributorsCategory}
      toggleHandler={toggleHandler}
      distributorsCategory={distributorsCategory}
    >
      {sectionConfig.title === 'What' && (
        <>{!distributorsCategory && <>{filterOptions}</>}</>
      )}
      {sectionConfig.title !== 'What' && filterOptions}
      {sectionConfig.title === 'What for' && (
        <Divider
          style={{
            margin: '24px 0'
          }}
        />
      )}
    </FilterSection>
  )
}
