import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { BaseLayoutBody } from 'components/Layouts/Base'

import InsightsCard from './Components/InsightsCard'

import { useStyles } from './useStyles'
import PaginationButtons, {
  InsightsPagination
} from 'components/Helpers/PaginationButtons'
import MainHeader from 'components/Helpers/PageHeader'
import SelectComponent from 'components/Helpers/SelectComponent/SelectComponent'
import { getInsightsList } from 'utils/api'
import { useQueryStringValues } from 'components/Contexts/QueryStringContext'
import { useOutsideClick } from 'utils/customHooks/useOutsideClickRef'
import useMediaQueries from 'utils/customHooks/useMediaQueries'

const Insights = ({ history }) => {
  const classes = useStyles()
  const {
    selectedContentType,
    setSelectedContentType,
    contentTypes,
    page,
    setPage,
    selectedCategory,
    setSelectedCategory,
    sortedBy,
    setSortedBy,
    categoriesOptions,
    sortedOptions,
    selectedCollectionTag,
    isFromCollectionPage,
    setIsFromCollectionPage,
    setSelectedCollectionTag
  } = useQueryStringValues()
  const { downSm } = useMediaQueries()
  const [loading, setLoading] = useState(false)
  const [insightsData, setInsightsData] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [selectedSelector, setSelectedSelector] = useState(null)
  const pageSize = 9
  const [_DATA, , count] = PaginationButtons({
    data: insightsData ? insightsData : [],
    countPerPage: pageSize,
    searchResultsLength: totalResults ? totalResults : 1
  })

  const getInsights = useCallback(async () => {
    setLoading(true)
    const type =
      selectedContentType === 'All article types' ? '' : selectedContentType
    const category =
      selectedCategory === 'All categories' ? '' : selectedCategory
    try {
      const params = {
        page: page,
        pageSize,
        sortOrder: sortedBy
      }
      if (type) params.type = type
      if (category) params.category = category
      const { insights, totalResults } = await getInsightsList(params)
      setInsightsData(insights)
      setTotalResults(totalResults)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
    }
  }, [page, selectedContentType, selectedCategory, sortedBy])

  useEffect(() => {
    if (selectedCollectionTag === '') {
      getInsights()
    }
  }, [
    selectedCategory,
    selectedContentType,
    page,
    sortedBy,
    selectedCollectionTag
  ])

  useEffect(() => {
    if (!isFromCollectionPage && selectedCollectionTag === '') {
      setSortedBy('desc')
    }
  }, [isFromCollectionPage, selectedCollectionTag])

  useEffect(() => {
    if (isFromCollectionPage && selectedCollectionTag !== '') {
      setSelectedContentType(selectedCollectionTag)
      setSortedBy('desc')
      setIsFromCollectionPage(false)
      setSelectedCollectionTag('')
    }
  }, [isFromCollectionPage, selectedCollectionTag])

  const categoriesRef = useRef()
  const contentRef = useRef()
  const sortedRef = useRef()

  const refs = [
    { ref: categoriesRef, name: 'categories' },
    { ref: contentRef, name: 'content' },
    { ref: sortedRef, name: 'sorted' }
  ]

  const getTextWidth = text => {
    // re-use canvas object for better performance
    var canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement('canvas'))
    var context = canvas.getContext('2d')

    var metrics = context.measureText(text)
    return metrics.width
  }

  const selectors = useMemo(() => {
    const options = {
      marginTop: 'none',
      isFromSearchPage: false,
      border: 'none',
      title: ' '
    }
    const width = downSm ? 200 : 250
    const selectorsData = [
      {
        width: getTextWidth(selectedContentType) + width,
        selectoptions: contentTypes,
        selectedValue: selectedContentType,
        setSelectedValue: setSelectedContentType,
        selectPlaceholder: 'Show',
        isFromSearchPage: !downSm
      },

      {
        width: 200,
        selectoptions: sortedOptions,
        selectedValue: sortedBy,
        setSelectedValue: setSortedBy,
        selectPlaceholder: 'Sort by'
      }
    ]
    const data = selectorsData.map((selectorData, i) => {
      return {
        ...options,
        ...selectorData,
        ref: refs[i].ref
      }
    })
    return data
  }, [
    selectedCategory,
    selectedContentType,
    sortedBy,
    categoriesOptions,
    contentTypes,
    setSortedBy,
    setSelectedCategory,
    setSelectedContentType,
    sortedOptions,
    categoriesRef,
    contentRef,
    sortedRef,
    refs,
    downSm,
    getTextWidth
  ])

  useOutsideClick(refs[selectedSelector]?.ref, setSelectedSelector, true)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [])

  const skeletonData = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  return (
    <BaseLayoutBody
      pageHeaderBottomLine={false}
      borderBottomLeftRadius={true}
      parentBodyBg={false}
      pageHeader={
        <MainHeader backBtn={false}>
          <Typography
            variant="h1"
            style={{
              margin: '20px 0'
            }}
          >
            Insights
          </Typography>

          <Grid container className={classes.insightsSortingBlock}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 400,
                fontSize: 16
              }}
            >
              Insurance technology deep dives, interviews, insights and more
              from Magellan’s team of industry specialists
            </Typography>
            <Grid className={classes.insightsSorting}>
              {selectors.map((selector, index) => (
                <SelectComponent
                  multipleSelectors={true}
                  id={index}
                  key={index}
                  marginTop={selector.marginTop}
                  isFromSearchPage={selector.isFromSearchPage}
                  border={selector.border}
                  title={selector.title}
                  selectoptions={selector.selectoptions}
                  selectedValue={selector.selectedValue}
                  setSelectedValue={selector.setSelectedValue}
                  selectPlaceholder={selector.selectPlaceholder}
                  styles={{ width: selector.width }}
                  setSelectedSelector={setSelectedSelector}
                  selectedSelector={selectedSelector}
                  selectorRef={selector.ref}
                  inputWidth="sm"
                />
              ))}
            </Grid>
          </Grid>
        </MainHeader>
      }
      rightPanel={
        <Box mb={10} className={classes.insightsMainContent}>
          <Box
            style={{
              height: '100%',
              marginBottom: 200
            }}
          >
            <InsightsCard
              data={_DATA}
              history={history}
              loading={loading}
              skeletonData={skeletonData}
            />
            {!loading && !_DATA.length && (
              <Grid container className="no-results">
                <Typography variant="h4">No results found</Typography>
                <Typography variant="h4">
                  We couldn't find what you search for
                </Typography>
              </Grid>
            )}
          </Box>
          <Box
            style={{
              position: 'absolute',
              bottom: 100,
              width: '100%'
            }}
          >
            {totalResults <= pageSize ? null : (
              <InsightsPagination
                countPerPage={count}
                _DATA={_DATA}
                page={page}
                setPage={setPage}
                sortedBy={sortedBy}
                selectedContentType={selectedContentType}
              />
            )}
          </Box>
        </Box>
      }
      bottomBanner={false}
    />
  )
}
export default Insights
