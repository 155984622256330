import React, { useEffect, useState } from 'react'
import useStyles from '../../../styles'

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary
} from '@material-ui/core'

import { useNavContext } from 'components/Contexts/NavbarContext'
import clsx from 'clsx'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {ExistingClientList} from "./ExistingClientList";

const ClientsAndActivity = ({ productData, isActive }) => {
  const classes = useStyles()
  const { currId } = useNavContext()
  const [expanded, setExpanded] = useState('panel1')

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const scrollToCurrId = () => {
    const el = document.getElementById(currId)
    if (el) {
      const rect = el.getBoundingClientRect()
      if (rect) {
        window.scrollTo({
          top: rect.top + window.scrollY - 100,
          behavior: 'smooth'
        })
      }
    }
  }

  useEffect(() => {
    if (currId) {
      const timeout = setTimeout(() => {
        scrollToCurrId(currId)
      }, [500])
      return () => {
        clearTimeout(timeout)
      }
    } else {
      return () => {}
    }
  }, [currId, scrollToCurrId])

  return (
    <Grid
      className={clsx({
        [classes.inActive]: !isActive
      })}
    >
      <Grid
        className={classes.productPageFullWidthBlock}
        style={{
          borderTopRightRadius: 50
        }}
        id="existing-clients-table"
      >
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1', setExpanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h3">Existing clients</Typography>
          </AccordionSummary>
          <ExistingClientList productData={productData} />
        </Accordion>
      </Grid>
    </Grid>
  )
}
export default ClientsAndActivity
