import { countriesData } from 'components/Pages/CustomisationJourney/countries'

export const londonMarketVirtualRegion = {
  region: 'Europe',
  type: 'country',
  name: "London market & Lloyd's"
};

export const defaultWhereList = [
  londonMarketVirtualRegion,
  {
    region: 'Europe',
    type: 'region',
    name: 'Europe',
    value: countriesData
      .find(item => item.region === 'Europe')
      .children.map(item => item.value)
  },
  { region: 'Europe', type: 'country', name: 'France' },
  { region: 'Europe', type: 'country', name: 'United Kingdom' },
  { region: 'Europe', type: 'country', name: 'Germany' },
  { region: 'Europe', type: 'country', name: 'Switzerland' },
  {
    region: 'United States',
    type: 'region',
    name: 'United States',
    value: countriesData
      .find(item => item.region === 'United States')
      .children.map(item => item.value)
  },
  {
    region: 'North America',
    type: 'region',
    name: 'North America',
    value: countriesData
      .find(item => item.region === 'North America')
      .children.map(item => item.value)
  }
]
