import React from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'

import ArrowLeftBlue from 'assets/icons/ArrowLeftBlue.png'

const useStyles = makeStyles(theme => ({
  collectionHeaderLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: 10,
    marginBottom: 7
  },
  backToCollectionsBtn: {
    color: theme.palette.primary.mainLightBlue,
    width: 195,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& h4': {
      fontSize: 14,
      fontWeight: '700 !important'
    },
    '& img': {
      width: 23,
      marginRight: 10
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
      width: 'unset'
      // paddingLeft: 20
    }
  }
}))

export const BackBtn = ({ title, onClick }) => {
  const classes = useStyles()
  const prevPaginationPage = () => {
    onClick()
  }

  return (
    <Box className={classes.collectionHeaderLeftContainer}>
      <div
        onClick={() => prevPaginationPage()}
        className={classes.backToCollectionsBtn}
      >
        <img alt="" src={ArrowLeftBlue} />
        <Typography variant="h4">{title}</Typography>
      </div>
    </Box>
  )
}
