import React from 'react'
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { useStyles } from './useStyles'
import { Link } from 'react-router-dom'
import useMediaQueries from 'utils/customHooks/useMediaQueries'

const TableComponent = ({ data, favourites = false }) => {
  const classes = useStyles()
  const { downSm } = useMediaQueries()

  return (
    <>
      {downSm ? (
        <>
          <React.Fragment>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableBody>
                  {data.slice(0, 5).map(product => {
                    return (
                      <React.Fragment key={product.id}>
                        <TableRow>
                          <TableCell
                            align="left"
                            className={classes.imgContainer}
                          >
                            <Typography variant="h4">Company</Typography>
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="right"
                            className={classes.companyName}
                            style={{ width: favourites ? '100%' : 'unset' }}
                          >
                            <Link
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: favourites ? 0 : 17
                              }}
                              to={{
                                pathname: `/product/${product.slug}`,
                                state: {
                                  breadcrumb: {
                                    key: 'productName',
                                    label: product.name
                                  }
                                }
                              }}
                            >
                              {favourites ? (
                                <img
                                  alt="Company Logo"
                                  src={product.logo || product.company?.logo}
                                  style={{
                                    width: 50,
                                    marginRight: favourites ? 0 : 40
                                  }}
                                />
                              ) : null}
                              <Typography
                                variant="h4"
                                className={classes.companyName}
                              >
                                {product.name}
                              </Typography>
                            </Link>
                          </TableCell>
                        </TableRow>
                        {favourites ? null : (
                          <>
                            <TableRow>
                              <TableCell
                                align="left"
                                className={classes.imgContainer}
                              >
                                <Typography variant="h4">Type</Typography>
                              </TableCell>
                              <TableCell align="center"></TableCell>
                              <TableCell align="left">
                                <Typography component="p" variant="subtitle2">
                                  {product.companyType}
                                </Typography>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                align="left"
                                className={classes.imgContainer}
                              >
                                <Typography variant="h4">
                                  Insurance classes
                                </Typography>
                              </TableCell>
                              <TableCell align="center"></TableCell>
                              <TableCell align="left">
                                <Typography component="p" variant="subtitle2">
                                  {product.insuranceClass
                                    ?.join(', ')
                                    .split(/\s(?=,\s)/)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        </>
      ) : (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.imgContainer}
                    style={{
                      padding: '10px 0'
                    }}
                  >
                    <Typography variant="h4">Company</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  {favourites ? null : (
                    <>
                      <TableCell align="left">
                        <Typography variant="h4">Type</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="h4">Insurance classes</Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data ? (
                  <>
                    {data.slice(0, 5).map(product => {
                      return (
                        <TableRow key={product.id}>
                          <TableCell
                            align="left"
                            className={classes.imgContainer}
                            style={{
                              paddingLeft: 0
                            }}
                          >
                            <Link
                              to={{
                                pathname: `/product/${product.slug}`,
                                state: {
                                  breadcrumb: {
                                    key: 'productName',
                                    label: product.name
                                  }
                                }
                              }}
                            >
                              <img
                                alt="Company Logo"
                                src={product.logo || product.company?.logo}
                              />
                            </Link>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={classes.companyName}
                            style={{ width: favourites ? '100%' : 'unset' }}
                          >
                            <Link
                              to={{
                                pathname: `/product/${product.slug}`,
                                state: {
                                  breadcrumb: {
                                    key: 'productName',
                                    label: product.name
                                  }
                                }
                              }}
                            >
                              <Typography
                                variant="h4"
                                className={classes.companyName}
                              >
                                {product.name}
                              </Typography>
                            </Link>
                          </TableCell>
                          {favourites ? null : (
                            <>
                              <TableCell align="left">
                                <Typography component="p" variant="subtitle2">
                                  {product.companyType}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ paddingRight: 0 }}
                              >
                                <Typography component="p" variant="subtitle2">
                                  {product.insuranceClass
                                    ?.join(', ')
                                    .split(/\s(?=,\s)/)}
                                </Typography>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      )
                    })}
                  </>
                ) : (
                  <CircularProgress
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      margin: 'auto'
                    }}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}
//temp removed
// export default React.memo(TableComponent, (prevProps, nextProps) => {
//   const prevProducts = prevProps.data ? prevProps.data.map(p => p.id) : []
//   const nextProducts = nextProps.data ? prevProps.data.map(p => p.id) : []
//   return isEqual(prevProducts, nextProducts)
// })
export default React.memo(TableComponent)
