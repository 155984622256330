import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'

const useStyles = makeStyles(theme => ({
  productInfoWrapper: {
    padding: '2rem 4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem'
    }
  },
  editModeActionsContainer: {
    position: 'fixed',
    left: '2rem',
    bottom: '2rem',
    width: 'auto',
    zIndex: 10
  },
  checkDix: {
    width: 100,
    height: 100,
    background: 'red'
  },

  mainDataContainer: {
    backgroundColor: theme.palette.secondary.mainLightGrey,
    fontFamily: theme.typography.secondaryFont,
    padding: '0 80px 20px',
    '& a': {
      textDecoration: 'none'
    },
    '& p': {
      color: '#000'
    },
    '& .MuiCircularProgress-root ': {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      top: 0,
      bottom: 0
    },
    '& .MuiAccordionSummary-root': {
      padding: '0'
    },
    [theme.breakpoints.up(1920)]: {
      padding: '0 260px 20px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 64px 20px'
    },
    [theme.breakpoints.down(1300)]: {
      padding: '0 24px 20px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      '& h3': {
        fontSize: 22
      }
    }
  },
  productPageMainHeader: {
    borderBottomLeftRadius: 50,
    // animation: `$animationInv 300ms ${theme.transitions.easing.easeInOut}`,

    '& h3': {
      color: '#fff'
    },
    [theme.breakpoints.up(1920)]: {
      padding: theme.spacing(12.5, 35, 0)
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(12.5, 6, 0, 4)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    }
  },

  mainInfoWrapper: {
    padding: theme.spacing(0, 0, 0, 7),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0, 0, 5)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 6.25, 0)
    }
  },
  valuePropositionContainer: {
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  mainInfoBlocks: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    '& .chips-container': {
      display: 'flex !important',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: '60%'
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& .chips-container': {
        width: '100%'
      },
      '& >div': {
        width: '100%'
      }
    }
  },
  offices: {
    marginTop: 15,
    '& .chips-container': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      width: '60%'
    },
    '& h4': {
      paddingBottom: 10
    },
    [theme.breakpoints.down('xs')]: {
      '& >div': {
        width: '100%',
        '& .chips-container': {
          width: '100%'
        }
      }
    }
  },
  existingClients: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      flexWrap: 'nowrap'
    },
    '& img': {
      marginRight: '0 !important'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& >div:last-child': {
        marginTop: 20
      }
    }
  },
  mainProductPageContainer: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    margin: '40px 0',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      '& >div': {
        margin: 0
      }
    }
  },
  productPageInfoBlock: {
    height: 'max-content',
    width: '58%',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: 1,
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '40px 60px 50px 60px',
    margin: '0 20px ',

    '& a': {
      color: theme.palette.secondary.main
    },
    '& h3': {
      marginBottom: 7
    },
    '& h6': {
      fontWeight: 400,
      padding: '4px 0'
    },
    '& h5': {
      fontWeight: 700,
      margin: '15px 0'
    },
    '& button': {
      marginTop: 40
    },
    '& img': {
      marginRight: 20
    },
    '& hr': {
      margin: '30px 0 20px'
    },
    [theme.breakpoints.up(1920)]: {
      width: '50% !important'
    },
    [theme.breakpoints.up('lg')]: {
      width: '58%'
    },
    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 50px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 22px 20px 30px',
      margin: '0 20px 40px !important'
    },
    [theme.breakpoints.down(1100)]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 20px 30px'
    }
  },
  companyNewsfeed: {
    borderTopRightRadius: 50,
    width: '39%',
    backgroundColor: 'white',
    position: 'relative',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '40px 15px 50px 60px',
    display: 'flex',
    alignItems: 'flex-start',
    margin: '0 20px',
    [theme.breakpoints.up(1920)]: {
      width: '50% !important'
    },
    [theme.breakpoints.up('lg')]: {
      width: '39%'
    },
    [theme.breakpoints.down('md')]: {
      width: '39%'
    },
    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 50px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
      borderTopRightRadius: 0,
      margin: '0 20px !important'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 50px 30px'
    }
  },

  productChipsBlock: {
    width: '39%',
    backgroundColor: theme.palette.secondary.backGroundLightGrey,
    borderTopRightRadius: 50,
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '40px 30px 50px 60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0 20px',
    '& > div': {
      flexDirection: 'column'
    },
    [theme.breakpoints.up(1920)]: {
      width: '50% !important'
    },
    [theme.breakpoints.up('lg')]: {
      width: '39%'
    },
    [theme.breakpoints.down('md')]: {
      width: '39%'
    },
    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 50px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: 0,
      padding: '30px 30px 50px 30px',
      width: '100%',
      maxWidth: 'unset',
      margin: '0 20px !important'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 50px 30px'
    }
  },
  liveInScrollBlock: {
    maxHeight: 150,
    overflow: 'auto',
    padding: '20px 0'
  },
  companiesByTrendsBlock: {
    justifyContent: 'space-between',
    margin: '0 20px',
    display: 'flex',
    position: 'relative',
    backgroundColor: theme.palette.secondary.backGroundLightGrey,
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,

    padding: '40px 60px 50px 60px',

    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 300,
    marginBottom: 20,
    '& .MuiPaper-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    },

    '& >div': {
      width: '100%'
    },
    '& >h3': {
      marginBottom: 11
    },
    '& hr': {
      margin: '3px 0 10px'
    },
    '& button': {
      // marginTop: 30
    },
    [theme.breakpoints.up(1920)]: {
      width: '50% !important'
    },
    [theme.breakpoints.up('lg')]: {
      width: '39%'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 490
    },

    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 50px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 22px 20px 30px',
      width: '100%',
      maxWidth: 'unset',
      margin: '0 20px 40px !important'
    },

    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 50px 30px',
      minHeight: 100
    }
  },
  overviewCompanies: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    height: 'max-content',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  otherCompanies: {
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '40px 60px 50px 60px',
    margin: '0 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '58%',
    '& button': {
      marginTop: 32
    },
    '& h3': {
      marginBottom: 11
    },
    '& a': {
      color: 'black'
    },
    [theme.breakpoints.up(1920)]: {
      width: '50%'
    },
    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 50px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 22px 20px 30px',
      width: '100%',
      maxWidth: 'unset'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 50px 30px',
      marginTop: 0
    }
  },
  mainCompanyInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(0)
    }
  },
  inActive: {
    filter: 'opacity(0.5)',
    pointerEvents: 'none'
  },
  inActiveLabel: {
    textAlign: 'center',
    backgroundColor: '#FFEAEA',
    position: 'absolute',
    right: 0,
    top: -40,
    left: 0,
    zIndex: 10,
    border: '1px solid red',
    padding: '15px 20px',
    fontSize: '16px'
  },
  titleColumn: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '100%',
    paddingTop: 20,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      width: '100%'
    }
  },
  mainProductInfo: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {}
  },
  backToBtn: {
    cursor: 'pointer',
    margin: '10px',
    color: theme.palette.primary.mainLightBlue,
    width: '200px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& h5': {
      fontWeight: 700
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
      marginTop: '10px'
    }
  },
  productName: {
    color: theme.palette.primary.main,
    fontSize: '36px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  },
  imageWrapper: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    background: 'white',
    display: 'block',
    '@supports not (aspect-ratio: 1/1)': {
      position: 'relative'
    },
    '& img': {
      width: 100,
      height: 100,
      objectFit: 'contain',
      borderRadius: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  },
  productHeaderLeabelBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',

      '& >div': {
        alignItems: 'center'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  productHeaderLabel: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'unset',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 30
    }
  },

  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& >img': {
      marginBottom: '25px',
      height: '135px'
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      '& button': {
        width: '36px !important',
        marginRight: '0 !important',
        alignSelf: 'flex-end'
      },
      '& button>span>div': {
        display: 'none'
      },
      '& button>span>span': {
        marginRight: '0 !important'
      }
    }
  },
  noDataText: {
    fontWeight: 400,
    opacity: 0.5
  },
  sectionNavigationContainer: {
    borderBottomLeftRadius: 50,
    flexDirection: 'column',
    '& p, h4, button': {
      color: '#fff'
    },
    '& .navItems': {
      alignItems: 'baseline',
      '& h4': {
        margin: '0 55px 0 40px',
        [theme.breakpoints.down('md')]: {
          paddingBottom: 0
        }
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        '&__items': {
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '90%'
        }
      }
    },
    '&.sticky': {
      width: 'auto',
      right: 3,
      animation: `$animation 100ms ${theme.transitions.easing.easeInOut}`,
      padding: '108px 100px 0 ',
      position: 'fixed',
      left: 0,
      top: 0,
      paddingTop: '79px',
      zIndex: 2,
      background: 'linear-gradient(130.97deg, #06233D 46.13%, #1C5677 75.17%)',
      '& h4': {
        margin: '0 55px 0 10px !important'
      },
      [theme.breakpoints.up(1920)]: {
        padding: '108px 280px 0 '
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 6, 0, 4)
      },

      [theme.breakpoints.down(1160)]: {
        padding: '24px 10px 0 24px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      flexDirection: 'column',
      height: '100%',
      '&.sticky': {
        padding: theme.spacing(0, 3),
        height: 'auto'
      }
    }
  },
  '@keyframes animation': {
    '0%': {
      opacity: 0
      // transform: 'translateY(10px)'
    },
    '100%': {
      opacity: 1
      // transform: 'translateY(0)'
    }
  },
  '@keyframes animationInv': {
    '0%': {
      opacity: 1
      //transform: 'translateY(-50px)'
    },
    '100%': {
      opacity: 0
      // transform: 'translateY(0)'
    }
  },
  productPageFullWidthBlock: {
    position: 'relative',
    background: '#fff',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent'
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    },

    '&:nth-child(even)': {
      background: derivedFromProp('background', {
        grey: '#F3F3F2',
        default: '#fff'
      })
    },
    padding: '40px 60px 50px 60px',
    border: '1px solid #DADADA',
    margin: '40px 20px',
    '& >span': {
      display: 'flex',
      flexWrap: 'wrap',

      '& >div': {
        width: '35%',
        margin: '10px 10px 10px 0px'
      }
    },
    '& >div': {
      marginTop: 20,
      display: derivedFromProp('display', {
        grid: 'grid',
        default: 'block'
      }),
      gridTemplateColumns: derivedFromProp('display', {
        grid: '1fr 1fr',
        default: 'unset'
      }),
      gridGap: derivedFromProp('display', {
        grid: '20px 50px',
        default: 'unset'
      })
    },
    '& article': {
      marginTop: 20,
      '& >div:not(:last-child)': {
        paddingBottom: 20
      }
    },

    '& h5': {
      fontWeight: 700
    },

    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 50px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 22px 20px 30px',
      '& >span': {
        '& >div': {
          width: '50% !important'
        }
      }
    },

    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 20px 30px',
      '& >div': {
        marginTop: 10,
        display: derivedFromProp('display', {
          grid: 'block',
          default: 'block'
        }),
        gridTemplateColumns: derivedFromProp('display', {
          grid: 'unset',
          default: 'unset'
        }),
        gridGap: derivedFromProp('display', {
          grid: 'unset',
          default: 'unset'
        })
      },
      '& >span': {
        '& >div': {
          width: '100% !important'
        }
      }
    }
  },
  productPageFullWidthBlockHeader: {
    display: 'flex !important',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  newsInsightsBlock: {
    overflowY: 'scroll',
    paddingRight: 15
  },
  bottomGradient: {
    height: 75,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0.47%, #FEFDFC 100%)',

    position: 'absolute',
    width: '100%',
    left: 0,
    right: 0,
    bottom: 0
  },
  sectionNavigationMainBox: {
    padding: 0,
    display: 'flex',
    // marginTop: 30,
    '& button': {
      color: '#fff'
    },

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 25,
      padding: 0,
      justifyContent: 'space-between'
    }
  },

  btnContainer: {
    '& button': {
      marginTop: 10
    },
    display: 'block',

    '& :first-child >span ': {
      justifyContent: 'flex-start',

      '& svg': {
        width: '15px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    }
  },
  circularProgress: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -4,
    '& svg': {
      width: '100% !important'
    }
  },
  bookmarksButtonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',

    [theme.breakpoints.down(780)]: {
      flexWrap: 'wrap',
      '& >:first-child': {
        marginRight: 0
      }
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      flexWrap: 'nowrap'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainInputContainer: {
    width: '100%',
    '& input, textarea': {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '16px',
      boxShadow: 'unset',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      '-webkit-border-radius': 'unset',
      appearance: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px'
      }
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    scrollbarColor: 'white',
    '& .mainInputBox': {
      justifyContent: 'space-between',
      '&>div': {
        textAlign: 'start',
        position: 'relative',
        marginTop: '11px',
        marginBottom: '8px',
        '& .errorText': {
          color: 'red',
          fontFamily: 'Open Sans',
          fontSize: '12px'
        },
        '& .hintError': {
          zIndex: 9,
          width: '40px',
          height: '39px',
          backgroundColor: 'red',
          position: 'absolute',
          top: '0',
          right: '0',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& >svg': {
            height: '18px'
          },
          [theme.breakpoints.down('md')]: {
            height: '35px'
          }
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
          marginBottom: 3
        }
      }
    },
    '& label, option': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    },
    '& input': {
      marginTop: '11px',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      outline: 'none',
      height: '40px',
      border: '1px solid #C8C8C8',
      display: 'block',
      width: derivedFromProp('inputWidth', {
        true: '360px',
        default: '260px'
      }),
      [theme.breakpoints.down('md')]: {
        height: '35px'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '5px'
      }
    },
    '& textarea': {
      padding: '10px',
      outline: 'none',
      border: '1px solid #C8C8C8'
    }
  },
  modalMainContainer: {
    height: 'max-content',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    background: '#fff',
    textAlign: 'center',
    '& h2': {
      margin: '30px 0 10px 0',
      lineHeight: '43px',
      fontSize: '36px',
      fontWeight: 500,
      fontFamily: '"Barlow Condensed", sans-serif',
      [theme.breakpoints.down('md')]: {
        margin: '20px 0 10px 0'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '26px'
      }
    },
    '& p': {
      margin: '13px 0 15px 0',
      fontSize: '18px',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    '& a': {
      textDecoration: 'none'
    },

    [theme.breakpoints.down('xs')]: {
      width: '400px'
    }
  },
  suggestionForm: {
    height: 'max-content',
    width: '96.5%',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: 1,
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '30px 60px',
    margin: '0 20px ',
    '& label': {
      marginBottom: '10px',
      display: 'block'
    },
    '& textarea': {
      resize: 'none'
    },
    [theme.breakpoints.down('md')]: {
      width: '94%',
      margin: '0 auto',
      padding: '30px 22px 20px 30px',
      '& textarea': {
        width: '100%'
      }
    }
  },
  suggestionList: {
    marginTop: '20px',
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& li': {
        borderBottom: '1px solid #DADADA',
        padding: '10px 15px',
        '& > *': {
          paddingBottom: '5px'
        }
      },
      '& li:first-child': {
        borderTop: '1px solid #DADADA',
      },
    }
  },
  attachmentsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  suggestionsAttachmentLinks: {
    '& span': {
      padding: '0 15px'
    }
  }
}))

export const useCustomStyles = makeStyles(() => ({
  chipsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr ',
    gridGap: '0 20px',
    '& h5': {
      fontWeight: 400,
      fontSize: 16
    },
    '& h4': {
      fontWeight: 700,
      paddingBottom: 12
    }
  }
}))

export const useSectioButtonStyles = makeStyles(theme => ({
  root: {
    margin: '0px 55px 0px 0px',
    padding: '0px 0px 25px !important',
    outline: 'none',
    background: 'transparent',
    fontSize: 16,
    cursor: 'pointer',
    fontFamily: theme.typography.secondaryFont,
    color: theme.palette.primary.main,
    fontWeight: 600,
    display: 'inline-flex',
    paddingBottom: '18px',
    border: `2px solid transparent`,
    alignItems: 'center',
    transition: '0.3s ease',
    [theme.breakpoints.down('md')]: {
      padding: '0px 0px 10px !important',

      margin: '10px 50px 0px 0px'
    },
    '& svg': {
      display: 'block',
      marginLeft: theme.spacing(2)
    },
    '&.selected': {
      fontWeight: 700,
      color: theme.palette.secondary.main,
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.mainLightBlue
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px'
    }
  }
}))

export default useStyles
