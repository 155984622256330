import { getAxiosInstance } from './axios'
import plainAxios from 'axios'
import fileDownload from 'js-file-download'

/**
 * Root URL of the Api(based on the application env)
 */
export const apiRootURL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'Testing':
      return 'http://localhost:4000/'
    case 'Development':
      return 'https://076oilxhw5.execute-api.eu-west-2.amazonaws.com/dev/'
    case 'Staging':
      return 'https://076oilxhw5.execute-api.eu-west-2.amazonaws.com/Stage/'
    case 'Production':
      return 'https://oma55off2b.execute-api.eu-west-2.amazonaws.com/prod/'
    default:
      return 'https://076oilxhw5.execute-api.eu-west-2.amazonaws.com/dev/'
  }
})()

export const bucketName = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'Testing':
      return 'dev-collections'
    case 'Development':
      return 'dev-collections'
    case 'Staging':
      return 'dev-collections'
    case 'Production':
      return 'prod-collections'
    default:
      return 'dev-collections'
  }
})()

const getReducedData = obj => {
  delete obj['4HEgyg7UrXljykUCmNAQC0']
  return obj
}

/**
 *
 * @param {string} productID Get the data of the Company which the owns the product referenced by the productID
 */
export const getCompanyByProdID = async productID => {
  const axios = getAxiosInstance()
  const { data } = await axios(`${apiRootURL}company`, {
    params: {
      productID
    }
  })
  return data
}

/**
 *
 * @param {string} slug Slug of the Product Name whose data has to be fetched
 */
export const getProductDataBySlug = async slug => {
  const axios = getAxiosInstance()
  const { data } = await axios(`${apiRootURL}product`, {
    params: {
      slug
    }
  })
  return data
}

export const sendContactUsData = async contactUsData => {
  const axios = getAxiosInstance()
  const { data } = await axios.post(`${apiRootURL}contact`, {
    ...contactUsData
  })
  return data
}

let getQueryResultsCancelToken

/**
 *
 * @param {stirng} searchQuery The searchkey for elastic search
 * @param {object} filters The object which contains all the values for the filters
 * @param {string} searchType Search Type either Keyword or Filter based
 */
export async function getQueryResults(
  searchQuery,
  query = {},
  queryStringParameters = {},
  excludeIds
) {
  let resDate = {}
  if (excludeIds && Object.entries(excludeIds).length > 0) {
    resDate = { ...query, excludeIds: excludeIds }
  } else {
    resDate = query
  }
  const parametersWithSearchQuery = {
    searchQuery: searchQuery.toLowerCase(),
    ...queryStringParameters
  }

  const axios = getAxiosInstance()
  if (!queryStringParameters.isFor) {
    if (getQueryResultsCancelToken) {
      getQueryResultsCancelToken()
    }
  }
  const { data } = await axios
    .post(`${apiRootURL}search`, resDate, {
      params: parametersWithSearchQuery,
      ...(queryStringParameters.isFor
        ? {}
        : {
            cancelToken: new plainAxios.CancelToken(c => {
              getQueryResultsCancelToken = c
            })
          })
    })
    .catch(err => {
      if (!plainAxios.isCancel(err)) {
        throw err
      }
      return {}
    })

  return {
    products: data ? data.products : [],
    aggregations: data ? data.aggregations : {},
    totalResults: data ? data.totalResults : 0,
    searchesLeft: data ? data.searchesLeft : 0
  }
}

/**
 * Return the filter aggregation data for bootstraping the initial load of the application
 */
export const getInitialFilterAggregations = async () => {
  const axios = getAxiosInstance()
  const res = await axios.post(
    `${apiRootURL}search`,
    {},
    {
      params: {
        bootstrapAggregationData: true
      }
    }
  )

  res.data.productsBootstrapData = getReducedData(
    res.data.productsBootstrapData
  )

  return res.data
}

export const getProductData = async ({ id, slug }) => {
  const axios = getAxiosInstance()
  // const data = await axios(`${apiRootURL}new-product`, {
  const data = await axios(`${apiRootURL}v2/product`, {
    params: {
      id,
      slug
    }
  }).then(res => res.data)
  return data
}

export const saveNewSearch = async dataValues => {
  const axios = getAxiosInstance()
  const { data } = await axios.post(`${apiRootURL}saved-searches`, {
    ...dataValues
  })
  return data
}

export const getSavedSearches = async () => {
  const axios = getAxiosInstance()
  const { data } = await axios.get(`${apiRootURL}saved-searches`)
  return data
}

export const getSavedSearchFilters = async id => {
  const axios = getAxiosInstance()
  const { data } = await axios(`${apiRootURL}saved-searches/${id}`)
  return data
}

export const deleteSavedSearchesAppliedFilters = async id => {
  const axios = getAxiosInstance()
  const { data } = await axios.delete(`${apiRootURL}saved-searches/${id}`)
  return data
}

export const updateSavedSearches = async (id, filters) => {
  const axios = getAxiosInstance()
  const { data } = await axios.patch(`${apiRootURL}saved-searches/${id}`, {
    ...filters
  })
  return data
}

export const getUserProfile = async () => {
  const axios = getAxiosInstance()
  const { data } = await axios.get(`${apiRootURL}user/profile`)
  return data
}
export const updateUserProfile = async filters => {
  const axios = getAxiosInstance()
  const { data } = await axios.patch(`${apiRootURL}user/profile`, {
    ...filters
  })
  return data
}

export const deleteUserProfile = async () => {
  const axios = getAxiosInstance()
  const { data } = await axios.delete(`${apiRootURL}user/profile`)
  return data
}

export const resetPassword = async () => {
  const axios = getAxiosInstance()
  const { data } = await axios.post(`${apiRootURL}user/reset-password`)
  return data
}

export const exportProductPdf = async (id, productName) => {
  const axios = getAxiosInstance()
  await axios
    .post(
      `${apiRootURL}export/product/${id}`,
      {},
      {
        headers: {
          Accept: 'application/pdf'
        },
        responseType: 'blob'
      }
    )
    .then(res => {
      fileDownload(res.data, `${productName}.pdf`, 'application/pdf')
    })
}

export const exportCollectionCsv = async (id, collectionName) => {
  const axios = getAxiosInstance()
  await axios
    .post(
      `${apiRootURL}export/collection/${id}`,
      {},
      {
        Accept: 'application/csv'
      }
    )
    .then(res => fileDownload(res.data, `${collectionName}.csv`, 'text/csv'))
}

let cancel

export const getNewsAndInsight = async (start, end, articleType) => {
  if (cancel) {
    cancel()
  }
  const axios = getAxiosInstance()
  const url = new URL(`${apiRootURL}news`)
  url.searchParams.append('start', start)
  url.searchParams.append('end', end)
  if (articleType) {
    url.searchParams.append('articleType', articleType)
  }
  const { data } = await axios
    .get(url.toString(), {
      Authorization: 'Bearer null',
      cancelToken: new plainAxios.CancelToken(c => {
        cancel = c
      })
    })
    .catch(err => {
      if (!plainAxios.isCancel(err)) {
        throw err
      }
      return { data: { news: [] } }
    })
  return data
}

export const getInsightsList = async params => {
  const axios = getAxiosInstance()
  const { data } = await axios.get(`${apiRootURL}insights`, {
    params
  })
  return data
}
export const getSingleArticle = async id => {
  const axios = getAxiosInstance()
  const { data } = await axios.get(`${apiRootURL}insights/${id}`)
  return data
}

/**
 * Sign up user using Auth0 API
 * @param {object} userInfo Object containing the userifo
 */
// export const signUpUser = async userInfo => {
//   const auth0ApiUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/signup`
//   await plainAxios.post(auth0ApiUrl, {
//     client_id: process.env.REACT_APP_AUTH0_CLIENTID,
//     email: userInfo.email,
//     password: userInfo.password,
//     connection: 'Username-Password-Authentication',
//     given_name: userInfo.fname,
//     family_name: userInfo.lname,
//     name: `${userInfo.fname} ${userInfo.lname}`,
//     user_metadata: {
//       company: userInfo.company,
//       title: userInfo.jobTitle
//     }
//   })
//   // await plainAxios.post(`${apiRootURL}signup`, userInfo)
// }

export const signUpUser = async userInfo => {
  await plainAxios.post(`${apiRootURL}signup`, userInfo)
}

/**
 * Search for quick suggestions
 * @param {string} query Query to search for the auto-suggestions
 */
export const quickSearch = query => {
  const axios = getAxiosInstance()
  const data = axios
    .get(`${apiRootURL}quick-search`, {
      params: {
        q: query
      }
    })
    .then(res => res.data)
  return data
}

export const submitProductDetailsSuggestions = async productDetailsSuggestions => {
  const axios = getAxiosInstance()
  return await axios.post(`${apiRootURL}suggestions`, productDetailsSuggestions, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const validateToken = async token => {
  const axios = getAxiosInstance()
  return await axios
    .post(`${apiRootURL}gripjwtauth`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res => res.data)
}

export const productsSuggestions = async productId => {
  const axios = getAxiosInstance();

  return await axios.get(`${apiRootURL}product/${productId}/suggestions`);
}