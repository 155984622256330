import React from 'react'
import { Box, Backdrop, Fade, Modal, Divider } from '@material-ui/core'

import { useFilterStateContext } from 'components/Contexts/FilterStateContext'

import { useStyles } from './useStyles'
import SavedSerchesTable from './SavedSerchesTable'

const SavedSerchesMainComponent = () => {
  const classes = useStyles()
  const {
    isSavedSearchModalOpen,
    setSavedSearchModalOpen
  } = useFilterStateContext()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isSavedSearchModalOpen}
      onClose={() => setSavedSearchModalOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      className={classes.savedSerchesMainBox}
    >
      <Fade in={isSavedSearchModalOpen}>
        <Box className={classes.savedSerchesArticleBox}>
          <div className={classes.triangleLeft}>
            <div className="inner-triangle"></div>
          </div>
          <Box>
            <SavedSerchesTable />
          </Box>
          <Divider
            style={{
              color: '#0E90D9',
              height: '2px',
              width: '100%',
              position: 'absolute',
              backgroundColor: '#0E90D9',
              top: 0
            }}
          />
        </Box>
      </Fade>
    </Modal>
  )
}

export default SavedSerchesMainComponent
