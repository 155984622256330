import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react'
import useMeasure from 'utils/customHooks/useMeasure'
import { useSpring, a } from 'react-spring'
import { useEditProductDetailsContext } from 'components/Contexts/EditProductDetailsContext'
import { CheckboxListItem } from './CheckkboxListItem'
import { ReactComponent as Drop } from 'assets/icons/DropWhite.svg'
import { useCheckboxListStyles } from './useCheckboxListStyles'
import useEditModeStyles from '../EditModeUtils/styles'
import { OxbowTransparentButton } from 'components/Helpers/Buttons'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { Typography } from '@material-ui/core'

const IS_EDITABLE = true
const dropSelectIcon = <Drop fill="#4A4441" />
const circularSelectIcon = (
  <div
    style={{
      display: 'block',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: '#b4aa7d'
    }}
  />
)
const transparentIcon = <Drop fill="#0000" />

export default function CheckboxList(props) {
  const { renderInEditMode, blurValue } = props
  if (renderInEditMode) {
    if (!blurValue && IS_EDITABLE) return <EditableCheckboxList {...props} />
    return null
  }

  return <CheckboxListComponent {...props} />
}

export const CheckboxListComponent = props => {
  const {
    options,
    removeUnselectedOptions = false,
    columns = 1,
    wrapLabel = true,
    blurValue,
    id
  } = props
  const classes = useCheckboxListStyles({ columns, wrapLabel, blurValue })

  const [showHiddenItems, setShowHiddenItems] = useState(true)
  const visibleOptions = options.filter(
    selectedItem => selectedItem.selected === true
  )
  const filteredHiddenItems = options.some(i => !i.selected)

  const allOptionsAreSelected = useMemo(
    () => !options.some(option => option.selected === false),
    [options]
  )

  const selectedIcon = useMemo(() => {
    if (allOptionsAreSelected || removeUnselectedOptions)
      return circularSelectIcon
    return dropSelectIcon
  }, [allOptionsAreSelected, removeUnselectedOptions])

  const seenFirstNonZeroValue = useRef(false)
  const [
    { ref: itemsWrapperRef },
    { height: itemsWrapperHeight }
  ] = useMeasure()
  const [animatedStyleProps, set] = useSpring(() => ({
    height: itemsWrapperHeight
  }))

  useEffect(() => {
    if (itemsWrapperHeight !== 0 && !seenFirstNonZeroValue.current) {
      seenFirstNonZeroValue.current = true
      set({ to: { height: itemsWrapperHeight }, immediate: true })
    } else if (seenFirstNonZeroValue.current) {
      set({ to: { height: itemsWrapperHeight }, immediate: false })
    }
  }, [itemsWrapperHeight, set])

  const visibleOtionsToShow = useMemo(() => {
    return visibleOptions.map((option, i) => {
      return (
        <CheckboxListItem
          key={i}
          label={option.label}
          icon={
            removeUnselectedOptions || allOptionsAreSelected
              ? selectedIcon
              : option.selected
              ? dropSelectIcon
              : transparentIcon
          }
          selected={option.selected}
        />
      )
    })
  }, [
    visibleOptions,
    removeUnselectedOptions,
    selectedIcon,
    allOptionsAreSelected
  ])

  const optionsToShow = useMemo(() => {
    return options.map((option, i) => {
      if (!option.selected && removeUnselectedOptions) {
        return null
      }
      return (
        <CheckboxListItem
          key={i}
          label={option.label}
          icon={
            removeUnselectedOptions || allOptionsAreSelected
              ? selectedIcon
              : option.selected
              ? dropSelectIcon
              : transparentIcon
          }
          selected={option.selected}
        />
      )
    })
  }, [options, removeUnselectedOptions, selectedIcon, allOptionsAreSelected])

  return (
    <div className={classes.root}>
      <a.div
        style={{ ...animatedStyleProps }}
        className={classes.optionsAnimatedWrapper}
      >
        <div
          className={classes.optionsContainer}
          ref={itemsWrapperRef}
          data-tip={''}
          data-for={id}
        >
          {showHiddenItems ? visibleOtionsToShow : optionsToShow}
        </div>
      </a.div>
      {options && filteredHiddenItems && (
        <OxbowTransparentButton
          className={classes.viewHiddenOptions}
          onClick={() => setShowHiddenItems(!showHiddenItems)}
        >
          <Typography variant="h4">
            {showHiddenItems ? 'View ' : ' Hide '}non-selected options
          </Typography>
        </OxbowTransparentButton>
      )}
    </div>
  )
}

export const EditableCheckboxList = ({
  options,
  id,
  isEditableWithoutValue
}) => {
  const classes = useCheckboxListStyles({ wrapLabel: true })
  const editModeStyles = useEditModeStyles()
  const isMorePage = window.location.hash === '#more'
  const { downSm } = useMediaQueries()
  const { setProductDetails, productDetails } = useEditProductDetailsContext()
  const optionsString = useMemo(() => {
    if (options)
      return options
        .map(option => {
          if (option.selected) return option.label
        })
        .filter(option => option)
    else if (isEditableWithoutValue) return ''
  }, [options, isEditableWithoutValue])

  useEffect(() => {
    setProductDetails(id, optionsString, true)
  }, [optionsString, id, setProductDetails])

  const handleInputValueChange = useCallback(
    e => {
      setProductDetails(id, e.target.value)
    },
    [id, setProductDetails]
  )

  // SECONDARY FUNCTION ...
  return (
    <div className={classes.root}>
      {id === 'secondaryFunction' ? (
        <textarea
          rows="5"
          type="text"
          value={productDetails[id]?.value || ''}
          onChange={handleInputValueChange}
          id={id}
          className={editModeStyles['textArea']}
        />
      ) : (
        <input
          style={{ width: isMorePage && !downSm && '640px' }}
          type="text"
          value={productDetails[id]?.value || ''}
          onChange={handleInputValueChange}
          id={id}
          className={editModeStyles['textInput']}
        />
      )}
    </div>
  )
}
