import { useMediaQuery, useTheme } from '@material-ui/core'
import { useMemo } from 'react'

// const breakPoints = ['xs', sm]

export const useMediaQueries = () => {
  const theme = useTheme()
  const downLg = useMediaQuery(theme.breakpoints.down('lg'))
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))
  const downXs = useMediaQuery(theme.breakpoints.down('xs'))
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  const breakPoints = useMemo(() => ({ downLg, downSm, downXs, downMd }), [
    downLg,
    downMd,
    downSm,
    downXs
  ])

  return breakPoints
}

export default useMediaQueries
