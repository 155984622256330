import React, { useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import { useStyles } from './useStyles'

import { Form, Formik } from 'formik'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import { sendContactUsData } from 'utils/api'
import { useAuth0 } from '@auth0/auth0-react'
import analytics from 'utils/analytics'

const DemoForm = ({
  submitBtnText = '',
  checkBoxContainerPosition,
  modalData,
  userName,
  userEmail,
  userNickName,
  setThxModalOpen,
  emailInputWidth,
  btnWidth,
  isFreeUser,
  setDemoFormOpen,
  submissionType,
  setMoreSearchesThxOpen,
  setThxModal
}) => {
  const classes = useStyles({
    emailInputWidth,
    checkBoxContainerPosition
  })

  const { isAuthenticated } = useAuth0()

  const [policy, setPolicy] = useState(false)
  const [policyError, setPolicyError] = useState(false)

  const handleChangePolicy = () => {
    setPolicy(prev => !prev)
    setPolicyError(false)
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const contactUsData = {
      ...values,
      submissionType: submissionType
    }
    if (!policy) {
      setPolicyError(true)
    } else {
      setSubmitting(true)
      try {
        await sendContactUsData(contactUsData)
        setSubmitting(false)
        submissionType(false)

        if (isFreeUser) {
          setMoreSearchesThxOpen(true)
          setDemoFormOpen(false)
        }
        if (!isAuthenticated) {
          setThxModalOpen(true)
        }

        analytics.createEvent({
          category: 'Form Submission',
          action: 'Request a Demo',
        });
      } catch (error) {
        setSubmitting(false)
      }
      setThxModal(true)
      resetForm()
      setPolicy(false)
    }
  }

  const validateFields = values => {
    const errors = {}

    Object.entries(values).forEach(([key, value]) => {
      if (
        (key === 'firstName' || key === 'lastName') &&
        (value.length > 30 ||
          !/^\s*(([A-Za-z]){2,30}([.,] |[-']| )?)+[A-Za-z]+\.?\s*$/.test(value))
      ) {
        errors[key] = `Please enter a valid ${modalData[key].toLowerCase()}!`
      }
      if (
        (key === 'companyName' || key === 'jobTitle') &&
        (/(.)\1\1/.test(value) ||
          !/^(?!^\s+)(?!^[\d\W ]*$)([\w\S ]{0,60})(?:\S+$)$/.test(value))
      ) {
        errors[key] = `Please enter a valid ${modalData[key].toLowerCase()}!`
      }
      if (!value) {
        errors[key] = 'Please fill out this field.'
      }
    })
    if (!values.email) {
      errors['email'] = 'Please fill out this field.'
    } else if (
      values.email.length > 100 ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i.test(values.email)
    ) {
      errors.email = 'Please enter a valid work email!'
    }
    return errors
  }
  const errorMsgContainer = msg => {
    return (
      <>
        <span className="errorText">{msg}</span>
        <span className="hintError">
          <PriorityHigh />
        </span>
      </>
    )
  }

  const [formValues, setFormValues] = useState({})

  useEffect(() => {
    let formValues = {}
    if (userName || userEmail || userNickName) {
      formValues = {
        firstName: userName,
        lastName: userNickName,
        email: userEmail
      }
    } else {
      Object.keys(modalData).forEach(i => {
        formValues[i] = ''
      })
    }

    setFormValues(formValues)
  }, [modalData, userName, userEmail, userNickName])

  return (
    <>
      {Object.keys(formValues).length > 0 && (
        <div className="demoRequestContainer">
          <Formik
            initialValues={{
              ...formValues
            }}
            validate={validateFields}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting
            }) => (
              <Form>
                <Box className={classes.mainInputContainer}>
                  <Grid
                    container
                    className="mainInputBox"
                    justify="space-between"
                  >
                    {typeof formValues.firstName === 'string' && (
                      <Grid item>
                        <Typography variant="h4" htmlFor="firstName">
                          First name *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.firstName &&
                              touched.firstName &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.firstName &&
                            touched.firstName &&
                            errorMsgContainer(errors.firstName)}
                        </Box>
                      </Grid>
                    )}
                    {typeof formValues.lastName === 'string' && (
                      <Grid item>
                        <Typography variant="h4" htmlFor="lastName">
                          Last name *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.lastName &&
                              touched.lastName &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.lastName &&
                            touched.lastName &&
                            errorMsgContainer(errors.lastName)}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    container
                    className="mainInputBox"
                    justify="space-between"
                  >
                    {typeof formValues.jobTitle === 'string' && (
                      <Grid item>
                        <Typography variant="h4" htmlFor="jobTitle">
                          Job title *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.jobTitle &&
                              touched.jobTitle &&
                              classes.inputError
                            }
                            type="text"
                            name="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.jobTitle &&
                            touched.jobTitle &&
                            errorMsgContainer(errors.jobTitle)}
                        </Box>
                      </Grid>
                    )}
                    {typeof formValues.companyName === 'string' && (
                      <Grid item>
                        <Typography variant="h4" htmlFor="companyName">
                          Company name *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.companyName &&
                              touched.companyName &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="companyName"
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.companyName &&
                            touched.companyName &&
                            errorMsgContainer(errors.companyName)}
                        </Box>
                      </Grid>
                    )}

                    {typeof formValues.email === 'string' && (
                      <Grid item className={classes.emailInput}>
                        <Typography variant="h4" htmlFor="email">
                          Work email *
                        </Typography>
                        <Box position="relative">
                          <input
                            className={
                              errors.email &&
                              touched.email &&
                              classes.inputError
                            }
                            required
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.email &&
                            touched.email &&
                            errorMsgContainer(errors.email)}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <Grid container direction="column">
                  <Box className={classes.checkBoxContainer}>
                    <label>
                      <input
                        type="checkbox"
                        className={policyError ? classes.inputError : ''}
                        checked={policy}
                        onChange={handleChangePolicy}
                        name="policy"
                      />
                      <span>
                        I consent to Oxbow Partners storing and processing my
                        personal data
                      </span>
                    </label>
                    {policyError && (
                      <span className="errorText">
                        Please check this box if you want to proceed
                      </span>
                    )}
                  </Box>
                </Grid>
                <Box
                  mt={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <OxbowPrimaryButton
                    background="md"
                    type="submit"
                    className={classes.contactUsBtn}
                    width={btnWidth}
                  >
                    {isSubmitting
                      ? 'Please wait ...'
                      : submitBtnText !== ''
                      ? submitBtnText
                      : 'Submit'}
                  </OxbowPrimaryButton>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  )
}
export default DemoForm
