import {
  OxbowPrimaryButton,
  OxbowPrimaryTransparentButton
} from 'components/Helpers/Buttons'
import actions from './actions'

export const MESSAGE_TYPES = {
  error_description: {
    messageTitle: 'Email verification',
    onClose: [],
    buttons: [
      {
        title: 'Cancel',
        component: OxbowPrimaryTransparentButton,

        color: 'primary',
        onClick: [
          {
            functionName: actions.SET_IS_OPEN,
            args: [false]
          },
          {
            functionName: actions.LOGOUT,
            args: []
          }
        ]
      },
      {
        title: 'Continue',
        component: OxbowPrimaryButton,
        variant: 'contained',
        color: 'primary',
        onClick: [
          {
            functionName: actions.SET_IS_OPEN,
            args: [false]
          },
          {
            functionName: actions.LOGIN_WITH_REDIRECT,
            args: []
          }
        ]
      }
    ]
  },
  message: {
    messageTitle: 'Congratulations',
    onClose: [
      {
        functionName: actions.SET_IS_OPEN,
        args: [false]
      },
      {
        functionName: actions.LOGIN_WITH_REDIRECT,
        args: []
      }
    ],
    buttons: [
      {
        title: 'OK',
        component: OxbowPrimaryButton,
        color: 'primary',
        onClick: [
          {
            functionName: actions.SET_IS_OPEN,
            args: [false]
          },
          {
            functionName: actions.LOGIN_WITH_REDIRECT,
            args: []
          }
        ]
      }
    ]
  }
}
