import React, { useContext, useEffect, useState } from 'react'
import { getUserProfile } from 'utils/api'
import { useAuth0 } from './AuthContext'

const CustomisationJourney = React.createContext()
export const useCustomisationJourney = () => useContext(CustomisationJourney)

export const CustomisationJourneyProvider = ({ children }) => {
  const { isAuthenticated } = useAuth0()

  const [customisationJourneyData, setCustomisationJourneyData] = useState({})
  const [isJourneyDataLoaded, setIsJourneyDataLoaded] = useState(false)
  const [isCustomisationJourneyDone, setIsCustomisationJourneyDone] = useState(
    false
  )
  const [personalData, setPersonalData] = useState({})

  const [maxUserSearches, setMaxUserSearches] = useState(0)

  const getUserData = async () => {
    try {
      setIsJourneyDataLoaded(false)
      const data = await getUserProfile()
      if (!data) {
        setCustomisationJourneyData({
          insuranceClass: [],
          insuranceSubclass: [],
          basicSubclass: [],
          primaryFunction: [],
          insuranceProductDistributors: false,
          regionsAndCountries: [],
          relatedTechnology: [],
          intentionsOfUse: [],
          insuranceClientType: [],
          done: false
        })
        setPersonalData({
          companyName: '',
          firstName: '',
          jobTitle: '',
          lastName: '',
          phoneNumber: ''
        })
      } else {
        setCustomisationJourneyData({
          insuranceClass: data.insurance_class,
          insuranceSubclass: data.insurance_subclass,
          basicSubclass: data.basic_subclass || [],
          primaryFunction: data.primary_function,
          insuranceProductDistributors: data.insurance_product_distributors,
          regionsAndCountries: data.regions_and_countries,
          relatedTechnology: data.related_technology,
          intentionsOfUse: data.intentions_of_use,
          insuranceClientType: data.insurance_client_type,
          done: data.done
        })
        setPersonalData({
          companyName: data.company_name,
          firstName: data.first_name,
          jobTitle: data.job_title,
          lastName: data.last_name,
          phoneNumber: data.phone_number
        })
        setMaxUserSearches(data.search_limit)
      }
      setIsJourneyDataLoaded(true)
    } catch (err) {
      console.log(err)
      setIsJourneyDataLoaded(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getUserData()
    } else {
      setIsJourneyDataLoaded(false)
    }
  }, [isAuthenticated])

  return (
    <CustomisationJourney.Provider
      value={{
        customisationJourneyData,
        isJourneyDataLoaded,
        isCustomisationJourneyDone,
        setIsCustomisationJourneyDone,
        setCustomisationJourneyData,
        getUserData,
        personalData,
        setPersonalData,
        maxUserSearches
      }}
    >
      {children}
    </CustomisationJourney.Provider>
  )
}
