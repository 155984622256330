import { withStyles } from '@material-ui/core'
import { derivedFromProp } from 'utils'
import Button from './Button'

export const OxbowDarkButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.secondary.main,

    borderRadius: '0 6px 0 6px',
    fontWeight: '600',
    textTransform: 'unset',
    position: 'relative',
    overflow: 'hidden',
    border: '1px solid transparent',
    width: derivedFromProp('width', {
      max: '100%',
      default: null
    }),
    fontFamily: derivedFromProp('fonttype', {
      secondary: theme.typography.secondaryFont,
      default: theme.typography.secondaryFont
    }),
    fontSize: derivedFromProp('size', {
      xl: '1.4rem',
      xxl: '1.6rem',
      default: null
    }),
    padding: derivedFromProp('size', {
      xl: theme.spacing(1, 2),
      xxl: theme.spacing(1.5, 3),
      default: null
    }),
    zIndex: 0,
    '&:hover': {
      background: theme.palette.primary.mainBlue
    },
    '&:disabled': {
      background: theme.palette.primary.primaryBig,
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: derivedFromProp('size', {
        xl: '1rem',
        xxl: '1.3rem',
        default: null
      }),
      padding: derivedFromProp('size', {
        xl: theme.spacing(1, 2),
        xxl: theme.spacing(1.5, 3),
        default: null
      })
    },
    '& .MuiButton-startIcon': {
      marginRight: derivedFromProp('marginright', {
        sm: '0 !important',
        default: 8
      })
    }
  }
}))(Button)

export const OxbowGoldButton = withStyles(theme => ({
  root: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    border: '1px solid transparent',
    borderRadius: '0 6px 0 6px',
    fontWeight: '600',
    // border: '1px solid #605A56',
    textTransform: 'uppercase',
    position: 'relative',
    overflow: 'hidden',
    width: derivedFromProp('width', {
      max: '100%',
      default: null
    }),
    fontFamily: derivedFromProp('fonttype', {
      secondary: theme.typography.fontFamily,
      default: null
    }),
    fontSize: derivedFromProp('size', {
      xl: '1.4rem',
      xxl: '1.6rem',
      default: null
    }),
    padding: derivedFromProp('size', {
      xl: theme.spacing(1, 2),
      xxl: theme.spacing(1.5, 3),
      default: null
    }),
    zIndex: 0,
    '&:hover': {
      background: theme.palette.secondary.dark
    },
    '&:disabled': {
      background: 'grey',
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: derivedFromProp('size', {
        xl: '1rem',
        xxl: '1.3rem',
        default: null
      }),
      padding: derivedFromProp('size', {
        xl: theme.spacing(1, 2),
        xxl: theme.spacing(1.5, 3),
        default: null
      })
    }
  },
  startIcon: {
    width: '10px',
    marginLeft: '0 !important',
    marginRight: '0 !important'
  }
}))(Button)

export const OxbowLightButton = withStyles(theme => ({
  root: {
    background: derivedFromProp('background', {
      transparent: 'transparent',
      default: '#fff'
    }),
    color: theme.palette.primary.main,
    borderRadius: '0 6px 0 6px',
    fontWeight: '600',
    border: '1px solid #605A56',
    textTransform: 'uppercase',
    position: 'relative',
    overflow: 'hidden',
    width: derivedFromProp('width', {
      max: '100%',
      default: null
    }),
    fontFamily: derivedFromProp('fonttype', {
      secondary: theme.typography.fontFamily,
      default: null
    }),
    fontSize: derivedFromProp('size', {
      xl: '1.4rem',
      xxl: '1.6rem',
      default: null
    }),
    padding: derivedFromProp('size', {
      xl: theme.spacing(1, 2),
      xxl: theme.spacing(1.5, 3),
      default: null
    }),
    zIndex: 0,
    '&:hover': {
      background: '#f2f0ef'
    },
    '&:disabled': {
      border: '1px solid #a3a19f'
    }
  },
  startIcon: {
    width: '10px',
    marginLeft: '0',
    marginRight: '0',
    margin: 'auto'
  }
}))(Button)

export const OxbowTextButton = withStyles(theme => ({
  root: {
    background: 'transparent',
    color: derivedFromProp('light', {
      true: theme.palette.primary.contrastText,
      default: theme.palette.primary.main
    }),
    fontFamily: 'Open Sans',
    padding: '0',
    boxShadow: 'none',
    fontSize: derivedFromProp('fontSize', {
      true: '14px',
      default: '1rem'
    }),
    transition: 'color 0.4s ease',
    '&:hover': {
      color: theme.palette.primary.mainLightBlue,
      boxShadow: 'none',
      background: 'transparent'
    },
    '&:disabled': {
      color: 'grey',
      pointerEvents: 'none'
    }
  }
}))(Button)

export const OxbowTransparentButton = withStyles(theme => ({
  root: {
    textTransform: 'unset',
    fontFamily: theme.typography.secondaryFont,
    background: 'transparent',

    borderRadius: '6px',
    color: theme.palette.primary.mainLightBlue,
    padding: derivedFromProp('padding', {
      default: '0.5rem 1rem',
      xs: '0',
      sm: '0 10px',
      md: '15px 0'
    }),
    fontWeight: derivedFromProp('fontWeight', {
      default: '500',
      lg: '700'
    }),
    fontSize: derivedFromProp('fontSize', {
      sm: 13,
      md: 16,
      default: 18,
      secondary: 14
    }),
    '&:hover': {
      background: 'transparent'
    },
    '&:disabled': {
      color: theme.palette.secondary.main,
      opacity: 0.5
    },
    [theme.breakpoints.down('xs')]: {
      padding: derivedFromProp('padding', {
        default: '0.5rem 1rem',
        xs: '0',
        sm: '0',
        md: '15px 0'
      })
    }
  },
  label: {
    justifyContent: 'flex-start'
  }
}))(Button)

export const OxbowGreyButton = withStyles(theme => ({
  root: {
    background: 'lightgrey',
    width: '100%',
    color: theme.palette.primary.main,
    padding: '0.5rem 1rem',
    borderRadius: '0 6px 0 6px',

    '&:hover': {
      background: theme.palette.primary.main,
      color: 'white'
    }
  }
}))(Button)

export const OxbowPrimaryTransparentButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    background: derivedFromProp('background', {
      light: theme.palette.primary.btnLight,
      default: 'rgba(0, 163, 255, 0.1)'
    }),
    cursor: derivedFromProp('cursor', {
      default: 'pointer',
      move: 'move'
    }),
    borderRadius: '0 6px 0 6px',
    fontWeight: derivedFromProp('fontWeight', {
      default: '600',
      lg: '700'
    }),
    position: 'relative',
    overflow: 'hidden',
    border: `1px solid #00A3FF`,
    textTransform: 'none',
    minWidth: 30,
    width: derivedFromProp('width', {
      sm: 30,
      max: '100%',
      smd: 120,
      default: 230,
      'max-content': 'max-content',
      'min-content': 150,
      md: 200,
      lg: 250,
      xl: 300
    }),
    height: derivedFromProp('height', {
      md: 30,
      lg: 45,
      default: 40
    }),
    fontFamily: derivedFromProp('fontype', {
      default: theme.typography.secondaryFont
    }),
    fontSize: derivedFromProp('fontSize', {
      sm: 13,
      md: 16,
      default: 18
    }),
    padding: derivedFromProp('padding', {
      sm: '0px',
      md: '10px 25px',
      lg: '10px 45px',
      xl: '10px 55px',
      default: '10px 15px'
    }),
    zIndex: 0,
    '&:hover': {
      background: theme.palette.secondary.transparentBtnHover
    },
    '&:disabled': {
      pointerEvents: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: derivedFromProp('size', {
        default: 14
      }),
      width: derivedFromProp('width', {
        'max-content': 'max-content',
        default: 170,

        sm: 30
      })
    },
    '& .MuiButton-startIcon': {
      marginRight: derivedFromProp('marginright', {
        sm: '0 !important',
        default: 8
      }),
      marginLeft: derivedFromProp('marginright', {
        sm: '0 !important',
        default: 8
      })
    }
  }
}))(Button)

export const OxbowPrimaryButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    background: derivedFromProp('background', {
      secondary: theme.palette.secondary.main,
      md: theme.palette.primary.primaryBig,
      xl: theme.palette.primary.primaryBig,
      default: theme.palette.secondary.main
    }),
    fontWeight: derivedFromProp('fontWeight', {
      default: '600',
      lg: '700'
    }),
    '& h4': {
      fontWeight: derivedFromProp('fontWeight', {
        default: '600',
        lg: '700'
      })
    },
    borderRadius: '0 6px 0 6px',
    position: 'relative',
    overflow: 'hidden',
    minWidth: 30,
    border: `1px solid ${theme.palette.primary.primaryBig}`,
    textTransform: 'none',
    width: derivedFromProp('width', {
      sm: 30,
      smd: 120,
      // max: '100%',
      default: 230,
      'max-content': 'max-content',
      md: 200
    }),

    height: derivedFromProp('height', {
      md: 30,
      lg: 45,
      default: 40
    }),
    fontFamily: derivedFromProp('fonttype', {
      default: theme.typography.secondaryFont
    }),
    fontSize: derivedFromProp('fontSize', {
      sm: 13,
      md: 16,
      default: 18
    }),
    padding: derivedFromProp('size', {
      sm: '0px',
      custom: '10px 20px 10px 10px',
      md: '10px 25px',
      lg: '10px 45px',
      xl: '10px 55px',
      default: '10px 15px'
    }),
    zIndex: 0,
    '&:hover': {
      background: theme.palette.primary.primaryBigHovered
    },
    '&:disabled': {
      background: theme.palette.primary.primaryBig,
      color: 'white',
      opacity: 0.5,
      pointerEvents: 'none'
    },

    '& .MuiButton-startIcon': {
      marginRight: derivedFromProp('marginright', {
        sm: '0 !important',
        default: 8
      }),
      marginLeft: derivedFromProp('marginright', {
        sm: '0 !important',
        default: 8
      })
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: derivedFromProp('size', {
        default: 14
      }),
      width: derivedFromProp('width', {
        'max-content': 'max-content',
        default: 170
      }),
      padding: derivedFromProp('size', {
        // default: '0 10px'
      })
    }
  }
}))(Button)
