import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  toolbar: {
    alignItems: 'center',
    padding: theme.spacing(10),
    transition: 'padding 0.45s ease',
    boxShadow: 'none',
    justifyContent: 'space-between',
    '&.small': {
      padding: theme.spacing(0),
      '& $navbarLogo': {
        width: '150px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      minHeight: 0,
      '&.small': {
        padding: '20px 20px 0 20px',
        // padding: theme.spacing(3, 3, 0),
        '& $navbarLogo': {
          width: '150px !important'
        }
      }
    }
  },
  collectionName: {
    fontSize: 20,
    paddingBottom: 20,
    paddingLeft: 20
  },
  searchbarContainer: {
    padding: '30px 0 50px !important',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  searchBarBox: {
    display: 'flex',
    width: '60%'
  },
  toolbarList: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& li': {
      cursor: 'pointer',
      textTransform: 'uppercase',
      '& a': {
        display: 'inline-block',
        fontFamily: 'Open Sans',
        color: 'white',
        fontSize: '16px',
        fontWeight: 'lighter',
        borderBottom: '2px solid transparent',
        transition: 'border-bottom 0.3s ease, color 0.3s ease',
        paddingBottom: 10,
        '&:hover': {
          borderBottom: '2px solid ' + theme.palette.secondary.main,
          color: theme.palette.secondary.main
        },
        [theme.breakpoints.down(1248)]: {
          fontSize: 14
        }
      }
    },
    '& li a.selected': {
      color: theme.palette.secondary.main,
      borderBottom: '2px solid ' + theme.palette.secondary.main
    },
    '& li:not(:first-child)': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'unset',
      '& li': {
        // borderBottom: '1px solid #494340',
        position: 'relative',
        color: 'white',
        padding: '14px 0 !important',
        '& a': {
          fontSize: '18px !important',
          fontWeight: '500 !important',
          fontFamily: theme.typography.secondaryFont,
          lineHeight: '1.3',
          textDecoration: 'none',
          display: 'block',
          textTransform: 'none'
        },
        '& .title': {
          fontSize: '18px !important',
          fontWeight: '500 !important',
          textTransform: 'none'
        }
      },
      '& li:not(:first-child)': {
        margin: 0
      }
    }
  },
  navbarLinks: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  menu: {
    listStyleType: 'none',
    display: 'flex',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '1.2rem',
    fontWeight: 'lighter'
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1
    }
  },
  largeButton: {
    padding: 0
  },
  largeIcon: {
    width: 30,
    height: 30
  },
  navbarLogo: {
    display: 'inline-block',
    width: '240px',
    height: 'auto',
    marginRight: 45,
    transition: 'width 0.15s ease',
    paddingBottom: 15,
    [theme.breakpoints.down('lg')]: {
      width: 190
    },
    [theme.breakpoints.down('sm')]: {
      width: 150
    },
    [theme.breakpoints.down(1248)]: {
      marginLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: 140
    }
  },
  link: {
    textDecoration: 'none'
  },
  mobileNavMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  landingBtnContainer: {
    marginBottom: 2,
    display: 'flex',
    alignItems: 'center',
    '& button:nth-child(2n)': {
      marginRight: 20
    },
    '& :not(:first-child)': {
      marginBottom: 10
    },
    [theme.breakpoints.down(1248)]: {
      '& button': {
        fontSize: 14
      }
    }
  },
  activeMenuItem: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',

    borderBottom: `2px solid ${theme.palette.secondary.main} !important`
  },
  leftMenuItem: {
    marginRight: 5,
    paddingBottom: 10,
    fontWeight: 700,
    fontSize: 16,
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    textTransform: 'uppercase',
    '&:hover': {
      color: theme.palette.secondary.main,
      borderBottom: `2px solid ${theme.palette.secondary.main} !important`
    },
    [theme.breakpoints.down(1248)]: {
      fontSize: 14
    }
  },
  animatedSearchBar: {
    animation: `$animation 500ms ${theme.transitions.easing.easeInOut}`
  },
  animatedSearchBarExit: {
    animation: `$myEffectExit 500ms ${theme.transitions.easing.easeInOut}`,

    transform: 'translateY(-50%)',
    display: 'none'
  },
  '@keyframes animation': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-50%)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  '@keyframes myEffectExit': {
    '0%': {
      transform: 'translateY(0)',
      display: 'block'
    },
    '100%': {
      transform: 'translateY(-50%)'
    }
  }
}))
