import React, { useState } from 'react'
import classnames from 'classnames'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useSpring, a } from 'react-spring'
import useMeasure from 'utils/customHooks/useMeasure'
import { makeStyles } from '@material-ui/core'
//for showing tooltip
import OxbowToolTip from 'components/Helpers/OxbowToolTip'
import { companyStage } from 'configs/stage.tooltip'
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon.svg'

const useStyles = makeStyles(theme => ({
  revealSectionTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  countBadge: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    fontSize: '12px',
    width: '19px',
    height: '19px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold'
  }
}))

export const RevealSectionTitleWithCountBadge = ({ title, count }) => {
  const classes = useStyles()
  return title === 'Company Stage' ? (
    <OxbowToolTip tip={companyStage}>
      <span classnames={classes.revealSectionTitleWrapper}>
        <b>{title}</b>
        {count !== 0 && <span className={classes.countBadge}>{count}</span>}
        <InfoIcon
          style={{
            height: '15px',
            width: 'auto',
            marginLeft: '5px',
            marginBottom: '-2.5px',
            padding: '0px'
          }}
        />
      </span>
    </OxbowToolTip>
  ) : (
    <span classnames={classes.revealSectionTitleWrapper}>
      <b>{title}</b>
      {count !== 0 && <span className={classes.countBadge}>{count}</span>}
    </span>
  )
}

export const RevealSection = ({
  label,
  style = {},
  children = null,
  icon = <ChevronRightIcon />,
  labelStyles,
  firstItem,
  lastItem,
  alreadyOpen,
  collectionCommentStyle,
  lastItemComments,
  dontClose = false,
  ...restProps
}) => {
  const [isOpen, setOpen] = useState(!!alreadyOpen)
  const isLarge = restProps['lg']
  const [bind, { height }] = useMeasure()
  const props = useSpring({ height: isOpen ? height : 0 })
  return (
    <div
      className={classnames(
        'checkbox-wrapper',
        {
          'rounded-border-top': firstItem,
          'rounded-border-bottom': lastItem,
          'rounded-border-bottom-comments': lastItemComments
        },
        collectionCommentStyle ? 'collectionCommentStyle' : ''
      )}
      style={style}
    >
      {!dontClose ? (
        <div
          className={classnames('checkbox-wrapper-label', {
            large: isLarge
          })}
          onClick={() => setOpen(prev => !prev)}
        >
          <span className={classnames('label')} style={labelStyles}>
            {label}
          </span>
          <span
            className={classnames('icon', {
              'rotate-down': isOpen
            })}
          >
            {icon}
          </span>
        </div>
      ) : (
        ''
      )}

      <a.div
        className={classnames('checkbox-wrapper-options', {
          large: isLarge
        })}
        style={{
          ...props
        }}
      >
        <div {...bind} className="chilldren-conrainer">
          {children}
        </div>
      </a.div>
    </div>
  )
}

export const RevealSectionGroup = ({ children }) => {
  const totalRevealSections = children.length
  const revealSections = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      firstItem: index === 0,
      lastItem: index === totalRevealSections - 1
    })
  )
  return <>{revealSections}</>
}

export default RevealSection
