import React, { useEffect, useState, useRef } from 'react'

import clsx from 'clsx'
import { useStyles } from './useStyles'

export const Animated = ({ animationTime = 500, children }) => {
  const classes = useStyles({ animationTime })
  const domRef = useRef()
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    const currRef = domRef.current

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisible(true)
        } else {
          setVisible(false)
        }
      })
    })

    observer.observe(currRef)

    return () => observer.unobserve(currRef)
  }, [domRef, setVisible])
  return (
    <section
      className={clsx(classes.animated, {
        [classes.visible]: isVisible
      })}
      ref={domRef}
    >
      {children}
    </section>
  )
}
