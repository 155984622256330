import selfServeDot from 'assets/icons/selfServeDot.svg'
import selfServePremiumDot from 'assets/icons/selfServePremiumDot.svg'
import curatedSearchDot from 'assets/icons/curatedSearchDot.svg'

const PricingPageInfoData = (isAuthenticated, isFullUser) => {
  return [
    {
      img: `${selfServeDot}`,
      name: 'Free account',
      heading:
        'Access our articles and collections, and get 20 free searches in our database',
      content: [
        'Read articles about insurance technology, including TechExec, Bitesize InsurTech and commentary pieces',
        'Browse vendor collections focused on specific insurance use cases and themes',
        'View summary profiles of 4,000+ insurance technology vendors, covering both InsurTechs and established companies',
        'See up-to-date news about insurance technology vendors',
        '20 free advanced searches, filtering by insurance-specific criteria like line of business'
      ],
      tellUs: '',
      buttonName: !isAuthenticated
        ? 'Create account'
        : isFullUser
        ? 'Free account'
        : 'Your account',
      buttonActive: !isAuthenticated
    },
    {
      img: `${selfServePremiumDot}`,
      name: 'Premium account',
      heading: 'Unlimited access to Magellan content',
      content: [
        'View full profiles of 4,000+ insurance technology vendors',
        'Save searches and get email updates',
        'Unlimited advanced searches',
        'Enhanced features like favourites and personal short-lists',
        'Export profiles to PDF',
        'Export Collections and lists to CSV',
        'Premium account management support',
        'Discounted Curated Searches (Enterprise users)',
        'Share lists with your company (Enterprise users)'
      ],
      tellUs: '',
      buttonName: isFullUser
        ? 'Your account'
        : isAuthenticated
        ? 'Request premium account'
        : 'Find out more',
      buttonActive: !isAuthenticated || !isFullUser,
      premium: true
    },
    {
      img: `${curatedSearchDot}`,
      name: 'Curated search',
      heading: '',
      content: [
        'Give us your technology selection requirements (e.g. policy admin system for motor in France, submission triage in commercial)',
        'The Magellan team works with our database and the Oxbow Partners technology team to build a short-list of vendors'
      ],
      tellUs: 'Let the Magellan team help you with your short-listing process',
      buttonName: 'Tell us your requirements',
      buttonActive: true
    }
  ]
}
export default PricingPageInfoData
