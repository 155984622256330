import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  heading: {
    padding: '8px 0',
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 700,
    color: 'black'
  },
  accordion: {
    background: theme.palette.secondary.mainLightGrey,
    boxShadow: 'none',
    transitionDuration: '0s !important',

    '& .MuiCollapse-hidden': {
      display: 'none'
    },
    '& .MuiCollapse-entered': {
      display: 'block'
    }
  },
  accordionSummary: {
    padding: 0,
    height: 0,
    minHeight: '35px !important',
    marginBottom: 10
  },
  accordionDetails: {
    padding: 0,
    display: 'block'
  },
  filterOptions: {
    height: 'calc(100vh - 100px)',
    overflow: 'auto',
    paddingRight: 20
  }
}))
