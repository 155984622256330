import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePagination } from '@material-ui/lab'
import ArrowRightBlue from 'assets/icons/ArrowRightBlue.svg'
import ArrowRightGrey from 'assets/icons/ArrowRightGrey.svg'

import { Box, List, Typography } from '@material-ui/core'
import { useStyles } from './useStyles'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'
import useDeviceType from 'utils/customHooks/useIsMobile'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useNavContext } from 'components/Contexts/NavbarContext'
import ReactTooltip from 'react-tooltip'
import { useCollectionTabsContext } from 'components/Contexts/CollectionTabsContext'

export function CustomnPagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1)
  const maxPage = Math.ceil(data.length / itemsPerPage)

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage
    const end = begin + itemsPerPage
    return data.slice(begin, end)
  }

  function jump(page) {
    setCurrentPage(page)
  }

  return { jump, currentData, currentPage, maxPage }
}
// fixed bug with deleting last item on the page
export const usePaginationItems = (items, _DATA, setPageChanged) => {
  const _currPage = _DATA.currentPage
  const _items = items.map(item => {
    if (item.type === 'previous') {
      return {
        ...item,
        page: _currPage - 1,
        disabled: _currPage === 1,
        onClick: () => {
          _DATA.jump(_currPage - 1)
          setPageChanged(true)
        }
      }
    } else if (item.type === 'next') {
      return {
        ...item,
        disabled: _currPage === _DATA.maxPage,
        page: _currPage + 1,
        onClick: () => {
          _DATA.jump(_currPage + 1)
          setPageChanged(true)
        }
      }
    } else {
      return {
        ...item,
        // stringify true/false needs for aria-current
        'aria-current': item.page === _currPage ? 'true' : 'false',
        selected: item.page === _currPage ? true : false,
        page: item.page,
        onClick: () => {
          _DATA.jump(item.page)
          setPageChanged(true)
        }
      }
    }
  })
  return _items
}

export const PaginationButtons = ({
  data,
  countPerPage,
  searchResultsLength
}) => {
  const _data = Object.keys(data).map(key => data[key])
  if (searchResultsLength) {
    const count = Math.ceil(searchResultsLength / countPerPage)
    const _DATA = _data
    return [_DATA, _data, count]
  } else {
    const dataCheck = typeof data === 'object'
    const count = Math.ceil(
      (dataCheck ? Object.keys(data).length : data) / countPerPage
    )
    const _DATA = CustomnPagination(_data, countPerPage)
    return [_DATA, _data, count]
  }
}

export default PaginationButtons

export function InsightsPagination({
  countPerPage,
  page,
  setPage,
  sortedBy,
  selectedContentType
}) {
  const { isMobileSmall } = useDeviceType()
  const [isPageChanged, setPageChanged] = useState(false)

  const { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      setPage(page)
      setPageChanged(true)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    page: page,
    defaultPage: page
  })
  useEffect(() => {
    if (isPageChanged) {
      window.scrollTo(0, 0)
      setPageChanged(false)
    }
  }, [isPageChanged])

  useEffect(() => {
    if (sortedBy || selectedContentType) {
      window.scrollTo(0, 0)
      setPageChanged(false)
      setPage(1)
    }
  }, [sortedBy, selectedContentType])
  return <MainPaginationContent items={items} allowedToLoad={true} />
}

export function SearchPagePagination({ countPerPage, freeUserSearchLimits }) {
  const { isMobileSmall } = useDeviceType()
  const { allCompaniesPage, setAllCompaniesPage } = useSearchPaginationContext()
  const [isPageChanged, setPageChanged] = useState(false)
  let allowedToLoad = freeUserSearchLimits ? true : false
  const { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      setAllCompaniesPage(page)
      setPageChanged(true)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    page: allCompaniesPage,
    defaultPage: allCompaniesPage
  })
  useEffect(() => {
    if (isPageChanged) {
      window.scrollTo(0, 0)
      setPageChanged(false)
    }
  }, [isPageChanged])
  return <MainPaginationContent items={items} allowedToLoad={allowedToLoad} />
}

export function UsePagination({ _DATA, countPerPage, setPageChanged }) {
  const { isMobileSmall } = useDeviceType()
  const { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      _DATA.jump(page)
      setPageChanged(true)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    selected: 1,
    defaultPage: 1
  })

  return <MainPaginationContent items={items} allowedToLoad={true} />
}

export function OxbowPagination({
  selectedCollectionType,
  _DATA,
  countPerPage,
  allowedToLoad,
  setPageChanged
}) {
  const { isMobileSmall } = useDeviceType()
  const { oxPrevPage, setOxBowPrevPage } = useNavContext()

  useEffect(() => {
    if (oxPrevPage) {
      _DATA.jump(oxPrevPage)
    }
  }, [oxPrevPage, selectedCollectionType, _DATA])
  const { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      setOxBowPrevPage(page)
      _DATA.jump(page)
      setPageChanged(true)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    selected: oxPrevPage,
    defaultPage: oxPrevPage
  })

  return <MainPaginationContent items={items} allowedToLoad={allowedToLoad} />
}

export function OrgPagination({
  selectedCollectionType,
  _DATA,
  countPerPage,
  setPageChanged
}) {
  const { isMobileSmall } = useDeviceType()
  const { orgPrevPage, setOrgPrevPage } = useNavContext()

  useEffect(() => {
    if (orgPrevPage) {
      _DATA.jump(orgPrevPage)
    }
  }, [orgPrevPage, selectedCollectionType, _DATA])
  const { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      setOrgPrevPage(page)
      _DATA.jump(page)
      setPageChanged(true)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    selected: orgPrevPage,
    defaultPage: orgPrevPage
  })

  return <MainPaginationContent items={items} />
}

export function CollectionPagination({
  selectedCollectionType,
  _DATA,
  countPerPage,
  setPageChanged
}) {
  const { isMobileSmall } = useDeviceType()
  const { personalPrevPage, setPersonalPrevPage } = useNavContext()

  useEffect(() => {
    if (personalPrevPage) {
      _DATA.jump(personalPrevPage)
    }
  }, [personalPrevPage, selectedCollectionType, _DATA])
  const { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      setPersonalPrevPage(page)
      _DATA.jump(page)
      setPageChanged(true)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    selected: personalPrevPage,
    defaultPage: personalPrevPage
  })

  return <MainPaginationContent items={items} />
}

export function MyBookMarksPagination({ _DATA, countPerPage, setPageChanged }) {
  const [currPage, setCurrPage] = useState(1)
  const { defaultPage, setDefaultPage } = useCollectionTabsContext()
  const { isMobileSmall } = useDeviceType()
  let allowedToLoad = true
  const cPage = useMemo(() => {
    if (_DATA.currentPage > _DATA.maxPage) {
      return _DATA.maxPage
    } else {
      return _DATA.currentPage
    }
  }, [_DATA])

  let { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      setCurrPage(page)
      _DATA.jump(page)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    defaultPage: 1,
    selected: currPage
  })

  // reset the pagination when the collectionId changes
  useEffect(() => {
    if (defaultPage) {
      _DATA.jump(defaultPage)
      setDefaultPage(null)
    } else {
      _DATA.jump(cPage)
    }
  }, [_DATA, cPage, defaultPage])

  return (
    <MainPaginationContent
      items={usePaginationItems(items, _DATA, setPageChanged)}
      allowedToLoad={allowedToLoad}
    />
  )
}

export function SavedSearchPagination({
  selectedCollectionType,
  _DATA,
  countPerPage,
  allowedToLoad
}) {
  const { isMobileSmall } = useDeviceType()
  const style = { paddingBottom: 40 }
  const [, setPageChanged] = useState(false)
  useEffect(() => {
    if (selectedCollectionType) {
      _DATA.jump(1)
    }
  }, [selectedCollectionType, _DATA])

  const cPage = useMemo(() => {
    if (_DATA.currentPage > _DATA.maxPage) {
      return _DATA.maxPage
    } else {
      return _DATA.currentPage
    }
  }, [_DATA])

  useEffect(() => {
    _DATA.jump(cPage)
  }, [_DATA, cPage])
  const { items } = usePagination({
    count: countPerPage,
    onChange: (_, page) => {
      _DATA.jump(page)
    },
    siblingCount: isMobileSmall ? 1 : 2,
    selected: 1,
    defaultPage: 1
  })

  return (
    <MainPaginationContent
      items={usePaginationItems(items, _DATA, setPageChanged)}
      style={style}
      allowedToLoad={allowedToLoad}
    />
  )
}

export const MainPaginationContent = ({ items, style, allowedToLoad }) => {
  const classes = useStyles()
  const { userPermissions } = useAuth0()
  let isUserAllowedToLoad = (userPermissions || []).includes('use:load-more')

  if (allowedToLoad) isUserAllowedToLoad = true

  const { setSignupOpen } = useNavContext()
  const showSignUpModal = useCallback(() => {
    setSignupOpen(true)
  }, [setSignupOpen])

  return (
    <Box
      data-tip="scrollBtnToolTip"
      data-for="scrollBtnToolTip"
      style={{ width: 'max-content', margin: 'auto' }}
    >
      <List className={classes.mainPaginationContainer} style={style}>
        {items.map(({ page, type, selected, disabled, ...item }, index) => {
          let children = null
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…'
          } else if (type === 'page') {
            children = (
              <div onClick={!isUserAllowedToLoad ? showSignUpModal : null}>
                <button
                  className={classes.paginationBtn}
                  type="button"
                  style={{
                    color: !isUserAllowedToLoad || selected ? '#c5c4c3' : '',
                    pointerEvents: !isUserAllowedToLoad ? 'none' : 'auto'
                  }}
                  {...item}
                >
                  <Typography component="p" variant="subtitle2">
                    {page}
                  </Typography>
                  {!isUserAllowedToLoad && (
                    <ReactTooltip
                      id="scrollBtnToolTip"
                      className="customTooltip"
                    >
                      Click to access full Magellan content
                    </ReactTooltip>
                  )}
                </button>
              </div>
            )
          } else {
            children = (
              <div onClick={!isUserAllowedToLoad ? showSignUpModal : null}>
                <button
                  type="button"
                  {...item}
                  className={classes.paginationBtn}
                  style={{
                    pointerEvents:
                      !isUserAllowedToLoad || disabled ? 'none' : 'auto'
                  }}
                >
                  <img
                    alt=""
                    src={
                      !isUserAllowedToLoad || disabled
                        ? ArrowRightGrey
                        : ArrowRightBlue
                    }
                    style={{
                      transform: index === 0 ? 'rotate(180deg)' : 'none'
                    }}
                  />
                  <Typography
                    component="p"
                    variant="subtitle2"
                    style={{
                      color:
                        !isUserAllowedToLoad || disabled
                          ? 'rgba(72, 67, 64, 0.5)'
                          : '#0E90D9'
                    }}
                  >
                    {type}
                  </Typography>
                  {!isUserAllowedToLoad && (
                    <ReactTooltip
                      id="scrollBtnToolTip"
                      className="customTooltip"
                    >
                      Click to access full Magellan content
                    </ReactTooltip>
                  )}
                </button>
              </div>
            )
          }

          return <li key={index}>{children}</li>
        })}
      </List>
    </Box>
  )
}
