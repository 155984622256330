import {Avatar, Box, Hidden, ListItemAvatar, Typography, makeStyles} from '@material-ui/core'
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import PropTypes from "prop-types";
import React from 'react'

import Anonymous from 'assets/Logos/ContentLogos/Anonymous.svg'
import CaseStudies from "./CaseStudies"
import Chip from "../../../../../Helpers/Chip"

const useStyles = makeStyles(() => ({
  fieldLabel: {
    fontWeight: 700
  }
}))

const ClientListItem = ({product}) => {
  const classes = useStyles();

  if (product.anonymousClientDescription) {
    product.clientName = 'Anonymous company'
  }

  const caseStudies = product.caseStudies || []
  let productDetails = [
    {label: 'Country:', value: product.countries?.[0]},
    {label: 'Year of implementation:', value: product.yearImplemented},
    {label: 'Client type:', value: product.clientType},
    {label: 'Partnership type:', value: product.partnershipType},
  ]
  productDetails = productDetails.filter(({value}) => !!value)
  const productClassesImplemented = product.insuranceSubclasses?.split(',').map(item => item.trim()).filter(item => item).slice(0, 2) || []

  return (
    <ListItem alignItems="flex-start">
      <Hidden smDown>
        <ListItemAvatar>
          <Avatar alt={product.logoName || product.clientName || 'Anonymous'} src={product.logo || Anonymous}
                  style={{borderRadius: 0, height: '100%', marginRight: '16px', width: '120px'}}/>
        </ListItemAvatar>
      </Hidden>
      <ListItemText
        disableTypography={true}
        primary={
          <Typography variant="h3">
            {product.clientName}
          </Typography>
        }
        secondary={
          <>
            <Box display="flex" flexWrap="wrap">
              {productDetails.map((detail, index) =>
                <Box key={index} mr={2} my={1}>
                  <Typography variant="h5" component="span" className={classes.fieldLabel}>
                    {`${detail.label} `}
                  </Typography>

                  <Typography variant="body2" component="span">
                    {detail.value}
                  </Typography>
                </Box>
              )}
            </Box>

            {productClassesImplemented?.length > 0 && (
              <Box mr={2} my={1}>
                <Typography variant="h5" component="span">
                  {`Classes implemented: `}
                </Typography>

                <Typography variant="body2" component="span">
                  {productClassesImplemented.map(i => <Chip key={i} label={i}/>)}
                </Typography>
              </Box>
            )}

            {caseStudies?.length > 0 && (
              <Box mr={2} my={1}>
                <CaseStudies caseStudies={caseStudies}/>
              </Box>
            )}
          </>
        }
      />
    </ListItem>
  )
}

ClientListItem.propTypes = {
  product: PropTypes.shape({
    anonymousClientDescription: PropTypes.any,
    caseStudies: PropTypes.arrayOf(
      PropTypes.shape({
        articleTitle: PropTypes.string,
        articleUrl: PropTypes.string,
        countries: PropTypes.arrayOf(PropTypes.string),
        datePublished: PropTypes.string,
        keyTakeaways: PropTypes.arrayOf(PropTypes.string),
        relatedTechnologies: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    clientName: PropTypes.string,
    clientType: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    insuranceSubclasses: PropTypes.string,
    logo: PropTypes.string,
    logoName: PropTypes.string,
    partnershipType: PropTypes.string,
    yearImplemented: PropTypes.number,
  })
}
export default ClientListItem
