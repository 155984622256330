import React from 'react'
import { Grid } from '@material-ui/core';
import useStyles from '../../../styles';


const SuggestionsList = ({ suggestions }) => {
    const classes = useStyles();

    const createFileList = (suggestion) => {
        return suggestion.files.map((a, index) => {
            return (
                <div key={index} className={classes.suggestionsAttachmentLinks}>
                    <a target="_blank" rel="noopener noreferrer" href={'https://' + a.url} download>{a.name}</a>
                    {index === suggestion.files.length - 1 ? <span></span> : <span>|</span>}
                </div>
            )
        })
    }

    const createSuggestionsList = suggestions.map((suggestion, index) => {
        return (
            <li key={index}>
                <h4>{suggestion.userName} ({suggestion.userEmail})</h4>
                <p><span style={{fontWeight: 'bold'}}>Subject: </span>{suggestion.subject}</p>
                <p>{suggestion.suggestions}</p>
                <div className={classes.attachmentsContainer}>{createFileList(suggestion)}</div>
            </li>
        )
    })

    return (
        <Grid className={classes.suggestionList}>
            <ul>{createSuggestionsList}</ul>
        </Grid>
    )
}

export default SuggestionsList;
