import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { defaultWhereList, londonMarketVirtualRegion } from './filtersData'
import { CountriesModal } from './CountriesModal'

import { countriesData } from 'components/Pages/CustomisationJourney/countries'
import { useStyles } from './useStyles'
import { countriesAndRegionsCreate } from 'components/Pages/UserAccount/UserInformation/components/AreasOfInterest'
import {
  handleChipClick,
  handleCountryCheck,
  handleRegionCheck,
  updateProductDetails
} from 'utils/countryAndRegions'
import produce from 'immer'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { useSearchPaginationContext } from 'components/Contexts/SeacrhPaginationContext'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import initialFilterConfig from 'configs/filters.config'

import analytics from 'utils/analytics'
import { useSearchContext } from 'components/Contexts/SearchContext'

export const BrowseMagellanTab = () => {
  const classes = useStyles()
  const { downSm } = useMediaQueries()
  const {
    filterValues,
    setFilterValues,
    checkedCountries,
    setCheckedCountries,
    isFreeUser,
    setHomePageFilters,
    defaultFilters,
    setCategories
  } = useFilterStateContext()
  const { setAllCompaniesPage } = useSearchPaginationContext()
  const { setIsSearchEnabled } = useSearchContext()
  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [selectedChips, setSelectedChips] = useState({
    filters: {
      primaryFunction: [],
      basicSubclass: [],
      category: {
        distributor: false
      }
    }
  })
  const classType = 'regionsAndCountries'
  const [dataDetails, setDataDetails] = useState({
    regionsAndCountries: []
  })

  const [whereList, setWhereList] = useState(defaultWhereList)

  const [allSelectedItems, setAllSelectedItems] = useState([])
  const distributorsChip = initialFilterConfig.categoryFilters.filters.category.options.find(
    item => item.name === 'distributor'
  )
  useEffect(() => {
    const filteredSelectedRegions = checkedCountries.filter(i => i.selected)

    setAllSelectedItems(filteredSelectedRegions.map(i => i.children).flat())
  }, [checkedCountries])

  const filters = useMemo(() => {
    const selectedCountries = checkedCountries.map(i => i.children).flat()
    const { category } = selectedChips.filters
    const filtersCheck = isFreeUser ? defaultFilters : filterValues

    const londonMarket = selectedCountries.find(c => c === londonMarketVirtualRegion.name);

    return {
      ...filtersCheck,
      category: {
        ...filtersCheck.category,
        distributor: category.distributor
      },
      geographiesWithImplementations: selectedCountries.filter(c => c !== londonMarketVirtualRegion.name),
      ...Object.values(selectedChips).reduce((result, item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            result[key] = value.flatMap(i => i.options.map(i => i.value))
          }
        })
        return result
      }, {}),
      ...(londonMarket ? {
        londonMarketRelevance: ['Focussed', 'Relevant'],
      } : {}),
    }
  }, [
    checkedCountries,
    selectedChips,
    isFreeUser,
    defaultFilters,
    filterValues
  ])

  useEffect(() => {
    if (isFirstRender && dataDetails.regionsAndCountries) {
      setCheckedCountries(
        countriesAndRegionsCreate({
          countriesData,
          dataDetails,
          checkedCountries
        })
      )
      setIsFirstRender(false)
    }
  }, [dataDetails, isFirstRender, checkedCountries])

  const handleClick = (type, name) => {
    if (selectedChips.filters[type].includes(name)) {
      setSelectedChips({
        ...selectedChips,
        filters: {
          ...selectedChips.filters,
          [type]: selectedChips.filters[type].filter(item => item !== name)
        }
      })
    } else {
      setSelectedChips({
        ...selectedChips,
        filters: {
          ...selectedChips.filters,
          [type]: [...selectedChips.filters[type], name]
        }
      })
    }
  }
  const handleItemClick = name => {
    if (defaultWhereList.every(item => item.name !== name)) {
      setWhereList(prev => prev.filter(item => item.name !== name))
    }
    if (dataDetails[classType]?.includes(name)) {
      const filtered = dataDetails[classType].filter(item => item !== name)

      setDataDetails(prev =>
        produce(prev, draftState => {
          draftState[classType] = filtered
        })
      )
    } else {
      updateProductDetails(classType, [name], setDataDetails)
    }
  }

  const handleCountryClick = (name, region) => {
    handleCountryCheck(
      name,
      region,
      countriesData,
      setCheckedCountries,
      checkedCountries
    )
  }

  const browseMagellanHandler = () => {
    if (isFreeUser) {
      setIsSearchEnabled(true)
      setAllCompaniesPage(1)
      setHomePageFilters(filters)
    } else {
      setFilterValues(prev => ({
        ...prev,
        ...filters
      }))
    }
    setCategories(prev => ({
      ...prev,
      category: {
        distributor: selectedChips.filters.category.distributor
      }
    }))
    history.push({
      pathname: '/search',
      state: {
        leftBarOpened: true
      }
    })

    analytics.createEvent({
      category: 'Browse Magellan',
      action: 'Browse Magellan',
      label: `from ${window.location.pathname.split('/')[2]}`
    })
  }

  const handleCategoryChange = () => {
    setSelectedChips(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        primaryFunction: [],
        category: {
          ...prev.filters.category,
          distributor: !prev.filters.category.distributor
        }
      }
    }))
  }

  return (
    <Grid className={classes.browseMagellanTab}>
      <Grid
        container
        justify="space-between"
        direction="column"
        style={{
          marginBottom: 20
        }}
      >
        <Typography variant="subtitle2">
          Start building your short-list of insurance technology companies
        </Typography>
        <Typography
          key={distributorsChip.label}
          variant="h6"
          className={clsx(classes.browseMagChips, {
            [classes.selected]: selectedChips.filters.category.distributor
          })}
          onClick={() => handleCategoryChange(distributorsChip.name)}
        >
          {distributorsChip.label}
        </Typography>
      </Grid>

      <Grid
        container
        justify="space-between"
        className={classes.magellanChipsBox}
      >
        <Grid item>
          <Typography variant="h4">
            {initialFilterConfig.whatFilters.title}
          </Typography>
          <Grid container>
            {Object.values(initialFilterConfig.whatFilters.filters).map(
              chip => (
                <Typography
                  key={chip.label}
                  variant="h6"
                  className={clsx(classes.browseMagChips, {
                    [classes.selected]: selectedChips.filters.primaryFunction.includes(
                      chip
                    ),
                    [classes.disabled]:
                      selectedChips.filters.category.distributor
                  })}
                  onClick={() => handleClick('primaryFunction', chip)}
                >
                  {chip.label}
                </Typography>
              )
            )}
          </Grid>
        </Grid>
        {downSm ? <Divider /> : <Divider orientation="vertical" flexItem />}
        <Grid item>
          <Typography variant="h4">
            {initialFilterConfig.whatForFilters.title}
          </Typography>
          <Grid container>
            {Object.values(initialFilterConfig.whatForFilters.filters).map(
              chip => (
                <Typography
                  key={chip.label}
                  variant="h6"
                  className={clsx(classes.browseMagChips, {
                    [classes.selected]: selectedChips.filters.basicSubclass.includes(
                      chip
                    )
                  })}
                  onClick={() => handleClick('basicSubclass', chip)}
                >
                  {chip.label}
                </Typography>
              )
            )}
          </Grid>
        </Grid>
        {downSm ? <Divider /> : <Divider orientation="vertical" flexItem />}
        <Grid item>
          <Typography variant="h4">Where</Typography>
          <Grid container>
            {whereList.slice(0, 8).map((chip, index) => {
              const arr = chip.region && chip.value && chip.value.map(i => i)

              return (
                <React.Fragment key={index}>
                  {chip.type === 'country' ? (
                    <Typography
                      key={chip}
                      variant="h6"
                      className={clsx(classes.browseMagChips, {
                        [classes.selected]:
                          allSelectedItems.includes(chip.name) ||
                          checkedCountries.find(
                            i =>
                              i.region === chip.region &&
                              i.children.includes(chip.name)
                          )
                      })}
                      onClick={() => {
                        handleItemClick(chip.name)
                        handleCountryClick(chip.name, chip.region)
                      }}
                    >
                      {chip.name}
                    </Typography>
                  ) : (
                    <Typography
                      key={chip}
                      variant="h6"
                      className={clsx(classes.browseMagChips, {
                        [classes.selected]:
                          allSelectedItems.includes(chip) ||
                          checkedCountries.find(
                            i => i.region === chip.region && i.selected
                          )
                      })}
                      onClick={() => {
                        handleChipClick(
                          chip.value.map(i => i),
                          dataDetails,
                          classType,
                          setDataDetails,
                          countriesData
                        )
                        handleRegionCheck(
                          arr,
                          chip.region,
                          countriesData,
                          setCheckedCountries,
                          checkedCountries
                        )
                      }}
                    >
                      {chip.name}
                    </Typography>
                  )}
                </React.Fragment>
              )
            })}

            <Typography
              variant="h6"
              className={clsx(classes.browseMagChips, {
                [classes.selected]: modalOpen
              })}
              onClick={() => setModalOpen(true)}
            >
              Type your own
            </Typography>
          </Grid>
        </Grid>
        {modalOpen && (
          <CountriesModal
            setShow={setModalOpen}
            show={modalOpen}
            data={whereList}
            countriesData={countriesData}
            checkedCountries={checkedCountries}
            setCheckedCountries={setCheckedCountries}
            dataDetails={dataDetails}
            classType={classType}
            setWhereList={setWhereList}
          />
        )}
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40
        }}
      >
        <OxbowPrimaryButton
          onClick={() => browseMagellanHandler()}
          style={{
            marginBottom: 25
          }}
        >
          <Typography variant="h4">Browse Magellan</Typography>
        </OxbowPrimaryButton>
      </div>
    </Grid>
  )
}
