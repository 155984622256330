import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { derivedFromProp } from 'utils'

const useStyle = makeStyles(theme => ({
  tabs: {
    '& .tab.selected h6': {
      color: theme.palette.secondary.main,
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    '& h6': {
      fontWeight: 700,
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      paddingBottom: 10,
      borderBottom: `2px solid transparent`,
      '&:hover': {
        color: theme.palette.secondary.main,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        transition: 'all 0.3s ease-in-out'
      }
    },
    '& div:first-child h6': {
      marginRight: 80
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: 30,
      '& div:first-child h6': {
        marginRight: derivedFromProp('isFreeUser', {
          true: '180px',
          false: '80px',
          default: '80px'
        }),
        [theme.breakpoints.down('xs')]: {
          marginRight: derivedFromProp('isFreeUser', {
            true: '180px',
            false: '0px',
            default: '0px'
          })
        }
      }
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      '& >div': {
        width: 140
      }
    }
  }
}))

export const Tabs = ({ activeTab, setActiveTab, tabs }) => {
  const classes = useStyle()

  return (
    <Grid container className={classes.tabs}>
      {tabs.map((tab, index) => (
        <Box
          key={index}
          data-tab={`tab${index}`}
          className={clsx('tab', { selected: activeTab === index })}
          onClick={() => setActiveTab(index)}
        >
          <Typography variant="subtitle2">{tab.title}</Typography>
        </Box>
      ))}
    </Grid>
  )
}
