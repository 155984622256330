import { makeStyles } from '@material-ui/core/styles'
import { derivedFromProp } from 'utils'
export const useStyles = makeStyles(theme => ({
  '@global a': {
    textDecoration: 'none'
  },
  header: {
    color: 'white',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '& .authenticated': {
      borderBottomLeftRadius: 50
    }
  },
  searchbarContainer: {
    '& h6': {
      color: 'white',
      marginBottom: 20
    }
  },
  headerContent: {
    color: 'white',

    '&.unauthenticated': {
      padding: '120px 0 40px 100px',
      '& h6': {
        margin: '20px 0 45px '
      },
      [theme.breakpoints.up(1920)]: {
        padding: '120px 0 40px 280px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '120px 0 40px 32px'
      },
      [theme.breakpoints.down(1248)]: {
        paddingLeft: 60
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 25
      }
    },
    '&.authenticated': {
      padding: '75px 100px 15px',
      [theme.breakpoints.up(1920)]: {
        padding: '75px 280px 15px'
      },

      [theme.breakpoints.down('md')]: {
        padding: '75px 48px 15px 32px'
      },

      [theme.breakpoints.down(1160)]: {
        padding: '75px 10px 15px 48px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '75px 20px 15px 15px'
      }
    },
    '& .MuiAccordion-root': {
      background: 'transparent',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column'
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      width: 170,
      alignSelf: 'flex-end',
      '& h4': {
        fontWeight: 600,
        color: theme.palette.secondary.main
      }
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: theme.palette.secondary.main
    },
    '& h1': {
      fontWeight: 700
    }
  },

  homePageTabs: {
    marginTop: 40,
    color: 'white',
    '& h6': {
      margin: '0 0 20px'
    }
  },

  insightsContainer: {
    [theme.breakpoints.up(1920)]: {
      margin: '0 280px'
    }
  },

  dividerBlock: {
    '& h1': {
      display: 'flex',
      alignItems: 'center',
      padding: '35px 0 0',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        padding: '40px 20px 10px'
      }
    },
    '& hr': {
      width: 170,
      background: theme.palette.primary.primaryBig,
      marginRight: 20
    },
    [theme.breakpoints.up(1920)]: {
      '& hr': {
        width: 70
      }
    }
  },
  whyMagellan: {
    paddingBottom: 50,
    marginBottom: 60
    // [theme.breakpoints.up(1920)]: {
    //   padding: '0 280px'
    // }
  },

  bottomBanner: {
    margin: '40px 100px 0',
    position: 'relative',
    zIndex: 2,

    '& >div': {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('1920')]: {
      margin: '40px 280px 0'
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3, 6, 0, 4)
    },

    [theme.breakpoints.down(1160)]: {
      margin: '24px 10px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 20
    }
  },
  newsAndInsight: {
    background: 'white',
    position: 'relative',
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '40px 10px 20px 50px',
    margin: '20px 100px',
    '& [role=listbox]': {
      left: 0
    },
    [theme.breakpoints.up('1920')]: {
      margin: '20px 0'
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3, 6, 0, 4)
    },

    [theme.breakpoints.down(1160)]: {
      margin: '24px 10px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 20,
      padding: '40px 10px 20px 20px'
    },
    [theme.breakpoints.down('xs')]: {
      '& [role=listbox]': {
        left: 'unset'
      }
    }
  },

  homePageMainContainer: {
    fontFamily: theme.typography.secondaryFont,
    '& a': {
      textDecoration: 'none'
    },
    '& p': {
      color: '#000'
    },
    '& .MuiCircularProgress-root ': {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      top: 0,
      bottom: 0
    },
    [theme.breakpoints.up('1920')]: {
      '& >div': {
        margin: '20px 280px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& h3': {
        fontSize: 22
      }
    }
  },

  homePageCompanies: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& >div': {
      margin: 20
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap',
      '& >div': {
        width: 'auto',
        margin: '20px 24px 0 24px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      '& >div': {
        margin: '20px 24px 0 24px'
      }
    }
  },
  interestedIn: {
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '40px 60px 20px 60px',
    width: '58%',
    marginRight: 20,

    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 20px 40px'
    },
    [theme.breakpoints.down(1100)]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 22px 20px 30px',
      height: 'auto',
      margin: 0
    },

    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 20px 30px'
    }
  },
  tableContainer: {
    marginTop: 30,
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    },

    '& thead>tr>th, tbody>tr>th,tbody>tr>td': {
      paddingTop: 10,
      paddingBottom: 10
    },
    '& tbody>tr>th, tbody>tr>td:not(:last-child)': {
      paddingRight: 30,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      }
    }
  },
  companyName: {
    textTransform: 'capitalize',
    color: theme.palette.primary.mainLightBlue,
    cursor: 'pointer',
    paddingLeft: 0
  },
  imgContainer: {
    padding: 0,
    width: 20,
    '& img': {
      width: 50
    }
  },

  homePageNews: {
    display: 'flex',
    boxSizing: 'border-box',
    height: 'max-content',
    justifyContent: 'space-between',
    margin: '20px 100px',
    [theme.breakpoints.up('1920')]: {
      margin: '20px 0'
    },
    [theme.breakpoints.down('md')]: {
      margin: '18px 48px 40px 32px'
    },

    [theme.breakpoints.down(1160)]: {
      margin: '24px 10px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      margin: 20
    }
  },

  collectionsBlock: {
    '& img': {
      marginRight: 40,
      backgroundColor: '#fff',
      width: 120,
      height: 60,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10
    },
    '& p': {
      fontWeight: 600,
      color: 'black'
    }
  },
  link: {
    textDecoration: 'none',
    '&:hover p': {
      transition: 'border-bottom 0.3s ease, color 0.3s ease',
      color: theme.palette.primary.mainLightBlue
    }
  },

  yourFavouritesCompanies: {
    background: 'white',
    marginLeft: 20,
    border: `1px solid ${theme.palette.secondary.mainDarkGrey}`,
    padding: '40px 60px 50px 60px',
    width: '40%',
    height: derivedFromProp('fullHight', {
      true: 'auto',
      false: 460
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& tbody>tr>td': {
      padding: 0
    },
    '& button': {
      marginTop: 32
    },
    '& hr': {
      marginTop: 20
    },
    '& a': {
      color: 'black'
    },
    [theme.breakpoints.down(1300)]: {
      padding: '30px 22px 20px 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 22px 20px 30px',
      width: '100%',
      marginTop: 20,
      marginLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 10px 50px 30px'
    }
  },

  scrollBox: {
    overflowY: 'scroll'
  },
  companyTypeContainer: {
    width: 300
  },
  iconSvg: {
    width: 70,
    height: 'auto',
    verticalAlign: 'middle',
    marginRight: 40
  },
  articleContainer: {
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    color: '#808080',
    fontSize: 12,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  newsInsightMainBlock: {
    minWidth: '80%',
    '& hr': {
      margin: '30px 60px 30px 0',

      [theme.breakpoints.down('xs')]: {
        margin: '20px 0 20px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  newsInsightContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 70,
      height: 70,
      backgroundColor: '#fff',
      border: '1px solid #C8C8C8',
      objectFit: 'contain',
      marginRight: 40,
      borderRadius: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      '& img': {
        marginRight: 20
      }
    }
  },
  articleBlock: {
    width: '100%',
    paddingRight: 60,
    '& img': {
      width: 50,
      marginTop: 10,
      marginRight: 10
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  newsInsightOverviewTileName: {
    cursor: 'pointer',
    color: '#000'
  },
  bottomGradient: {
    height: 75,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.21) 0.47%, #FEFDFC 100%)',

    position: 'absolute',
    width: '100%',
    left: 0,
    right: 0,
    bottom: 0
  }
}))
