import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    fontFamily: 'Open Sans',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(6, 0, 6),
    background: theme.palette.primary.mainDarkBlue,
    color: theme.palette.primary.contrastText,
    '&.MuiButton-startIcon': {
      marginRight: '0 !important'
    },
    '& .footer-link-lists-wrapper': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },

    '& .footer-common-link-list': {
      display: 'flex',
      listStyle: 'none',
      alignItems: 'center',
      '& li': {
        margin: '0 20px',
        '& a': {
          textDecoration: 'none',
          color: 'inherit'
        },
        [theme.breakpoints.down('sm')]: {
          margin: '7px 10px'
        }
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    '& .footer-content': {
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(2),
      '& span': {
        '&:not(:first-child)': {
          marginLeft: theme.spacing(4)
        }
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(8),
        width: '80%',
        margin: 'auto',
        '& span': {
          display: 'block',
          textAlign: 'center',
          '&:not(:first-child)': {
            marginLeft: '0 !important',
            marginTop: theme.spacing(1)
          }
        }
      }
    },
    actionButtons: {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(1, 2),
      lineHeight: 1,
      fontSize: '1rem',
      borderRadius: 0,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      },
      '&:not(:last-child)': {
        marginRight: theme.spacing(2)
      }
    },
    '& .footer-social-link-list': {
      display: 'flex',
      listStyle: 'none',
      alignItems: 'center',
      '& .footer-social-list-item': {
        '& a': {
          display: 'block',
          textDecoration: 'none',
          color: theme.palette.primary.contrastText,

          '& svg': {
            verticalAlign: 'top',
            fontSize: '2rem'
          }
        }
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 40
      }
    }
  }
}))
