import { Box, Grid, Step, Stepper, Typography } from '@material-ui/core'
import {
  OxbowPrimaryButton,
  OxbowPrimaryTransparentButton
} from 'components/Helpers/Buttons'
import MagellanModal from 'components/Helpers/Modal'
import React, { useEffect, useState } from 'react'
import cross from 'assets/icons/cross.svg'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { useStyles } from './useStyles'

import { countriesData } from './countries'
import { updateUserProfile } from 'utils/api'

import { StepsComponent } from './StepsComponents/StepsComponent'
import { journeyData } from './journeyData'
import { countriesAndRegionsCreate } from '../UserAccount/UserInformation/components/AreasOfInterest'

export const CustomizationJourney = ({
  customisationJourneyData,
  show,
  setShow,
  setIsCustomisationJourneyDone,
  setCustomisationJourneyData
}) => {
  const { downSm: isMobileDevice } = useMediaQueries()
  const classes = useStyles()
  const [dataDetails, setDataDetails] = useState({})
  const [journeyPage, setJourneyPage] = useState(1)
  const [otherValue, setOtherValue] = useState('')
  const [error, setError] = useState('')
  const [checkedCountries, setCheckedCountries] = useState([])
  const [isTextFieldOpened, setIsTextFieldOpened] = useState(false)
  const [
    isInsuranceProductDistributorsChecked,
    setInsuranceProductDistributorsChecked
  ] = useState(false)
  useEffect(() => {
    if (customisationJourneyData.intentionsOfUse) {
      const otherDataValue = customisationJourneyData[
        'intentionsOfUse'
      ]?.filter(i => !journeyData()[4]?.checkBoxes.includes(i))

      setOtherValue(...otherDataValue)
    }

    setDataDetails(customisationJourneyData)
    setInsuranceProductDistributorsChecked(
      customisationJourneyData.insuranceProductDistributors
    )
  }, [customisationJourneyData])

  useEffect(() => {
    if (dataDetails['regionsAndCountries']) {
      setCheckedCountries(
        countriesAndRegionsCreate({
          countriesData,
          dataDetails,
          checkedCountries
        })
      )
    }
  }, [dataDetails])

  const nextJourneyPage = () => {
    if (journeyPage === 5) {
      sendUserFilters(true)
      setError(false)
      setShow(false)
      setIsCustomisationJourneyDone(true)
      setIsTextFieldOpened(false)
    } else {
      sendUserFilters()
      setJourneyPage(prevJourneyPage => prevJourneyPage + 1)
    }
  }

  const prevJourneyPage = () => {
    if (journeyPage === 1) {
      sendUserFilters(true)
      setShow(false)
      setIsCustomisationJourneyDone(true)
    } else {
      setJourneyPage(prevJourneyPage => prevJourneyPage - 1)
    }
  }

  const steps = () => {
    let items = []
    for (let i = 0; i < journeyData().length; i++) {
      items.push(journeyData()[i].step)
    }
    return items
  }

  const sendUserFilters = async (useInterestedIn = false) => {
    if (Object.keys(dataDetails).length > 0) {
      const otherData = dataDetails.intentionsOfUse?.filter(i =>
        journeyData()[4]?.checkBoxes.includes(i)
      )
      let userData = {
        ...dataDetails,
        intentionsOfUse: [...otherData],
        insuranceProductDistributors: isInsuranceProductDistributorsChecked,
        intentionsOfUseOther: otherValue,
        done: true
      }
      try {
        const data = await updateUserProfile(userData)
        setDataDetails({
          insuranceClass: [],
          insuranceSubclass: [],
          basicSubclass: data.basic_subclass,
          primaryFunction: data.primary_function,
          insuranceProductDistributors: data.insurance_product_distributors,
          regionsAndCountries: data.regions_and_countries,
          relatedTechnology: data.related_technology,
          intentionsOfUse: data.intentions_of_use,
          done: data.done
        })
        if (useInterestedIn) {
          setCustomisationJourneyData({
            insuranceClass: [],
            insuranceSubclass: [],
            basicSubclass: data.basic_subclass,
            primaryFunction: data.primary_function,
            insuranceProductDistributors: data.insurance_product_distributors,
            regionsAndCountries: data.regions_and_countries,
            relatedTechnology: data.related_technology,
            intentionsOfUse: data.intentions_of_use,
            done: data.done
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <MagellanModal
      modalClass={classes.journeyModal}
      show={show}
      style={{
        overflowY: journeyPage === 5 && 'auto'
      }}
    >
      {!isMobileDevice && (
        <Grid container>
          <Stepper activeStep={journeyPage} className={classes.stepper}>
            {steps().map(label => {
              return (
                <Step className={classes.activeStep} key={label} icon=""></Step>
              )
            })}
          </Stepper>
        </Grid>
      )}
      <Typography variant="h1" align="center" className={classes.modalHeading}>
        Welcome to Magellan
        <img
          alt=""
          src={cross}
          onClick={() => {
            sendUserFilters(true)
            setShow(false)
            setIsCustomisationJourneyDone(true)
          }}
        />
      </Typography>

      <Box mt={6}>
        <Grid
          container
          spacing={5}
          alignItems="center"
          justify="center"
          style={{
            maxHeight: journeyPage === 5 && 550,
            overflowY: journeyPage === 5 && 'auto'
          }}
        >
          {journeyData().map(data => {
            return (
              <React.Fragment key={data.title}>
                {data.step === journeyPage && (
                  <StepsComponent
                    key={data.title}
                    checkBoxes={data.checkBoxes}
                    article={data.article}
                    header={data.title}
                    selectAll={data.selectAll}
                    chipsType={data.chipsType}
                    selectorType={data.selectorType}
                    dataDetails={dataDetails}
                    setDataDetails={setDataDetails}
                    selectName={data.selectName}
                    placeholder={data.placeholder}
                    divider={data.divider}
                    isInsuranceProductDistributorsChecked={
                      isInsuranceProductDistributorsChecked
                    }
                    setInsuranceProductDistributorsChecked={
                      setInsuranceProductDistributorsChecked
                    }
                    countriesData={countriesData}
                    otherValue={otherValue}
                    setOtherValue={setOtherValue}
                    journeyPage={journeyPage}
                    error={error}
                    setError={setError}
                    checkedCountries={checkedCountries}
                    setCheckedCountries={setCheckedCountries}
                    isTextFieldOpened={isTextFieldOpened}
                    setIsTextFieldOpened={setIsTextFieldOpened}
                  />
                )}
              </React.Fragment>
            )
          })}
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.btnBox}
          >
            <Grid item>
              <OxbowPrimaryTransparentButton
                fontSize="md"
                onClick={prevJourneyPage}
                disabled={!otherValue && isTextFieldOpened}
              >
                {journeyPage === 1 ? "I'll do it later" : 'Back'}
              </OxbowPrimaryTransparentButton>
            </Grid>
            <Grid item>
              <OxbowPrimaryButton
                disabled={!otherValue && isTextFieldOpened}
                fontSize="md"
                onClick={nextJourneyPage}
              >
                {journeyPage === 5 ? 'Done' : 'Next'}
              </OxbowPrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MagellanModal>
  )
}
