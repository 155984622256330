const sortByCompanyNameAZ = resultsToDisplay => {
  return resultsToDisplay.sort((a, b) =>
    a.company?.name?.trim().localeCompare(b?.company?.name?.trim())
  )
}
const sortByCompanyNameZA = resultsToDisplay => {
  return resultsToDisplay.sort((a, b) =>
    b.company?.name?.trim().localeCompare(a?.company?.name.trim())
  )
}

const sortByCompanyStageAsc = resultsToDisplay => {
  return resultsToDisplay.sort((a, b) => {
    if (!a.company?.stage && !b.company?.stage) {
      return a.company?.name?.trim().localeCompare(b?.company?.name?.trim())
    }
    if (!a.company?.stage) return 1
    return a.company?.stage.localeCompare(b.company?.stage)
  })
}

const sortByCompanyStageDesc = resultsToDisplay => {
  return resultsToDisplay.sort((a, b) => {
    if (!a.company?.stage && !b.company?.stage) {
      return a.company?.name?.trim().localeCompare(b?.company?.name?.trim())
    }
    if (!b.company?.stage) return -1
    return b.company?.stage.localeCompare(a.company?.stage)
  })
}

const sortByYearAsc = resultsToDisplay => {
  return resultsToDisplay.sort(
    (a, b) => a.company?.yearFounded - b.company?.yearFounded
  )
}
const sortByYearDesc = resultsToDisplay => {
  return resultsToDisplay.sort(
    (a, b) => b.company?.yearFounded - a.company?.yearFounded
  )
}

export const sortingFunctions = (value, data) => {
  const convertToArr = Object.keys(data).map(key => data[key])
  switch (value.field) {
    case 'company.name':
      return value.order === 'asc'
        ? sortByCompanyNameAZ(convertToArr)
        : sortByCompanyNameZA(convertToArr)
    case 'company.stage':
      return value.order === 'asc'
        ? sortByCompanyStageAsc(convertToArr)
        : sortByCompanyStageDesc(convertToArr)
    case 'company.yearFounded':
      return value.order === 'asc'
        ? sortByYearAsc(convertToArr)
        : sortByYearDesc(convertToArr)
    case 'relevance':
    default:
      return convertToArr
  }
}

export const findValueByFirstLetter = (
  filteredOptionData,
  inputValue,
  searchByName = false,
  value = false
) => {
  const filteredResults = []
  if (filteredOptionData) {
    if (inputValue) {
      if (value) {
        filteredOptionData.map(arr => {
          for (let i = 0; i < arr.length; i++) {
            if (
              arr[i].value?.toLowerCase().indexOf(inputValue?.toLowerCase()) ===
              0
            ) {
              filteredResults.push(arr[i])
            }
          }
        })
      }

      const len = filteredOptionData.length
      for (let i = 0; i < len; i++) {
        const isSearchBy = searchByName
          ? filteredOptionData[i].companyName
          : filteredOptionData[i].label

        if (
          isSearchBy?.toLowerCase().indexOf(inputValue?.toLowerCase()) === 0
        ) {
          filteredResults.push(filteredOptionData[i])
        }
      }
    } else filteredResults.push(...filteredOptionData)
  }

  return filteredResults
}
