import React, { useState } from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'
import { useStyles } from '../useStyles'
import { OxbowTransparentButton } from 'components/Helpers/Buttons'

import { UserAccountMainInformation } from './components/Header'
import { UserAccountAreasOfInterest } from './components/AreasOfInterest'
import clsx from 'clsx'
import { UserAccountPassword } from './components/Password'

export const UserAccountBlockHeader = ({
  name,
  func,
  component,
  length,
  index
}) => {
  const classes = useStyles()
  return (
    <Grid className={classes.accountInformationTitle}>
      <Grid container>
        <Typography variant="h2">{name}</Typography>
        <OxbowTransparentButton
          style={{ paddingBottom: 7 }}
          disabled={component.props.isEditModeOpened}
          onClick={func}
          fontSize="secondary"
          fontWeight="lg"
          padding="xs"
          className={classes.editBtn}
        >
          Edit
        </OxbowTransparentButton>
      </Grid>
      <Divider className={classes.headerDivider} />
      <Grid container>{component}</Grid>
      {index !== length - 1 ? (
        <Divider variant="fullWidth" className={classes.componentDivider} />
      ) : null}
    </Grid>
  )
}

export const UserAccountInformation = ({
  customisationJourneyData,
  setCustomisationJourneyData,
  personalData,
  setPersonalData
}) => {
  const classes = useStyles()
  const [
    isUserAccountMainInformationEditModeOpen,
    setIsUserAccountMainInformationEditModeOpen
  ] = useState(false)

  const [isPasswordEditModeOpen, setIsPasswordEditModeOpen] = useState(false)
  const [
    isAreasOfInterestEditModeOpen,
    setIsAreasOfInterestEditModeOpen
  ] = useState(false)
  const [activeBlock, setActiveBlock] = useState('')

  const handleActiveBlock = (isEditModeOpened, blockName = '') => {
    if (isEditModeOpened) {
      setActiveBlock('')
    } else setActiveBlock(blockName)
  }

  const userAccountBlocks = [
    {
      name: 'Profile',
      func: name => {
        handleActiveBlock(isUserAccountMainInformationEditModeOpen, name)
        setIsUserAccountMainInformationEditModeOpen(
          !isUserAccountMainInformationEditModeOpen
        )
      },
      component: (
        <UserAccountMainInformation
          isEditModeOpened={isUserAccountMainInformationEditModeOpen}
          setIsUserAccountMainInformationEditModeOpen={
            setIsUserAccountMainInformationEditModeOpen
          }
          handleActiveBlock={handleActiveBlock}
          personalData={personalData}
          setPersonalData={setPersonalData}
          customisationJourneyData={customisationJourneyData}
        />
      )
    },
    {
      name: 'Password',
      func: name => {
        handleActiveBlock(isPasswordEditModeOpen, name)
        setIsPasswordEditModeOpen(!isPasswordEditModeOpen)
      },
      component: (
        <UserAccountPassword
          isEditModeOpened={isPasswordEditModeOpen}
          setIsPasswordEditModeOpen={setIsPasswordEditModeOpen}
          handleActiveBlock={handleActiveBlock}
        />
      )
    },
    {
      name: 'Areas of interest',
      func: name => {
        handleActiveBlock(isAreasOfInterestEditModeOpen, name)
        setIsAreasOfInterestEditModeOpen(!isAreasOfInterestEditModeOpen)
      },
      component: (
        <UserAccountAreasOfInterest
          customisationJourneyData={customisationJourneyData}
          setCustomisationJourneyData={setCustomisationJourneyData}
          isEditModeOpened={isAreasOfInterestEditModeOpen}
          setIsAreasOfInterestEditModeOpen={setIsAreasOfInterestEditModeOpen}
          handleActiveBlock={handleActiveBlock}
        />
      )
    }
  ]

  return (
    <Grid className={classes.accountInformation}>
      {userAccountBlocks.map(({ name, func, component }, index) => {
        return (
          <div
            key={name}
            className={clsx(classes.activeBlock, {
              [classes.inActiveBlock]:
                activeBlock !== '' && name !== activeBlock
            })}
          >
            <UserAccountBlockHeader
              name={name}
              func={() => func(name)}
              component={component}
              length={userAccountBlocks.length}
              index={index}
            />
          </div>
        )
      })}
    </Grid>
  )
}
