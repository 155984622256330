import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Grid,
  Typography,
  Box,
  Divider,
  Accordion,
  AccordionSummary
} from '@material-ui/core'
import IconLabelGroup from 'components/Helpers/Data-Display/IconLabelGroup'
import useStyles from '../../../styles'
import NewsAndInsight from 'components/Helpers/Data-Display/NewsInsight'
import CompaniesByTrends from 'components/Pages/HomePage/CompaniesByTrends'
import SimilarProducts from 'components/Helpers/Data-Display/SimilarProducts'
import { ChipsBtns, ChipsToShow } from 'components/Helpers/ChipsToShow'
import { useNavContext } from 'components/Contexts/NavbarContext'
import PrimaryFunc from 'assets/Logos/ContentLogos/PrimaryFunc.svg'
import Vector from 'assets/Logos/ContentLogos/Vector.svg'
import VectorBulding from 'assets/Logos/ContentLogos/VectorBulding.svg'
import YearFounded from 'assets/Logos/ContentLogos/YearFounded.png'
import VectorStage from 'assets/Logos/ContentLogos/VectorStage.svg'
import Union from 'assets/Logos/ContentLogos/Union.svg'
import clsx from 'clsx'
import { sanitizeUrl } from 'components/Pages/NewProductDetails/utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import analytics from 'utils/analytics'
import GridSection from 'components/Helpers/Data-Display/GridSection'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { getInsightsList } from 'utils/api'
import LockIcon from '@material-ui/icons/Lock'
const Overview = ({
  renderInEditMode,
  productData,
  isActive,
  setFilterValues,
  isFullUser,
  isAuthenticated
}) => {
  const classes = useStyles()
  const { downXs } = useMediaQueries()
  const ref = useRef(null)
  const [scrollBlockHeight, setHeight] = useState(0)
  const { setCurrId, setSignupOpen } = useNavContext()
  const [expanded, setExpanded] = useState('panel1')
  const [expandedPanel2, setExpandedPanel2] = useState('panel2')

  const handleChange = (callback, panel) => (_, isExpanded) => {
    callback(isExpanded ? panel : false)
  }

  const { fields } = productData.company
  const content = {
    content: fields.newsInsights
  }
  const companyInfo = fields

  const companyHq = companyInfo.hq?.map(i => i)
  const companyType = [...productData.companyType]

  const [relevantContent, setRelevantContent] = useState([])
  const [loading, setLoading] = useState(false)

  const getRelevantContent = useCallback(async () => {
    try {
      setLoading(true)
      const params = {
        page: 1,
        pageSize: 5,
        sortOrder: 'desc',
        sortField: 'relevance',
        productId: productData.sys.id
      }
      const { insights } = await getInsightsList(params)
      setRelevantContent(insights)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [productData.sys.id])

  useEffect(() => {
    if (!relevantContent?.length && !loading) {
      getRelevantContent()
    }
  }, [getRelevantContent, relevantContent])

  const officeRegions = productData.mapPOIs.offices
  const implementationRegions = productData.mapPOIs.implementationRegions

  const scrollTo = 'existing-clients-table'

  const summaryDataLength = data => (data && data.length > 0 ? 1 : 0)

  useEffect(() => {
    if (ref.current && ref.current.clientHeight) {
      setHeight(ref.current.clientHeight)
    }
  }, [ref.current])

  const handleClick = useCallback(() => {
    if (!isFullUser) {
      analytics.createEvent({
        category: 'ProductDetails',
        action: 'Section (Locked) clicked',
        label: window.location.pathname.split('/')[2]
      })
      return setSignupOpen(true)
    } else {
      analytics.createEvent({
        category: 'ProductDetails',
        action: 'Section clicked',
        label: window.location.pathname.split('/')[2]
      })
    }
  }, [setSignupOpen, isFullUser])
  if (!productData) return null
  const overviewTab = (
    <>
      <Typography
        variant="subtitle2"
        style={{
          fontWeight: 700
        }}
      >
        {productData.shortDescription}
      </Typography>
      <Box mt={1}>
        {productData.primaryFunction && (
          <Typography variant="h6">
            <img src={PrimaryFunc} alt="" />
            {productData.primaryFunction}
          </Typography>
        )}
        <Typography variant="h6">
          <img src={Vector} alt="" />
          {companyHq}
        </Typography>
        {companyInfo.yearFounded && (
          <Typography variant="h6">
            <img src={YearFounded} alt="" />
            Founded {companyInfo.yearFounded}
          </Typography>
        )}
        {companyType && (
          <Typography variant="h6">
            <img src={VectorBulding} alt="" />
            {companyType}
          </Typography>
        )}
        {companyInfo.companyStage && companyInfo.companyStage.length > 0 && (
          <Typography variant="h6">
            <img src={VectorStage} alt="" />
            {companyInfo.companyStage.map(i => i)}
          </Typography>
        )}
        <span>
          <img src={Union} alt="" />
          <a
            variant="h6"
            href={companyInfo.website}
            target="_blank"
            rel="noopener noreferrer"
            alt="CompanyProfile"
          >
            {sanitizeUrl(companyInfo.website)}
          </a>
        </span>
        <Divider />
        <Typography
          variant="h3"
          style={{
            marginBottom: 15
          }}
        >
          People and offices
        </Typography>
        <Box>
          {productData.company.fields.keyPeople?.length > 0 ? (
            <GridSection
              renderInEditMode={renderInEditMode}
              content={productData.company.fields.keyPeople}
              isEditableWithoutValue={true}
            />
          ) : (
            <Typography variant="h4" className={classes.noDataText}>
              No relevant content
            </Typography>
          )}
        </Box>

        {officeRegions && officeRegions.length > 0 && (
          <Box className={classes.offices}>
            <Typography variant="h4">Offices</Typography>
            <Grid container>
              <ChipsToShow
                selectedItems={officeRegions}
                data={officeRegions}
                isFromJourney={true}
                marginTop="sm"
                editable={false}
                count={5}
                isClickable={true}
                setCurrId={setCurrId}
                isFullUser={isFullUser}
                marginX="top"
                btnLabel="See"
              />
            </Grid>
          </Box>
        )}
        <Divider />
        <Grid container>
          <Box>
            <Typography variant="h3">Existing clients</Typography>
            <>
              {summaryDataLength(productData.detailedClientInformation) > 0 ? (
                <>
                  {productData.detailedClientInformation?.length > 0 && (
                    <Box py={3} className={classes.existingClients}>
                      <IconLabelGroup
                        showAllOptions={true}
                        options={productData.detailedClientInformation}
                        renderInEditMode={renderInEditMode}
                        isEditableWithoutValue={true}
                        linkTo="capabilities"
                        limitTo={3}
                      />

                      <ChipsBtns
                        data={productData.detailedClientInformation}
                        lengthToShow={3}
                        count={productData.detailedClientInformation.length}
                        isClickable={false}
                        scrollTo={scrollTo}
                        setCurrId={setCurrId}
                        navTo="clientsAndActivity"
                        isFullUser={isFullUser}
                        handleClick={handleClick}
                        marginTop="none"
                        btnLabel="See"
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Typography variant="h4" className={classes.noDataText}>
                  No relevant content
                </Typography>
              )}
            </>
          </Box>
        </Grid>
      </Box>

      <Grid container className={classes.valuePropositionContainer}>
        <Grid container className={classes.mainInfoBlocks}>
          {productData.targetClient && (
            <Box>
              <Typography variant="h5">Target clients type</Typography>
              <Grid container>
                <ChipsToShow
                  selectedItems={productData.targetClient}
                  data={productData.targetClient}
                  isFromJourney={true}
                  marginTop="sm"
                  editable={false}
                  isClickable={false}
                  setCurrId={setCurrId}
                  navTo="capabilities"
                  isFullUser={isFullUser}
                  handleClick={handleClick}
                  btnLabel="Show"
                />
              </Grid>
            </Box>
          )}
          {implementationRegions.length > 0 && (
            <Box>
              <Typography variant="h5">Countries of implementations</Typography>
              <Grid container>
                <ChipsToShow
                  selectedItems={implementationRegions}
                  data={implementationRegions}
                  isFromJourney={true}
                  marginTop="sm"
                  editable={false}
                  count={5}
                  isClickable={true}
                  setCurrId={setCurrId}
                  isFullUser={isFullUser}
                  marginX="top"
                  btnLabel="See"
                />
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  )

  return (
    <Grid>
      <Grid container className={classes.mainProductPageContainer}>
        <Grid container className={classes.productPageInfoBlock} ref={ref}>
          <Box
            className={clsx({
              [classes.inActive]: !isActive
            })}
          >
            {downXs ? (
              <Accordion
                expanded={expandedPanel2 === 'panel2'}
                onChange={handleChange(setExpandedPanel2, 'panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h3">Company overview</Typography>
                </AccordionSummary>
                {overviewTab}
              </Accordion>
            ) : (
              overviewTab
            )}
          </Box>
        </Grid>
        <Grid
          container
          className={clsx(classes.companyNewsfeed, {
            [classes.inActive]: !isActive
          })}
        >
          <Typography
            variant="h3"
            style={{
              marginBottom: 11
            }}
          >
            Company newsfeed
          </Typography>
          {content.content && content.content.length > 0 ? (
            <Box
              className={classes.newsInsightsBlock}
              style={{
                height: scrollBlockHeight - 150
              }}
            >
              <NewsAndInsight config={content} data={productData} />
            </Box>
          ) : (
            <Typography
              variant="h4"
              className={classes.noDataText}
              style={{
                paddingTop: 20
              }}
            >
              No relevant content
            </Typography>
          )}

          <div className={classes.bottomGradient}></div>
        </Grid>
      </Grid>

      <Grid container className={classes.overviewCompanies}>
        <Box className={classes.companiesByTrendsBlock}>
          {downXs ? (
            <>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange(setExpanded, 'panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h3">Relevant collections</Typography>
                </AccordionSummary>
                <CompaniesByTrends
                  divider={true}
                  relevantContent={relevantContent}
                  article={`Read collections and articles relating to ${productData.name}`}
                  title="Relevant Magellan content"
                  isBtnVisible={false}
                  loadingCollections={loading}
                />
              </Accordion>
            </>
          ) : (
            <CompaniesByTrends
              divider={true}
              relevantContent={relevantContent}
              article={`Read collections and articles relating to ${productData.name}`}
              title="Relevant Magellan content"
              isBtnVisible={false}
              loadingCollections={loading}
              isAuthenticated={isAuthenticated}
            />
          )}
          {!isAuthenticated && <LockIcon/>}
        </Box>
        <Box className={classes.otherCompanies}>
          <Box>
            <Typography variant="h3">Other companies to explore</Typography>
            <Typography variant="subtitle2">
              Browse companies that do similar things
            </Typography>
          </Box>
          {!isAuthenticated && <LockIcon />}
          {isAuthenticated && <SimilarProducts
            productValues={productData}
            setFilterValues={setFilterValues}
          />}
        </Box>
      </Grid>
    </Grid>
  )
}
export default Overview
