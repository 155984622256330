import React, { useMemo } from 'react'
import { useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingLeft: '50px',
    marginBottom: 0,
    willChange: 'opacity',
    cursor: 'pointer',
    transition: 'border-bottom 0.3s ease, color 0.3s ease',
    '& h4': {
      transition: 'border-bottom 0.3s ease, color 0.3s ease',
      paddingBottom: 30,
      border: '2px solid transparent',
      '&:hover': {
        borderBottom: '2px solid ' + theme.palette.secondary.main,
        color: theme.palette.secondary.main
      }
    },
    '&.inverse': {
      borderBottom: '2px solid ' + theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      '&.checked >h4': {
        color: theme.palette.secondary.main,
        borderBottom: '2px solid ' + theme.palette.secondary.main
      }
    },
    '&.checked': {
      '&>h4': {
        color: theme.palette.secondary.main,
        borderBottom: '2px solid ' + theme.palette.secondary.main
      }
    },
    '&.disabled': {
      pointerEvents: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      '& h4': {
        fontSize: 14,
        paddingBottom: 15
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& h4': {
        paddingBottom: 6
      }
    }
  },
  radioButtonContainer: {
    display: 'flex',
    '&>div': {
      marginBottom: 0,
      '& h4': {
        color: 'white'
      }
    }
  },

  subTitle: {
    fontFamily: theme.typography.secondaryFont,
    lineHeight: 1.25,
    fontSize: 12
  },
  [theme.breakpoints.down('xs')]: {
    radioButtonContainer: {
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
      '&>div': {
        marginTop: 6
      }
    },
    root: {
      paddingLeft: 0
    }
  }
}))

export const RadioButton = ({
  label,
  onChange,
  checked,
  name,
  disabled,
  subTitle,
  inverseStyling,
  labelStyles = {},
  isSavedSearchModalOpen,
  ...restProps
}) => {
  const classes = useStyles(restProps)
  // disable filter buttons when modal is open
  if (isSavedSearchModalOpen) {
    disabled = true
    checked = false
  } else disabled = false

  const handleChange = useCallback(() => {
    onChange(name)
  }, [onChange, name])

  return (
    <div
      className={clsx(classes.root, {
        checked,
        disabled,
        inverse: inverseStyling
      })}
      onClick={!disabled ? handleChange : null}
      {...restProps}
    >
      <Typography variant="h4" style={labelStyles} className={classes.label}>
        {label}
      </Typography>

      {subTitle && (
        <Box mt={1}>
          <Typography variant="subtitle1" className={classes.subTitle}>
            {subTitle}
          </Typography>
        </Box>
      )}
    </div>
  )
}

export function RadioSection({
  children,
  onChange,
  value = {},
  style,
  containerClasses = null,
  inverseStyling = false,
  toggle = false
}) {
  const classes = useStyles()

  const isOtherChecked =
    value && !value['other'] && !value['supplier'] && !value['distributor']

  const radioButtons = useMemo(
    () =>
      React.Children.map(children, child => {
        let props = !toggle
          ? {
              onChange: name => onChange(child.props.name, !value[name]),
              checked:
                value[child.props.name] ||
                (child.props.name === 'other' && isOtherChecked)
            }
          : {
              onChange,
              checked: value === child.props.name
            }
        props = { ...props, inverseStyling }
        const newChild = React.cloneElement(child, props)
        return (
          <Box mb={3} className={containerClasses}>
            {newChild}
          </Box>
        )
      }),
    [
      children,
      toggle,
      value,
      onChange,
      inverseStyling,
      containerClasses,
      isOtherChecked
    ]
  )
  return (
    <div className={classes.radioButtonContainer} style={style}>
      {radioButtons}
    </div>
  )
}
