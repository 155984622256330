import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Container } from '@material-ui/core'
import { BaseLayoutBody } from 'components/Layouts/Base'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useParams } from 'react-router-dom'
import MainHeader from 'components/Helpers/PageHeader'

import { getSingleArticle } from 'utils/api'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import clsx from 'clsx'

import { useStyles } from './useStyles'
import { MemoizedInsightsMainBlock } from './InsightsMainBlock'
import { MemoizedInsightsHeader } from './InsightsHeader'

const InsightsSinglePost = ({ history }) => {
  const classes = useStyles()
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const { adminCollections, getCollectionDetails } = useCollections()
  const { id: articleId, collectionId, collectionType } = useParams()
  const [showStickyNavBar, setShowStickyNavBar] = useState(false)

  const [loading, setLoading] = useState(false)
  const [postData, setPostData] = useState({})

  const getData = useCallback(async () => {
    setLoading(true)
    try {
      if (collectionType === 'oxbow-partners') {
        const data = await getCollectionDetails({ collectionId })
        setPostData(data)
      } else {
        const { article } = await getSingleArticle(articleId)
        setPostData(article)
      }
    } catch (error) {
      console.log('error', error)
    }

    setLoading(false)
  }, [collectionId, articleId, collectionType, getCollectionDetails])

  useEffect(() => {
    getData()
  }, [collectionId, articleId])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [articleId, collectionId])

  return (
    <BaseLayoutBody
      pageHeaderBottomLine={false}
      pageHeader={
        <Box
          className={clsx(classes.productPageMainHeader, {
            relative: showStickyNavBar
          })}
        >
          <>
            <MainHeader backBtn={true} margin="xs">
              <MemoizedInsightsHeader
                postData={postData}
                isAuthenticated={isAuthenticated}
                loading={loading}
                showStickyNavBar={showStickyNavBar}
                setShowStickyNavBar={setShowStickyNavBar}
                history={history}
              />
            </MainHeader>
          </>
        </Box>
      }
      rightPanel={
        <Box className={classes.singlePostPage}>
          {loading || !postData || Object.keys(postData).length === 0 ? (
            <Container
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <CircularProgress />
            </Container>
          ) : (
            <>
              <MemoizedInsightsMainBlock
                postData={postData}
                collectionType={collectionType}
                history={history}
                isAuthenticated={isAuthenticated}
                adminCollections={adminCollections}
                loginWithRedirect={loginWithRedirect}
              />
            </>
          )}
        </Box>
      }
      bottomBanner={false}
    />
  )
}
export default InsightsSinglePost
