import React, { useEffect } from 'react'
import qs from 'query-string'
import { CircularProgress, Container } from '@material-ui/core'
import { useAuth0 } from '../../Contexts/AuthContext'
import { BaseLayoutBody } from 'components/Layouts/Base'
import { validateToken } from 'utils/api'
import { history } from 'Router/history'

export default function ITCPreAuth() {
  const { loginWithRedirect } = useAuth0()
  useEffect(() => {
    const queryParams = qs.parse(window.location.search)
    if (queryParams.jwt) {
      ;(async () => {
        try {
          const validationResult = await validateToken(queryParams.jwt)
          loginWithRedirect({
            appState: { targetUrl: queryParams.redirect || '/search' },
            login_hint: validationResult.user?.email || '',
            passwordless:
              validationResult.user?.user_metadata.passwordless || false
          })
        } catch (err) {
          history.push('/')
        }
      })()
    }
  }, [loginWithRedirect])

  return (
    <BaseLayoutBody
      leftPanel={<></>}
      rightPanel={
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <CircularProgress />
        </Container>
      }
      pageHeader={<></>}
      enableLeftSideNav={true}
    ></BaseLayoutBody>
  )
}
