import React from 'react'
import { Grid, makeStyles, Typography, Box } from '@material-ui/core'
import DefaultImgProductImg from 'assets/Logos/Magellan Logos/DefaultProductImg.png'
import moment from 'moment'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Skeleton } from '@material-ui/lab'
import analytics from 'utils/analytics'

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer'
  },
  insightsMainContent: {
    padding: '0 80px',
    justifyContent: 'space-between',
    marginBottom: 70,
    display: 'flex',
    flexWrap: 'wrap',

    '& a': {
      textDecoration: 'none'
    },

    [theme.breakpoints.up(1920)]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      padding: 0,
      justifyItems: 'center'
    },

    [theme.breakpoints.down('md')]: {
      padding: 0,
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px 32px 6px',
      justifyContent: 'center',
      marginBottom: 8
    }
  },

  insightCard: {
    width: 385,
    margin: '40px 20px 0',
    transition: '0.3s',
    '& h4, h6': {
      color: '#000'
    },
    '& img': {
      width: 385,
      height: 215,
      objectFit: 'cover',
      border: '1px solid #CCC'
    },
    '&:hover': {
      transform: 'scale3d(1.03, 1.03, 1.03)'
    },

    [theme.breakpoints.up(1920)]: {
      margin: '40px auto 0'
    },
    [theme.breakpoints.down(1440)]: {
      margin: '40px 10px 0'
    },
    [theme.breakpoints.down('xs')]: {
      '& >div:first-child': {
        display: 'flex',
        justifyContent: 'center'
      },
      '& img': {
        width: '100%'
      }
    },
    [theme.breakpoints.down(400)]: {
      width: '100%',
      margin: '40px 0 0'
    }
  },
  insightCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '22px 0 24px',

    '& h6': {
      color: '#808080',
      fontSize: 12
    }
  },
  insightCardTitle: {
    textAlign: 'start',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    '& h6 div p span, h6 div h2 strong ': {
      fontSize: '16px !important',
      lineHeight: 'unset !important'
    },
    '& i': {
      fontStyle: 'normal !important'
    },
    '& a': {
      textDecoration: 'none',
      color: '#000',
      pointerEvents: 'none'
    }
  }
}))
const InsightsCard = ({ data, loading, skeletonData, history }) => {
  const classes = useStyles()

  const articleOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <Typography variant="subtitle2" className="align-center">
          {children}
        </Typography>
      )
    },

    renderText: text =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
  }

  const goTo = item => {
    analytics.createEvent({
      category: 'Insights',
      action: 'View',
      label: item.opArticleType,
    });
    history.push({
      pathname: `/insights/${item.id}`,
      state: {
        insights: {
          articleType: item.opArticleType,
          title: item.nameOfArticle,
        }
      }
    })
  }

  const loadingData = skeletonData ? skeletonData : [1, 2, 3, 4, 5, 6]
  return (
    <Grid container className={classes.insightsMainContent}>
      {loading ? (
        <>
          {loadingData.map((_, index) => (
            <div className={classes.insightCard} key={index}>
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.skeleton}
              />

              <div className="card">
                <React.Fragment>
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={385}
                    height={215}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />

                  <Skeleton animation="wave" height={10} width="100%" />
                </React.Fragment>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {data.map(item => (
            <div
              className={classes.link}
              onClick={() => goTo(item)}
              key={item.id}
            >
              <div className={classes.insightCard}>
                <div className={classes.insightCardImage}>
                  <img
                    loading=""
                    style={{
                      objectFit: item.tileImage ? 'cover' : 'contain',
                      border: item.tileImage ? '1px solid #CCC' : 'none'
                    }}
                    src={item.tileImage ? item.tileImage : DefaultImgProductImg}
                    alt="insight"
                  />
                </div>
                <div className={classes.insightCardContent}>
                  <Grid container>
                    <Typography variant="h6">
                      {moment(item.datePublished).format('LL')}
                    </Typography>
                    &nbsp;
                    <Typography variant="h6">
                      &nbsp;by {item.articleAuthor}
                    </Typography>
                  </Grid>

                  <Typography variant="h6">{item.opArticleType}</Typography>
                </div>
                <div className={classes.insightCardTitle}>
                  <Typography variant="h4">{item.nameOfArticle}</Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginTop: 5
                    }}
                  >
                    <Box>
                      {Object.keys(item).length > 0 &&
                        documentToReactComponents(item, articleOptions)}
                    </Box>
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </Grid>
  )
}
export default InsightsCard
