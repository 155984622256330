import { useAuth0 } from 'components/Contexts/AuthContext'
import { useMemo } from 'react'

export const checkv2 = (action, userPermissions, isAuthenticated) => {
  if (
    isAuthenticated &&
    userPermissions &&
    Array.isArray(userPermissions) &&
    userPermissions.length > 0 &&
    userPermissions.includes(action)
  ) {
    return true
  }
  return false
}

const Can = props => {
  const { userPermissions, isAuthenticated } = useAuth0()

  return checkv2(props.perform, userPermissions, isAuthenticated)
    ? props.yes()
    : props.no()
}

export const CanV2 = ({ children, perform }) => {
  const { userPermissions, isAuthenticated } = useAuth0()
  const isAllowed = checkv2(perform, userPermissions, isAuthenticated)
  return children(isAllowed)
}

// export const useCan = permission => {
//   const { userPermissions, isAuthenticated } = useAuth0()
//   return checkv2(permission, userPermissions, isAuthenticated)
// }

export const useCan = permission => {
  const { userPermissions, isAuthenticated } = useAuth0()
  const isAllowed = useMemo(() => {
    return checkv2(permission, userPermissions, isAuthenticated)
  }, [permission, userPermissions, isAuthenticated])
  return isAllowed
}

Can.defaultProps = {
  yes: () => null,
  no: () => null
}

export default Can

/* // How to use it as a component 
<Can
  roles={user.roles}
  perform="dashboard-page:visit"
  yes={() => (
    <h2>User can do it</h2>
  )}
  no={() => <h2>User can't do it</h2>}
/>
*/
