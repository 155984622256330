import React, { useRef } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { useAutocomplete } from '@material-ui/lab'
import { ArrowDropDown } from '@material-ui/icons'
import clsx from 'clsx'
import useStyles from './useStyles'
import { capitalize } from 'utils'
import lo from 'lodash'
import { useOutsideClick } from 'utils/customHooks/useOutsideClickRef'

const SelectComponent = ({
  multipleSelectors = false,
  id,
  selectoptions,
  selectedValue,
  setSelectedValue,
  title,
  border,
  styles,
  mobileSelectStyle,
  marginTop,
  isDisabled,
  spanStyle,
  isFromSearchPage = false,
  selectPlaceholder = '',
  selectedSelector,
  setSelectedSelector = () => {},
  setManageCollectionOpen = () => {},
  selectorRef,
  selectedValueWeight,
  inputWidth,
  placeholder,
  positionRight
}) => {
  const classes = useStyles({
    border,
    mobileSelectStyle,
    marginTop,
    isFromSearchPage,
    selectedValueWeight,
    inputWidth,
    positionRight
  })
  const wrapperRef = useRef()
  const getOptionName = Object.keys(selectoptions).some(
    i => 'name' in selectoptions[i]
  )

  let selectedLabel
  Object.keys(selectoptions).forEach(i => {
    if (lo.isEqual(selectoptions[i].value, selectedValue)) {
      selectedLabel = selectoptions[i].name
    }
  })

  const [isOpen, setIsOpen] = React.useState(false)
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    focused
  } = useAutocomplete({
    options: selectoptions,
    open: multipleSelectors ? id === selectedSelector : isOpen,
    getOptionLabel: option => (getOptionName ? option.name : option.value),
    getOptionSelected: (option, value) => {
      return (
        option &&
        value &&
        (option.name === value.name || option.value === value.value)
      )
    },
    onChange: (_, values) => {
      if (values.disabled || lo.isEqual(selectedValue, values.value))
        return () => {}
      setSelectedValue(values.value ? values.value : values.name)
    },
    onOpen: () => {
      setIsOpen(true)
      if (multipleSelectors) setSelectedSelector(id)
    },

    onClose: () => {
      setIsOpen(false)
      if (multipleSelectors) setSelectedSelector(null)
    }
  })

  useOutsideClick(wrapperRef, setIsOpen)

  const collectionType = type => {
    switch (type) {
      case 'admin':
        return 'Oxbow Partners collection'
      case 'personal':
        return 'Personal collection'
      case 'organisation':
        return 'Organisation collection'
      default:
        return type
    }
  }
  return (
    <Grid
      item
      className={classes.companyTypeContainer}
      ref={selectorRef ? selectorRef : wrapperRef}
    >
      <Typography variant="h4" htmlFor="companyType">
        {title ? title : 'Collection type'}
      </Typography>
      <div className="selectMainBlock">
        <Box position="relative" className="selectBlock">
          <div
            {...getRootProps()}
            ref={setAnchorEl}
            style={{
              pointerEvents: isDisabled && 'none',
              opacity: isDisabled ? 0.5 : 1
            }}
            className={clsx(classes.inputContainer, {
              focused
            })}
          >
            <div
              {...getInputProps()}
              className={classes.inputContainerBlock}
              onClick={() => setManageCollectionOpen(true)}
            >
              <select
                autoComplete={'no'}
                style={{ ...styles, border: 'none', marginTop: '0 ' }}
              />
              <ArrowDropDown fontSize="small" />
            </div>

            {isFromSearchPage ? (
              <Box className={classes.sortByContainer}>
                <Typography variant="h4">
                  {placeholder ? placeholder : 'Sort by'}
                </Typography>
                <Typography
                  className={classes.selectedValue}
                  style={spanStyle}
                  component="span"
                  variant="inherit"
                >
                  {selectedLabel
                    ? selectedLabel
                    : capitalize(selectedValue || '')}
                </Typography>
              </Box>
            ) : (
              <Typography
                className={classes.selectedValue}
                component="span"
                variant="inherit"
              >
                {selectPlaceholder ? (
                  <Typography
                    variant="h4"
                    style={{
                      marginRight: 10
                    }}
                  >
                    {selectPlaceholder}
                  </Typography>
                ) : null}
                {selectedLabel
                  ? selectedLabel
                  : selectedValue
                  ? collectionType(selectedValue)
                  : selectedValue}
              </Typography>
            )}
          </div>
        </Box>
      </div>

      {groupedOptions?.length > 0 && (
        <div style={{ position: 'relative' }}>
          <div className={classes.selectContainer}>
            <ul
              style={{ border: '1px solid #ccc' }}
              {...getListboxProps()}
              className={`${classes.listBox} ${classes.hidden}`}
            >
              {groupedOptions?.map((option, index) => {
                const itemProps = getOptionProps({
                  option,
                  index
                })
                return (
                  <li {...itemProps} key={index} id={index}>
                    <span
                      className={clsx(classes.option, {
                        ['selected']:
                          option.disabled ||
                          lo.isEqual(selectedValue, option.value)
                      })}
                    >
                      {option.name
                        ? capitalize(option.name)
                        : collectionType(option.value)}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
    </Grid>
  )
}
export default SelectComponent
