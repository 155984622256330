// startup
// scale
// established
// lasrgeEnterprise
export const stage = {
  'Start Up':
    'Stage 1 of 4 in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded.',
  'Scale Up':
    'Stage 2 of 4 in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded.',
  Established:
    'Stage 3 of 4 in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded.',
  'Large Enterprise':
    'Stage 4 of 4 in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded.'
}
export const webSite = 'Please enter your company website.'
export const companyStage =
  'Oxbow Partners’ proprietary 4-stage company categorisation based on revenue, FTE and year founded.'
export const shortDescription =
  'Please provide a 1-2 line, impartial, third-person description of what the company does.'
export const liveIn =
  'Please identify all countries where you have a current, live system implementation.'
export const existingClients =
  'Please provide details of clients you are able to disclose. If possible also provide the year and line of business of the implementation.'
export const primaryFunction =
  'If you are a solution provider, please identify the function that most closely fits with how your company helps clients.'
export const distributorType =
  'If you distribute insurance products, please select the option that most closely fits with your business model.'
export const suitableFor =
  'Please select the types of insurer that your solution can help.'
export const businessModel = 'Please indicate how you distribute your products.'
export const relatedTechnologies =
  'Please select the technology trends that are most relevant to your business activities.'
export const unpublishedCollection = 'This collection is unpublished'

// export const stage = {
//     "Start Up" : "<b>Stage 1 of 4</b> in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded.",
//     "Scale Up" : "<b>Stage 2 of 4</b> in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded.",
//     "Established" : "<b>Stage 3 of 4</b> in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded.",
//     "Large Enterprise" : "<b>Stage 4 of 4</b> in Oxbow Partners’ proprietary Company Stage categorisation based on revenue, FTE and year founded."
// }
