import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  animated: {
    visibility: 'hidden',
    transition: props =>
      `opacity 300ms ease-out, transform ${props.animationTime}ms ease-out`,
    willChange: 'opacity, visibility',
    transform:
      'translate3d(0px, 20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
    opacity: 0
  },
  visible: {
    visibility: 'visible',
    opacity: '1 ',
    transform:
      'translate3d(0px, 0, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
  }
}))
