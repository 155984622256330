/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from 'react'
import { getAxiosInstance, isAuthTokenPresent } from 'utils/axios'
import { useAuth0 } from './AuthContext'
import { useNavContext } from './NavbarContext'
import analytics from 'utils/analytics'
import CollectionsApi from '../ApiClient/Collections'

export const CollectionsContext = React.createContext({})
export const useCollections = () => useContext(CollectionsContext)

export const CollectionsProvider = ({ children }) => {
  const { isAuthenticated, userPermissions } = useAuth0()
  const { setMyFavCollectionsLink } = useNavContext()
  const isAuthCollectionsCallComplete = React.useRef(false)
  const initialBootstrapComplete = React.useRef(false)
  const [userType, setUserType] = useState('')
  const [myFavId, setMyFavId] = useState(null)
  const [myFavCollection, setMyFavCollection] = useState({})
  const [error, setError] = useState(false)
  const [collections, setCollections] = useState([])
  const [specialAccessibleProducts, setSpecialAccessibleProducts] = useState([])
  const [collectionTags, setCollectionTags] = useState([])
  const [orgCollectionTags, setOrgCollectionTags] = useState([])
  const [adminCollectionTags, setAdminCollectionTags] = useState([])
  const [orgCollections, setOrgCollections] = useState([])
  const [adminCollections, setAdminCollections] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [products, setProducts] = useState([])
  const [orgProducts, setOrgProducts] = useState([])
  const [adminProducts, setAdminProducts] = useState([])
  const [loadingCollections, setLoadingCollections] = useState(false)
  const [addCollectionLoading, setAddCollectionLoading] = useState(false)
  const [canUseOrgCollections, setCanUseOrgCollections] = useState(false)
  const [bootstrappingCollections, setBootstrappingCollections] = useState(
    false
  )
  const [resStatus, setResStatus] = useState('')
  const isMagellanCollectionsPage = window.location.pathname.startsWith(
    '/collections/oxbow-partners'
  )
  const isAdminUser = userType === 'admin'
  const [collectionDetails, setCollectionDetails] = useState(null)
  const [collectionEditedName, setCollectionEditedName] = useState('')

  useEffect(() => {
    const bootstrapCollections = async () => {
      if (!isAuthenticated && !isMagellanCollectionsPage) return
      setBootstrappingCollections(true)
      if (userPermissions.includes('use:org-collections')) {
        setCanUseOrgCollections(true)
      }
      await getCollectionsOfThisUser({ isAuthenticated })
      setBootstrappingCollections(false)
    }
    bootstrapCollections()
  }, [isMagellanCollectionsPage, isAuthenticated]) //eslint-disable-line

  const updateCollectionHelper = async (
    res,
    _tokenRaw = null,
    dontRender = false,
    isAuthTokenPresentThisTime = 'yes'
  ) => {
    setLoadingCollections(true)
    let respCol = {}
    let respOrgCol = {}
    let respAdminCol = {}
    let allProducts = []
    let adminProducts = []
    let products = []
    let orgProducts = []

    if (res && res.data) {
      if (res.data.type === 'personal') {
        respCol = {}

        if (res.data.name === 'My Favourites') {
          setMyFavCollectionsLink(
            `/collections/personal/${res.data.magellan_id}`
          )
          setMyFavId(res.data.magellan_id)
        }

        respCol[res.data.magellan_id] = res.data
      }

      if (res.data.type === 'organisation') {
        respOrgCol = {}

        respOrgCol[res.data.magellan_id] = res.data
      }

      if (res.data.type === 'admin') {
        respAdminCol = {}
        let adminProdsSpecial = []

        respAdminCol[res.data.magellan_id] = res.data

        setSpecialAccessibleProducts(adminProdsSpecial)
      }
    }

    if (res.data._err) {
      throw new Error('Failed!')
    }

    if (
      initialBootstrapComplete.current &&
      isAuthTokenPresentThisTime === 'no'
    ) {
      return
    }

    if (isAuthTokenPresentThisTime === 'yes') {
      initialBootstrapComplete.current = true
    }

    setError(false)

    if (!dontRender) {
      setAllProducts(allProducts)
    }
    setError(false)

    setAdminCollections(prev => ({ ...prev, ...respAdminCol }))
    setOrgCollections(prev => ({ ...prev, ...respOrgCol }))
    setCollections(prev => ({ ...prev, ...respCol }))
    setAdminProducts(prev => ({ ...prev, ...adminProducts }))
    setOrgProducts(prev => ({ ...prev, ...orgProducts }))
    setProducts(prev => ({ ...prev, ...products }))
  }

  const setMyFav = async collection => {
    setMyFavCollectionsLink(`/collections/personal/${collection.magellan_id}`)
    const myFavResponse = await CollectionsApi.get(collection.magellan_id)
    setMyFavCollection(myFavResponse.data)
    setMyFavId(collection.magellan_id)
  }

  const collectionsResponseHelper = async (
    res,
    _tokenRaw = null,
    dontRender = false,
    setTags = false,
    isAuthTokenPresentThisTime = 'yes'
  ) => {
    setLoadingCollections(true)
    let respCol = {}
    let respOrgCol = {}
    let respAdminCol = {}
    let allProducts = []
    let adminProducts = []
    let products = []
    let orgProducts = []
    if (
      res &&
      res.data &&
      Array.isArray(res.data.collections) &&
      res.data.collections.length > 0
    ) {
      respCol = {}

      res.data.collections.forEach(collection => {
        if (
          collection.name === 'My Favourites' &&
          collection.magellan_id !== myFavId
        ) {
          setMyFav(collection)
        }

        respCol[collection.magellan_id] = collection
      })
    }
    if (
      res &&
      res.data &&
      Array.isArray(res.data.orgCollections) &&
      res.data.orgCollections.length > 0
    ) {
      respOrgCol = {}

      res.data.orgCollections.forEach(collection => {
        respOrgCol[collection.magellan_id] = collection
      })
    }
    if (
      res &&
      res.data &&
      Array.isArray(res.data.adminCollections) &&
      res.data.adminCollections.length > 0
    ) {
      respAdminCol = {}
      let adminProdsSpecial = []
      res.data.adminCollections.forEach(collection => {
        respAdminCol[collection.magellan_id] = collection
      })
      setSpecialAccessibleProducts(adminProdsSpecial)
    }

    if (res.data._err) {
      throw new Error('Failed!')
    }

    if (
      initialBootstrapComplete.current &&
      isAuthTokenPresentThisTime === 'no'
    ) {
      return
    }

    if (isAuthTokenPresentThisTime === 'yes') {
      initialBootstrapComplete.current = true
    }

    setError(false)

    if (!dontRender) {
      setAllProducts(allProducts)
    }
    setError(false)
    if (setTags) {
      setAdminCollectionTags(
        res.data.adminCollectionTags
          ? res.data.adminCollectionTags.length
            ? res.data.adminCollectionTags[0].tags
              ? res.data.adminCollectionTags[0].tags
              : []
            : []
          : []
      )
      setOrgCollectionTags(
        res.data.orgCollectionTags
          ? res.data.orgCollectionTags.length
            ? res.data.orgCollectionTags[0].tags
              ? res.data.orgCollectionTags[0].tags
              : []
            : []
          : []
      )
      setCollectionTags(
        res.data.collectionTags
          ? res.data.collectionTags.length
            ? res.data.collectionTags[0].tags
              ? res.data.collectionTags[0].tags
              : []
            : []
          : []
      )
    }
    setCollections(respCol)
    setAdminCollections(respAdminCol)
    setOrgCollections(respOrgCol)
    setProducts(products)
    setAdminProducts(adminProducts)
    setOrgProducts(orgProducts)
    setUserType(res.data.userType)
  }

  const getCollectionsOfThisUser = async ({ isAuthenticated }) => {
    try {
      setLoadingCollections(true)
      let isAuthTokenPresentThisTime = isAuthTokenPresent()

      let finalResult = await CollectionsApi.list()

      if (
        isAuthTokenPresentThisTime &&
        !isAuthCollectionsCallComplete.current
      ) {
        isAuthCollectionsCallComplete.current = true
      }

      if (
        !isAuthTokenPresentThisTime &&
        isAuthCollectionsCallComplete.current
      ) {
        return
      }

      if (!isAuthTokenPresentThisTime && isAuthenticated) {
        return
      }

      await collectionsResponseHelper(
        finalResult,
        null,
        false,
        true,
        isAuthTokenPresentThisTime ? 'yes' : 'no'
      )
      setLoadingCollections(false)
    } catch (err) {
      console.error(err)
    }
  }

  const getCollectionDetails = async ({ collectionId }) => {
    if (collectionId) {
      setLoadingCollections(true)
      try {
        const collection = await CollectionsApi.get(collectionId)
        setCollectionDetails({
          ...collection.data,
          products: collection.data.products.filter(product => product)
        })
        return collection.data
      } catch (err) {
        console.error(err)
      } finally {
        setLoadingCollections(false)
      }
    }
  }

  // #region TODO Tags
  const updateEntireCollectionTags = async (
    user,
    tagsArray,
    idsToDelete,
    collectionId,
    type
  ) => {
    // setLoadingCollections(true)
    //const headers = {
    //   "Content-Type": "application/json",
    // };
    await getAxiosInstance()
      .post(CollectionsApi.COLLECTIONS_API, {
        productEvent: {
          adminCollection: type === 'admin',
          isShared: type === 'organisation',
          collectionId,
          tagsArray,
          tagIdsToDelete: idsToDelete,
          type: 'updateEntireCollectionTags'
        }
      })
      .then(async res => {
        await collectionsResponseHelper(res, null, false, true)
        setLoadingCollections(false)
        analytics.createEvent({
          category: 'Collections',
          action: 'Update Entire Collection Tags',
          label:
            type === 'admin'
              ? 'OP'
              : type === 'organisation'
              ? 'Organisation'
              : 'Personal'
        })
        return true
      })
      .catch(_err => {
        setError(true)
        setLoadingCollections(false)
      })
  }
  const updateEntireCollectionListTags = async (
    user,
    tagsArray,
    idsToDelete,
    type
  ) => {
    // setLoadingCollections(true)
    //const headers = {
    //   "Content-Type": "application/json",
    // };
    // TODO
    await getAxiosInstance()
      .post(CollectionsApi.COLLECTIONS_API, {
        productEvent: {
          adminCollection: type === 'admin',
          isShared: type === 'organisation',
          tagsArray,
          tagIdsToDelete: idsToDelete,
          type: 'updateEntireCollectionListTags'
        }
      })
      .then(async res => {
        await collectionsResponseHelper(res, null, false, true)
        setLoadingCollections(false)
        analytics.createEvent({
          category: 'Collections',
          action: 'Update Entire Collection List Tags',
          label:
            type === 'admin'
              ? 'OP'
              : type === 'organisation'
              ? 'Organisation'
              : 'Personal'
        })
      })
      .catch(_err => {
        setError(true)
        setLoadingCollections(false)
      })
  }

  // #endregion

  const getCollectionType = collectionType => {
    if (collectionType === 'admin' && userType === 'admin') {
      return [adminCollections, setAdminCollections]
    } else if (collectionType === 'organisation') {
      return [orgCollections, setOrgCollections]
    } else {
      return [collections, setCollections]
    }
  }

  const addProductToCollectionsForThisUser = async ({
    productId,
    collectionId,
    collectionType,
    dontRender = false,
    data
  }) => {
    if (!dontRender) {
      setLoadingCollections(true)
    }
    try {
      if (!dontRender) {
        setAddCollectionLoading(true)
      }
      const response = await CollectionsApi.addProduct(
        collectionId,
        productId,
        data
      )
      if (collectionId === myFavId) {
        setMyFav({ magellan_id: collectionId })
      }

      if (error) {
        const result = await CollectionsApi.list()
        await collectionsResponseHelper(result, null, dontRender)
      } else {
        if (
          collectionDetails &&
          collectionDetails.magellan_id === collectionId
        ) {
          const isIncludesResId = collectionDetails.products.some(
            p => p.id === response.data.productsDelta.added[0].id
          )

          if (isIncludesResId) {
            setCollectionDetails({
              ...collectionDetails,
              products: [...collectionDetails.products]
            })
          } else {
            setCollectionDetails({
              ...collectionDetails,
              products: [
                ...collectionDetails.products,
                ...response.data.productsDelta.added
              ]
            })
          }
        }

        let [collection, collectionToSet] = getCollectionType(collectionType)
        collectionToSet({
          ...collection,
          [collectionId]: {
            ...collection[collectionId],
            products: [
              ...collection[collectionId].products,
              ...response.data.productsDelta.added.map(p => ({
                id: p.id,
                slug: p.slug
              }))
            ]
          }
        })
      }
    } catch (err) {
      setError(true)
      console.error(err)
    } finally {
      if (!error) {
        if (dontRender) {
          let allProductsArray = [...allProducts]
          allProductsArray.push({
            collectionId,
            prodId: productId,
            collectionType
          })
          setAllProducts(allProductsArray)
        }

        analytics.createEvent({
          category: 'Collections',
          action: 'Add Product To Collections For A User',
          label: collectionType
        })
      }
      setLoadingCollections(false)
      setAddCollectionLoading(false)
    }
  }

  const removeProductFromCollectionsForThisUser = async ({
    collectionId,
    collectionType,
    productId,
    dontRender
  }) => {
    if (!dontRender) {
      setLoadingCollections(true)
    }

    try {
      const response = await CollectionsApi.removeProduct(
        collectionId,
        productId
      )
      if (collectionId === myFavId) {
        setMyFav({ magellan_id: collectionId })
      }
      if (error) {
        const result = await CollectionsApi.list()
        await collectionsResponseHelper(result, null, dontRender)
      } else {
        if (
          collectionDetails &&
          collectionDetails.magellan_id === collectionId
        ) {
          setCollectionDetails(prev => ({
            ...prev,
            products: prev.products.filter(
              p => !response.data.productsDelta.removed.includes(p.id)
            )
          }))
        }

        let [collection, collectionToSet] = getCollectionType(collectionType)
        collectionToSet({
          ...collection,
          [collectionId]: {
            ...collection[collectionId],
            products: collection[collectionId].products.filter(
              p => !response.data.productsDelta.removed.includes(p.id)
            )
          }
        })
        analytics.createEvent({
          category: 'Collections',
          action: 'Remove Product From Collections For A User',
          label: collectionType
        })
      }
    } catch (err) {
      setError(err)
    } finally {
      if (!error && dontRender) {
        const allProductsArray = [...allProducts]
        const resArray = allProductsArray.filter(
          prod =>
            prod.collectionId !== collectionId || prod.prodId !== productId
        )
        setAllProducts(resArray)
      }
      setLoadingCollections(false)
    }
  }

  const addNewCollection = async ({
    collectionName,
    description,
    thumbnail,
    imgType,
    published,
    type
  }) => {
    setLoadingCollections(true)
    try {
      const res = await CollectionsApi.create({
        type,
        collectionName: collectionName.trim(),
        description,
        thumbnail,
        imgType,
        published
      })
      const response = await CollectionsApi.list()
      await collectionsResponseHelper(response, null, false)
      setResStatus(response.status)
      setLoadingCollections(false)
      analytics.createEvent({
        category: 'Collections',
        action: 'Create Collection',
        label: type
      })
      return res.data
    } catch (err) {
      console.error(err)
      setError(true)
      setLoadingCollections(false)
    }
  }

  const copyCollection = async ({ collectionId, destination }) => {
    setLoadingCollections(true)
    try {
      await CollectionsApi.copy(collectionId, destination)
      const response = await CollectionsApi.list()
      await collectionsResponseHelper(response, null, false)
      setResStatus(response.status)
      setLoadingCollections(false)
      analytics.createEvent({
        category: 'Collections',
        action: 'Copy Collection',
        label: destination
      })
    } catch (err) {
      console.log(err)
      setError(true)
      setLoadingCollections(false)
    }
  }

  const editCollectionType = async ({ collectionId, destination }) => {
    try {
      await CollectionsApi.update(collectionId, {
        destination
      })
      const response = await CollectionsApi.list()
      await collectionsResponseHelper(response, null, false)
      setLoadingCollections(false)
      analytics.createEvent({
        category: 'Collections',
        action: 'Move Collection',
        label: destination
      })
    } catch (err) {
      console.log(err)
      setError(true)
      setLoadingCollections(false)
    }
  }

  const updateCollection = async ({ collectionId, data }) => {
    setLoadingCollections(true)
    try {
      const response = await CollectionsApi.update(collectionId, data)

      // setCollectionDetails({
      //   ...collectionDetails
      // })
      setCollectionDetails(prev => ({
        ...prev,
        products: [
          ...prev.products.filter(p => !response.data.products.includes(p.id))
        ]
      }))
      await updateCollectionHelper(response, null, false)
      setLoadingCollections(false)
      analytics.createEvent({
        category: 'Collections',
        action: 'Update Collection',
        label:
          data.type === 'admin'
            ? 'OP'
            : data.type === 'organisation'
            ? 'Organisation'
            : 'Personal'
      })
      return response
    } catch (err) {
      console.error(err)
      setError(true)
      setLoadingCollections(false)
    }
  }

  const removeThisCollection = async ({ collectionId, type }) => {
    setLoadingCollections(true)
    try {
      await CollectionsApi.delete(collectionId)
      const response = await CollectionsApi.list()
      await collectionsResponseHelper(response, null, false)
      setLoadingCollections(false)
      analytics.createEvent({
        category: 'Collections',
        action: 'Remove Collection',
        label: type
      })
    } catch (err) {
      console.error(err)
      setError(true)
      setLoadingCollections(false)
    }
  }

  const updateCollectionsOrder = async ({
    reorderedCollections,
    selectedCollectionType
  }) => {
    setLoadingCollections(true)
    try {
      await CollectionsApi.order(selectedCollectionType, reorderedCollections)
      const response = await CollectionsApi.list()
      await collectionsResponseHelper(response, null, false)
      setLoadingCollections(false)
      analytics.createEvent({
        category: 'Collections',
        action: 'Update Collections Order',
        label: selectedCollectionType
      })
    } catch (err) {
      setError(true)
      setLoadingCollections(false)
    }
  }

  return (
    <CollectionsContext.Provider
      value={{
        collections,
        products,
        allProducts,
        adminCollections,
        adminProducts,
        myFavId,
        myFavCollection,
        orgCollections,
        orgProducts,
        userType,
        addNewCollection,
        removeThisCollection,
        editCollectionType,
        getCollectionsOfThisUser,
        updateEntireCollectionListTags,
        updateEntireCollectionTags,
        adminCollectionTags,
        orgCollectionTags,
        collectionTags,
        error,
        addProductToCollectionsForThisUser,
        specialAccessibleProducts,
        removeProductFromCollectionsForThisUser,
        loadingCollections,
        setCollections,
        canUseOrgCollections,
        setAdminCollections,
        setOrgCollections,
        updateCollectionsOrder,
        addCollectionLoading,
        bootstrappingCollections,
        resStatus,
        setResStatus,
        updateCollection,
        copyCollection,
        collectionDetails,
        setCollectionDetails,
        getCollectionDetails,
        collectionEditedName,
        setCollectionEditedName,
        isAdminUser
      }}
    >
      {children}
    </CollectionsContext.Provider>
  )
}
