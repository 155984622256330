import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Divider,
  CircularProgress
} from '@material-ui/core'
import { useStyles } from './useStyles'
import { Link } from 'react-router-dom'
import { OxbowPrimaryButton } from 'components/Helpers/Buttons'
import HalfCircle from 'assets/icons/HalfCircle.svg'

const CompaniesByTrends = ({
  style = {},
  isBtnVisible,
  divider,
  loadingCollections,
  relevantContent,
  article,
  title,
  isAuthenticated,
}) => {
  const classes = useStyles()

  return (
    <Box>
      <Typography variant="h3">{title}</Typography>
      <Grid>
        {article && <Typography variant="subtitle2">{article}</Typography>}
        {isAuthenticated && (<>
          {relevantContent.length > 0 ? (
            <Box py={3} style={style}>
              {loadingCollections ? (
                <CircularProgress />
              ) : (
                <>
                  {relevantContent.map(collection => (
                    <Box key={collection.id}>
                      <Link
                        to={{
                          pathname: `/insights/${collection.id}`
                        }}
                        className={classes.link}
                      >
                        <Grid
                          key={collection.id}
                          container
                          alignItems="center"
                          wrap="nowrap"
                          className={classes.collectionsBlock}
                        >
                          <Box
                            position="relative"
                            className={classes.companiesImgContainer}
                          >
                            <img
                              alt="Collection"
                              src={
                                collection.tileImage
                                  ? collection.tileImage
                                  : HalfCircle
                              }
                            />
                          </Box>

                          <Typography variant="subtitle2" component="p">
                            {collection.nameOfArticle}
                          </Typography>
                        </Grid>
                      </Link>
                      {divider && (
                        <Divider
                          variant="fullWidth"
                          style={{
                            margin: '20px 0'
                          }}
                        />
                      )}
                    </Box>
                  ))}
                </>
              )}
            </Box>
          ) : (
            <Typography
              variant="h4"
              style={{
                fontWeight: 400,
                opacity: 0.5,
                padding: '20px 0'
              }}
            >
              Not currently included in any Oxbow Partners collections
            </Typography>
          )}
        </>)}
      </Grid>
      {loadingCollections || !isBtnVisible ? null : (
        <Link to="/insights">
          <OxbowPrimaryButton
            fontSize="default"
            height="lg"
            disabled={loadingCollections}
          >
            View all collections
          </OxbowPrimaryButton>
        </Link>
      )}
    </Box>
  )
}
export default CompaniesByTrends
