import { Box, Grid, withStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { useNavContext } from 'components/Contexts/NavbarContext'
import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import useMediaQueries from 'utils/customHooks/useMediaQueries'
import { NavAuthButtons } from './NavAuthButtons'
import NavbarContent from './NavbarContent'
import { useStyles } from './useStyles'
import { useAuth0 } from 'components/Contexts/AuthContext'
import { useFilterStateContext } from 'components/Contexts/FilterStateContext'
import Magellan_logo_white from 'assets/Logos/Magellan Logos/magellan-logo-white.svg'

import LandingPageNavBar from 'components/Pages/LandingPage/LandingPageNavBar'
import LandingModal from 'components/Pages/LandingPage/LandingModal'
import { BackBtn } from 'components/Pages/Collections/components/BackBtn'
import { useCollections } from 'components/Contexts/CollectionAPIContext'
import ContactUsModal from 'components/Pages/PricingPage/Modals/ContactusModal'
import { useHomePageContext } from 'components/Contexts/HomePageContext'
import { useQueryStringValues } from 'components/Contexts/QueryStringContext'

const MagellanAppBar = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    borderBottomLeftRadius: 50,
    padding: '24px 100px 0',
    [theme.breakpoints.up(1920)]: {
      padding: '24px 280px 0'
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 6, 0, 4)
    },

    [theme.breakpoints.down(1160)]: {
      padding: '24px 10px 0 48px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important'
    }
  }
}))(AppBar)

// Navbar Component
export function Navbar({ excludeRoutes = [] }) {
  const history = useHistory()
  const classes = useStyles()
  const {
    isSavedSearchModalOpen,
    setSavedSearchModalOpen
  } = useFilterStateContext()
  const { isAuthenticated } = useAuth0()
  const { downSm: isMobileDevice } = useMediaQueries()
  const {
    toggleDrawer,
    drawerOpen,
    setDemoFormOpen,
    isThxModalOpen,
    setThxModalOpen,
    isDemoFormOpen,
    visibleSection,
    setVisibleSection,
    landingNavButtons,
    setCurrId,
    modalData,
    showStickyNav
  } = useNavContext()
  const { collectionDetails, myFavId, isAdminUser } = useCollections()
  let location = useLocation()
  const {
    setSelectedCollectionTag,
    setIsFromCollectionPage,
    setSelectedContentType
  } = useQueryStringValues()
  // const isLandingPage = window.location.pathname === '/about-magellan'
  // const isCollectionPage = window.location.pathname.includes('/collections')
  // const searchPage = window.location.pathname === '/search'
  // const isProductPage = window.location.pathname.includes('/product')
  const { contactUsModalOpen, setContactUsModalOpen } = useHomePageContext()

  // const [lastScrollY, setLastScrollY] = useState(0)

  const isCollectionPageSelected = () => {
    const selectedPage = window.location.pathname.split('/')[3]
    if (!myFavId || !selectedPage) return false
    let isCollectionSelected = false

    if (
      selectedPage.includes('collection') &&
      selectedPage !== myFavId &&
      isAdminUser &&
      (collectionDetails?.type === 'admin' ||
        collectionDetails?.type === 'organisation')
    ) {
      isCollectionSelected = true
    } else isCollectionSelected = false

    return isCollectionSelected
  }

  const isBackBtnVisible = isCollectionPageSelected()

  // const controlNavbar = useCallback(() => {
  //   let heightToHideFrom = 130
  //   const winScroll =
  //     document.body.scrollTop || document.documentElement.scrollTop

  //   if (typeof window !== 'undefined') {
  //     if (winScroll > heightToHideFrom) {
  //       setShow(false)
  //       setPaddingBottom(false)
  //     } else {
  //       setShow(true)
  //       setPaddingBottom(true)
  //     }
  //     setLastScrollY(window.scrollY)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('scroll', controlNavbar)
  //     return () => {
  //       window.removeEventListener('scroll', controlNavbar)
  //     }
  //   } else {
  //     return () => {}
  //   }
  // }, [lastScrollY, controlNavbar])

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     if (window.location.pathname.includes('/collections')) {
  //       setSearchBarEnabled(true)
  //       setPaddingBottom(true)
  //     } else {
  //       setSearchBarEnabled(false)
  //       setPaddingBottom(false)
  //     }
  //   } else {
  //     if (window.location.pathname === '/home') {
  //       setPaddingBottom(true)
  //     } else setPaddingBottom(false)
  //     if (searchPage || window.location.pathname === '/home') {
  //       setSearchBarEnabled(true)
  //     } else setSearchBarEnabled(false)
  //   }
  // }, [isAuthenticated, searchPage])

  useEffect(() => {
    if (
      (window.location.pathname !== '/insights' ||
        !window.location.pathname.includes('insights')) &&
      setSelectedContentType !== 'All article types'
    ) {
      setSelectedContentType('All article types')
      setIsFromCollectionPage(false)
      setSelectedCollectionTag('')
    }
  }, [
    setIsFromCollectionPage,
    setSelectedContentType,
    setSelectedCollectionTag
  ])

  // useEffect(() => {
  //   setNavHeight(height)
  // }, [height, setNavHeight])

  if (excludeRoutes.includes(location.pathname)) {
    return null
  }

  return (
    <MagellanAppBar
      position="static"
      style={{
        backgroundColor: 'transparent'
      }}
    >
      <Toolbar
        className={clsx(classes.toolbar, {
          small: true
        })}
      >
        {!isMobileDevice && (
          <>
            {!isAuthenticated ? (
              <LandingPageNavBar
                toggleDrawer={toggleDrawer}
                visibleSection={visibleSection}
                setVisibleSection={setVisibleSection}
                landingNavButtons={landingNavButtons}
                setDemoFormOpen={setDemoFormOpen}
                setCurrId={setCurrId}
              />
            ) : (
              <>
                <NavbarContent showStickyNav={showStickyNav} />
                <div
                  style={{
                    marginLeft: isMobileDevice ? 'auto' : null,
                    display: 'flex'
                  }}
                >
                  {/* Auth buttons on desktop */}
                  <NavAuthButtons />
                </div>
              </>
            )}
          </>
        )}

        {/* Hamburger Icon */}
        {isMobileDevice && (
          <Box className={classes.mobileNavMenu}>
            <Link to="/home">
              <img
                src={Magellan_logo_white}
                alt="OxbowLogo"
                className={classes.navbarLogo}
              />
            </Link>
            <IconButton
              edge="start"
              className={classes.largeButton}
              color="inherit"
              aria-label="menu"
              onClick={() => {
                {
                  isSavedSearchModalOpen && setSavedSearchModalOpen(false)
                }
                toggleDrawer()
              }}
            >
              {drawerOpen ? (
                <CloseIcon className={classes.largeIcon} />
              ) : (
                <MenuIcon className={classes.largeIcon} />
              )}
            </IconButton>
          </Box>
        )}
        {!isAuthenticated && (
          <LandingModal
            modalData={modalData}
            setDemoFormOpen={setDemoFormOpen}
            isThxModalOpen={isThxModalOpen}
            setThxModalOpen={setThxModalOpen}
            isDemoFormOpen={isDemoFormOpen}
            submissionType={3}
            modalWindowWidth="lg"
            modalWindowPadding="lg"
          />
        )}
      </Toolbar>
      {isBackBtnVisible ? (
        <Grid container direction="column">
          <BackBtn
            title="Back to previous page"
            onClick={() => history.goBack()}
            selectedCollectionType={collectionDetails && collectionDetails.type}
            history={history}
          />
        </Grid>
      ) : null}
      {contactUsModalOpen && (
        <ContactUsModal
          open={contactUsModalOpen}
          setContactUsModalOpen={setContactUsModalOpen}
        />
      )}
    </MagellanAppBar>
  )
}
